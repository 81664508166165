import { FieldFilterMenuProps } from '../FieldFilterChip'
import { RecordFilter } from '@indigohive/cogfy-types'
import { getFilterOperator } from '../methods'
import { useTranslation } from 'react-i18next'

const selectOptions = [
  { value: '', label: '', disabled: true },
  { value: 'hasError', label: 'has error' },
  { value: 'hasNoError', label: 'has no error' },
  { value: 'isPending', label: 'is pending' },
  { value: 'notPending', label: 'is not pending' }
]

export function VectorFilterMenu (props: FieldFilterMenuProps) {
  const { controller, field, filter, viewFilter } = props

  const { t } = useTranslation()
  const operator = getFilterOperator(filter, field) ?? ''

  const onUpdateFilterValue = (newOperator: string) => {
    const calculatedNewOperator = calculateOperator(newOperator)
    const newValue = calculateFilterValue(newOperator)

    const newFilter = {
      property: field.id,
      propertyType: 'vector',
      [calculatedNewOperator]: newValue
    }

    controller.onViewFilterChange(viewFilter, newFilter as RecordFilter)
  }

  return (
    <div className="space-y-2 p-2">
      <div className="flex items-center gap-x-2">
        <div className="whitespace-nowrap">{field.name}</div>
        <select
          value={operator}
          onChange={event => onUpdateFilterValue(event.target.value)}
          className="select select-xs select-ghost max-w-xs w-full"
        >
          {selectOptions.map(option => (
            <option
              key={option.value}
              value={option.value}
            >
              {t(`filters:${option.label}`)}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

function calculateOperator (operator: string) {
  const newOperator = operator === 'hasNoError'
    ? 'hasError'
    : operator === 'notPending'
      ? 'isPending'
      : operator ?? 'isPending'

  return newOperator
}

function calculateFilterValue (operator: string) {
  const newValue = operator !== ''
    ? (operator === 'hasError' || operator === 'isPending')
    : undefined

  return newValue
}
