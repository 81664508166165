import { format, parseISO } from 'date-fns'

export type ChatDateProps = {
  sendDate: string
}

export function ChatDate (props: ChatDateProps) {
  const { sendDate } = props

  return (
    <div className="flex justify-center my-4">
      <div className="px-4 py-2 bg-neutral rounded-lg text-sm text-neutral-content font-semibold">
        {format(parseISO(sendDate), 'dd/MM/yyyy')}
      </div>
    </div>
  )
}
