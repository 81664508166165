import clsx from 'clsx'
import { useDrag, useDragDropManager, useDrop } from 'react-dnd'
import { CollectionState, useActiveViewId } from '../../../../../lib'
import { useEffect } from 'react'
import { getEmptyImage } from 'react-dnd-html5-backend'

const EDIT_FIELD_DRAWER_HANDLER = 'EditFieldDrawerResizeHandler'

export type EditFieldDrawerResizeHandlerProps = {
  state: CollectionState
  onDrag?: (change: number) => void
  onDragStop?: () => void
}

export function EditFieldDrawerResizeHandler (props: EditFieldDrawerResizeHandlerProps) {
  const { state, onDrag, onDragStop } = props

  const viewId = useActiveViewId(state)
  const dragDropManager = useDragDropManager()
  const monitor = dragDropManager.getMonitor()

  const [, drop] = useDrop({
    accept: EDIT_FIELD_DRAWER_HANDLER,
    drop: (_, monitor) => {
      if (monitor.didDrop()) {
        return
      }

      onDragStop?.()
    }
  }, [viewId, onDragStop])

  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: EDIT_FIELD_DRAWER_HANDLER,
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  }, [viewId])

  useEffect(() => {
    if (!isDragging) {
      return
    }

    monitor.subscribeToOffsetChange(() => {
      const offset = monitor.getDifferenceFromInitialOffset()
      const itemType = monitor.getItemType()

      if (offset && itemType === EDIT_FIELD_DRAWER_HANDLER) {
        onDrag?.(offset.x)
      }
    })
  }, [viewId, isDragging, monitor, onDrag])

  useEffect(() => {
    drop(document.body)

    return () => {
      drop(null)
    }
  }, [])

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true })
  }, [dragPreview])

  return (
    <div
      ref={drag}
      className={clsx(
        'w-[5px]',
        'transition-colors',
        'cursor-col-resize',
        'active:bg-info',
        'hover:bg-info',
        'h-[calc(100%+2px)]',
        'top-[-1px]',
        'right-[-3px]',
        'z-10'
      )}
    />
  )
}
