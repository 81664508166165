import axios from 'axios'
import { v4 as uuid } from 'uuid'
import { FileFieldData, GetCollectionResult, UUID } from '@indigohive/cogfy-types'
import { useMutation, useQuery } from '@tanstack/react-query'
import { CollectionTable, CollectionTableOverlay, CollectionTabs, CollectionToolbar, CreateFieldMenu, EditFieldDrawer, EditFieldMenu, EditPropertiesDrawer } from './components'
import { useCogfy, useCollectionFields, useToasts, useViewRecords } from '../../hooks'
import { CollectionState, useActiveViewId, useCountRecords, useFields, useViews } from '../../lib'
import { CollectionPageController } from './collection-page-controller'
import { useEffect, useRef, useState } from 'react'
import { OverlayContent } from '../../components'
import { useBeforeUnload, useBlocker, useSearchParams } from 'react-router-dom'
import { UploadFilesMenu } from './components/UploadFilesMenu/UploadFilesMenu'
import { useTranslation } from 'react-i18next'
import { validFile } from '../../helpers'
import { GetUserCollectionPermissionsResult } from '@indigohive/cogfy-types/endpoints/getUserCollectionPermissions'

const closeMenuWarningMessage = 'Close menu? Some uploads are already in progress.'

export type DatabaseCollectionPageProps = {
  state: CollectionState
  controller: CollectionPageController
  collection: GetCollectionResult
  permissions?: GetUserCollectionPermissionsResult
  currentViewFieldId?: UUID
}

export type UploadData = {
  id: UUID
  status: 'loading' | 'done' | 'error'
  file: Pick<File, 'name' | 'type' | 'size'>
  progress?: number | null
  errorMessage?: string
}

export function DatabaseCollectionPage (props: DatabaseCollectionPageProps) {
  const { state, collection, controller } = props

  const cogfy = useCogfy()
  const [addFieldMenuEl, setAddFieldMenuEl] = useState<HTMLDivElement | null>(null)
  const [propertiesMenuOpen, setPropertiesMenuOpen] = useState(false)
  const [uploadFiles, setUploadFiles] = useState<Record<UUID, UploadData>>({})
  const [uploadFileMenuOpen, setUploadFileMenuOpen] = useState(false)
  const activeViewId = useActiveViewId(state)
  const views = useViews(state)
  const tableRef = useRef<HTMLDivElement>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const countRecords = useCountRecords(state) ?? 0
  const fields = useFields(state)
  const toast = useToasts()
  const { t } = useTranslation()

  const pageNumber = parseInt(searchParams.get('pageNumber') ?? '0')
  const pageSize = parseInt(searchParams.get('pageSize') ?? '100')

  const getWorkspaceAssistant = useQuery({
    queryKey: ['getWorkspaceAssistant'],
    queryFn: ({ signal }) => cogfy.getWorkspaceAssistant({ signal })
  })
  const getViewRecords = useViewRecords(state, activeViewId, { pageNumber, pageSize })
  const getCollectionViews = useQuery({
    queryKey: ['getCollectionViews', collection.id, state.id],
    queryFn: async ({ signal }) => {
      const result = await cogfy.collections.getViews(collection.id, signal)
      state.setViews(result.data)
      return result
    }
  })
  const getViewFields = useQuery({
    queryKey: ['getViewFields', activeViewId, state.id],
    queryFn: async ({ signal }) => {
      const result = await cogfy.views.getFields(activeViewId!, signal)
      state.setViewFields(result.data.map(viewField => ({ ...viewField, viewId: activeViewId! })))
      return result
    },
    enabled: Boolean(activeViewId)
  })
  const getCollectionFields = useCollectionFields(state, state.id)

  useEffect(() => {
    if (!activeViewId && views && views.length > 0) {
      state.setActiveViewId(views[0].id)
    }
  }, [activeViewId, views])

  const onNextPageClick = () => {
    setSearchParams(prev => {
      const totalPages = Math.ceil(countRecords / pageSize)

      if (pageNumber < totalPages - 1) {
        prev.set('pageNumber', (pageNumber + 1).toString())
        controller.onPaginationChange()
      }

      return prev
    })
  }
  const onPreviousPageClick = () => {
    setSearchParams(prev => {
      if (pageNumber === 1) {
        prev.delete('pageNumber')
        controller.onPaginationChange()
      } else if (pageNumber > 0) {
        prev.set('pageNumber', (pageNumber - 1).toString())
        controller.onPaginationChange()
      }

      return prev
    })
  }

  const onUploadFiles = useMutation({
    mutationFn: async (data: { files: File[], fieldId: UUID }) => {
      const { files, fieldId } = data

      setUploadFileMenuOpen(true)

      const newUploadFiles: Record<UUID, UploadData> = Object.fromEntries(files.map(file => {
        const fileId = uuid() as UUID
        const fieldData = fields?.find(field => field.id === fieldId)?.data as FileFieldData | null
        const error = validFile(fieldData, file)

        if (error) {
          return [fileId, { id: fileId, status: 'error', errorMessage: error, file }]
        }

        return [fileId, { id: fileId, status: 'loading', file }]
      }))

      setUploadFiles(prev => ({ ...prev, ...newUploadFiles }))

      const uploads = Object.values(newUploadFiles).filter(upload => upload.status !== 'error')
      const uploadPromises = uploads.map(async upload => {
        const result = await cogfy.files.upload({
          id: upload.id,
          collectionId: state.id,
          fieldId,
          type: upload.file.type,
          size: upload.file.size,
          name: upload.file.name
        })
        const signedUrl = result.signedUrl
        const data = { ...result.fields, file: upload.file }
        const headers = { 'Content-Type': 'multipart/form-data' }

        await axios.post(
          signedUrl,
          data,
          {
            headers,
            onUploadProgress: event => {
              if (event.total) {
                const progress = Math.ceil(Math.round(event.loaded * 100) / event.total)

                setUploadFiles(prev => ({ ...prev, [upload.id]: { ...upload, progress, status: 'loading' } }))
              }
            }
          }
        )

        await cogfy.files.finishUpload(upload.id)

        setUploadFiles(prev => ({ ...prev, [upload.id]: { ...upload, status: 'done' } }))

        await getViewRecords.refetch()
      })

      await Promise.all(uploadPromises)
    },
    onError: () => toast.error(t('Error on uploading documents'))
  })

  const uploadsAreDone = Object.values(uploadFiles).every(upload => upload.status !== 'loading')

  useBlocker(() => !uploadsAreDone && !window.confirm(t(closeMenuWarningMessage)))
  useBeforeUnload(event => {
    if (!uploadsAreDone) {
      event.returnValue = closeMenuWarningMessage
      return closeMenuWarningMessage
    }
  })

  const disabledNextPage = getViewRecords.data ? (pageNumber >= Math.ceil(countRecords / pageSize) - 1) : true
  const disabledPreviousPage = pageNumber === 0
  const loading = getViewRecords.isLoading || getCollectionViews.isLoading || getViewFields.isLoading || getCollectionFields.isLoading

  return (
    <>
      <CollectionTabs state={state} controller={controller} loading={getCollectionViews.isLoading} />
      <CollectionToolbar
        state={state}
        controller={controller}
        loading={loading}
        permissions={props.permissions}
      />
      <div className="flex w-full h-[calc(100vh-170px)] -mt-[1px]">
        <CollectionTable
          ref={tableRef}
          state={state}
          controller={controller}
          onAddFieldClick={event => setAddFieldMenuEl(event.currentTarget)}
          onPropertiesMenuButtonClick={() => setPropertiesMenuOpen(true)}
          onNextPageClick={onNextPageClick}
          onPreviousPageClick={onPreviousPageClick}
          disabledNextPage={disabledNextPage}
          disabledPreviousPage={disabledPreviousPage}
          loading={loading}
          permissions={props.permissions}
          workspaceAssistant={getWorkspaceAssistant.data}
        />
        <CollectionTableOverlay tableRef={tableRef} state={state} controller={controller} />
        <OverlayContent
          open={Boolean(addFieldMenuEl)}
          anchorEl={addFieldMenuEl}
          onClose={() => setAddFieldMenuEl(null)}
        >
          <CreateFieldMenu
            onClick={(fieldType) => {
              controller.onAddFieldClick(fieldType, activeViewId!)
              setAddFieldMenuEl(null)
            }}
          />
        </OverlayContent>
        <EditFieldMenu
          state={state}
          controller={controller}
          onUploadFiles={(files: File[], fieldId: UUID) => { onUploadFiles.mutateAsync({ files, fieldId }) }}
          permissions={props.permissions}
        />
        <EditFieldDrawer state={state} controller={controller} />
        <EditPropertiesDrawer
          open={propertiesMenuOpen}
          onClose={() => setPropertiesMenuOpen(false)}
          state={state}
          controller={controller}
        />
        {uploadFileMenuOpen && (
          <div className="absolute bottom-0 right-0">
            <UploadFilesMenu
              uploads={Object.values(uploadFiles)}
              uploadsAreDone={uploadsAreDone}
              onClose={() => {
                setUploadFileMenuOpen(false)
                setUploadFiles({})
              }}
            />
          </div>
        )}
      </div>
    </>
  )
}
