import type {
  GetOperationsQuery,
  GetOperationsResult
} from '@indigohive/cogfy-types'
import { BaseClient } from './base-client'

export class OperationsClient extends BaseClient {
  async get (
    params: GetOperationsQuery = {},
    signal?: AbortSignal
  ): Promise<GetOperationsResult> {
    return (await this.axios.get('/operations', { params, signal  })).data
  }
}
