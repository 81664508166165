import { FieldFilterMenuProps } from '../FieldFilterChip'
import { RecordFilter } from '@indigohive/cogfy-types'
import { isIsEmptyFilter } from '@indigohive/cogfy-common'
import { useTranslation } from 'react-i18next'

const selectOptions = [
  { value: '', label: '', disabled: true },
  { value: 'isEmpty', label: 'is empty' },
  { value: 'notEmpty', label: 'is not empty' }
]

export function FileFilterMenu (props: FieldFilterMenuProps) {
  const { controller, field, filter, viewFilter } = props

  const { t } = useTranslation()
  const filterValue = isIsEmptyFilter(filter)
    ? filter.isEmpty ? 'isEmpty' : 'notEmpty'
    : ''

  const onUpdateFilterValue = (value: string) => {
    const newFilter = {
      property: field.id,
      propertyType: 'file',
      isEmpty: value === 'isEmpty'
    }

    controller.onViewFilterChange(viewFilter, newFilter as RecordFilter)
  }

  return (
    <div className="space-y-2 p-2">
      <div className="flex items-center gap-x-2">
        <div className="whitespace-nowrap">{field.name}</div>
        <select
          value={filterValue}
          onChange={event => onUpdateFilterValue(event.target.value)}
          className="select select-xs select-ghost max-w-xs w-full"
        >
          {selectOptions.map(option => (
            <option
              key={option.label}
              value={option.value}
            >
              {t(`filters:${option.label}`)}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}
