import { PlusIcon } from 'lucide-react'
import clsx from 'clsx'

export type DataTableHeadAddButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export function DataTableHeadAddButton (props: DataTableHeadAddButtonProps) {
  return (
    <div
      className={clsx(
        'h-full',
        'cursor-pointer',
        'hover:bg-base-200',
        'active:bg-base-300',
        'text-gray-400',
        'flex',
        'items-center',
        'px-2.5'
      )}
      role="button"
      onClick={props.onClick}
    >
      <PlusIcon size={16} />
    </div>
  )
}
