import { useEffect } from 'react'
import { useDrag, useDragDropManager, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { DataTableCol } from '../DataTable'
import clsx from 'clsx'

export type DataTableHeadResizeHandlerProps<T> = {
  col: DataTableCol<T>
  onDrag?: (col: DataTableCol<T>, change: number) => void
  onResize?: (col: DataTableCol<T>, change: number) => void
}

const DATA_TABLE_HANDLER = 'DataTableColResizeHandler'

export function DataTableHeadResizeHandler<T> (props: DataTableHeadResizeHandlerProps<T>) {
  const { col, onDrag, onResize } = props
  const dragDropManager = useDragDropManager()
  const monitor = dragDropManager.getMonitor()

  const [, drop] = useDrop<DataTableCol<T>>({
    accept: DATA_TABLE_HANDLER,
    drop: (item, monitor) => {
      if (monitor.didDrop()) {
        return
      }

      const offset = monitor.getDifferenceFromInitialOffset()

      if (offset) {
        onResize?.(item, offset.x)
      }
    }
  }, [col, onResize])

  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: DATA_TABLE_HANDLER,
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    item: col
  }, [col])

  // Usando drop no document.body ao invés do método end do useDrag
  // https://github.com/react-dnd/react-dnd/issues/3115
  useEffect(() => {
    drop(document.body)
    return () => { drop(null) }
  }, [])

  // Emitir o evento onDrag
  useEffect(() => {
    if (!isDragging) {
      return
    }

    monitor.subscribeToOffsetChange(() => {
      const offset = monitor.getDifferenceFromInitialOffset()

      if (offset) {
        onDrag?.(col, offset.x)
      }
    })
  }, [isDragging, monitor, col, onDrag])

  // Não renderizar o preview
  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true })
  }, [dragPreview])

  return (
    <div
      ref={drag}
      className={clsx(
        'absolute',
        'w-[5px]',
        'transition-colors',
        'cursor-col-resize',
        'active:bg-info',
        'hover:bg-info',
        'h-[calc(100%+2px)]',
        'top-[-1px]',
        'right-[-3px]',
        'z-10'
      )}
    />
  )
}
