import { ConnectionFieldData, ConnectionRecordProperty } from '@indigohive/cogfy-types'
import { FieldOverlayProps } from '../FieldOverlay'
import { Select } from '../../../components'
import { useRecords } from '../../../lib'
import { useQuery } from '@tanstack/react-query'
import { useCogfy } from '../../../hooks'
import { useTranslation } from 'react-i18next'
import { GetConnectionsListQuery, GetConnectionsListResultData } from '@indigohive/cogfy-types/endpoints/getConnectionsList'

export function ConnectionOverlay (props: FieldOverlayProps) {
  const { state, field, controller } = props

  const { t } = useTranslation()
  const record = useRecords(state)?.find(record => record.id === props.record.id) ?? props.record
  const fieldData = field.data as ConnectionFieldData | undefined

  const updateRecordProperties = (connection: GetConnectionsListResultData | undefined) => {
    controller.onUpdateRecordProperties(
      record.id,
      {
        [field.id]: {
          type: 'connection',
          connection: {
            value: connection
              ? { id: connection.id, name: connection.name, provider: connection.provider }
              : undefined
          }
        }
      }
    )
  }

  const cogfy = useCogfy()
  const query: GetConnectionsListQuery = {
    provider: fieldData?.connection?.provider,
    recordId: record.id
  }
  const getConnections = useQuery({
    queryKey: ['getConnectionsList', query] as const,
    queryFn: ({ queryKey, signal }) => cogfy.getConnectionsList(queryKey[1], { signal })
  })
  const connections = getConnections.data?.data ?? []
  const property = record.properties[field.id] as ConnectionRecordProperty

  return (
    <Select
      size="sm"
      options={[
        { value: '', label: t('Select an option') },
        ...connections.map(connection => ({
          value: connection.id,
          label: connection.name
        }))
      ]}
      value={property?.connection?.value?.id ?? ''}
      onChange={event => {
        const connection = connections.find(connection => connection.id === event.target.value)
        updateRecordProperties(connection)
      }}
    />
  )
}
