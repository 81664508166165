import type {
  CancelChatMessageCommand,
  ChatCreatedEvent,
  ChatDeletedEvent,
  ConfirmChatMessageCommand,
  CreateChatCommand,
  CreateChatResult,
  GetChatMessagesPageQuery,
  GetChatMessagesPageResult,
  GetChatResult,
  SendChatMessageCommand,
  SendChatMessageResult,
  SetChatMessageReactionCommand,
  UpdateChatMessageStatusCommand,
  UUID,
  WhatsAppMediaReceivedEvent,
  WhatsAppMediaUploadedEvent,
  WhatsAppMessageRepliedEvent,
  WhatsAppMessageStatusChanged
} from '@indigohive/cogfy-types'
import {
  WhatsAppMessageReceivedEvent
} from '@indigohive/cogfy-types/events/whatsAppMessageReceived'
import { BaseClient, BaseClientOptions } from './base-client'
import { SocketEvent } from '../socket'

export class ChatsClient extends BaseClient {
  onChatCreated: SocketEvent<ChatCreatedEvent>
  onChatDeleted: SocketEvent<ChatDeletedEvent>
  onWhatsAppMediaReceived: SocketEvent<WhatsAppMediaReceivedEvent>
  onWhatsAppMediaUploaded: SocketEvent<WhatsAppMediaUploadedEvent>
  onWhatsAppMessageReceived: SocketEvent<WhatsAppMessageReceivedEvent>
  onWhatsAppMessageReplied: SocketEvent<WhatsAppMessageRepliedEvent>
  onWhatsAppMessageStatusChanged: SocketEvent<WhatsAppMessageStatusChanged>

  constructor(options: BaseClientOptions) {
    super(options)

    this.onChatCreated = new SocketEvent(this.socket, 'chat_created')
    this.onChatDeleted = new SocketEvent(this.socket, 'chat_deleted')
    this.onWhatsAppMediaReceived = new SocketEvent(this.socket, 'whatsapp_media_received')
    this.onWhatsAppMediaUploaded = new SocketEvent(this.socket, 'whatsapp_media_uploaded')
    this.onWhatsAppMessageReceived = new SocketEvent(this.socket, 'whatsapp_message_received')
    this.onWhatsAppMessageReplied = new SocketEvent(this.socket, 'whatsapp_message_replied')
    this.onWhatsAppMessageStatusChanged = new SocketEvent(this.socket, 'whatsapp_message_status_changed')
  }

  /**
   * Calls the `POST /chats/${chatId}/messages/${chatMessageId}/cancel` endpoint.
   * @param chatId The chat id.
   * @param chatMessageId The chat message id.
   * @param data The request body.
   * @param signal The abort signal
   */
  async cancelChatMessage(
    chatId: UUID,
    chatMessageId: UUID,
    data: CancelChatMessageCommand,
    signal?: AbortSignal
  ): Promise<void> {
    await this.axios.post(`/chats/${chatId}/messages/${chatMessageId}/cancel`, data, { signal })
  }

  /**
   * Calls the `POST /chats/${chatId}/messages/${chatMessageId}/confirm` endpoint.
   * @param chatId The chat id.
   * @param chatMessageId The chat message id.
   * @param data The request body.
   * @param signal The abort signal
   */
  async confirmChatMessage(
    chatId: UUID,
    chatMessageId: UUID,
    data: ConfirmChatMessageCommand,
    signal?: AbortSignal
  ): Promise<void> {
    await this.axios.post(`/chats/${chatId}/messages/${chatMessageId}/confirm`, data, { signal })
  }

  async updateMessageStatus(
    chatId: UUID,
    chatMessageId: UUID,
    data: UpdateChatMessageStatusCommand
  ): Promise<void> {
    await this.axios.patch(`/chats/${chatId}/messages/${chatMessageId}/status`, data)
  }

  /**
   * Calls the `POST /chats` endpoint.
   * @param data  The request body.
   * @param signal The abort signal.
   * @returns The response body.
   */
  async create (
    data: CreateChatCommand,
    signal?: AbortSignal
  ): Promise<CreateChatResult> {
    return (await this.axios.post('/chats', data, { signal })).data
  }

  /**
   * Calls the `GET /chats/{chatId}` endpoint.
   * @param chatId The chat id.
   * @param signal The abort signal.
   * @returns The response body.
   */
  async getById (
    chatId: UUID,
    signal?: AbortSignal
  ): Promise<GetChatResult> {
    return (await this.axios.get(`/chats/${chatId}`, { signal })).data
  }

  /**
   * Calls the `GET /chats/{chatId}/messages` endpoint.
   * @param chatId The chat id.
   * @param params The request query params.
   * @param signal The abort signal.
   * @returns The response body.
   */
  async getMessagesPage (
    chatId: UUID,
    params: GetChatMessagesPageQuery,
    signal?: AbortSignal
  ): Promise<GetChatMessagesPageResult> {
    return (await this.axios.get(`/chats/${chatId}/messages`, { params, signal })).data
  }

  /**
   * Calls the `POST /chats/{chatId}/messages` endpoint.
   * @param chatId The chat id.
   * @param data The request body.
   * @returns The response body.
   */
  async sendMessage (
    chatId: UUID,
    data: SendChatMessageCommand
  ): Promise<SendChatMessageResult> {
    return (await this.axios.post(`/chats/${chatId}/messages`, data)).data
  }

  async setMessageReaction (
    chatId: UUID,
    chatMessageId: UUID,
    data: SetChatMessageReactionCommand
  ): Promise<void> {
    await this.axios.put(`/chats/${chatId}/messages/${chatMessageId}/reaction`, data)
  }
}
