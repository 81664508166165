import { PermissionActionName } from '@indigohive/cogfy-types'
import { createContext } from 'react'

export type AuthorizationContextValue = {
  loading?: boolean
  refetch?: () => void
  isAdmin?: boolean
  hasPermission?: (
    action: PermissionActionName,
    subject: string | null
  ) => boolean | undefined
}

export const AuthorizationContext = createContext<AuthorizationContextValue>({})
