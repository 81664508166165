import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ChatMiddlewareDataFormProps } from '../ChatMiddlewareDataFormProps'

export function HttpForm (props: ChatMiddlewareDataFormProps) {
  const { data, onChange } = props
  const { t } = useTranslation()
  const [url, setUrl] = useState<string>(
    typeof data.url === 'string' ? data.url : ''
  )

  return (
    <>
      <div className="label">
        <span className="label-text">
          {t('URL')}
        </span>
      </div>
      <input
        className="input input-bordered input-sm w-full"
        value={url}
        max={20}
        min={0}
        onChange={event => {
          setUrl(event.target.value)
          onChange({ url: event.target.value })
        }}
      />
    </>
  )
}
