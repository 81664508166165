import { VectorRecordProperty } from '@indigohive/cogfy-types'
import { FieldCellProps } from '../FieldCell'

export function VectorCell (props: FieldCellProps<VectorRecordProperty>) {
  return (
    <span className="px-2 py-1">
      {props.property?.vector?.value?.count}
    </span>
  )
}
