import { useEffect, useState } from 'react'
import { CollectionState } from '../collection-state'

export function useSelectedCollectionTitleFieldId (state: CollectionState) {
  const [collectionTitleFieldId, setCollectionTitleFieldId] = useState(state.selectedTitleFieldId)

  useEffect(() => {
    const selectedTitleFieldIdChanged = () => {
      setCollectionTitleFieldId(state.selectedTitleFieldId)
    }

    state.addEventListener('selectedCollectionTitleFieldId', selectedTitleFieldIdChanged)

    return () => {
      state.removeEventListener('selectedCollectionTitleFieldId', selectedTitleFieldIdChanged)
    }
  })

  return collectionTitleFieldId
}
