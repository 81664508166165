import { RunTransactionCommand } from '@indigohive/cogfy-types'
import { Command } from '../command-stack'
import { CollectionState } from '../collection-state'

export type UpdateViewFilterCommandData = {
  filter: object | null
}

export class UpdateViewFilterCommand implements Command<RunTransactionCommand> {
  private readonly _state: CollectionState
  private readonly _data: UpdateViewFilterCommandData
  private readonly _previousFilter: object | null

  constructor (
    state: CollectionState,
    data: UpdateViewFilterCommandData
  ) {
    this._state = state
    this._data = data
    this._previousFilter = this._state.views?.find(view => view.id === this._state.activeViewId)?.filter ?? null
  }

  run (): RunTransactionCommand {
    const newViews = this._state.views?.map(view => {
      if (view.id === this._state.activeViewId) {
        return {
          ...view,
          filter: this._data.filter
        }
      }
      return view
    })

    this._state.setViews(newViews ?? [])

    return {
      operations: [
        {
          type: 'update_view_filter',
          data: {
            collectionId: this._state.id,
            viewId: this._state.activeViewId!,
            filter: this._data.filter
          }
        }
      ]
    }
  }

  undo (): RunTransactionCommand {
    const newViews = this._state.views?.map(view => {
      if (view.id === this._state.activeViewId) {
        return {
          ...view,
          filter: this._previousFilter
        }
      }
      return view
    })

    this._state.setViews(newViews ?? [])

    return {
      operations: [
        {
          type: 'update_view_filter',
          data: {
            collectionId: this._state.id,
            viewId: this._state.activeViewId!,
            filter: this._previousFilter
          }
        }
      ]
    }
  }
}
