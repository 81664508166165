import { ChevronDownIcon, ChevronUpIcon, XIcon } from 'lucide-react'
import { UploadData } from '../../_DatabaseCollectionPage'
import { Button, Menu } from '../../../../components'
import { UploadFileProgress } from './subcomponents'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const closeMenuWarningMessage = 'Close menu? Some uploads are already in progress.'

const uploadStates: Record<UploadData['status'], number> = {
  error: 0,
  loading: 1,
  done: 2
}

export type UploadFilesMenuProps = {
  uploads: UploadData[]
  uploadsAreDone?: boolean
  onClose?: () => void
}

export function UploadFilesMenu (props: UploadFilesMenuProps) {
  const { uploads, uploadsAreDone, onClose } = props

  const [hide, setHide] = useState(false)
  const { t } = useTranslation()

  const uploadsInProgress = uploads.filter(upload => upload.status === 'loading').length
  const uploadsCompleted = uploads.filter(upload => upload.status === 'done').length
  const sortedUploads = uploads.sort((a, b) => {
    return uploadStates[a.status] - uploadStates[b.status]
  })

  return (
    <Menu className="w-96 max-h-96 p-0">
      <div className="flex flex-row items-center justify-between sticky top-0 bg-white z-10 font-semibold p-4">
        <span>
          {uploadsAreDone && t('Files uploaded', { count: uploadsCompleted })}
          {uploadsInProgress > 0 && t('Uploading files', { count: uploadsCompleted })}
        </span>
        <div className="flex flex-row gap-1">
          <Button
            ghost
            square
            size="xs"
            onClick={() => setHide(!hide)}
          >
            {hide ? <ChevronUpIcon size={20} /> : <ChevronDownIcon size={20} />}
          </Button>
          <Button
            ghost
            square
            size="xs"
            onClick={() => {
              if (uploadsAreDone || window.confirm(t(closeMenuWarningMessage))) {
                onClose?.()
              }
            }}
          >
            <XIcon size={20} />
          </Button>
        </div>
      </div>
      {!hide && (
        <div className="flex flex-col gap-1 px-4">
          {sortedUploads.map(uploadFile => (
            <UploadFileProgress
              key={uploadFile.id}
              upload={uploadFile}
            />
          ))}
        </div>
      )}
    </Menu>
  )
}
