import { NumberRecordProperty } from '@indigohive/cogfy-types'
import { FieldOverlayProps } from '../FieldOverlay'

export function NumberOverlay (props: FieldOverlayProps) {
  const { field, record, controller } = props

  const recordProperty = record.properties[field.id] as NumberRecordProperty | undefined

  return (
    <input
      type="number"
      className="input input-sm input-bordered"
      onFocus={event => {
        event.currentTarget.value = recordProperty?.number?.value?.toString() ?? ''
      }}
      onBlur={event => {
        const currentValue = recordProperty?.number?.value
        const newValue = event.currentTarget.value
          ? parseFloat(event.currentTarget.value)
          : undefined

        if (currentValue !== newValue) {
          controller.onUpdateRecordProperties(
            record.id,
            {
              [field.id]: newValue === undefined
                ? undefined
                : { type: 'number', number: { value: newValue } }
            }
          )
        }
        props.onClose('blur')
      }}
      onKeyDown={event => {
        if (event.key === 'Escape' || event.key === 'Enter' || event.key === 'Tab') {
          if (event.key === 'Tab') {
            event.preventDefault()
          }

          props.onClose(event.key)
        }
      }}
      onWheel={event => event.currentTarget.blur()}
      autoFocus
    >
    </input>
  )
}
