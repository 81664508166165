import { useFormik } from 'formik'
import { useState } from 'react'
import { GetUserGroupResult, UUID } from '@indigohive/cogfy-types'
import { useMutation } from '@tanstack/react-query'
import * as yup from 'yup'
import { useCogfy } from '../../../../hooks'
import { Button, Dialog, Input } from '../../../../components'
import { useTranslation } from 'react-i18next'

export type AddMemberToGroupDialogProps = {
  open: boolean
  group: GetUserGroupResult
  onClose: () => void
  onSuccess: () => void
}

const userNotFoundMessage = 'User not found'
const memberAlredyAddedToGroup = 'Member alredy added to group'

export function AddMemberToGroupDialog (props: AddMemberToGroupDialogProps) {
  const { open, onClose, onSuccess, group } = props
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const cogfy = useCogfy()
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: yup.object().shape({
      email: yup.string().email('Field must be an email').required('Email is required')
    }),
    onSubmit: async values => {
      try {
        const user = await cogfy.users.getByEmail(values.email)
        createMember.mutate(user.id)
      } catch (error) {
        if (cogfy.isNotFound(error)) {
          setErrorMessage(t(userNotFoundMessage))
        }
      }
    }
  })
  const createMember = useMutation({
    mutationFn: async (userId: UUID) => {
      try {
        await cogfy.createUserGroupMember({ userGroupId: group.id, userId })
        setErrorMessage(null)
        formik.resetForm()
        onSuccess()
      } catch (error) {
        if (cogfy.isConflict(error)) {
          setErrorMessage(t(`userGroupsPage:${memberAlredyAddedToGroup}`))
        }
      }
    }
  })

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setErrorMessage(null)
          formik.resetForm()
          onClose()
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <h2 className="text-md font-bold mb-6">{group.name ?? t('Unnamed')}</h2>
          <div>
            <Input
              size="sm"
              type="text"
              placeholder={t('User e-mail')}
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            <div className="flex flex-col">
              {errorMessage && (
                <span className="text-gray-400 text-xs mt-1">{errorMessage}</span>
              )}
              {(formik.errors.email && formik.touched.email) && (
                <span className="text-rose-600 text-xs mt-1">{formik.errors.email}</span>
              )}
              <Button
                type="submit"
                size="sm"
                className="mt-4"
                disabled={formik.isSubmitting}
              >
                {t('Add to group')}
              </Button>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  )
}
