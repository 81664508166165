import { useTranslation } from 'react-i18next'
import { ChatMiddlewareDataFormProps } from '../ChatMiddlewareDataFormProps'
import { Mentions, MentionsValue } from '../../Mentions'
import { useState } from 'react'
import { Select } from '../../Select'

export function ReplyForm (props: ChatMiddlewareDataFormProps) {
  const { data, fields, onChange } = props

  const { t } = useTranslation()
  const [contentType, setContentType] = useState('text')
  const [textContent, setTextContent] = useState<MentionsValue>({
    parts: data.message?.content?.template?.strings ?? [],
    options: data.message?.content?.template?.args?.map((arg: { fieldId: string }) => ({
      id: arg.fieldId,
      name: fields?.find(field => field.id === arg.fieldId)?.name ?? '???'
    })) ?? []
  })

  return (
    <>
      <div className="label">
        <span className="label-text">
          {t('Reply')}
        </span>
      </div>
      <Select
        label={t('Content type')}
        size="sm"
        value={contentType}
        options={[
          { label: 'Text', value: 'text' }
        ]}
        onChange={event => setContentType(event.target.value)}
      />
      <div className="label">
        <span className="label-text">
          {t('Content')}
        </span>
      </div>
      <Mentions
        value={textContent}
        onChange={value => {
          setTextContent(value)
          onChange({
            ...data,
            message: {
              contentType,
              content: {
                type: 'template',
                template: {
                  strings: value.parts,
                  args: value.options.map(option => ({ fieldId: option.id }))
                }
              }
            }
          })
        }}
        options={fields?.map(field => ({ id: field.id, name: field.name })) ?? []}
      />
    </>
  )
}
