import { RunTransactionCommand, UUID } from '@indigohive/cogfy-types'
import { Command } from '../command-stack'
import { CollectionState, CollectionStateRecord } from '../collection-state'

export type DeleteRecordsCommandData = {
  recordIds: UUID[]
}

export class DeleteRecordsCommand implements Command<RunTransactionCommand> {
  private readonly _state: CollectionState
  private readonly _data: DeleteRecordsCommandData
  private readonly _originalRecords: CollectionStateRecord[]
  private readonly _originalSelectedRows: Record<string, CollectionStateRecord>

  constructor (
    state: CollectionState,
    data: DeleteRecordsCommandData
  ) {
    this._state = state
    this._data = data
    this._originalRecords = state.records ? [...state.records] : []
    this._originalSelectedRows = { ...this._state.selectedRows }
  }

  run (): RunTransactionCommand {
    const recordsSet = new Set(this._data.recordIds)
    const newRecords = this._state.records?.filter(record => !recordsSet.has(record.id)) ?? []

    this._state.setRecords(newRecords)
    this._state.setSelectedRows({})
    this._state.setCountRecords((this._state.countRecords ?? 0) - this._data.recordIds.length)

    return {
      operations: [
        {
          type: 'soft_delete_records',
          data: {
            collectionId: this._state.id,
            recordIds: this._data.recordIds
          }
        }
      ]
    }
  }

  undo (): RunTransactionCommand {
    this._state.setRecords(this._originalRecords)
    this._state.setSelectedRows(this._originalSelectedRows)

    this._state.setCountRecords((this._state.countRecords ?? 0) + this._data.recordIds.length)

    return {
      operations: [
        {
          type: 'restore_records',
          data: {
            collectionId: this._state.id,
            recordIds: this._data.recordIds
          }
        }
      ]
    }
  }
}
