import clsx from 'clsx'
import { PropsWithChildren } from 'react'

export type DataTableRowProps = PropsWithChildren<{
  checked?: boolean
}>

export function DataTableRow (props: DataTableRowProps) {
  const { checked, children } = props

  return (
    <div
      className={clsx(
        'inline-flex',
        'flex-row',
        'border-b',
        'min-h-8',
        'text-sm',
        'min-w-full',
        checked && 'bg-blue-50'
      )}
    >
      {children}
    </div>
  )
}
