import { PlusIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../components'
import { useCogfy } from '../../../hooks'
import { useQuery } from '@tanstack/react-query'

export function AddGoogleButton () {
  const cogfy = useCogfy()
  const { t } = useTranslation()

  const getGoogleAuthUrl = useQuery({
    queryKey: ['getGoogleAuthUrl'],
    queryFn: ({ signal }) => cogfy.getGoogleAuthUrl({ signal })
  })

  return (
    <Button
      color="primary"
      size="sm"
      disabled={getGoogleAuthUrl.isLoading}
      to={getGoogleAuthUrl.data?.authUrl}
    >
      <PlusIcon size={16} />
      {t('WorkspaceConnectionsPage:Connect')}
    </Button>
  )
}
