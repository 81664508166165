import { FieldOperationConfigCollectionInput, OperationInputType, OperationSchemaInput, UUID } from '@indigohive/cogfy-types'
import { CollectionState, useSelectedFieldToUpdate } from '../../../../../lib'
import { CollectionPageController } from '../../../collection-page-controller'
import { MultiSelect } from '../../../../../components/MultiSelect'
import { useTranslation } from 'react-i18next'
import { useCollections } from '../../../../../hooks'

export type FieldOperationCollectionInputProps = {
  controller: CollectionPageController
  state: CollectionState
  inputName: string
  input: OperationSchemaInput<OperationInputType>
}

export function FieldOperationCollectionInput (props: FieldOperationCollectionInputProps) {
  const { controller, state, inputName, input } = props

  const { t } = useTranslation()
  const selectedField = useSelectedFieldToUpdate(state)
  const getCollections = useCollections({ type: 'database' })

  const collections = getCollections?.data?.data.filter(collection => collection.type !== 'folder')
  const options = collections?.map(collection => ({ label: collection.name ?? 'Untitled', value: collection.id }))

  const configInput = selectedField?.operationConfig?.[inputName] as FieldOperationConfigCollectionInput | undefined
  const values = configInput?.collections?.value ?? []
  const multiple = Boolean(input.multiple)

  return (
    <>
      {collections && multiple && (
        <MultiSelect
          options={options!}
          labels={{
            selectItems: t('editFieldDrawer:Select collections'),
            allItems: t('editFieldDrawer:All collections'),
            noResultsFound: t('No collections found')
          }}
          selected={values.map(({ id }) => id)}
          onChange={(selected: string) => {
            const newValues = values.some(value => value.id === selected)
              ? values.filter(item => item.id !== selected)
              : [...values, { id: selected as UUID }]
            controller.onOperationChange(
              selectedField!.id,
              selectedField!.operation ?? null,
              selectedField!.recalculateStrategy ?? 'always',
              {
                ...selectedField!.operationConfig,
                [inputName]: { type: 'collections', collections: { value: newValues } }
              }
            )
          }}
          onClearSelections={() => {
            controller.onOperationChange(
              selectedField!.id,
              selectedField!.operation ?? null,
              selectedField!.recalculateStrategy ?? 'always',
              {
                ...selectedField!.operationConfig,
                [inputName]: { type: 'collections', collections: { value: [] } }
              }
            )
          }}
          onSelectAll={() => {
            const newValues = collections.map(collection => ({ id: collection.id }))
            controller.onOperationChange(
              selectedField!.id,
              selectedField!.operation ?? null,
              selectedField!.recalculateStrategy ?? 'always',
              {
                ...selectedField!.operationConfig,
                [inputName]: { type: 'collections', collections: { value: newValues } }
              }
            )
          }}
        />
      )}
    </>
  )
}
