import { GetUserGroupsPageQuery } from '@indigohive/cogfy-types'
import { useCogfy } from './use-cogfy'
import { useQuery } from '@tanstack/react-query'

export function useUserGroupsPage (params: GetUserGroupsPageQuery) {
  const cogfy = useCogfy()

  const getUserGroupsPage = useQuery({
    queryKey: ['getUserGroupsPage', params],
    queryFn: ({ signal }) => cogfy.userGroups.getPage(params, signal)
  })

  return getUserGroupsPage
}
