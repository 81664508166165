import { CollectionAccessControlPermissions, UUID } from '@indigohive/cogfy-types'
import { CreateCollectionAccessControlCommand } from '@indigohive/cogfy-types/endpoints/createCollectionAccessControl'
import { DeleteCollectionAccessControlCommand } from '@indigohive/cogfy-types/endpoints/deleteCollectionAccessControl'
import { UpdateCollectionAccessControlCommand } from '@indigohive/cogfy-types/endpoints/updateCollectionAccessControl'
import { UpdateCollectionAccessControlTypeCommand } from '@indigohive/cogfy-types/endpoints/updateCollectionAccessControlType'
import { GetUsersOrUserGroupsResultData } from '@indigohive/cogfy-types/endpoints/getUsersOrUserGroups'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { useDebounce } from 'use-debounce'
import { useCogfy, useWorkspaceSlug } from '../../../../hooks'
import { Button } from '../../../../components'

export type CollectionAccessControlEditorProps = {
  collectionId: UUID
}

const labelByType: Record<string, string> = {
  full_access: 'Full access',
  editor: 'Editor',
  viewer: 'Viewer'
}

export function CollectionAccessControlEditor (props: CollectionAccessControlEditorProps) {
  const { collectionId } = props

  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search, 300)
  const client = useCogfy()
  const queryClient = useQueryClient()
  const workspaceSlug = useWorkspaceSlug()

  const createCollectionAccessControl = useMutation({
    mutationFn: (data: CreateCollectionAccessControlCommand) => client.createCollectionAccessControl(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getCollectionAccessControlList', { collectionId }]
      })
    }
  })
  const deleteCollectionAccessControl = useMutation({
    mutationFn: (data: DeleteCollectionAccessControlCommand) => client.deleteCollectionAccessControl(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getCollectionAccessControlList', { collectionId }]
      })
    }
  })
  const updateCollectionAccessControl = useMutation({
    mutationFn: (data: UpdateCollectionAccessControlCommand) => client.updateCollectionAccessControl(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getCollectionAccessControlList', { collectionId }]
      })
    }
  })
  const updateCollectionAccessControlType = useMutation({
    mutationFn: (data: UpdateCollectionAccessControlTypeCommand) => client.updateCollectionAccessControlType(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getCollectionAncestors', { collectionId }]
      })
    }
  })
  const createAccessControl = (userOrGroup: GetUsersOrUserGroupsResultData, permissions: CollectionAccessControlPermissions) => {
    createCollectionAccessControl.mutate({
      collectionId,
      userId: userOrGroup.type === 'user' ? userOrGroup.id : undefined,
      userGroupId: userOrGroup.type === 'user_group' ? userOrGroup.id : undefined,
      permissions
    })
  }
  const getCollectionAncestors = useQuery({
    queryKey: ['getCollectionAncestors', { collectionId }] as const,
    queryFn: ({ queryKey, signal }) => client[queryKey[0]](queryKey[1], { signal })
  })
  const getCollectionAccessControlList = useQuery({
    queryKey: ['getCollectionAccessControlList', { collectionId }] as const,
    queryFn: ({ queryKey, signal }) => client[queryKey[0]](queryKey[1], { signal })
  })
  const getUsersOrUserGroups = useQuery({
    queryKey: ['getUsersOrUserGroups', { search: debouncedSearch }] as const,
    queryFn: ({ queryKey, signal }) => client[queryKey[0]](queryKey[1], { signal }),
    enabled: Boolean(debouncedSearch)
  })

  const collection = getCollectionAncestors.data?.data[0]
  const firstCustom = getCollectionAncestors.data?.data.find(ancestor => ancestor.accessControlType === 'custom')
  const last = getCollectionAncestors.data?.data[getCollectionAncestors.data?.data.length - 1]
  const inheritFrom = firstCustom ?? last
  const accessControlList = getCollectionAccessControlList.data?.data

  return (
    <div className="bg-base-100 border w-96 p-4 shadow rounded-box">
      {inheritFrom && inheritFrom.id !== collectionId && (
        <p>
          Inheriting permissions from{' '}
          <Link
            to={`/${workspaceSlug}/${inheritFrom.id}`}
            className="link link-primary"
          >
            {inheritFrom.name ?? <span className="opacity-40">Untitled</span>}
          </Link>
        </p>
      )}

      {collection?.accessControlType === 'custom' && (
        <p>
          Using custom permissions
        </p>
      )}

      <div>
        {collection?.accessControlType === 'custom' && (
          <Button
            ghost
            size="sm"
            className="w-full mt-4"
            onClick={() => {
              updateCollectionAccessControlType.mutate({
                collectionId,
                accessControlType: 'inherit'
              })
            }}
          >
            Use inherited permissions
          </Button>
        )}
        {collection?.accessControlType === 'inherit' && (
          <Button
            ghost
            size="sm"
            className="w-full mt-4"
            onClick={() => {
              updateCollectionAccessControlType.mutate({
                collectionId,
                accessControlType: 'custom'
              })
            }}
          >
            Use custom permissions
          </Button>
        )}
      </div>

      {collection?.accessControlType === 'custom' && (
        <div>
          <div className="mt-2 py-2">
            <input
              className="input input-sm input-bordered w-full"
              value={search}
              placeholder="Search for users or groups"
              onChange={event => setSearch(event.target.value)}
            />
          </div>

          {Boolean(debouncedSearch) && (
            <div>
              <h3 className="opacity-70"></h3>
              {getUsersOrUserGroups.data?.data
                .filter(userOrGroup =>
                  !accessControlList!.some(accessControl => accessControl.user?.id === userOrGroup.id) &&
                  !accessControlList!.some(accessControl => accessControl.userGroup?.id === userOrGroup.id))
                .map(userOrGroup => (
                  <div key={userOrGroup.id} className="flex py-2 items-center">
                    <div className="grow">{userOrGroup.name}</div>
                    <div>
                      <div className="dropdown dropdown-bottom dropdown-end">
                        <div tabIndex={0} role="button" className="btn btn-ghost btn-sm">
                          Select
                        </div>
                        <ul tabIndex={0} className="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow">
                          <li onClick={() => createAccessControl(userOrGroup, { type: 'full_access' })}>
                            <a>Full access</a>
                          </li>
                          <li onClick={() => createAccessControl(userOrGroup, { type: 'editor' })}>
                            <a>Editor</a>
                          </li>
                          <li onClick={() => createAccessControl(userOrGroup, { type: 'viewer' })}>
                            <a>Viewer</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}

          {accessControlList && accessControlList.length > 0 && (
            <div className="mt-4">
              <h3 className="opacity-70">In page</h3>
              {accessControlList?.map(accessControl => (
                <div key={accessControl.id} className="flex py-2 items-center">
                  <div className="grow">
                    {accessControl.user?.name ?? accessControl.userGroup?.name ?? '?'}
                  </div>
                  <div className="dropdown dropdown-bottom dropdown-end">
                    <div tabIndex={0} role="button" className="btn btn-ghost btn-sm">
                      {labelByType[accessControl.permissions.type]}
                    </div>
                    <ul tabIndex={0} className="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow">
                      <li onClick={() => updateCollectionAccessControl.mutate({ collectionAccessControlId: accessControl.id, permissions: { type: 'full_access' } })}>
                        <a>Full access</a>
                      </li>
                      <li onClick={() => updateCollectionAccessControl.mutate({ collectionAccessControlId: accessControl.id, permissions: { type: 'editor' } })}>
                        <a>Editor</a>
                      </li>
                      <li onClick={() => updateCollectionAccessControl.mutate({ collectionAccessControlId: accessControl.id, permissions: { type: 'viewer' } })}>
                        <a>Viewer</a>
                      </li>
                      <li onClick={() => deleteCollectionAccessControl.mutate({ accessControlId: accessControl.id })}>
                        <a>Remove</a>
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
