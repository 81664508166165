import { BotIcon } from 'lucide-react'
import { Container, SettingsPagesHeader } from '../../components'
import { Trans, useTranslation } from 'react-i18next'
import { WorkspaceUserContactsTable } from './components/WorkspaceUserContactsTable'
import { WorkspaceAssistantForm } from './components/WorkspaceAssistantForm'

export function WorkspaceAssistantPage () {
  const { t } = useTranslation()

  return (
    <>
      <SettingsPagesHeader
        title={t('Assistant')}
        description={(
          <span>
            <Trans
              i18nKey={'WorkspaceAssistantPage:Configure a chat for Cogfy Assistant to use'}
              components={{
                url: (
                  <a
                    className="link link-primary"
                    href="https://wa.me/5511935028768"
                    target="_blank"
                    rel="noreferrer"
                  />
                )
              }}
            />
          </span>
        )}
      >
        <BotIcon size={48} />
      </SettingsPagesHeader>
      <Container className="mt-12">
        <div className='flex flex-col items-center gap-6 max-w-[550px] mx-auto'>
          <div className="w-full p-6 rounded-2xl bg-background-default">
            <WorkspaceAssistantForm />
          </div>
          <div className="w-full p-6 rounded-2xl bg-background-default">
            <WorkspaceUserContactsTable />
          </div>
        </div>
      </Container>
    </>
  )
}
