import { useTranslation } from 'react-i18next'
import { Select } from '../../../components'
import { useEffect, useState } from 'react'
import { useSelectedFieldToUpdate } from '../../../lib'
import { NumberField, NumberFieldDataStyle } from '@indigohive/cogfy-types'
import { FieldConfigurationsProps } from '../FieldConfigurations'

const styleOptions = [
  { value: 'decimal', label: 'Decimal' },
  { value: 'currency', label: 'Currency' },
  { value: 'percent', label: 'Percent' }
]

const currencyoptions = [
  { value: 'BRL', label: 'Real' },
  { value: 'USD', label: 'Dollar' },
  { value: 'EUR', label: 'Euro' },
  { value: 'JPY', label: 'Yen' }
]

export function NumberConfigurations (props: FieldConfigurationsProps) {
  const { state, controller } = props

  const { t } = useTranslation()
  const field = useSelectedFieldToUpdate(state) as NumberField
  const [style, setStyle] = useState(field.data?.number?.style ?? 'decimal')
  const [currency, setCurrency] = useState(field.data?.number?.currency ?? 'BRL')

  useEffect(() => {
    setStyle(field.data?.number?.style ?? 'decimal')
    setCurrency(field.data?.number?.currency ?? 'BRL')
  }, [field.data])

  return (
    <>
      <Select
        label={t('Style')}
        size="sm"
        value={style}
        onChange={event => setStyle(event.target.value as NumberFieldDataStyle)}
        onBlur={_ => controller.onUpdateFieldData(field.id, { number: { ...field.data?.number, style } })}
        options={styleOptions}
      />
      {style === 'currency' && (
        <Select
          label={t('Currency')}
          size="sm"
          value={currency}
          onChange={event => setCurrency(event.target.value)}
          onBlur={_ => controller.onUpdateFieldData(field.id, { number: { ...field.data?.number, currency } })}
          options={currencyoptions}
        />
      )}
    </>
  )
}
