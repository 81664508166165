import { useTranslation } from 'react-i18next'

export function PendingCell () {
  const { t } = useTranslation()

  return (
    <span className="px-2 py-1 flex items-center">
      <em>{t('Calculating')}...</em>
      <span className="loading loading-ring text-primary loading-xs ml-2"></span>
    </span>
  )
}
