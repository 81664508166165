import { UUID } from '@indigohive/cogfy-types'
import { useMutation } from '@tanstack/react-query'
import clsx from 'clsx'
import { DownloadIcon, PauseIcon, PlayIcon, UserRoundIcon } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import WaveSurfer, { WaveSurferOptions } from 'wavesurfer.js'
import { Button } from '../../../components'
import { useCogfy } from '../../../hooks'
import { WhatsAppContentProps } from './WhatsAppContent'
import './WhatsAppContentAudio.css'

const formWaveSurferOptions: (ref: HTMLDivElement) => WaveSurferOptions = ref => ({
  container: ref,
  waveColor: '#e7e8e9',
  progressColor: '#6f8171',
  cursorColor: '#29afdf',
  cursorWidth: 12,
  barWidth: 3,
  barRadius: 3,
  barGap: 2,
  height: 24,
  width: 200,
  fillParent: true,
  normalize: true,
  dragToSeek: true
})

export function WhatsAppContentAudio (props: WhatsAppContentProps) {
  const { files, fromAuthenticatedUser } = props
  const cogfy = useCogfy()
  const waveformRef = useRef<HTMLDivElement | null>(null)
  const wavesurfer = useRef<WaveSurfer | null>(null)
  const [playing, setPlay] = useState(false)
  const [playbackRate, setPlaybackRate] = useState(1)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [audioUrl, setAudioUrl] = useState('')
  const [audioReady, setAudioReady] = useState(false)

  const durationMinutes = Math.floor(duration / 60)
  const durationSeconds = duration % 60
  const formattedDuration = `${durationMinutes}:${durationSeconds.toString().padStart(2, '0')}`

  const currentTimeMinutes = Math.floor(currentTime / 60)
  const currentTimeSeconds = currentTime % 60
  const formattedCurrentTime = `${currentTimeMinutes}:${currentTimeSeconds.toString().padStart(2, '0')}`

  const isPlayingAudio = playing || (!playing && currentTime > 0)

  // We are assuming that there is only one audio file
  const file = files[0]

  const getAudioUrl = useMutation({
    mutationFn: async (chatMessageFileId: UUID) => await cogfy.getChatMessageFileById({ chatMessageFileId }),
    onSuccess: (data) => setAudioUrl(data.url)
  })

  const handlePlayPauseClick = () => {
    setPlay(!playing)
    wavesurfer.current?.playPause()
  }

  const handlePlaybackRateClick = () => {
    const nextPlaybackRate = playbackRate === 1
      ? 1.5
      : playbackRate === 1.5
        ? 2
        : 1

    setPlaybackRate(nextPlaybackRate)
  }

  useEffect(() => {
    setPlay(false)

    const options = formWaveSurferOptions(waveformRef.current!)

    wavesurfer.current = WaveSurfer.create(options)
    wavesurfer.current.load(audioUrl)

    const handleDecode = (duration: number) => {
      setDuration(Math.floor(duration))
    }

    const handleReady = () => {
      setAudioReady(true)
    }

    const handleTimeUpdate = (currentTime: number) => {
      setCurrentTime(Math.floor(currentTime))
    }

    const handleFinish = () => {
      wavesurfer.current?.setTime(0)
      setPlay(false)
    }

    wavesurfer.current.on('decode', handleDecode)
    wavesurfer.current.on('ready', handleReady)
    wavesurfer.current.on('timeupdate', handleTimeUpdate)
    wavesurfer.current.on('finish', handleFinish)

    return () => {
      wavesurfer.current?.un('decode', handleDecode)
      wavesurfer.current?.un('ready', handleReady)
      wavesurfer.current?.un('timeupdate', handleTimeUpdate)
      wavesurfer.current?.un('finish', handleFinish)
      wavesurfer.current?.destroy()
    }
  }, [audioUrl])

  useEffect(() => {
    wavesurfer.current?.setPlaybackRate(playbackRate)
  }, [playbackRate])

  return (
    <div className="flex gap-2 items-center h-[70px]">
      <div
        className={clsx(
          fromAuthenticatedUser && 'order-first',
          !fromAuthenticatedUser && 'order-last'
        )}
      >
        {!isPlayingAudio && (
          <div className="chat-image avatar placeholder">
            <div className="w-10 rounded-full text-white bg-[#dfe5e7]">
              <UserRoundIcon size={24} />
            </div>
          </div>
        )}
        {isPlayingAudio && (
          <Button
            onClick={() => handlePlaybackRateClick()}
            className="whitespace-nowrap rounded-full text-white bg-[#54656f80] w-[50px]"
            size="xs"
          >
            {playbackRate} x
          </Button>
        )}
      </div>
      <>
        {audioReady && (
          <Button className="text-[#6f8171]" onClick={() => handlePlayPauseClick()} size="sm" circle ghost>
            {playing && <PauseIcon size={20} />}
            {!playing && <PlayIcon size={20} />}
          </Button>
        )}
        {!audioReady && (
          <div className="flex items-center gap-2">
            <Button
              onClick={() => getAudioUrl.mutate(file.id)}
              className="text-[#6f8171] border-1 border-[#6f8171] bg-transparent"
              size="sm"
              disabled={getAudioUrl.isPending}
              circle
            >
              <DownloadIcon size={16} />
            </Button>
            <div className="w-[200px] h-1 bg-[#dfe5e7] rounded-full" />
          </div>
        )}
      </>
      <div className="mt-1">
        <div
          id="waveform"
          className="mb-2"
          ref={waveformRef}
        />
        {audioReady && (
          <div
            className={
              clsx(
                'text-xs text-gray-600',
                audioUrl && 'mb-[-20px]',
                !audioUrl && 'mb-1'
              )
            }
          >
            {playing && <div>{formattedCurrentTime}</div>}
            {!playing && currentTime > 0 && <div>{formattedCurrentTime}</div>}
            {!playing && currentTime === 0 && <div>{formattedDuration}</div>}
          </div>
        )}
      </div>
    </div>
  )
}
