import {
  InfoIcon,
  CheckCircleIcon,
  TriangleAlertIcon,
  XIcon
} from 'lucide-react'
import { PropsWithChildren, ReactNode, useCallback, useState } from 'react'
import { ToastsContext } from '../../contexts'
import clsx from 'clsx'
import { Button } from '../Button'

type ToastValue = {
  variant: 'info' | 'success' | 'warning' | 'error'
  message: ReactNode
}

export type ToastsProviderProps = PropsWithChildren

export function ToastsProvider (props: ToastsProviderProps) {
  const [toasts, setToasts] = useState<ToastValue[]>([])

  const handleRemoveToast = useCallback(
    (toast: ToastValue) => setToasts((prevToasts) => prevToasts.filter((t) => t !== toast)),
    []
  )

  const handleAddToast = useCallback(
    (variant: ToastValue['variant'], message: ReactNode) => {
      const toast: ToastValue = { variant, message }

      setToasts((prevToasts) => [...prevToasts, toast])

      setTimeout(() => handleRemoveToast(toast), 4_000)
    },
    [handleRemoveToast]
  )

  return (
    <ToastsContext.Provider
      value={{
        info: (message) => handleAddToast('info', message),
        success: (message) => handleAddToast('success', message),
        warning: (message) => handleAddToast('warning', message),
        error: (message) => handleAddToast('error', message)
      }}
    >
      {props.children}
      <div className="toast toast-start">
        {toasts.map((toast, index) => (
          <div
            key={index}
            role="alert"
            className={clsx(
              'w-96',
              'whitespace-break-spaces',
              'alert',
              {
                'alert-success': toast.variant === 'success',
                'alert-info': toast.variant === 'info',
                'alert-warning': toast.variant === 'warning',
                'alert-error': toast.variant === 'error'
              }
            )}
          >
            {toast.variant === 'info' && <InfoIcon size={24} />}
            {toast.variant === 'success' && <CheckCircleIcon size={24} />}
            {toast.variant === 'warning' && <TriangleAlertIcon size={24} />}
            {toast.variant === 'error' && <XIcon size={24} />}
            <span>{toast.message}</span>
            <Button
              size="sm"
              ghost
              onClick={() => handleRemoveToast(toast)}
            >
              Dismiss
            </Button>
          </div>
        ))}
      </div>
    </ToastsContext.Provider>
  )
}
