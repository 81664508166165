import type {
  GetUserGroupResult,
  GetUserGroupsPageQuery,
  GetUserGroupsPageResult,
  UUID,
  UpdateUserGroupNameCommand,
  UpdateUserGroupDescriptionCommand,
  GetUserGroupMembersResult,
  UpdateUserGroupIsDefaultCommand
} from '@indigohive/cogfy-types'
import { BaseClient } from './base-client'

export class UserGroupsClient extends BaseClient {
  async getById (
    userGroupId: UUID,
    signal?: AbortSignal
  ): Promise<GetUserGroupResult> {
    return (await this.axios.get(`/user-groups/${userGroupId}`, { signal })).data
  }

  async getPage (
    params: GetUserGroupsPageQuery,
    signal?: AbortSignal
  ): Promise<GetUserGroupsPageResult> {
    return (await this.axios.get('/user-groups', { params, signal })).data
  }

  async getMembers (
    userGroupId: UUID,
    signal?: AbortSignal
  ): Promise<GetUserGroupMembersResult> {
    return (await this.axios.get(`/user-groups/${userGroupId}/members`, { signal })).data
  }

  async updateDescription (
    userGroupId: UUID,
    data: UpdateUserGroupDescriptionCommand,
    signal?: AbortSignal
  ): Promise<void> {
    await this.axios.patch(`/user-groups/${userGroupId}/description`, data, { signal })
  }

  async updateName (
    userGroupId: UUID,
    data: UpdateUserGroupNameCommand,
    signal?: AbortSignal
  ): Promise<void> {
    await this.axios.patch(`/user-groups/${userGroupId}/name`, data, { signal })
  }

  async updateIsDefault (
    userGroupId: UUID,
    data: UpdateUserGroupIsDefaultCommand,
  ): Promise<void> {
    await this.axios.patch(`/user-groups/${userGroupId}/default`, data)
  }
}
