import { GetWorkspaceResult, GetWorkspacesListResult } from '@indigohive/cogfy-types'
import { WorkspaceLogo } from '../../../WorkspaceLogo'
import { ChevronsRight, FolderIcon, PlusIcon, TableIcon } from 'lucide-react'
import { useCogfy, useDrawer, usePermissions } from '../../../../hooks'
import { Link, useNavigate } from 'react-router-dom'
import { GetCollectionsTreeResultData } from '@indigohive/cogfy-types/endpoints/getCollectionsTree'
import { OverlayContent } from '../../../OverlayContent'
import { DrawerCreateCollectionMenu } from '../DrawerCreateCollectionMenu'
import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { CreateFolderCommand } from '@indigohive/cogfy-types/endpoints/createFolder'
import { DrawerWorkspacesMenu } from '../DrawerWorkspacesMenu'

export type DrawerCollapsedProps = {
  collections: GetCollectionsTreeResultData[]
  workspace: GetWorkspaceResult
  workspaces?: GetWorkspacesListResult
  workspacesLoading?: boolean
  collectionsLoading?: boolean
  onCollectionsChange?: () => void
}

export function DrawerCollapsed (props: DrawerCollapsedProps) {
  const { workspace, collections } = props

  const cogfy = useCogfy()
  const navigate = useNavigate()
  const permissions = usePermissions()
  const canCreateCollection = permissions.hasPermission?.('collection:create', null)
  const [createCollectionMenuEl, setCreateCollectionMenuEl] = useState<HTMLElement | null>(null)
  const [workspacesMenuEl, setWorkspacesMenuEl] = useState<HTMLElement | null>(null)
  const { setOpen } = useDrawer()

  const createFolder = useMutation({
    mutationFn: (data: CreateFolderCommand) => cogfy.createFolder(data),
    onSuccess: (result) => {
      props.onCollectionsChange?.()
      navigate(`/${workspace.slug}/${result.id}`)
    }
  })

  return (
    <div className="px-3 py-6 borderw-fit flex justify-center overflow-visible">
      <div className="flex flex-col items-center gap-6 w-11">
        <div
          className="btn btn-sm btn-square btn-ghost"
          role="button"
          onClick={() => setOpen?.(true)}
        >
          <ChevronsRight size={20} />
        </div>
        <div
          className="btn btn-md btn-square btn-ghost"
          role="button"
          onClick={event => setWorkspacesMenuEl(event.currentTarget)}
        >
          <WorkspaceLogo
            className="w-8 rounded-lg"
            name={workspace.name}
            logoUrl={workspace.logoUrl}
          />
        </div>
        {canCreateCollection && (
          <div
            className="btn btn-sm btn-square btn-ghost"
            role="button"
            onClick={event => setCreateCollectionMenuEl(event.currentTarget)}
          >
            <PlusIcon size={20} />
          </div>
        )}

        <div className="flex flex-col justify-center items-center gap-2 border-t-2 border-t-base-300 pt-5">
          {props.collectionsLoading && new Array(5).fill(0).map((_, index) => (
            <div key={index} className="skeleton h-4 w-4 rounded-md" />
          ))}

          {!props.collectionsLoading && collections.map(collection => (
            <Link
              className="btn btn-sm btn-square btn-ghost"
              to={`/${workspace.slug}/${collection.id}`}
              key={collection.id}
            >
              {collection.type === 'folder' ? <FolderIcon size={16} /> : <TableIcon size={16} />}
            </Link>
          ))}
        </div>

        <OverlayContent
          open={Boolean(createCollectionMenuEl && canCreateCollection)}
          anchorEl={createCollectionMenuEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={() => setCreateCollectionMenuEl(null)}
        >
          <DrawerCreateCollectionMenu
            workspace={workspace.slug}
            onCreateCollectionClick={() => {
              setCreateCollectionMenuEl(null)
            }}
            onCreateFolderClick={() => {
              setCreateCollectionMenuEl(null)
              createFolder.mutate({ parentId: null })
            }}
          />
        </OverlayContent>

        <OverlayContent
          open={Boolean(workspacesMenuEl)}
          anchorEl={workspacesMenuEl}
          anchorPosition={{ top: 140, left: 10 }}
          onClose={() => setWorkspacesMenuEl(null)}
        >
          <DrawerWorkspacesMenu
            workspaces={props.workspaces}
            currentWorkspace={workspace}
            onSettingsClick={() => {
              setWorkspacesMenuEl(null)
              navigate(`/${workspace.slug}/settings`)
            }}
            onClosePicker={() => setWorkspacesMenuEl(null)}
          />
        </OverlayContent>
      </div>
    </div>
  )
}
