import { useEffect } from 'react'
import { useCogfy } from './use-cogfy'

export function useRoom (room: string) {
  const cogfy = useCogfy()

  useEffect(() => {
    cogfy.join(room)

    return () => {
      cogfy.leave(room)
    }
  }, [room])
}
