import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ChatMiddlewareDataFormProps } from '../ChatMiddlewareDataFormProps'
import { Mentions, MentionsValue } from '../../Mentions'

export function InstructionsForm (props: ChatMiddlewareDataFormProps) {
  const { data, fields, onChange } = props
  const { t } = useTranslation()
  const [instructions, setInstructions] = useState<MentionsValue>({
    parts: data.instructions?.template?.strings ?? [],
    options: data.instructions?.template?.args?.map((arg: { fieldId: string }) => ({
      id: arg.fieldId,
      name: fields?.find(field => field.id === arg.fieldId)?.name ?? '???'
    })) ?? []
  })

  return (
    <>
      <div className="label">
        <span className="label-text">
          {t('Instructions')}
        </span>
      </div>
      <Mentions
        value={instructions}
        onChange={value => {
          setInstructions(value)
          onChange({
            ...data,
            instructions: {
              type: 'template',
              template: {
                strings: value.parts,
                args: value.options.map(option => ({ fieldId: option.id }))
              }
            }
          })
        }}
        options={fields?.map(field => ({ id: field.id, name: field.name })) ?? []}
      />
    </>
  )
}
