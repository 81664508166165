import { ChatField } from '@indigohive/cogfy-types'
import { useTranslation } from 'react-i18next'
import { CollectionState } from '../../../../../lib'
import { CollectionPageController } from '../../../../../pages/CollectionPageV2/collection-page-controller'
import { ChatWhatsAppConnection } from './ChatWhatsAppConnection'

export type ChatConnectionsProps = {
  controller: CollectionPageController
  state: CollectionState
  field: ChatField
}

export function ChatConnections (props: ChatConnectionsProps) {
  const { controller, state, field } = props
  const { t } = useTranslation()

  return (
    <>
      <div className="text-sm font-bold">
        {t('Connections')}
      </div>
      <ChatWhatsAppConnection
        field={field}
        state={state}
        controller={controller}
      />
    </>
  )
}
