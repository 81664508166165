import type {
  CollectionDeletedEvent,
  CollectionRenamedEvent,
  UUID
} from '@indigohive/cogfy-types'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useCogfy } from './use-cogfy'
import { useNavigate, useParams } from 'react-router-dom'
import { GetCollectionsQuery, GetCollectionsResult, GetCollectionsResultData } from '@indigohive/cogfy-types/endpoints/getCollections'

export function useCollections (data: GetCollectionsQuery = {}) {
  const cogfy = useCogfy()

  const getCollectionsPage = useQuery({
    queryKey: ['getCollectionsPage', data],
    queryFn: ({ signal }) => cogfy.getCollections(data, { signal })
  })
  const queryClient = useQueryClient()
  const urlParams = useParams<{ workspace: string, collectionId: UUID }>()
  const navigate = useNavigate()

  const collectionId = urlParams.collectionId
  const workspace = urlParams.workspace

  useEffect(() => {
    const onCollectionCreated = () => {
      getCollectionsPage.refetch()
    }

    const onCollectionDeleted = (event: CollectionDeletedEvent) => {
      getCollectionsPage.refetch()

      if (event.data.collectionId === collectionId) {
        navigate(`/${workspace}`)
      }
    }

    const onCollectionNameUpdated = (event: CollectionRenamedEvent) => {
      queryClient.setQueryData<GetCollectionsResult>(
        ['getCollectionsPage', data],
        (oldData) => {
          if (oldData) {
            const newData = [...oldData.data]

            for (let index = 0; index < newData.length; index++) {
              newData[index] = updateCollectionName(newData[index], event.data.collectionId, event.data.name)
            }

            return { data: newData }
          }
        }
      )
    }

    cogfy.collections.onCollectionCreated.add(onCollectionCreated)
    cogfy.transactions.onCollectionDeleted.add(onCollectionDeleted)
    cogfy.transactions.onCollectionRenamed.add(onCollectionNameUpdated)

    return () => {
      cogfy.collections.onCollectionCreated.remove(onCollectionCreated)
      cogfy.transactions.onCollectionDeleted.remove(onCollectionDeleted)
      cogfy.transactions.onCollectionRenamed.remove(onCollectionNameUpdated)
    }
  }, [collectionId, queryClient])

  return getCollectionsPage
}

const updateCollectionName = (
  collection: GetCollectionsResultData,
  id: string,
  name: string | null
): GetCollectionsResultData => {
  if (collection.id === id) {
    return {
      ...collection,
      name
    }
  }

  return collection
}
