import { useEffect, useState } from 'react'
import { CollectionState } from '../collection-state'

export function useSelectedRange (state: CollectionState) {
  const [selectedRange, setSelectedRange] = useState(state.selectedRange)

  useEffect(() => {
    const selectedRangeChanged = () => {
      setSelectedRange(state.selectedRange)
    }

    state.addEventListener('selectedRangeChanged', selectedRangeChanged)

    return () => {
      state.removeEventListener('selectedRangeChanged', selectedRangeChanged)
    }
  })

  return selectedRange
}
