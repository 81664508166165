import { CollectionState } from '../../../lib'
import { useEffect, useState } from 'react'

export function useSelectedFieldToUpdate (state: CollectionState) {
  const [selectedFieldToUpdate, setSelectedFieldToUpdate] = useState(state.selectedFieldToUpdate)

  useEffect(() => {
    const handleSelectedFieldToUpdateChanged = () => setSelectedFieldToUpdate(state.selectedFieldToUpdate)

    state.addEventListener('selectedFieldToUpdateChanged', handleSelectedFieldToUpdateChanged)

    return () => state.removeEventListener('selectedFieldToUpdateChanged', handleSelectedFieldToUpdateChanged)
  }, [state])

  return selectedFieldToUpdate
}
