import { Toggle } from '../../../../../components'
import { CollectionPageController } from '../../../collection-page-controller'
import { CollectionState, useSelectedFieldToUpdate } from '../../../../../lib'
import { FieldOperationConfigBooleanInput, OperationInputType, OperationSchemaInput } from '@indigohive/cogfy-types'
import { useTranslation } from 'react-i18next'

export type FieldOperationBooleanInputProps = {
  controller: CollectionPageController
  state: CollectionState
  inputName: string
  input: OperationSchemaInput<OperationInputType>
}

export function FieldOperationBooleanInput (props: FieldOperationBooleanInputProps) {
  const { controller, state, inputName, input } = props

  const selectedField = useSelectedFieldToUpdate(state)
  const { t } = useTranslation()

  const configInput = selectedField?.operationConfig?.[inputName] as FieldOperationConfigBooleanInput | undefined
  const value = configInput?.boolean.value ?? input.default as boolean ?? false

  return (
    <Toggle
      color="primary"
      label={t(`operationForm:${input.displayName ?? inputName}`)}
      checked={value}
      onChange={event => {
        controller.onOperationChange(
          selectedField!.id,
          selectedField?.operation ?? null,
          selectedField!.recalculateStrategy,
          {
            ...selectedField?.operationConfig,
            [inputName]: { type: 'boolean', boolean: { value: event.target.checked } }
          }
        )
      }}
    />
  )
}
