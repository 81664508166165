import type {
  ForgotPasswordCommand,
  ResetPasswordCommand,
  SignInCommand,
  SignInGoogleCommand,
  SignInResult,
  TotpFinishSetupCommand,
  TotpSignInCommand,
  TotpStartSetupCommand,
  TotpStartSetupResult,
} from '@indigohive/cogfy-types'
import { BaseClient } from './base-client'

export class AuthenticationClient extends BaseClient {
  /**
   * Calls the `POST /authentication/forgot-password` endpoint.
   * @param data The request body.
   * @returns A promise that resolves when the request is complete.
   */
  async forgotPassword (
    data: ForgotPasswordCommand
  ): Promise<void> {
    return (await this.axios.post('/authentication/forgot-password', data)).data
  }

  /**
   * Calls the `GET /authentication/authenticated-user` endpoint.
   * @param signal The abort signal.
   * @returns The response body.
   */
  async getAuthenticatedUser (
    signal?: AbortSignal
  ): Promise<SignInResult | null> {
    return (await this.axios.get('/authentication/authenticated-user', { signal })).data
  }

  /**
   * Calls the `POST /authentication/reset-password` endpoint.
   * @param data The request body.
   * @returns A promise that resolves when the request is complete.
   */
  async resetPassword (
    data: ResetPasswordCommand
  ): Promise<void> {
    return (await this.axios.post('/authentication/reset-password', data)).data
  }

  /**
   * Calls the `POST /authentication/setup-totp` endpoint.
   * @returns The response body.
   */
  async totpStartSetup (command: TotpStartSetupCommand): Promise<TotpStartSetupResult> {
    return (await this.axios.post('/authentication/totp-start-setup', command)).data
  }

  /**
   * Calls the `POST /authentication/finish-totp-setup` endpoint.
   * @param command The request body.
   */
  async totpFinishSetup (command: TotpFinishSetupCommand): Promise<SignInResult> {
    return (await this.axios.post('/authentication/totp-finish-setup', command)).data
  }

  /**
   * Calls the `POST /authentication/totp-sign-in` endpoint.
   * @param command The request body.
   * @returns The response body.
   */
  async totpSignIn (command: TotpSignInCommand): Promise<SignInResult> {
    return (await this.axios.post('/authentication/totp-sign-in', command)).data
  }

  /**
   * Calls the `POST /authentication/sign-in` endpoint.
   * @param command The request body.
   * @returns The response body.
   */
  async signIn (
    command: SignInCommand
  ): Promise<SignInResult> {
    return (await this.axios.post('/authentication/sign-in', command)).data
  }

  /**
   * Calls the `POST /authentication/sign-in/google` endpoint.
   * @param command The request body.
   * @returns The response body.
  */
  async signInGoogle (
    command: SignInGoogleCommand
  ): Promise<SignInResult> {
    return (await this.axios.post('/authentication/sign-in/google', command)).data
  }

  /**
   * Calls the `POST /authentication/sign-out` endpoint.
   */
  async signOut (): Promise<void> {
    await this.axios.post('/authentication/sign-out')
  }
}
