import clsx from 'clsx'
import { Checkbox } from '../../../../../components'
import { CollectionStateRecord } from '../../../../../lib'
import { memo } from 'react'

export type CollectionTableRowCheckboxProps = {
  record: CollectionStateRecord
  checked?: boolean
  disabled?: boolean
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    record: CollectionStateRecord
  ) => void
}

function _CollectionTableRowCheckbox (props: CollectionTableRowCheckboxProps) {
  const { record, checked, disabled, onChange } = props

  return (
    <label
      className={clsx(
        'group',
        'flex',
        'items-start',
        'py-1',
        'justify-center',
        'w-8',
        'min-h-7',
        !disabled && 'cursor-pointer',
        disabled && 'invisible'
      )}
    >
      <Checkbox
        className={clsx(
          'opacity-0',
          'group-hover:opacity-100',
          'transition-opacity',
          'duration-200',
          checked && 'opacity-100'
        )}
        size="sm"
        color="info"
        checked={checked}
        disabled={disabled}
        onChange={event => onChange?.(event, record)}
      />
    </label>
  )
}

export const CollectionTableRowCheckbox = memo(_CollectionTableRowCheckbox)
