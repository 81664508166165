import { useTranslation } from 'react-i18next'
import { FieldFilterMenuProps } from '../FieldFilterChip'
import { Select } from '../../../components'
import { isEqualsFilter } from '@indigohive/cogfy-common'

export function ChatFilterMenu (props: FieldFilterMenuProps) {
  const { controller, filter, field, viewFilter } = props

  const { t } = useTranslation()

  return (
    <div className="space-y-2 p-2">
      <div className="flex items-center gap-x-2">
        <div className="whitespace-nowrap">{field.name}</div>
        <Select
          size="xs"
          className="border-none"
          value="is"
          options={[
            { label: t('filters:with messages from'), value: 'equals' }
          ]}
        />
      </div>
      <Select
        size="sm"
        value={isEqualsFilter(filter) ? filter.equals as string : ''}
        options={[
          { label: t('Select a option'), value: '', disabled: true },
          { label: t('filters:today'), value: 'today' },
          { label: t('filters:yesterday'), value: 'yesterday' },
          { label: t('filters:this week'), value: 'this week' },
          { label: t('filters:this month'), value: 'this month' }
        ]}
        onChange={event => controller.onViewFilterChange(
          viewFilter,
          {
            property: field.id,
            propertyType: 'chat',
            equals: event.target.value
          }
        )}
      />
    </div>
  )
}
