import { ArrowDownIcon, ArrowUpIcon, XIcon } from 'lucide-react'
import { RecordOrder } from '@indigohive/cogfy-types'
import { useRef } from 'react'
import { useOnClickOutside } from '../../../../../hooks'
import { Button, Select } from '../../../../../components'
import { CollectionState, useFields } from '../../../../../lib'
import { CollectionPageController } from '../../../collection-page-controller'

export type OrderByChipProps = {
  state: CollectionState
  controller: CollectionPageController
  recordOrder: RecordOrder
}

const orderByOptions = [
  { value: 'asc', label: 'ascendent' },
  { value: 'desc', label: 'descendent' }
]

export function OrderByChip (props: OrderByChipProps) {
  const { state, controller, recordOrder } = props

  const ref = useRef<HTMLDetailsElement>(null)
  const fields = useFields(state)
  useOnClickOutside(ref, () => { ref.current!.open = false })

  const field = fields?.find(field => field.id === recordOrder.fieldId)

  return (
    <details ref={ref} className="dropdown dropdown-end">
      <summary className="badge badge-lg hover:cursor-pointer flex h-5 max-w-52 badge-outline badge-primary bg-primary-content">
        <span className="mr-2">
          {recordOrder.direction === 'asc' && <ArrowUpIcon size={12} />}
          {recordOrder.direction === 'desc' && <ArrowDownIcon size={12} />}
        </span>
        <span className="text-xs truncate">
          {field?.name}
        </span>
        {field && (
          <Button
            size="xs"
            ghost
            circle
            className="ml-1"
            onClick={() => controller.onDeleteSortField(field.id)}
          >
            <XIcon size={12} />
          </Button>
        )}
      </summary>
      <ul className="p-4 shadow menu dropdown-content z-[2] bg-base-100 rounded-box w-52">
        <div className="flex gap-4 items-center">
          <a className="text-xs">{field?.name}</a>
          <Select
            options={orderByOptions}
            size="xs"
            onChange={(event) => field && controller.onUpdateSortField(field.id, event.target.value as 'asc' | 'desc')}
            value={recordOrder.direction}
          />
        </div>
      </ul>
    </details>
  )
}
