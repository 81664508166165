import { FieldData, RecordProperty, UUID } from '@indigohive/cogfy-types'
import { v4 as uuid } from 'uuid'
import type {
  CollectionStateCell,
  CollectionStateClickedCell,
  CollectionStateEventListener,
  CollectionStateEventType,
  CollectionStateField,
  CollectionStateRange,
  CollectionStateRecord,
  CollectionStateSelectedField,
  CollectionStateView,
  CollectionStateViewField
} from './types'

export class CollectionState {
  stateId: string
  id: UUID
  private _activeViewId: UUID | null = null
  private _clickedCell: CollectionStateClickedCell | null = null
  private _countRecords: number | null = null
  private _fields: CollectionStateField[] | null = null
  private _fieldsById: Record<UUID, CollectionStateField> | null = null
  private _name: string | null = null
  private _locked: boolean | null = null
  private _records: CollectionStateRecord[] | null = null
  private _searchRecords: string | null = null
  private _selectedCell: CollectionStateCell | null = null
  private _selectedField: CollectionStateSelectedField | null = null
  private _selectedTitleFieldId: UUID | null = null
  private _selectedFieldToUpdate: CollectionStateField | null = null
  private _selectedRange: CollectionStateRange | null = null
  private _selectedRows: Record<string, CollectionStateRecord> = {}
  private _views: CollectionStateView[] | null = null
  private _viewFields: CollectionStateViewField[] | null = null
  private _eventListeners: Record<string, CollectionStateEventListener[]> = {}

  constructor (collectionId: UUID) {
    this.stateId = uuid()
    this.id = collectionId
  }

  get activeViewId () {
    return this._activeViewId
  }

  get clickedCell () {
    return this._clickedCell
  }

  get countRecords () {
    return this._countRecords
  }

  get fields () {
    return this._fields
  }

  get fieldsById () {
    return this._fieldsById
  }

  get name () {
    return this._name
  }

  get locked () {
    return this._locked
  }

  get records () {
    return this._records
  }

  get searchRecords () {
    return this._searchRecords
  }

  get selectedCell () {
    return this._selectedCell
  }

  get selectedField () {
    return this._selectedField
  }

  get selectedTitleFieldId () {
    return this._selectedTitleFieldId
  }

  get selectedFieldToUpdate () {
    return this._selectedFieldToUpdate
  }

  get selectedRange () {
    return this._selectedRange
  }

  get selectedRows () {
    return this._selectedRows
  }

  get views () {
    return this._views
  }

  get viewFields () {
    return this._viewFields
  }

  setActiveViewId (viewId: UUID | null): void {
    this._activeViewId = viewId
    this._dispatch('activeViewIdChanged')
  }

  setClickedCell (cell: CollectionStateClickedCell | null): void {
    this._clickedCell = cell
    this._dispatch('clickedCellChanged')
  }

  setCountRecords (count: number | null): void {
    this._countRecords = count
    this._dispatch('countRecordsChanged')
  }

  setFields (fields: CollectionStateField[]): void {
    this._fields = fields
    this._fieldsById = Object.fromEntries(fields.map(field => [field.id, field]))
    this._dispatch('fieldsChanged')
  }

  setName (name: string | null): void {
    this._name = name
    this._dispatch('nameChanged')
  }

  setLocked (locked: boolean): void {
    this._locked = locked
    this._dispatch('lockedChanged')
  }

  setRecords (records: CollectionStateRecord[]): void {
    this._records = records
    this._dispatch('recordsChanged')
  }

  setSearchRecords (search: string | null): void {
    this._searchRecords = search
    this._dispatch('searchRecordsChanged')
  }

  setSelectedCell (cell: CollectionStateCell | null): void {
    this._selectedCell = cell
    this._dispatch('selectedCellChanged')
  }

  setSelectedCollectionTitleFieldId (fieldId: UUID | null): void {
    this._selectedTitleFieldId = fieldId
    this._dispatch('selectedCollectionTitleFieldId')
  }

  setSelectedField (field: CollectionStateSelectedField | null): void {
    this._selectedField = field
    this._dispatch('selectedFieldChanged')
  }

  setSelectedFieldToUpdate (field: CollectionStateField | null): void {
    this._selectedFieldToUpdate = field
    this._dispatch('selectedFieldToUpdateChanged')
  }

  setSelectedRange (range: CollectionStateRange | null): void {
    this._selectedRange = range
    this._dispatch('selectedRangeChanged')
  }

  setSelectedRows (rows: Record<string, CollectionStateRecord>): void {
    this._selectedRows = rows
    this._dispatch('selectedRowsChanged')
  }

  setViews (views: CollectionStateView[]): void {
    this._views = views
    this._dispatch('viewsChanged')
  }

  setViewFields (viewFields: CollectionStateViewField[]): void {
    this._viewFields = viewFields
    this._dispatch('viewFieldsChanged')
  }

  updateRecord (recordId: UUID, properties?: Record<UUID, RecordProperty>): void {
    if (this._records) {
      this._records = this._records?.map(record => {
        if (record.id !== recordId) {
          return record
        }

        return {
          ...record,
          properties: {
            ...record.properties,
            ...properties
          }
        }
      })
      this._dispatch('recordsChanged')
    }
  }

  updateField (fieldId: UUID, data?: FieldData): void {
    if (this._fields) {
      this._fields = this._fields.map(field => {
        if (field.id !== fieldId) {
          return field
        }

        return {
          ...field,
          data: {
            ...field.data,
            ...data
          }
        }
      })
      this._dispatch('fieldsChanged')
    }

    if (this.selectedFieldToUpdate?.id === fieldId) {
      this.setSelectedFieldToUpdate({
        ...this.selectedFieldToUpdate,
        data: {
          ...this.selectedFieldToUpdate?.data,
          ...data
        }
      })
    }
  }

  addEventListener (
    event: CollectionStateEventType,
    listener: CollectionStateEventListener
  ): void {
    if (this._eventListeners[event]) {
      this._eventListeners[event].push(listener)
    } else {
      this._eventListeners[event] = [listener]
    }
  }

  removeEventListener (
    event: CollectionStateEventType,
    listener: CollectionStateEventListener
  ): void {
    if (this._eventListeners[event]) {
      const index = this._eventListeners[event].indexOf(listener)

      if (index > -1) {
        this._eventListeners[event].splice(index, 1)
      }
    }
  }

  private _dispatch (event: CollectionStateEventType) {
    if (this._eventListeners[event]) {
      for (const listener of this._eventListeners[event]) {
        listener()
      }
    }
  }
}
