import { useEffect, useState } from 'react'
import { CollectionState } from '../..'

export function useCountRecords (state: CollectionState) {
  const [countRecords, setCountRecords] = useState(state.countRecords)

  useEffect(() => {
    const handleCountRecordsChanged = () => setCountRecords(state.countRecords)

    state.addEventListener('countRecordsChanged', handleCountRecordsChanged)

    return () => state.removeEventListener('countRecordsChanged', handleCountRecordsChanged)
  }, [state])

  return countRecords
}
