import { RunTransactionCommand, UUID } from '@indigohive/cogfy-types'
import { Command } from '../command-stack'
import { CollectionState } from '../collection-state'

export type RenameViewCommandData = {
  id: UUID
  name: string | null
}

export class RenameViewCommand implements Command<RunTransactionCommand> {
  private readonly _state: CollectionState
  private readonly _data: RenameViewCommandData
  private _previousName: string | null

  constructor (
    state: CollectionState,
    data: RenameViewCommandData
  ) {
    this._state = state
    this._data = data
    this._previousName = data.name
  }

  run (): RunTransactionCommand {
    const views = this._state.views?.map(view => {
      if (view.id === this._data.id) {
        this._previousName = view.name ?? null

        view.name = this._data.name
      }

      return view
    })

    this._state.setViews(views ?? [])

    return {
      operations: [
        {
          type: 'rename_view',
          data: {
            collectionId: this._state.id,
            viewId: this._data.id,
            name: this._data.name
          }
        }
      ]
    }
  }

  undo (): RunTransactionCommand {
    const views = this._state.views?.map(view => {
      if (view.id === this._data.id) {
        view.name = this._previousName
      }

      return view
    })

    this._state.setViews(views ?? [])

    return {
      operations: [
        {
          type: 'rename_view',
          data: {
            collectionId: this._state.id,
            viewId: this._data.id,
            name: this._previousName
          }
        }
      ]
    }
  }
}
