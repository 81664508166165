import { useEffect, useState } from 'react'
import { CollectionState } from '../..'

export function useClickCell (state: CollectionState) {
  const [clickedCell, setClickedCell] = useState(state.clickedCell)

  useEffect(() => {
    const handleClickedCellChanged = () => setClickedCell(state.clickedCell)

    state.addEventListener('clickedCellChanged', handleClickedCellChanged)

    return () => state.removeEventListener('clickedCellChanged', handleClickedCellChanged)
  }, [state])

  return clickedCell
}
