import type {
  GetApiKeyResult,
  GetApiKeysPageQuery,
  GetApiKeysPageResult,
  UUID
} from '@indigohive/cogfy-types'
import { BaseClient } from './base-client'

export class ApiKeysClient extends BaseClient {
  /**
   * Calls the `GET /api-keys/:apiKeyId` endpoint.
   * @param apiKeyId The api key id.
   * @param signal The abort signal.
   * @returns A promise that resolves to the response body.
   */
  async getById (
    apiKeyId: UUID | string,
    signal?: AbortSignal
  ): Promise<GetApiKeyResult> {
    return (await this.axios.get(`/api-keys/${apiKeyId}`, { signal })).data
  }

  /**
   * Calls the `GET /api-keys` endpoint.
   * @param params The request query params.
   * @param signal The abort signal.
   * @returns A promise that resolves to the response body.
   */
  async getPage (
    params: GetApiKeysPageQuery,
    signal?: AbortSignal
  ): Promise<GetApiKeysPageResult> {
    return (await this.axios.get('/api-keys', { params, signal })).data
  }
}
