import { WhatsAppContentProps } from './WhatsAppContent'

export function WhatsAppContentLocation (props: WhatsAppContentProps) {
  const { contentData } = props

  const hasAllData = contentData.latitude && contentData.longitude && contentData.name && contentData.address

  const googleMapsLink = `https://maps.google.com/?q=${contentData.latitude},${contentData.longitude}`

  return (
    <div className="text-sm">
      {hasAllData && (
        <div className="px-1">
          <a className="text-[#027eb5] link link-hover" href={googleMapsLink}>{contentData.name}</a>
          <div className="text-xs">{contentData.address}</div>
        </div>
      )}
      {!hasAllData && (
        <a className="text-[#027eb5] link link-hover" href={googleMapsLink}>{googleMapsLink}</a>
      )}
    </div>
  )
}
