import clsx, { ClassValue } from 'clsx'
import { ReactElement, ReactNode, Ref, forwardRef, useCallback, useState } from 'react'
import { DataTableRowCheckbox } from './subcomponents/DataTableRowCheckbox'
import { DataTableHeadCheckbox } from './subcomponents/DataTableHeadCheckbox'
import { DataTableColHead } from './subcomponents/DataTableColHead'
import { DataTableHeadAddButton } from './subcomponents/DataTableHeadAddButton'
import { DataTableHeadMenuButton } from './subcomponents/DataTableHeadMenuButton'
import { DataTableRow } from './subcomponents/DataTableRow'
import { Skeleton } from '../Skeleton'
import { DataTableCell } from './subcomponents/DataTableCell'

export type DataTableCol<T> = {
  id: string
  label: ReactNode
  width: number
  icon?: ReactNode
  endIcons?: ReactNode[]
  wrap?: boolean
  render?: (row: T) => ReactNode
}

export type DataTableRange = {
  start: DataTableAddress
  end: DataTableAddress
}

export type DataTableAddress = {
  row: number
  col: number
}

export type DataTableProps<T> = {
  className?: ClassValue
  getRowKey: (row: T) => string
  cols?: DataTableCol<T>[]
  colsLoading?: boolean
  rows?: T[]
  rowsLoading?: boolean
  rowsError?: unknown
  emptyMessage?: ReactNode
  selectedCell?: DataTableAddress | null
  selectedRange?: DataTableRange | null
  selection?: Record<string, T>
  onSelectionChange?: (updateFn: (prev: Record<string, T>) => Record<string, T>) => void
  onColClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, col: DataTableCol<T>) => void
  onResize?: (col: DataTableCol<T>, change: number) => void
  onCellClick?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    row: T,
    col: DataTableCol<T>,
    rowIndex: number,
    colIndex: number
  ) => void
  onAddColClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onColsMenuClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void
}

export type ResizedCol<T> = {
  col: DataTableCol<T>
  change: number
}

function isInRange (range: DataTableRange, rowIndex: number, colIndex: number) {
  return (
    rowIndex >= range.start.row &&
    rowIndex <= range.end.row &&
    colIndex >= range.start.col &&
    colIndex <= range.end.col
  )
}

// https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref
type DataTableComponentType = <T>(p: DataTableProps<T> & { ref?: Ref<HTMLDivElement> }) => ReactElement

export const DataTable = forwardRef(
  function DataTable<T> (props: DataTableProps<T>, ref: React.Ref<HTMLDivElement>) {
    const {
      className,
      cols,
      colsLoading = false,
      rows,
      rowsLoading = false,
      rowsError,
      getRowKey,
      emptyMessage,
      selection = {},
      selectedCell,
      selectedRange,
      onSelectionChange
    } = props
    const [resizedCol, setResizedCol] = useState<ResizedCol<T> | null>(null)

    const handleDrag = useCallback((col: DataTableCol<T>, change: number) => {
      setResizedCol({ col, change })
    }, [])

    const handleResize = useCallback((col: DataTableCol<T>, change: number) => {
      props.onResize?.(col, change)
      setResizedCol(null)
    }, [props.onResize])

    const someSelected = Object.keys(selection).length > 0
    const allSelected = rows?.length === Object.keys(selection).length

    const handleHeadCheckboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        onSelectionChange?.(() => {
          return rows?.reduce<Record<string, T>>((acc, row) => {
            acc[getRowKey(row)] = row
            return acc
          }, {}) ?? {}
        })
      } else {
        onSelectionChange?.(() => ({}))
      }
    }, [onSelectionChange, rows, getRowKey])

    const handleRowCheckboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, row: T) => {
      if (event.target.checked) {
        onSelectionChange?.(prev => {
          return { ...prev, [getRowKey(row)]: row }
        })
      } else {
        onSelectionChange?.(prev => {
          const { [getRowKey(row)]: _, ...rest } = prev
          return rest
        })
      }
    }, [onSelectionChange, getRowKey])

    const calculateWidth = (col: DataTableCol<T>) => {
      return col.id === resizedCol?.col.id
        ? `${Math.max(col.width + resizedCol.change, 100)}px`
        : `${Math.max(col.width, 100)}px`
    }

    return (
      <div
        ref={ref}
        className={clsx('overflow-x-auto', 'outline-none', className)}
        tabIndex={0}
        onKeyDown={props.onKeyDown}
        onBlur={props.onBlur}
      >
        <div className="inline-flex flex-col min-w-full h-full">
          <div className="sticky top-0 z-[1] inline-flex flex-row border-y items-center text-sm h-9 box-border min-w-full bg-base-100">
            <DataTableHeadCheckbox
              checked={someSelected}
              disabled={rowsLoading}
              indeterminate={someSelected && !allSelected}
              onChange={handleHeadCheckboxChange}
            />
            {colsLoading && !cols && new Array(4).fill(0).map((_, index) => (
              <DataTableColHead key={index} loading />
            ))}
            {cols?.map(col => (
              <DataTableColHead
                key={col.id}
                col={col}
                width={calculateWidth(col)}
                onClick={props.onColClick}
                onDrag={handleDrag}
                onResize={handleResize}
              />
            ))}
            {cols && <DataTableHeadAddButton onClick={props.onAddColClick} />}
            {cols && <DataTableHeadMenuButton onClick={props.onColsMenuClick} />}
          </div>
          {(colsLoading || rowsLoading) && (
            new Array(5).fill(0).map((_, index) => (
              <DataTableRow key={index}>
                <DataTableRowCheckbox disabled />
                {colsLoading && (
                  new Array(4).fill(0).map((_, colIndex) => (
                    <DataTableCell
                      key={colIndex}
                      className={['flex', 'items-center', 'border-r', 'px-2', 'py-1']}
                      width="100px"
                    >
                      <Skeleton className="w-full h-3" />
                    </DataTableCell>
                  ))
                )}
                {cols?.map(col => (
                  <DataTableCell
                    key={col.id}
                    className={['flex', 'items-center', 'border-r', 'px-2', 'py-1']}
                    width={calculateWidth(col)}
                  >
                    <Skeleton className="w-full h-3" />
                  </DataTableCell>
                ))}
              </DataTableRow>
            ))
          )}
          <div className="h-[calc(100%-3px)] overflow-y-auto">
            {cols && rowsError !== undefined && (
              <DataTableRow>
                <DataTableRowCheckbox disabled />
                <DataTableCell className="px-2 py-1">
                  Error loading rows
                </DataTableCell>
              </DataTableRow>
            )}
            {cols && rows?.length === 0 && (
              <DataTableRow>
                <DataTableRowCheckbox disabled />
                <DataTableCell className="px-2 py-1">
                  {emptyMessage ?? 'No records found'}
                </DataTableCell>
              </DataTableRow>
            )}
            {cols && rows?.map((row, rowIndex) => (
              <DataTableRow
                key={getRowKey(row)}
                checked={Boolean(selection[getRowKey(row)])}
              >
                <DataTableRowCheckbox
                  row={row}
                  checked={Boolean(selection[getRowKey(row)])}
                  onChange={handleRowCheckboxChange}
                />
                {cols.map((col, colIndex) => (
                  <DataTableCell
                    key={col.id}
                    className="border-r"
                    onClick={props.onCellClick}
                    row={row}
                    col={col}
                    rowIndex={rowIndex}
                    colIndex={colIndex}
                    width={calculateWidth(col)}
                    wrap={col.wrap}
                    selected={selectedCell?.col === colIndex && selectedCell?.row === rowIndex}
                    selectedRange={Boolean(selectedRange && isInRange(selectedRange, rowIndex, colIndex))}
                  >
                    {col.render?.(row)}
                  </DataTableCell>
                ))}
                <div className="grow" />
              </DataTableRow>
            ))}
          </div>
        </div>
      </div>
    )
  }
) as DataTableComponentType
