import { PropsWithChildren } from 'react'
import { AppManager } from '../../lib'
import { AppManagerContext } from './AppManagerContext'

export type AppManagerProviderProps = PropsWithChildren<{
  manager: AppManager
}>

export function AppManagerProvider (props: AppManagerProviderProps) {
  return (
    <AppManagerContext.Provider value={props.manager}>
      {props.children}
    </AppManagerContext.Provider>
  )
}
