import { RecordOrder, RunTransactionCommand } from '@indigohive/cogfy-types'
import { CollectionState } from '../collection-state'
import { Command } from '../command-stack'

export type UpdateViewOrderByCommandData = {
  orderBy: RecordOrder[] | null
}

export class UpdateViewOrderByCommand implements Command<RunTransactionCommand> {
  private readonly _state: CollectionState
  private readonly _data: UpdateViewOrderByCommandData
  private readonly _previousOrderBy: RecordOrder[] | null

  constructor (
    state: CollectionState,
    data: UpdateViewOrderByCommandData
  ) {
    this._state = state
    this._data = data
    this._previousOrderBy = this._state.views?.find(view => view.id === this._state.activeViewId)?.orderBy ?? null
  }

  run (): RunTransactionCommand {
    const newViews = this._state.views?.map(view => {
      if (view.id === this._state.activeViewId) {
        view.orderBy = this._data.orderBy
      }
      return view
    })

    this._state.setViews(newViews ?? [])

    return {
      operations: [
        {
          type: 'update_view_order_by',
          data: {
            collectionId: this._state.id,
            viewId: this._state.activeViewId!,
            orderBy: this._data.orderBy
          }
        }
      ]
    }
  }

  undo (): RunTransactionCommand {
    const newViews = this._state.views?.map(view => {
      if (view.id === this._state.activeViewId) {
        view.orderBy = this._previousOrderBy
      }
      return view
    })

    this._state.setViews(newViews ?? [])

    return {
      operations: [
        {
          type: 'update_view_order_by',
          data: {
            collectionId: this._state.id,
            viewId: this._state.activeViewId!,
            orderBy: this._previousOrderBy
          }
        }
      ]
    }
  }
}
