import { Redo2Icon } from 'lucide-react'
import { useTranslation } from 'react-i18next'

export type WhatsAppSubtitleProps = {
  forwarded?: boolean
  frequentlyForwarded?: boolean
}

export function WhatsAppSubtitle (props: WhatsAppSubtitleProps) {
  const { forwarded, frequentlyForwarded } = props
  const { t } = useTranslation('chatPage')

  return (
    <>
      {(forwarded || frequentlyForwarded) && (
        <div className="inline-flex items-center gap-1 italic text-xs text-gray-600">
          <Redo2Icon size={14} />
          {forwarded && (
            <>{t('Forwarded')}</>
          )}
          {frequentlyForwarded && (
            <>{t('Frequently forwarded')}</>
          )}
        </div>
      )}
    </>
  )
}
