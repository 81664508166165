import { NumberFieldData, NumberRecordProperty } from '@indigohive/cogfy-types'
import { FieldCellProps } from '../../FieldCell'

type Formatters = {
  currency: Record<string, Intl.NumberFormat | undefined>
  decimal: Intl.NumberFormat
  percent: Intl.NumberFormat
}

const formatters: Formatters = {
  currency: {
    BRL: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }),
    USD: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }),
    EUR: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }),
    JPY: new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' })
  },
  decimal: new Intl.NumberFormat('pt-BR', { style: 'decimal' }),
  percent: new Intl.NumberFormat('pt-BR', { style: 'percent' })
}

export type NumberCellProps = Pick<
FieldCellProps<NumberRecordProperty, NumberFieldData>,
'field' | 'property'
>

export function NumberCell (props: NumberCellProps) {
  const { field, property } = props

  const number = property?.number?.value
  const style = field.data?.number?.style ?? 'decimal'
  const currency = field.data?.number?.currency ?? 'BRL'
  const formatter = style === 'currency'
    ? formatters.currency[currency]
    : formatters[style]

  return (
    <div className="h-full overflow-clip px-2 py-1 text-right w-full">
      {number !== undefined && number !== null && (
        formatter ? formatter.format(number) : number
      )}
    </div>
  )
}
