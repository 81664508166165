import { GetChatMessagesPageResultData, UUID } from '@indigohive/cogfy-types'
import { useTranslation } from 'react-i18next'
import { ChatBubble } from './ChatBubble'
import { Button } from '../../../components'
import { useMutation } from '@tanstack/react-query'
import { useCogfy } from '../../../hooks'

export type ChatToolConfirmationProps = {
  message: GetChatMessagesPageResultData
  chatId: UUID
  messageId: UUID
  collectionId: UUID
  onUpdateMessageStatus?: (messageId: UUID) => void
}

export function ChatToolConfirmation (props: ChatToolConfirmationProps) {
  const { message, chatId, messageId, collectionId, onUpdateMessageStatus } = props

  const { t } = useTranslation()
  const cogfy = useCogfy()

  const updateMessageStatus = useMutation({
    mutationFn: async (status: 'confirmed' | 'canceled') => {
      await cogfy.chats.updateMessageStatus(chatId, messageId, { collectionId, status })
      onUpdateMessageStatus?.(messageId)
    }
  })

  const toolName = (message.data as any)?.tool?.name
  const rawArguments = (message.data as any)?.tool?.arguments
  const toolArguments = rawArguments === null
    ? {}
    : typeof rawArguments === 'string'
      ? JSON.parse(rawArguments)
      : rawArguments

  if (
    message.status !== 'error' &&
    message.status !== 'canceled' &&
    message.status !== 'confirmed' &&
    message.status !== 'confirming'
  ) {
    return
  }

  return (
    <ChatBubble
      color="neutral"
      position="start"
      avatarImageSrc="/cogfy-avatar.png"
      sendDate={message.sendDate}
      className="mt-4"
      buttons={
        <>
          {message.status === 'confirming' && (
            <div className="mt-4 flex flex-col gap-1 w-full">
              <Button
                className="w-full"
                size="sm"
                color="primary"
                disabled={updateMessageStatus.isPending}
                onClick={() => updateMessageStatus.mutate('confirmed')}
              >
                {t('Confirm')}
              </Button>
              <Button
                className="w-full"
                size="sm"
                ghost
                disabled={updateMessageStatus.isPending}
                onClick={() => updateMessageStatus.mutate('canceled')}
              >
                {t('Cancel')}
              </Button>
            </div>
          )}
        </>
      }
    >
      <div className="max-w-lg">
        <p className="font-semibold text-base pb-2">
          {`${t('chatPage:Would you like to execute', { toolName })}`}
        </p>
        {Object.entries(toolArguments).map(([key, value]) => (
          <p
            key={key}
            className="pb-2"
          >
            {key}: {value as any}
          </p>
        ))}
      </div>
    </ChatBubble>
  )
}
