import { PropsWithChildren } from 'react'
import clsx from 'clsx'

export type NavbarProps = PropsWithChildren

export function Navbar (props: NavbarProps) {
  return (
    <div
      className={clsx(
        'navbar',
        'min-h-12',
        'h-12',
        'sticky',
        'top-0',
        'z-40',
        'bg-white',
        'shadow-sm'
      )}
    >
      {props.children}
    </div>
  )
}
