import { GetWebhooksPageResultData, UUID } from '@indigohive/cogfy-types'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { ConfirmDeleteDialog, Container, SettingsPagesHeader } from '../../components'
import { useCogfy } from '../../hooks'
import { WebhooksTable, WebhooksToolbar } from './components'
import { WebhookIcon } from 'lucide-react'

export function WebhooksIndexPage () {
  const { t } = useTranslation()
  const cogfy = useCogfy()
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedWebhooks, setSelectedWebhooks] = useState<Record<UUID, GetWebhooksPageResultData>>({})
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)

  const pageNumber = parseInt(searchParams.get('pageNumber') ?? '0')
  const pageSize = parseInt(searchParams.get('pageSize') ?? '100')

  const getWebhooksPage = useQuery({
    queryKey: ['getWebhooksPage', pageNumber, pageSize],
    queryFn: () => cogfy.webhooks.getPage({ pageNumber, pageSize })
  })

  const disabledNextPage = getWebhooksPage.data ? (pageNumber >= Math.ceil(getWebhooksPage.data.totalSize / pageSize) - 1) : true
  const disabledPreviousPage = pageNumber === 0

  const handleHeadCheckboxChange = () => {
    setSelectedWebhooks(prev => {
      if (Object.keys(prev).length === getWebhooksPage.data?.data.length) {
        return {}
      }

      return (getWebhooksPage.data?.data ?? []).reduce<Record<UUID, GetWebhooksPageResultData>>((acc, webhook) => {
        acc[webhook.id] = webhook
        return acc
      }, {})
    })
  }
  const handleCheckboxChange = (webhook: GetWebhooksPageResultData) => {
    setSelectedWebhooks(prev => {
      if (prev[webhook.id]) {
        const { [webhook.id]: _, ...rest } = prev
        return rest
      }

      return { ...prev, [webhook.id]: webhook }
    })
  }
  const handleNextPageClick = () => {
    setSearchParams(prev => {
      const totalPages = Math.ceil((getWebhooksPage.data?.totalSize ?? 0) / pageSize)

      if (pageNumber < totalPages - 1) {
        prev.set('pageNumber', (pageNumber + 1).toString())
      }

      return prev
    })
  }
  const handlePreviousPageClick = () => {
    setSearchParams(prev => {
      if (pageNumber === 1) {
        prev.delete('pageNumber')
      } else if (pageNumber > 0) {
        prev.set('pageNumber', (pageNumber - 1).toString())
      }

      return prev
    })
  }
  const handleConfirmDelete = useMutation({
    mutationFn: async () => {
      await cogfy.webhooks.delete({ webhookIds: Object.keys(selectedWebhooks) as UUID[] })
      getWebhooksPage.refetch()
      setSelectedWebhooks({})
      setIsDeleteDialogOpen(false)
    }
  })

  const handleDeleteRow = (webhook: GetWebhooksPageResultData) => {
    setSelectedWebhooks(() => ({ [webhook.id]: webhook }))
    setIsDeleteDialogOpen(true)
  }

  return (
    <>
      <SettingsPagesHeader
        title={'Webhooks'}
        description={t('SettingsPages:Create and manage Webhooks to receive real time notifications')}
      >
        <WebhookIcon size={46} />
      </SettingsPagesHeader>
      <Container className='pt-8'>
        <WebhooksToolbar
          totalCount={getWebhooksPage.data?.totalSize ?? 0}
          selectedCount={Object.keys(selectedWebhooks).length}
          loading={getWebhooksPage.isLoading}
          error={getWebhooksPage.isError}
          onDeleteSelection={() => setIsDeleteDialogOpen(true)}
        />
        <WebhooksTable
          webhooks={getWebhooksPage.data?.data ?? []}
          selectedWebhooks={selectedWebhooks}
          loading={getWebhooksPage.isLoading}
          error={getWebhooksPage.isError}
          disabledNextPage={disabledNextPage}
          disabledPreviousPage={disabledPreviousPage}
          onDeleteRow={handleDeleteRow}
          onHeadCheckboxChange={handleHeadCheckboxChange}
          onCheckboxChange={handleCheckboxChange}
          onNextPageClick={handleNextPageClick}
          onPreviousPageClick={handlePreviousPageClick}
          onRefetch={() => { getWebhooksPage.refetch() }}
        />
      </Container>
      <ConfirmDeleteDialog
        title={t('WorkspaceWebhooksPage:Delete all selected webhooks?')}
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => handleConfirmDelete.mutate()}
      />
    </>
  )
}
