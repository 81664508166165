import { useEffect, useState } from 'react'
import { CollectionState } from '../collection-state'

export function useSearchRecords (state: CollectionState) {
  const [searchRecords, setSearchRecords] = useState(state.searchRecords)

  useEffect(() => {
    const searchRecordsChanged = () => {
      setSearchRecords(state.searchRecords)
    }

    state.addEventListener('searchRecordsChanged', searchRecordsChanged)

    return () => {
      state.removeEventListener('searchRecordsChanged', searchRecordsChanged)
    }
  })

  return searchRecords
}
