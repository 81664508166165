import { v4 as uuid } from 'uuid'
import { FieldData, FieldOperationConfig, FieldType, RunTransactionCommand, UUID } from '@indigohive/cogfy-types'
import { Command } from '../command-stack'
import { CollectionState, CollectionStateField, CollectionStateViewField } from '../collection-state'
import { incrementSuffix } from '../../helpers'
import { en } from '../../field-types'

export type CreateFieldCommandData = {
  fieldType: FieldType
  viewId: UUID
  fieldData?: FieldData | null
  fieldOperation?: string | null
  fieldOperationConfig?: FieldOperationConfig | null
  fieldName?: string | null
}

export class CreateFieldCommand implements Command<RunTransactionCommand> {
  private readonly _state: CollectionState
  private readonly _fieldToCreate: CollectionStateField
  private readonly _viewFieldToCreate: CollectionStateViewField

  constructor (
    state: CollectionState,
    data: CreateFieldCommandData
  ) {
    this._state = state

    const newNameValue = (data.fieldName ?? en[data.fieldType]) || 'New field'

    this._fieldToCreate = {
      id: uuid() as UUID,
      name: incrementSuffix(newNameValue.replace(/[0-9]+$/g, '').trim(), this._state.fields?.map(field => field.name) ?? []),
      type: data.fieldType,
      data: data.fieldData,
      recalculateStrategy: 'empty_only',
      operation: data.fieldOperation,
      operationConfig: data.fieldOperationConfig,
      order: this._state?.fields?.length ?? 0
    }
    this._viewFieldToCreate = {
      id: uuid() as UUID,
      viewId: data.viewId,
      fieldId: this._fieldToCreate.id,
      order: this._state.viewFields?.length ?? 0,
      config: this._state.viewFields?.find(vw => vw.fieldId === this._state.selectedField?.field.id && vw.viewId === this._state.activeViewId)?.config
    }
  }

  run (): RunTransactionCommand {
    const currentFields = this._state.fields
    const currentViewFields = this._state.viewFields
    const newFields = currentFields ? [...currentFields, this._fieldToCreate] : [this._fieldToCreate]
    const newViewFields = currentViewFields ? [...currentViewFields, this._viewFieldToCreate] : [this._viewFieldToCreate]

    this._state.setFields(newFields)
    this._state.setViewFields(newViewFields)
    this._state.setSelectedField(null)
    this._state.setSelectedFieldToUpdate(this._fieldToCreate)

    return {
      operations: [
        {
          type: 'create_field',
          data: {
            collectionId: this._state.id,
            fieldId: this._fieldToCreate.id,
            type: this._fieldToCreate.type,
            data: this._fieldToCreate.data,
            operation: this._fieldToCreate.operation,
            operationConfig: this._fieldToCreate.operationConfig,
            name: this._fieldToCreate.name
          }
        },
        {
          type: 'create_view_field',
          data: {
            collectionId: this._state.id,
            viewFieldId: this._viewFieldToCreate.id,
            viewId: this._viewFieldToCreate.viewId,
            fieldId: this._viewFieldToCreate.fieldId,
            order: null,
            config: this._viewFieldToCreate.config
          }
        }
      ]
    }
  }

  undo (): RunTransactionCommand {
    const newFields = this._state.fields?.filter(field => field.id !== this._fieldToCreate.id) ?? []
    const newViewFields = this._state.viewFields?.filter(viewField => viewField.id !== this._viewFieldToCreate.id) ?? []

    this._state.setFields(newFields)
    this._state.setViewFields(newViewFields)

    if (this._state.selectedField?.field.id === this._fieldToCreate.id || this._state.selectedFieldToUpdate?.id === this._fieldToCreate.id) {
      this._state.setSelectedField(null)
      this._state.setSelectedFieldToUpdate(null)
    }

    return {
      operations: [
        {
          type: 'hard_delete_field',
          data: {
            collectionId: this._state.id,
            fieldId: this._fieldToCreate.id
          }
        }
      ]
    }
  }
}
