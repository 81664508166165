import { PlusIcon } from 'lucide-react'
import clsx from 'clsx'

export type CollectionTableAddButtonProps = {
  loading?: boolean
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export function CollectionTableAddButton (props: CollectionTableAddButtonProps) {
  const { loading, onClick } = props

  return (
    <div
      className={clsx(
        loading ? 'cursor-not-allowed' : 'cursor-pointer',
        !loading && 'hover:bg-base-200',
        !loading && 'active:bg-base-300',
        'h-full',
        'text-gray-400',
        'flex',
        'items-center',
        'px-2.5'
      )}
      role={loading ? 'none' : 'button'}
      onClick={event => { !loading && onClick?.(event) }}
    >
      <PlusIcon size={16} />
    </div>
  )
}
