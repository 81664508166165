import { EllipsisIcon } from 'lucide-react'
import clsx, { ClassValue } from 'clsx'

export type DataTableHeadMenuButtonProps = {
  className?: ClassValue
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export function DataTableHeadMenuButton (props: DataTableHeadMenuButtonProps) {
  return (
    <div
      className={clsx(
        props.className,
        'h-full',
        'w-9',
        'cursor-pointer',
        'hover:bg-base-200',
        'active:bg-base-300',
        'text-gray-400',
        'flex',
        'items-center',
        'px-2.5',
        'grow'
      )}
      role="button"
      onClick={props.onClick}
    >
      <EllipsisIcon size={16} />
    </div>
  )
}
