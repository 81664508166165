import { CheckIcon, FileIcon, TriangleAlertIcon, XIcon } from 'lucide-react'
import { UploadData } from '../../../_DatabaseCollectionPage'
import { useTranslation } from 'react-i18next'

export type UploadFileProgressProps = {
  upload: UploadData
}
export function UploadFileProgress (props: UploadFileProgressProps) {
  const { upload } = props

  const { t } = useTranslation()

  const name = upload.file.name
  const isDone = upload.status === 'done'
  const isError = upload.status === 'error'
  const isLoading = upload.status === 'loading'
  const progress = upload.progress
  const errorMessage = upload.errorMessage

  return (
    <div className="flex flex-row justify-between items-center pb-2">
      <div className="flex flex-row justify-between gap-2">
        {isDone && <FileIcon size={16} />}
        {isLoading && <span className="loading loading-spinner text-info loading-sm" />}
        {isError && <XIcon size={16} className="text-error" />}
        <div className="w-[256px] inline-block overflow-hidden">
          <span className="whitespace-nowrap overflow-ellipsis overflow-hidden block">{name}</span>
        </div>
      </div>
      <div className="p-2">
        {isDone && <CheckIcon size={16} className="text-success" />}
        {isError && errorMessage && (
          <span className="tooltip tooltip-left" data-tip={t(errorMessage)}>
            <TriangleAlertIcon size={16} className="text-error" />
          </span>
        )}
        {(isLoading && progress) && <span className="text-slate-400">{progress} %</span>}
      </div>
    </div>
  )
}
