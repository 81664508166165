import clsx from 'clsx'
import { useEffect, useRef } from 'react'

export type DataTableHeadCheckboxProps = {
  checked?: boolean
  disabled?: boolean
  indeterminate?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export function DataTableHeadCheckbox (props: DataTableHeadCheckboxProps) {
  const { checked, disabled, indeterminate, onChange } = props
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (ref.current) {
      if (indeterminate) {
        ref.current.indeterminate = true
      } else {
        ref.current.indeterminate = false
      }
    }
  }, [indeterminate])

  return (
    <label
      className={clsx(
        'group',
        'flex',
        'items-center',
        'justify-center',
        'w-8',
        'h-full',
        'cursor-pointer',
        disabled && 'invisible'
      )}
    >
      <input
        ref={ref}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        className={clsx(
          'checkbox',
          'checkbox-sm',
          'checked:checkbox-info',
          'indeterminate:checkbox-info',
          'opacity-0',
          'group-hover:opacity-100',
          'transition-opacity',
          'duration-200',
          checked && 'opacity-100'
        )}
      />
    </label>
  )
}
