import clsx from 'clsx'

export type DataTableRowCheckboxProps<T> = {
  row?: T
  checked?: boolean
  disabled?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, row: T) => void
}

export function DataTableRowCheckbox<T> (props: DataTableRowCheckboxProps<T>) {
  const { row, checked, disabled, onChange } = props

  return (
    <label
      className={clsx(
        'group',
        'flex',
        'items-start',
        'py-1',
        'justify-center',
        'w-8',
        'min-h-7',
        !disabled && 'cursor-pointer',
        disabled && 'invisible'
      )}
    >
      <input
        type="checkbox"
        className={clsx(
          'checkbox',
          'checkbox-sm',
          'checked:checkbox-info',
          'opacity-0',
          'group-hover:opacity-100',
          'transition-opacity',
          'duration-200',
          checked && 'opacity-100'
        )}
        checked={checked}
        disabled={disabled}
        onChange={event => onChange?.(event, row!)}
      />
    </label>
  )
}
