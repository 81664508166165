import { PropsWithChildren, useEffect, useRef } from 'react'

export type DialogProps = PropsWithChildren<{
  open: boolean
  onClose: () => void
}>

export function Dialog (props: DialogProps) {
  const { children, open, onClose } = props
  const ref = useRef<HTMLDialogElement>(null)

  useEffect(() => {
    if (open) {
      ref.current?.showModal()
    } else {
      ref.current?.close()
    }
  }, [open])

  useEffect(() => {
    const dialog = ref.current

    dialog?.addEventListener('close', onClose)

    return () => {
      dialog?.removeEventListener('close', onClose)
    }
  }, [])

  return (
    <dialog ref={ref} className="modal">
      <div className="modal-box">
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
        </form>
        {children}
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  )
}
