import { FileFieldData } from '@indigohive/cogfy-types'

const MAX_FILE_SIZE_IN_B = 100 * 1024 * 1024 // 100 MB

export function validFile (fieldData: FileFieldData | null | undefined, file: File) {
  const maximumSize = fieldData?.file?.size ?? MAX_FILE_SIZE_IN_B
  const allowedTypes = fieldData?.file?.types

  if (file.size > maximumSize) {
    return 'File size is larger than allowed'
  }

  if (allowedTypes && !allowedTypes.some((fieldType) => fieldType === file.type) && !allowedTypes.includes('*')) {
    return 'This type of document is not allowed'
  }
}
