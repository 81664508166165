import { useTranslation } from 'react-i18next'
import { Select } from '../../Select'
import { ChatMiddlewareDataFormProps } from '../ChatMiddlewareDataFormProps'
import { useState } from 'react'
import { Input } from '../../Input'

const DEFAULT_MAX_COUNT = 10
const DEFAULT_MIN_SIMILARITY = 0.45

export function RetrievalForm (props: ChatMiddlewareDataFormProps) {
  const { data, collections, onChange } = props

  const [collectionId, setCollectionId] = useState(data?.source?.collection?.id ?? '')
  const [maxCount, setMaxCount] = useState(data?.source?.maxCount ?? DEFAULT_MAX_COUNT)
  const [minSimilarity, setMinSimilarity] = useState(data?.source?.minSimilarity ?? DEFAULT_MIN_SIMILARITY)
  const { t } = useTranslation()

  return (
    <>
      <Select
        label={t('Collections')}
        size="sm"
        options={[
          { label: t('Select a collection'), value: '' },
          ...(collections ?? []).map(collection => ({
            label: collection.name,
            value: collection.id
          }))
        ]}
        value={collectionId}
        onChange={event => {
          setCollectionId(event.target.value)
          onChange({
            ...data,
            source: {
              ...data.source,
              type: 'collection',
              collection: {
                id: event.target.value
              }
            }
          })
        }}
      />
      <Input
        label={t('Maximum documents')}
        size="sm"
        type="number"
        step={1}
        min={1}
        max={100}
        value={maxCount}
        onChange={event => {
          setMaxCount(event.target.value)
          onChange({
            ...data,
            source: {
              ...data.source,
              type: 'collection',
              maxCount: event.target.value
            }
          })
        }}
      />
      <Input
        label={t('Minimum similarity')}
        size="sm"
        type="number"
        step={0.01}
        min={0.01}
        max={1}
        value={minSimilarity}
        onChange={event => {
          setMinSimilarity(event.target.value)
          onChange({
            ...data,
            source: {
              ...data.source,
              type: 'collection',
              minSimilarity: event.target.value
            }
          })
        }}
      />
    </>
  )
}
