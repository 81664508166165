import { format, isToday, isYesterday, parseISO, subWeeks } from 'date-fns'
import { useTranslation } from 'react-i18next'

export type WhatsAppDateProps = {
  sendDate: string
}

export function WhatsAppDate (props: WhatsAppDateProps) {
  const { sendDate } = props
  const { t } = useTranslation()

  const date = parseISO(sendDate)
  const today = isToday(sendDate)
  const yesterday = isYesterday(sendDate)
  const sevenDaysBefore = subWeeks(new Date(), 1)
  const week = date > sevenDaysBefore

  return (
    <div className="flex justify-center my-4">
      <div className="px-4 py-2 bg-white rounded-lg text-xs text-gray-600 uppercase shadow-[0_1px_0.5px_rgba(11,20,26,0.13)]">
        {week && today && <>{t('Today')}</>}
        {week && yesterday && <>{t('Yesterday')}</>}
        {week && !today && !yesterday && <>{t(format(date, 'EEEE'))}</>}
        {!week && <>{format(date, 'dd/MM/yyyy')}</>}
      </div>
    </div>
  )
}
