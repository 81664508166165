import { useState } from 'react'
import { Select } from '../../../Select'
import { Toggle } from '../../../Toggle'
import { ChatMiddlewareDataFormProps } from '../../ChatMiddlewareDataFormProps'
import { Input } from '../../../Input'

export function ToolFormSearchCogfyRecords (props: ChatMiddlewareDataFormProps) {
  const { data, onChange, collections = [] } = props

  const [collectionId, setCollectionId] = useState(data.method?.config?.collection?.id ?? '')
  const [trigrams, setTrigrams] = useState(data.method?.config?.trigrams ?? { enabled: false, threshold: 0.45 })

  const handleConfigChange = (key: string, value: any) => {
    const updatedConfig = { ...data.method?.config, [key]: value }
    onChange({ ...data, method: { ...data.method, config: updatedConfig } })
  }

  return (
    <>
      <Select
        label="Collection"
        size="sm"
        options={[
          { label: 'Select a collection', value: '', disabled: true },
          ...collections.map(collection => ({ label: collection.name, value: collection.id }))
        ]}
        value={collectionId}
        onChange={event => {
          const value = event.target.value
          setCollectionId(value)
          handleConfigChange('collection', { id: value })
        }}
      />
      <Toggle
        label="Trigrams"
        inputSize="md"
        checked={trigrams.enabled}
        onChange={event => {
          const enabled = event.target.checked
          setTrigrams({ ...trigrams, enabled })
          handleConfigChange('trigrams', { ...trigrams, enabled })
        }}
      />
      {trigrams.enabled && (
        <Input
          label="Threshold"
          size="sm"
          type="number"
          max={1}
          min={0.01}
          step={0.01}
          value={trigrams.threshold}
          onChange={event => {
            const threshold = parseFloat(event.target.value)
            setTrigrams({ ...trigrams, threshold })
            handleConfigChange('trigrams', { ...trigrams, threshold })
          }}
        />
      )}
    </>
  )
}
