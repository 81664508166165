import { TextRecordProperty } from '@indigohive/cogfy-types'
import { FieldOverlayProps } from '../FieldOverlay'
import { DEFAULT_FIELD_WIDTH, MIN_FIELD_WIDTH } from '../../../pages/CollectionPageV2/components'

const VERTICAL_MARGIN = 160

export function TextOverlay (props: FieldOverlayProps) {
  const { record, field, viewField, element, controller } = props

  const elementRect = element.getBoundingClientRect()

  const height = Math.min(elementRect.height, window.innerHeight - VERTICAL_MARGIN)
  const width = Math.max(viewField.config?.ui?.width ?? DEFAULT_FIELD_WIDTH, MIN_FIELD_WIDTH)

  return (
    <textarea
      className="input input-sm input-bordered leading-5 px-[7px] py-[3px]"
      style={{ height, width, resize: 'both' }}
      autoFocus
      onFocus={event => {
        event.currentTarget.value = (record.properties[field.id] as TextRecordProperty)?.text?.value ?? ''
      }}
      onBlur={event => {
        const currentValue = (record.properties[field.id] as TextRecordProperty)?.text?.value
        const newValue = event.currentTarget.value

        if (currentValue !== newValue) {
          controller.onUpdateRecordProperties(record.id, { [field.id]: { type: 'text', text: { value: newValue } } })
        }
        props.onClose('blur')
      }}
      onKeyDown={event => {
        if (event.key === 'Escape' || (event.key === 'Enter' && !event.shiftKey) || event.key === 'Tab') {
          if (event.key === 'Tab') {
            event.preventDefault()
          }

          props.onClose(event.key)
        }
      }}
    />
  )
}
