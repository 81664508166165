import { PlusIcon, TrashIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Button, Skeleton } from '../../../components'
import { useWorkspaceSlug } from '../../../hooks'

export type WebhooksToolbarProps = {
  totalCount: number
  selectedCount: number
  loading: boolean
  error: boolean
  onDeleteSelection: () => void
}

export function WebhooksToolbar (props: WebhooksToolbarProps) {
  const { totalCount, selectedCount, loading, error, onDeleteSelection } = props

  const { t } = useTranslation()
  const workspace = useWorkspaceSlug()

  return (
    <div className="flex items-center w-full">
      <div className="grow">
        {loading && !error && <Skeleton className="h-3 w-32" />}
        {!loading && !error && selectedCount > 0 && <span className="text-sm">{t('Webhooks selected', { count: selectedCount })}</span>}
        {!loading && !error && selectedCount === 0 && <span className="text-sm">{t('Webhooks found', { count: totalCount })}</span>}
      </div>
      <div>
        {!loading && (
          <>
            {selectedCount === 0 && (
              <Button size="sm" color="primary" to={`/${workspace}/webhooks/create`}>
                <PlusIcon size={16} className="mr-1" />
                {t('New webhook')}
              </Button>
            )}
            {selectedCount > 0 && (
              <Button size="xs" onClick={onDeleteSelection}>
                <TrashIcon size={16} className="mr-1" />
                {t('Delete')}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  )
}
