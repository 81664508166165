import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './locales/en.json'
import ptBR from './locales/pt-BR.json'
import { en as enFields, ptBR as brFields } from './field-types'

const resources = {
  en: { ...en, fieldType: enFields },
  'pt-BR': { ...ptBR, fieldType: brFields }
}

i18n
  .use(initReactI18next)
  .init({
    defaultNS: 'common',
    resources,
    lng: 'pt-BR',
    nsSeparator: ':',
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
