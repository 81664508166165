import { FieldFilterMenuProps } from '../FieldFilterChip'
import { useEffect, useState } from 'react'
import { RecordFilter } from '@indigohive/cogfy-types'
import { getFilterOperator } from '../methods'
import { useTranslation } from 'react-i18next'

const selectOptions = [
  { value: 'equals', label: 'is' },
  { value: 'notEquals', label: 'is not' },
  { value: 'isEmpty', label: 'is empty' },
  { value: 'notEmpty', label: 'is not empty' },
  { value: 'hasError', label: 'has error' },
  { value: 'hasNoError', label: 'has no error' },
  { value: 'isPending', label: 'is pending' },
  { value: 'notPending', label: 'is not pending' }
]

export function TextFilterMenu (props: FieldFilterMenuProps) {
  const { controller, field, filter, viewFilter } = props

  const { t } = useTranslation()
  const operator = getFilterOperator(filter, field) ?? 'equals'

  const [filterValue, setFilterValue] = useState(() => {
    if (['empty', 'notEmpty', 'hasError', 'hasNoError', 'isPending', 'notPending'].includes(operator)) {
      return ''
    }

    return (filter as any)[operator] ?? ''
  })

  useEffect(() => {
    setFilterValue(() => {
      if (['empty', 'notEmpty', 'hasError', 'hasNoError', 'isPending', 'notPending'].includes(operator)) {
        return ''
      }

      return (filter as any)[operator] ?? ''
    })
  }, [filter])

  const onFilterValueChange = () => {
    const newOperator = calculateOperator(operator)
    const newValue = calculateFilterValue(operator, filterValue)

    const newFilter = {
      property: field.id,
      propertyType: 'text',
      [newOperator]: newValue
    }

    controller.onViewFilterChange(viewFilter, newFilter as RecordFilter)
  }

  const onUpdateOperator = (newOperator: string) => {
    const calculatedNewOperator = calculateOperator(newOperator)
    const newValue = calculateFilterValue(newOperator, filterValue)

    const newFilter = {
      property: field.id,
      propertyType: 'text',
      [calculatedNewOperator]: newValue
    }

    controller.onViewFilterChange(viewFilter, newFilter as RecordFilter)
  }

  return (
    <div className="space-y-2 p-2">
      <div className="flex items-center gap-x-2">
        <div className="whitespace-nowrap">{field.name}</div>
        <select
          value={operator}
          onChange={event => onUpdateOperator(event.target.value)}
          className="select select-xs select-ghost max-w-xs w-full"
        >
          {selectOptions.map(option => (
            <option
              key={option.value}
              value={option.value}
            >
              {t(`filters:${option.label}`)}
            </option>
          ))}
        </select>
      </div>
      {!['isEmpty', 'notEmpty', 'hasError', 'hasNoError', 'isPending', 'isNotPending'].includes(operator) && (
        <input
          value={filterValue}
          onChange={event => setFilterValue(event.target.value)}
          onBlur={onFilterValueChange}
          type="text"
          placeholder="Type a value..."
          className="input input-sm w-full max-w-xs"
        />
      )}
    </div>
  )
}

function calculateOperator (operator: string) {
  const newOperator = operator === 'notEmpty'
    ? 'isEmpty'
    : operator === 'hasNoError'
      ? 'hasError'
      : operator === 'notPending'
        ? 'isPending'
        : operator

  return newOperator
}

function calculateFilterValue (operator: string, value: string) {
  const newValue = (operator === 'isEmpty' || operator === 'hasError' || operator === 'isPending')
    ? true
    : (operator === 'notEmpty' || operator === 'hasNoError' || operator === 'notPending')
        ? false
        : value

  return newValue
}
