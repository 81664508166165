import { RecordProperty, RunTransactionCommand, UUID } from '@indigohive/cogfy-types'
import { CollectionState } from '../collection-state'
import { Command } from '../command-stack'

export type UpdateRecordPropertiesCommandData = {
  recordId: UUID
  properties: Record<UUID, RecordProperty | undefined>
}

export class UpdateRecordPropertiesCommand implements Command<RunTransactionCommand> {
  private readonly _state: CollectionState
  private readonly _data: UpdateRecordPropertiesCommandData
  private readonly _previousRecordProperties: Record<UUID, RecordProperty | undefined>

  constructor (
    state: CollectionState,
    data: UpdateRecordPropertiesCommandData
  ) {
    this._state = state
    this._data = data
    this._previousRecordProperties = this._state.records?.find(record => record.id === this._data.recordId)?.properties ?? {}
  }

  run (): RunTransactionCommand {
    const records = this._state.records?.map(record => {
      if (record.id === this._data.recordId) {
        const newRecord = {
          ...record,
          properties: {
            ...record.properties,
            ...this._data.properties
          }
        }

        return newRecord
      }

      return record
    })

    this._state.setRecords(records ?? [])

    return {
      operations: [
        {
          type: 'update_record_properties',
          data: {
            collectionId: this._state.id,
            recordId: this._data.recordId,
            properties: this._data.properties
          }
        }
      ]
    }
  }

  undo (): RunTransactionCommand {
    const records = this._state.records?.map(record => {
      if (record.id === this._data.recordId) {
        record.properties = this._previousRecordProperties
      }

      return record
    })

    this._state.setRecords(records ?? [])

    return {
      operations: [
        {
          type: 'update_record_properties',
          data: {
            collectionId: this._state.id,
            recordId: this._data.recordId,
            properties: this._previousRecordProperties
          }
        }
      ]
    }
  }
}
