import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import { CollectionStateRecord } from '../../../../../lib'

export type CollectionTableRowProps = PropsWithChildren<{
  checked?: boolean
  record?: CollectionStateRecord
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, record: CollectionStateRecord) => void
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, record: CollectionStateRecord) => void
}>

export function CollectionTableRow (props: CollectionTableRowProps) {
  const { checked, children, record, onMouseEnter, onMouseLeave } = props

  return (
    <div
      onMouseEnter={event => record && onMouseEnter?.(event, record)}
      onMouseLeave={event => record && onMouseLeave?.(event, record)}
      className={clsx(
        'inline-flex',
        'flex-row',
        'border-b',
        'min-h-8',
        'text-sm',
        'min-w-full',
        checked && 'bg-info bg-opacity-10'
      )}
    >
      {children}
    </div>
  )
}
