import { CompleteUploadChatMessageFilesCommand } from '@indigohive/cogfy-types/endpoints/completeUploadChatMessageFiles'
import { CreateConnectionCommand, CreateConnectionResult } from '@indigohive/cogfy-types/endpoints/createConnection'
import { CreateApiKeyCommand, CreateApiKeyResult } from '@indigohive/cogfy-types/endpoints/createApiKey'
import { CreateChatMiddlewareCommand, CreateChatMiddlewareResult } from '@indigohive/cogfy-types/endpoints/createChatMiddleware'
import { CreateCollectionAccessControlCommand, CreateCollectionAccessControlResult } from '@indigohive/cogfy-types/endpoints/createCollectionAccessControl'
import { CreateCollectionCommand, CreateCollectionResult } from '@indigohive/cogfy-types/endpoints/createCollection'
import { CreateFolderCommand, CreateFolderResult } from '@indigohive/cogfy-types/endpoints/createFolder'
import { CreateUserGroupCommand, CreateUserGroupResult } from '@indigohive/cogfy-types/endpoints/createUserGroup'
import { CreateUserGroupMemberCommand } from '@indigohive/cogfy-types/endpoints/createUserGroupMember'
import { CreateWorkspaceMemberCommand } from '@indigohive/cogfy-types/endpoints/createWorkspaceMember'
import { CreateWorkspaceUserContactCommand } from '@indigohive/cogfy-types/endpoints/createWorkspaceUserContact'
import { DeleteApiKeyCommand } from '@indigohive/cogfy-types/endpoints/deleteApiKey'
import { DeleteChatMiddlewareCommand } from '@indigohive/cogfy-types/endpoints/deleteChatMiddleware'
import { DeleteCollectionAccessControlCommand } from '@indigohive/cogfy-types/endpoints/deleteCollectionAccessControl'
import { DeleteConnectionCommand } from '@indigohive/cogfy-types/endpoints/deleteConnection'
import { DeleteUserGroupCommand } from '@indigohive/cogfy-types/endpoints/deleteUserGroup'
import { DeleteUserGroupMembersCommand } from '@indigohive/cogfy-types/endpoints/deleteUserGroupMembers'
import { DeleteWorkspaceMembersCommand } from '@indigohive/cogfy-types/endpoints/deleteWorkspaceMembers'
import { DeleteWorkspaceUserContactCommand } from '@indigohive/cogfy-types/endpoints/deleteWorkspaceUserContact'
import { DuplicateChatMiddlewareCommand } from '@indigohive/cogfy-types/endpoints/duplicateChatMiddleware'
import { GetChatMessageFileByIdQuery, GetChatMessageFileByIdResult } from '@indigohive/cogfy-types/endpoints/getChatMessageFileById'
import { GetChatMiddlewaresQuery, GetChatMiddlewaresResult } from '@indigohive/cogfy-types/endpoints/getChatMiddlewares'
import { GetCollectionAccessControlListQuery, GetCollectionAccessControlListResult } from '@indigohive/cogfy-types/endpoints/getCollectionAccessControlList'
import { GetCollectionAncestorsQuery, GetCollectionAncestorsResult } from '@indigohive/cogfy-types/endpoints/getCollectionAncestors'
import { GetCollectionsQuery, GetCollectionsResult } from '@indigohive/cogfy-types/endpoints/getCollections'
import { GetCollectionsTreeResult } from '@indigohive/cogfy-types/endpoints/getCollectionsTree'
import { GetConnectionsListQuery, GetConnectionsListResult } from '@indigohive/cogfy-types/endpoints/getConnectionsList'
import { GetCurrentUserRoleResult } from '@indigohive/cogfy-types/endpoints/getCurrentUserRole'
import { GetFieldsQuery, GetFieldsResult } from '@indigohive/cogfy-types/endpoints/getFields'
import { GetGoogleAuthUrlResult } from '@indigohive/cogfy-types/endpoints/getGoogleAuthUrl'
import { GetUserCollectionPermissionsQuery, GetUserCollectionPermissionsResult } from '@indigohive/cogfy-types/endpoints/getUserCollectionPermissions'
import { GetUserGroupsQuery, GetUserGroupsResult } from '@indigohive/cogfy-types/endpoints/getUserGroups'
import { GetUsersOrUserGroupsQuery, GetUsersOrUserGroupsResult } from '@indigohive/cogfy-types/endpoints/getUsersOrUserGroups'
import { GetWorkspaceAssistantResult } from '@indigohive/cogfy-types/endpoints/getWorkspaceAssistant'
import { GetWorkspaceByIdQuery, GetWorkspaceByIdResult } from '@indigohive/cogfy-types/endpoints/getWorkspaceById'
import { GetWorkspaceMembersQuery, GetWorkspaceMembersResult } from '@indigohive/cogfy-types/endpoints/getWorkspaceMembers'
import { GetWorkspacesResult } from '@indigohive/cogfy-types/endpoints/getWorkspaces'
import { GetWorkspaceUserContactsQuery, GetWorkspaceUserContactsResult } from '@indigohive/cogfy-types/endpoints/getWorkspaceUserContacts'
import { MoveCollectionCommand } from '@indigohive/cogfy-types/endpoints/moveCollection'
import { ReorderChatMiddlewareCommand } from '@indigohive/cogfy-types/endpoints/reorderChatMiddleware'
import { SendChatMessageCommand } from '@indigohive/cogfy-types/endpoints/sendChatMessage'
import { SendWhatsAppMessageCommand, SendWhatsAppMessageResult } from '@indigohive/cogfy-types/endpoints/sendWhatsAppMessage'
import { UpdateChatMiddlewareCommand } from '@indigohive/cogfy-types/endpoints/updateChatMiddleware'
import { UpdateCollectionAccessControlCommand } from '@indigohive/cogfy-types/endpoints/updateCollectionAccessControl'
import { UpdateCollectionAccessControlTypeCommand } from '@indigohive/cogfy-types/endpoints/updateCollectionAccessControlType'
import { UpdateWorkspaceAssistantCommand } from '@indigohive/cogfy-types/endpoints/updateWorkspaceAssistant'
import { UploadChatMessageFilesCommand, UploadChatMessageFilesResult } from '@indigohive/cogfy-types/endpoints/uploadChatMessageFiles'
import { UserEventCommand } from '@indigohive/cogfy-types/endpoints/userEvent'
import { AxiosInstance } from 'axios'

type Milliseconds = number

type RequestOptions = {
  signal?: AbortSignal
  timeout?: Milliseconds
}

export abstract class EndpointsClient {
  protected axios: AxiosInstance

  constructor (axios: AxiosInstance) {
    this.axios = axios
  }

  async completeUploadChatMessageFiles (
    data: CompleteUploadChatMessageFilesCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/completeUploadChatMessageFiles', data, options)
  }

  async createConnection(
    data: CreateConnectionCommand,
    options?: RequestOptions
  ): Promise<CreateConnectionResult> {
    return (await this.axios.post('/createConnection', data, options)).data
  }

  async createApiKey (
    data: CreateApiKeyCommand,
    options?: RequestOptions
  ): Promise<CreateApiKeyResult> {
    return (await this.axios.post('/createApiKey', data, options)).data
  }

  async createChatMiddleware (
    data: CreateChatMiddlewareCommand,
    options?: RequestOptions
  ): Promise<CreateChatMiddlewareResult> {
    return (await this.axios.post('/createChatMiddleware', data, options)).data
  }

  async createCollectionAccessControl (
    data: CreateCollectionAccessControlCommand,
    options?: RequestOptions
  ): Promise<CreateCollectionAccessControlResult> {
    return (await this.axios.post('/createCollectionAccessControl', data, options)).data
  }

  async createCollection (
    data: CreateCollectionCommand,
    options?: RequestOptions
  ): Promise<CreateCollectionResult> {
    return (await this.axios.post('/createCollection', data, options)).data
  }

  async createFolder (
    data: CreateFolderCommand,
    options?: RequestOptions
  ): Promise<CreateFolderResult> {
    return (await this.axios.post('/createFolder', data, options)).data
  }

  async createUserGroup (
    data: CreateUserGroupCommand,
    options?: RequestOptions
  ): Promise<CreateUserGroupResult> {
    return (await this.axios.post('/createUserGroup', data, options)).data
  }

  async createUserGroupMember (
    data: CreateUserGroupMemberCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/createUserGroupMember', data, options)
  }

  async createWorkspaceMember (
    data: CreateWorkspaceMemberCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/createWorkspaceMember', data, options)
  }

  async createWorkspaceUserContact (
    data: CreateWorkspaceUserContactCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/createWorkspaceUserContact', data, options)
  }

  async deleteApiKey (
    data: DeleteApiKeyCommand,
    options?: RequestOptions
  ): Promise<void> {
    return (await this.axios.post('/deleteApiKey', data, options)).data
  }

  async deleteChatMiddleware (
    data: DeleteChatMiddlewareCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/deleteChatMiddleware', data, options)
  }

  async deleteCollectionAccessControl (
    data: DeleteCollectionAccessControlCommand,
    options?: RequestOptions
  ): Promise<void> {
    return (await this.axios.post('/deleteCollectionAccessControl', data, options)).data
  }

  async deleteConnection (
    data: DeleteConnectionCommand,
    options?: RequestOptions
  ): Promise<void> {
    return (await this.axios.post('/deleteConnection', data, options)).data
  }

  async deleteUserGroup (
    data: DeleteUserGroupCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/deleteUserGroup', data, options)
  }

  async deleteUserGroupMembers (
    data: DeleteUserGroupMembersCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/deleteUserGroupMembers', data, options)
  }

  async deleteWorkspaceMembers (
    data: DeleteWorkspaceMembersCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/deleteWorkspaceMembers', data, options)
  }

  async deleteWorkspaceUserContact (
    data: DeleteWorkspaceUserContactCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/deleteWorkspaceUserContact', data, options)
  }

  async duplicateChatMiddleware (
    data: DuplicateChatMiddlewareCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/duplicateChatMiddleware', data, options)
  }

  async getChatMessageFileById (
    data: GetChatMessageFileByIdQuery,
    options?: RequestOptions
  ): Promise<GetChatMessageFileByIdResult> {
    return (await this.axios.post('/getChatMessageFileById', data, options)).data
  }

  async getChatMiddlewares (
    data: GetChatMiddlewaresQuery,
    options?: RequestOptions
  ): Promise<GetChatMiddlewaresResult> {
    return (await this.axios.post('/getChatMiddlewares', data, options)).data
  }

  async getCollectionAccessControlList (
    data: GetCollectionAccessControlListQuery,
    options?: RequestOptions
  ): Promise<GetCollectionAccessControlListResult> {
    return (await this.axios.post('/getCollectionAccessControlList', data, options)).data
  }

  async getCollectionAncestors (
    data: GetCollectionAncestorsQuery,
    options?: RequestOptions
  ): Promise<GetCollectionAncestorsResult> {
    return (await this.axios.post('/getCollectionAncestors', data, options)).data
  }

  async getCollections (
    data: GetCollectionsQuery,
    options?: RequestOptions
  ): Promise<GetCollectionsResult> {
    return (await this.axios.post('/getCollections', data, options)).data
  }

  async getCollectionsTree (
    options?: RequestOptions
  ): Promise<GetCollectionsTreeResult> {
    return (await this.axios.post('/getCollectionsTree', null, options)).data
  }

  async getConnectionsList (
    data: GetConnectionsListQuery,
    options?: RequestOptions
  ): Promise<GetConnectionsListResult> {
    return (await this.axios.post('/getConnectionsList', data, options)).data
  }

  async getCurrentUserRole (
    options?: RequestOptions
  ): Promise<GetCurrentUserRoleResult> {
    return (await this.axios.post('/getCurrentUserRole', null, options)).data
  }

  async getFields (
    data: GetFieldsQuery,
    options?: RequestOptions
  ): Promise<GetFieldsResult> {
    return (await this.axios.post('/getFields', data, options)).data
  }

  async getGoogleAuthUrl (
    options?: RequestOptions
  ): Promise<GetGoogleAuthUrlResult> {
    return (await this.axios.post('/getGoogleAuthUrl', null, options)).data
  }

  async getGoogleCalendarAuthUrl (
    options?: RequestOptions
  ): Promise<GetGoogleAuthUrlResult> {
    return (await this.axios.post('/getGoogleCalendarAuthUrl', null, options)).data
  }

  async getUserCollectionPermissions (
    data: GetUserCollectionPermissionsQuery,
    options?: RequestOptions
  ): Promise<GetUserCollectionPermissionsResult> {
    return (await this.axios.post('/getUserCollectionPermissions', data, options)).data
  }

  async getUserGroups (
    data: GetUserGroupsQuery,
    options?: RequestOptions
  ): Promise<GetUserGroupsResult> {
    return (await this.axios.post('/getUserGroups', data, options)).data
  }

  async getUsersOrUserGroups (
    data: GetUsersOrUserGroupsQuery,
    options?: RequestOptions
  ): Promise<GetUsersOrUserGroupsResult> {
    return (await this.axios.post('/getUsersOrUserGroups', data, options)).data
  }

  async getWorkspaceAssistant (
    options?: RequestOptions
  ): Promise<GetWorkspaceAssistantResult> {
    return (await this.axios.post('/getWorkspaceAssistant', null, options)).data
  }

  async getWorkspaceById (
    data: GetWorkspaceByIdQuery,
    options?: RequestOptions
  ): Promise<GetWorkspaceByIdResult> {
    return (await this.axios.post('/getWorkspaceById', data, options)).data
  }

  async getWorkspaceMembers (
    data: GetWorkspaceMembersQuery,
    options?: RequestOptions
  ): Promise<GetWorkspaceMembersResult> {
    return (await this.axios.post('/getWorkspaceMembers', data, options)).data
  }

  async getWorkspaces (
    options?: RequestOptions
  ): Promise<GetWorkspacesResult> {
    return (await this.axios.post('/getWorkspaces', null, options)).data
  }

  async getWorkspaceUserContacts (
    data: GetWorkspaceUserContactsQuery,
    options?: RequestOptions
  ): Promise<GetWorkspaceUserContactsResult> {
    return (await this.axios.post('/getWorkspaceUserContacts', data, options)).data
  }

  async moveCollection (
    data: MoveCollectionCommand,
    options?: RequestOptions
  ): Promise<void> {
    return (await this.axios.post('/moveCollection', data, options)).data
  }

  async reorderChatMiddleware (
    data: ReorderChatMiddlewareCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/reorderChatMiddleware', data, options)
  }

  async sendChatMessage (
    data: SendChatMessageCommand,
    options?: RequestOptions
  ): Promise<void> {
    return (await this.axios.post('/sendChatMessage', data, options)).data
  }

  async sendWhatsAppMessage (
    data: SendWhatsAppMessageCommand,
    options?: RequestOptions
  ): Promise<SendWhatsAppMessageResult> {
    return (await this.axios.post('/sendWhatsAppMessage', data, options)).data
  }

  async updateChatMiddleware (
    data: UpdateChatMiddlewareCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/updateChatMiddleware', data, options)
  }

  async updateCollectionAccessControl (
    data: UpdateCollectionAccessControlCommand,
    options?: RequestOptions
  ): Promise<void> {
    return (await this.axios.post('/updateCollectionAccessControl', data, options)).data
  }

  async updateCollectionAccessControlType (
    data: UpdateCollectionAccessControlTypeCommand,
    options?: RequestOptions
  ): Promise<void> {
    return (await this.axios.post('/updateCollectionAccessControlType', data, options)).data
  }

  async updateWorkspaceAssistant (
    data: UpdateWorkspaceAssistantCommand,
    options?: RequestOptions
  ): Promise<void> {
    return (await this.axios.post('/updateWorkspaceAssistant', data, options)).data
  }

  async uploadChatMessageFiles (
    data: UploadChatMessageFilesCommand,
    options?: RequestOptions
  ): Promise<UploadChatMessageFilesResult> {
    return (await this.axios.post('/uploadChatMessageFiles', data, options)).data
  }

  async userEvent (
    data: UserEventCommand,
    options?: RequestOptions
  ): Promise<void> {
    await this.axios.post('/userEvent', data, options)
  }
}
