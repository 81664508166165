import clsx, { type ClassValue } from 'clsx'
import { ReactNode, type InputHTMLAttributes } from 'react'

export type ToggleProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  className?: ClassValue
  color?: 'primary' | 'secondary' | 'success'
  label?: ReactNode
  helperText?: ReactNode
  inputSize?: 'lg' | 'md' | 'sm' | 'xs'
}

export function Toggle (props: ToggleProps) {
  const { className, color, label, helperText, inputSize, ...rest } = props

  return (
    <div className="form-control">
      <label className="label cursor-pointer">
        <div className="flex flex-col gap-1">
          {label && (
            <span className="label-text flex gap-2">
              {label}
            </span>
          )}
          {helperText && (
            <span className="text-xs">{helperText}</span>
          )}
        </div>
        <input
          type="checkbox"
          className={clsx(
            'toggle',
            {
              'toggle-primary': color === 'primary',
              'toggle-secondary': color === 'secondary',
              'toggle-success': color === 'success'
            },
            {
              'toggle-lg': inputSize === 'lg',
              'toggle-md': inputSize === 'md',
              'toggle-sm': inputSize === 'sm',
              'toggle-xs': inputSize === 'xs'
            },
            className
          )}
          {...rest}
        />
      </label>
    </div>
  )
}
