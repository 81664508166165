import { HouseIcon } from 'lucide-react'
import type { UUID } from '@indigohive/cogfy-types'
import type { CreateCollectionCommand } from '@indigohive/cogfy-types/endpoints/createCollection'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { TemplateCard } from './components'
import { BreadcrumbItem, Breadcrumbs, Container, Heading, Navbar } from '../../components'
import { useCollection, useCollectionTemplatesPage, useCogfy, useToasts, useWorkspaceSlug } from '../../hooks'

export function CollectionTemplatesPage () {
  const cogfy = useCogfy()
  const toasts = useToasts()
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const workspace = useWorkspaceSlug()
  const parent = useCollection(searchParams.get('folder') as UUID | undefined)
  const getTemplatesPage = useCollectionTemplatesPage({})
  const createCollection = useMutation({
    mutationFn: (data: CreateCollectionCommand) => cogfy.createCollection(data),
    onSuccess: result => {
      navigate(`/${workspace}/${result.id}`)
      toasts.success(t('collectionTemplatesPage:Collection created'))
    },
    onError: () => {
      toasts.error(t('collectionTemplatesPage:Error creating collection'))
    }
  })

  const breadcrumbItems: BreadcrumbItem[] = [
    {
      label: t('Home'),
      icon: <HouseIcon size={16} />
    }
  ]

  return (
    <>
      <Navbar>
        <Breadcrumbs items={breadcrumbItems} />
      </Navbar>
      <Container>
        <Heading className="mt-4">
          Templates
        </Heading>

        {getTemplatesPage.isLoading && <progress className="progress w-full" />}

        <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-4 my-4">
          {getTemplatesPage.data && (
            <>
              <TemplateCard
                disabled={createCollection.isPending}
                name={t('collectionTemplatesPage:Blank')}
                description={t('collectionTemplatesPage:Empty collection')}
                onClick={() => createCollection.mutate({
                  parentId: parent.data?.id,
                  templateId: null
                })}
              />
              {getTemplatesPage.data?.data.map(template => (
                <TemplateCard
                  key={template.id}
                  disabled={createCollection.isPending}
                  name={template.name}
                  description={template.description}
                  template={template}
                  onClick={template => createCollection.mutate({
                    parentId: parent.data?.id,
                    templateId: template!.id
                  })}
                />
              ))}
            </>
          )}
        </div>
      </Container>
    </>
  )
}
