import { useEffect, useState } from 'react'
import { CollectionState } from '../collection-state'

export function useFieldsById (state: CollectionState) {
  const [fieldsById, setFieldsById] = useState(state.fieldsById)

  useEffect(() => {
    const fieldsChanged = () => {
      setFieldsById(state.fieldsById)
    }

    state.addEventListener('fieldsChanged', fieldsChanged)

    return () => {
      state.removeEventListener('fieldsChanged', fieldsChanged)
    }
  })

  return fieldsById
}
