import { AssistantChatMessageData, DeprecatedChatMessageData, GetChatMessagesPageResultData, SourceCellResult, SourceFileResult } from '@indigohive/cogfy-types'
import { useCogfy, useWorkspaceSlug } from '../../../hooks'
import { useMutation } from '@tanstack/react-query'
import { Button } from '../../../components'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TableIcon } from 'lucide-react'
import { ChatMessageSource } from './ChatMessageSource'
import { ChatRetrievalSource } from '@indigohive/cogfy-types/src/chat-messages/chat-retrieval-source'

export type ChatSourcesBubbleContentProps = {
  message?: GetChatMessagesPageResultData
}

export function ChatSourcesBubbleContent (props: ChatSourcesBubbleContentProps) {
  const { message } = props

  const workspaceSlug = useWorkspaceSlug()
  const cogfy = useCogfy()
  const showFile = useMutation({
    mutationFn: async (data: { fileId: string, pageNumber: number }) => {
      const { fileId, pageNumber } = data

      const file = await cogfy.files.getById(fileId)
      const documentPage = pageNumber ? `#page=${pageNumber}` : ''

      window.open(`${file.url}${documentPage}`, '_blank')
    }
  })
  const { t } = useTranslation()

  const data = message?.data as AssistantChatMessageData | DeprecatedChatMessageData | null
  const files = (data as AssistantChatMessageData | null)?.assistant?.source?.files ?? (data as DeprecatedChatMessageData | null)?.source?.files ?? []
  const cells = (data as AssistantChatMessageData | null)?.assistant?.source?.cells ?? []
  const fieldToolSources = (data as AssistantChatMessageData)?.assistant?.fieldToolSources ?? []

  const middlewaresSources: ChatRetrievalSource[] = [...(message?.data as any)?.completion?.sources ?? [], ...(message?.data as any)?.tool?.sources ?? []]

  if (files.length === 0 && cells.length === 0 && fieldToolSources.length === 0 && middlewaresSources.length === 0) return

  return (
    <>
      {fieldToolSources.map((source, index) => (
        <div
          key={index}
          className="dropdown dropdown-hover dropdown-top dropdown-start"
        >
          <div tabIndex={0} role="button">
            <Button
              size="xs"
              circle
              className="bg-primary bg-opacity-5 text-primary border-primary border-opacity-30 hover:border-primary hover:border-opacity-30 mt-2 mr-2"
            >
              {index + 1}
            </Button>
          </div>
          <div tabIndex={0} className="dropdown-content menu p-4 shadow bg-base-100 rounded-box text-wrap max-h-40 overflow-y-auto w-80 overflow-x-hidden">
            <div className="flex flex-col">
              <span className="text-slate-600 w-full break-words max-w-72">
                {source.type === 'cogfy_embedding' && source.cogfyEmbedding.content}
                {source.type === 'cogfy_record' && source.cogfyRecord.content}
                {source.type === 'url' && source.url.content}
              </span>
              <div className="flex pt-2 gap-2 items-center">
                {source.type === 'cogfy_record' && (
                  <Link
                    className="link break-words max-w-72"
                    to={`/${workspaceSlug}/${source.cogfyRecord.collectionId}/${source.cogfyRecord.recordId}`}
                  >
                    {source.cogfyRecord.recordTitle}
                  </Link>
                )}

                {source.type === 'cogfy_embedding' && (
                  <>
                    {!source.cogfyEmbedding.fileId && (
                      <Link
                        className="link"
                        to={`/${workspaceSlug}/${source.cogfyEmbedding.collectionId}/${source.cogfyEmbedding.recordId}`}
                      >
                        {source.cogfyEmbedding.content}
                      </Link>
                    )}

                    {source.cogfyEmbedding.fileId && (
                      <a
                        className="link w-full truncate pt-2"
                        onClick={event => {
                          event.preventDefault()
                          showFile.mutate({ fileId: source.cogfyEmbedding.fileId!, pageNumber: source.cogfyEmbedding.pageNumber ?? 0 })
                        }}
                      >
                        {source.cogfyEmbedding.fileName}
                      </a>
                    )}
                  </>
                )}

                {source.type === 'url' && (
                  <a
                    className="link w-full truncate pt-2"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={source.url.value}
                  >
                    {source.url.title}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}

      {[...files, ...cells].map((source: SourceFileResult | SourceCellResult, index: number) => (
        <div
          key={index}
          className="dropdown dropdown-hover dropdown-top dropdown-start"
        >
          <div tabIndex={0} role="button">
            <Button
              size="xs"
              circle
              className="bg-primary bg-opacity-5 text-primary border-primary border-opacity-30 hover:border-primary hover:border-opacity-30 mt-2 mr-2"
            >
              {index + 1}
            </Button>
          </div>
          <div tabIndex={0} className="dropdown-content menu p-4 shadow bg-base-100 rounded-box text-wrap max-h-40 overflow-y-auto w-80 overflow-x-hidden">
            <div className="flex flex-col">
              <span className="text-slate-600 break-words max-w-72">
                {'contentPreview' in source && source.contentPreview}
                {'content' in source && source.content}
              </span>
              {'id' in source && (
                <a
                  className="link w-full truncate pt-2"
                  onClick={event => {
                    event.preventDefault()
                    showFile.mutate({ fileId: source.id, pageNumber: source.pageNumber ?? 0 })
                  }}
                >
                  {source.name}
                </a>
              )}
              {'collectionName' in source && (
                <div className="flex pt-2 gap-2 items-center">
                  <div>
                    <TableIcon size={14} />
                  </div>
                  <Link className="link" to={`/${workspaceSlug}/${source.collectionId}`}>
                    {source.collectionName ?? t('Untitled')}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}

      {/* From chat engine */}
      {middlewaresSources.map((source, index) => (
        <ChatMessageSource
          key={index}
          source={source}
          order={index + 1}
          showFile={showFile.mutate}
          workspace={workspaceSlug}
        />
      ))}
    </>
  )
}
