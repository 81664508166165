import { ChatMiddlewareType } from '@indigohive/cogfy-types'
import { siAmazonwebservices, siOpenai, siWhatsapp, SimpleIcon as SimpleIconType } from 'simple-icons'
import { createElement } from 'react'
import {
  CircleStopIcon,
  FolderTreeIcon,
  GlobeIcon,
  HammerIcon,
  HistoryIcon,
  ReplaceIcon,
  ReplyIcon,
  ScrollTextIcon,
  SquareTerminalIcon,
  TextSearchIcon,
  ZapIcon
} from 'lucide-react'
import { SimpleIcon } from '../SimpleIcon'

export type ChatMiddlewareIconProps = {
  type: ChatMiddlewareType
  size?: number
}

const CHAT_MIDDLEWARE_LUCIDE_ICONS: Partial<Record<ChatMiddlewareType, typeof SquareTerminalIcon>> = {
  action: ZapIcon,
  reply: ReplyIcon,
  transform: ReplaceIcon,
  http: GlobeIcon,
  group: FolderTreeIcon,
  stop: CircleStopIcon,
  instructions: ScrollTextIcon,
  history: HistoryIcon,
  retrieval: TextSearchIcon,
  tool: HammerIcon
}

const CHAT_MIDDLEWARE_SIMPLE_ICONS: Partial<Record<ChatMiddlewareType, SimpleIconType>> = {
  bedrock: siAmazonwebservices,
  openai: siOpenai,
  whatsapp: siWhatsapp
}

const CHAT_MIDDLEWARE_PUBLIC_ICONS: Partial<Record<ChatMiddlewareType, string>> = {
  azure: '/azure-blackwhite-avatar.webp'
}

export function ChatMiddlewareIcon (props: ChatMiddlewareIconProps) {
  const { type, size = 16 } = props

  if (CHAT_MIDDLEWARE_LUCIDE_ICONS[type]) {
    return createElement(CHAT_MIDDLEWARE_LUCIDE_ICONS[type], { size })
  }

  if (CHAT_MIDDLEWARE_SIMPLE_ICONS[type]) {
    return <SimpleIcon icon={CHAT_MIDDLEWARE_SIMPLE_ICONS[type]} size={size} />
  }

  if (CHAT_MIDDLEWARE_PUBLIC_ICONS[type]) {
    return <img src={CHAT_MIDDLEWARE_PUBLIC_ICONS[type]} alt={type} width={size} height={size} />
  }

  return (
    <></>
  )
}
