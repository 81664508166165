import { PlusIcon } from 'lucide-react'
import { CreateConnectionCommand } from '@indigohive/cogfy-types/endpoints/createConnection'
import { Button, Input } from '../../../components'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useCogfy, useToasts } from '../../../hooks'

const dialogId = 'add_http_dialog'

export type AddHttpButtonProps = {
  onSuccess?: () => void
}

export function AddHttpButton (props: AddHttpButtonProps) {
  const { t } = useTranslation()
  const cogfy = useCogfy()
  const toasts = useToasts()
  const formik = useFormik({
    initialValues: {
      name: '',
      baseUrl: '',
      headers: [] as { name: string, value: string }[]
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(t('Required field')),
      headers: yup.array().of(
        yup.object().required().shape({
          name: yup.string().required(t('Required field')),
          value: yup.string().required(t('Required field'))
        })
      )
    }),
    onSubmit: async (values, helpers) => {
      try {
        const commandValues: CreateConnectionCommand['values'] = []

        if (values.baseUrl) {
          commandValues.push({ name: 'base_url', value: values.baseUrl })
        }

        await cogfy.createConnection({
          provider: 'http',
          name: values.name,
          values: [
            ...commandValues,
            ...values.headers.map((header, index) => ({
              name: 'header_name',
              value: header.name,
              order: index
            })),
            ...values.headers.map((header, index) => ({
              name: 'header_value',
              value: header.value,
              order: index
            }))
          ]
        })
        props.onSuccess?.()
        const dialog = document.getElementById(dialogId) as HTMLDialogElement
        dialog.close()
        helpers.resetForm()
      } catch {
        toasts.error(t('Unexpected error'))
      }
    }
  })

  return (
    <>
      <Button
        color="primary"
        size="sm"
        onClick={() => {
          const dialog = document.getElementById(dialogId) as HTMLDialogElement
          dialog.showModal()
        }}
      >
        <PlusIcon size={16} />
        {t('WorkspaceConnectionsPage:Connect')}
      </Button>
      <dialog id={dialogId} className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">
            {t('WorkspaceConnectionsPage:Configure HTTP requests')}
          </h3>

          <form
            id={dialogId + '_form'}
            className="flex flex-col gap-4 my-4"
            onSubmit={formik.handleSubmit}
          >
            <Input
              label={t('WorkspaceConnectionsPage:Connection name')}
              error={Boolean(formik.touched.name && formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              {...formik.getFieldProps('name')}
            />

            <Input
              label="Base URL"
              error={Boolean(formik.touched.baseUrl && formik.errors.baseUrl)}
              helperText={formik.touched.baseUrl && formik.errors.baseUrl}
              {...formik.getFieldProps('baseUrl')}
            />

            <div className="flex">
              <h4 className="grow font-bold">Headers</h4>
              <Button
                size="xs"
                onClick={() => {
                  formik.setFieldValue('headers', [...formik.values.headers, { name: '', value: '' }])
                }}
              >
                {t('WorkspaceConnectionsPage:Add header')}
              </Button>
            </div>
            {formik.values.headers.length === 0 && (
              <p className="text-sm opacity-40">
                {t('WorkspaceConnectionsPage:No headers configured')}
              </p>
            )}
            {formik.values.headers.map((header, index) => (
              <div key={index} className="flex flex-col gap-2">
                <div className="flex">
                  <h5 className="grow text-sm">Header {index + 1}</h5>
                  <Button
                    size="xs"
                    onClick={() => {
                      formik.setFieldValue('headers', formik.values.headers.filter((_, i) => i !== index))
                    }}
                  >
                    {t('Delete')}
                  </Button>
                </div>
                <Input
                  placeholder="Header name"
                  value={header.name}
                  onChange={event => {
                    formik.setFieldValue(
                      'headers',
                      formik.values.headers.map((h, i) => i === index ? { ...h, name: event.target.value } : h)
                    )
                  }}
                  error={Boolean(formik.errors.headers?.[index])}
                />
                <Input
                  type="password"
                  placeholder="Header value"
                  value={header.value}
                  onChange={event => {
                    formik.setFieldValue(
                      'headers',
                      formik.values.headers.map((h, i) => i === index ? { ...h, value: event.target.value } : h)
                    )
                  }}
                  error={Boolean(formik.errors.headers?.[index])}
                />
              </div>
            ))}
          </form>

          <form method="dialog" id={dialogId + '_close_button'}>
            <div className="modal-action">
              <Button
                size="sm"
                form={dialogId + '_close_button'}
                disabled={formik.isSubmitting}
                onClick={() => {
                  formik.resetForm()
                }}
              >
                {t('Close')}
              </Button>
              <Button
                type="submit"
                size="sm"
                color="primary"
                form={dialogId + '_form'}
                disabled={formik.isSubmitting}
              >
                {t('Confirm')}
              </Button>
            </div>
          </form>
        </div>
      </dialog>
    </>
  )
}
