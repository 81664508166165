import { useEffect } from 'react'
import { useAppManager } from '../../../hooks'

export function useUndoRedo () {
  const appManager = useAppManager()

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const shiftKey = event.shiftKey
      const ctrlKey = event.ctrlKey || event.metaKey
      const key = event.key

      const isUndo = ctrlKey && key === 'z' && !shiftKey
      const isRedo =
        (ctrlKey && key === 'z' && shiftKey) ||
        (ctrlKey && key === 'y' && !shiftKey)

      if (isUndo) {
        appManager.undo()
        event.preventDefault()
      } else if (isRedo) {
        appManager.redo()
        event.preventDefault()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [appManager])
}
