import {
  KeyRoundIcon,
  SettingsIcon,
  Share2Icon,
  UsersIcon,
  UserIcon,
  WebhookIcon,
  BotIcon
} from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const drawerSettingsItems = [
  {
    title: 'Workspace',
    icon: SettingsIcon,
    to: 'settings'
  },
  {
    title: 'Members',
    icon: UserIcon,
    to: 'members'
  },
  {
    title: 'Groups',
    icon: UsersIcon,
    to: 'groups'
  },
  {
    title: 'Assistant',
    icon: BotIcon,
    to: 'assistant'
  },
  {
    title: 'Connections',
    icon: Share2Icon,
    to: 'connections'
  },
  {
    title: 'API Keys',
    icon: KeyRoundIcon,
    to: 'api-keys'
  },
  {
    title: 'Webhooks',
    icon: WebhookIcon,
    to: 'webhooks'
  }
]

export type SettingsDrawerItemsProps = {
  workspace: string
}

export function SettingsDrawerItems (props: SettingsDrawerItemsProps) {
  const { workspace } = props
  const { t } = useTranslation()

  return (
    <ul className="menu p-0">
      {drawerSettingsItems.map(item => (
        <li key={item.title} className="px-1">
          <Link to={`/${workspace}/${item.to}`}>
            <item.icon size={16} /> {t(item.title)}
          </Link>
        </li>
      ))}
    </ul>
  )
}
