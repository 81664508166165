import { UpdateDateFieldData, UpdateDateRecordProperty } from '@indigohive/cogfy-types'
import { FormattedDate } from '../../../../components'
import { FieldCellProps } from '../../FieldCell'
import clsx from 'clsx'

export type UpdateCellProps = Pick<
FieldCellProps<UpdateDateRecordProperty, UpdateDateFieldData>,
'property' | 'field' | 'textWrap'
>

export function UpdateDateCell (props: UpdateCellProps) {
  const { field, textWrap, property } = props

  return (
    <span
      className={clsx(
        'px-2 py-1',
        textWrap && 'whitespace-pre-wrap',
        !textWrap && 'truncate'
      )}
    >
      <FormattedDate
        date={property?.updateDate?.value}
        format={field.data?.updateDate?.format ?? 'Pp'}
      />
    </span>
  )
}
