import { AuthorRecordProperty } from '@indigohive/cogfy-types'
import { FieldCellProps } from '../../FieldCell'
import clsx from 'clsx'

export type AuthorCellProps = Pick<FieldCellProps<AuthorRecordProperty>, 'property' | 'textWrap'>

export function AuthorCell (props: AuthorCellProps) {
  const { property, textWrap } = props

  return (
    <span
      className={clsx(
        'px-2 py-1',
        textWrap && 'whitespace-pre-wrap',
        !textWrap && 'truncate'
      )}
    >
      {property?.author?.value?.name}
    </span>
  )
}
