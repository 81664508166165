import { useFormik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, CogfyLogo } from '../../components'
import { OneTimePasswordInput } from '../../components/OneTimePasswordInput'
import { useAuthentication, useCogfy, useToasts } from '../../hooks'

export function TotpSignInPage () {
  const cogfy = useCogfy()
  const location = useLocation()
  const navigate = useNavigate()
  const authentication = useAuthentication()
  const toasts = useToasts()
  const { t } = useTranslation()

  const accessToken: string | undefined = location.state?.accessToken

  const formik = useFormik({
    initialValues: {
      token: ''
    },
    onSubmit: async values => {
      try {
        const result = await cogfy.authentication.totpSignIn({
          accessToken: accessToken!,
          token: values.token
        })
        authentication.onAuthenticatedUserChange?.(result)
        navigate('/')
      } catch (error) {
        if (cogfy.isUnauthorized(error)) {
          toasts.error(t('Invalid token'))
        } else {
          toasts.error(t('Unexpected error'))
        }
      }
    }
  })

  useEffect(() => {
    if (!accessToken) {
      navigate('/sign-in', { replace: true })
    }
  }, [accessToken])

  return (
    <div className="w-full flex flex-col items-center">
      <div className="navbar max-w-screen-xl">
        <CogfyLogo size={48} />
      </div>
      <div className="w-96 p-6 mt-[8vh] mb-[16vh]">
        <h1 className="text-2xl font-semibold mb-2">
          {t('Two-factor authentication')}
        </h1>
        <p>
          {t('Enter the verification code generated by your mobile application.')}
        </p>
        <form onSubmit={formik.handleSubmit}>
          <div className="my-8">
            <OneTimePasswordInput
              value={formik.values.token}
              onChange={token => { void formik.setFieldValue('token', token) }}
            />
          </div>
          <Button
            type="submit"
            color="primary"
            className="w-full mt-4"
            disabled={formik.isSubmitting}
          >
            {t('Confirm')}
          </Button>
        </form>
      </div>
    </div>
  )
}
