export function getHashOfString (str: string) {
  let hash = 0

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }

  hash = Math.abs(hash)

  return hash
}
