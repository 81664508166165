import type {
  CollectionParentUpdatedEvent,
  DuplicateCollectionResult,
  GetCollectionFieldsResult,
  GetCollectionRecordsPageQuery,
  GetCollectionRecordsPageResult,
  GetCollectionResult,
  GetCollectionViewsResult,
  QueryCollectionRecordsPageQuery,
  QueryCollectionRecordsPageResult,
  UUID
} from '@indigohive/cogfy-types'
import { CollectionCreatedEvent } from '@indigohive/cogfy-types/events/collectionCreated'
import { BaseClient, BaseClientOptions } from './base-client'
import { SocketEvent } from '../socket'

export class CollectionsClient extends BaseClient {
  onCollectionCreated: SocketEvent<CollectionCreatedEvent>
  onCollectionParentUpdated: SocketEvent<CollectionParentUpdatedEvent>

  constructor (options: BaseClientOptions) {
    super(options)

    this.onCollectionCreated = new SocketEvent(this.socket, 'collection_created')
    this.onCollectionParentUpdated = new SocketEvent(this.socket, 'collection_parent_updated')
  }

  async duplicateCollection(
    collectionId: UUID
  ): Promise<DuplicateCollectionResult>{
    return (await this.axios.post(`/collections/${collectionId}/duplicate`)).data
  }

  exportCsvUrl (
    workspaceSlug: string,
    collectionId: string,
    viewId?: string | null
  ): string {
    return this.axios.defaults.baseURL + `/collections/${collectionId}/export-csv?workspace=${workspaceSlug}&viewId=${viewId}`
  }

  exportXlsxUrl (
    workspaceSlug: string,
    collectionId: string,
    viewId?: string | null
  ): string {
    return this.axios.defaults.baseURL + `/collections/${collectionId}/export-xlsx?workspace=${workspaceSlug}&viewId=${viewId}`
  }

  async getById (
    collectionId: string,
    signal?: AbortSignal
  ): Promise<GetCollectionResult> {
    return (await this.axios.get(`/collections/${collectionId}`, { signal })).data
  }

  async getFields (
    collectionId: string,
    signal?: AbortSignal
  ): Promise<GetCollectionFieldsResult> {
    return (await this.axios.get(`/collections/${collectionId}/fields`, { signal })).data
  }

  async getRecordsPage (
    collectionId: string,
    params: GetCollectionRecordsPageQuery = {},
    signal?: AbortSignal
  ): Promise<GetCollectionRecordsPageResult> {
    const url = `/collections/${collectionId}/records`
    return (await this.axios.get(url, { params, signal })).data
  }

  async getViews (
    collectionId: string,
    signal?: AbortSignal
  ): Promise<GetCollectionViewsResult> {
    return (await this.axios.get(`/collections/${collectionId}/views`, { signal })).data
  }

  async queryRecordsPage (
    collectionId: string,
    data: QueryCollectionRecordsPageQuery = {},
    signal?: AbortSignal
  ): Promise<QueryCollectionRecordsPageResult> {
    return (await this.axios.post(`/collections/${collectionId}/query`, data, { signal })).data
  }
}
