import { EqualsFilter, NotEqualsFilter, RecordFilter, SelectFieldData, UUID } from '@indigohive/cogfy-types'
import { FieldFilterMenuProps } from '../FieldFilterChip'
import { OptionChip, OptionChipColorName } from '../../../components'
import { useEffect, useState } from 'react'
import { getFilterOperator } from '../methods'
import { useTranslation } from 'react-i18next'

export function SelectFilterMenu (props: FieldFilterMenuProps) {
  const { controller, viewFilter, filter, field } = props

  const { t } = useTranslation()
  const operator = getFilterOperator(filter, field) ?? 'equals'

  const [filterValue, setFilterValue] = useState(() => {
    if (['empty', 'notEmpty'].includes(operator)) {
      return ''
    }

    return (filter as any)[operator] ?? ''
  })

  const fieldData = field.data as SelectFieldData | undefined
  const options = fieldData?.select?.options ?? []
  const multiple = fieldData?.select?.multiple ?? false
  const optionSelectedIds =
    Array.isArray((filter as EqualsFilter)?.equals ?? (filter as NotEqualsFilter)?.notEquals)
      ? ((filter as EqualsFilter)?.equals || (filter as NotEqualsFilter)?.notEquals) as UUID[]
      : []
  const isEmptyOperator = operator === 'isEmpty' || operator === 'notEmpty'

  const selectOptions = [
    { value: 'equals', label: multiple ? 'contains' : 'is' },
    { value: 'notEquals', label: multiple ? 'not contains' : 'is not' },
    { value: 'isEmpty', label: 'is empty' },
    { value: 'notEmpty', label: 'is not empty' }
  ]

  useEffect(() => {
    setFilterValue(() => {
      if (['empty', 'notEmpty'].includes(operator)) {
        return ''
      }

      return (filter as any)[operator] ?? ''
    })
  }, [filter])

  return (
    <div className="space-y-2 p-2">
      <div className="flex items-center gap-x-2">
        <div className="whitespace-nowrap">{field.name}</div>
        <select
          className="select select-xs select-ghost max-w-xs w-full"
          value={operator}
          onChange={event => {
            const newOperator = event.target.value
            const calculatedNewOperator = calculateOperator(newOperator)
            const newValue = calculateFilterValue(newOperator, filterValue)
            const newFilter = {
              property: field.id,
              propertyType: 'select',
              [calculatedNewOperator]: newValue
            }

            controller.onViewFilterChange(viewFilter, newFilter as RecordFilter)
          }}
        >
          {selectOptions.map(option => (
            <option
              key={option.label}
              value={option.value}
            >
              {t(`filters:${option.label}`)}
            </option>
          ))}
        </select>
      </div>
      {!isEmptyOperator && (
        <div className="flex flex-col justify-center">
          {options
            .sort((a, b) => a.order - b.order)
            .map(option => (
              <li
                key={option.id}
              >
                <label className="flex items-center gap-2 p-2">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-info checkbox-sm"
                    checked={optionSelectedIds.some(id => id === option.id)}
                    onChange={event => {
                      const newOptionSelectedIds = event.target.checked ? [...optionSelectedIds, option.id] : optionSelectedIds.filter(id => id !== option.id)
                      const newFilter: any = { property: field.id }

                      if (newOptionSelectedIds.length > 0) {
                        newFilter.propertyType = 'select'
                        newFilter[operator] = newOptionSelectedIds
                      }

                      controller.onViewFilterChange(viewFilter, newFilter as RecordFilter)
                    }}
                  />
                  <OptionChip
                    label={option.label}
                    color={option.color as OptionChipColorName}
                  />
                </label>
              </li>
            ))
          }
        </div>
      )}
    </div>
  )
}

function calculateOperator (operator: string) {
  const newOperator = operator === 'notEmpty'
    ? 'isEmpty'
    : operator

  return newOperator
}

function calculateFilterValue (operator: string, value: UUID[]) {
  const newValue = operator === 'isEmpty'
    ? true
    : operator === 'notEmpty'
      ? false
      : value

  return newValue
}
