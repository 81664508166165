import { SendgridInboundEmailRecordProperty } from '@indigohive/cogfy-types'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { FormattedDate } from '../../../../components'
import { FieldCellProps } from '../../FieldCell'

export type SendgridInboundEmailCellProps = Pick<FieldCellProps<SendgridInboundEmailRecordProperty>, 'property' | 'textWrap'>

export function SendgridInboundEmailCell (props: SendgridInboundEmailCellProps) {
  const { property, textWrap } = props

  const { t } = useTranslation('fieldCell')

  const value = property?.['sendgrid.inboundEmail']

  return (
    <div className="w-full px-2 py-1">
      {property && (
        <div className="flex flex-wrap gap-1 items-center opacity-70">
          <div className="flex w-full items-center">
            <div className="grow truncate" title={value?.from ?? undefined}>
              {value?.from ?? t('Unknown sender')}
            </div>
            <FormattedDate
              className="text-xs"
              date={value?.createDate}
            />
          </div>
          <div className={clsx('grow', textWrap && 'truncate')}>
            {value?.subject ?? <span className="opacity-40 italic">{t('No subject')}</span>}
          </div>
        </div>
      )}
    </div>
  )
}
