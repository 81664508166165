import { useQuery } from '@tanstack/react-query'
import { TransactionEvent, UUID } from '@indigohive/cogfy-types'
import { useCogfy } from './use-cogfy'
import { CollectionState } from '../lib'
import { useEffect } from 'react'

export function useCollectionFields (
  state?: CollectionState,
  collectionId?: UUID
) {
  const cogfy = useCogfy()

  const getCollectionFields = useQuery({
    queryKey: ['getCollectionFields', collectionId],
    queryFn: async ({ signal }) => {
      const result = await cogfy.collections.getFields(collectionId!, signal)

      if (state) {
        state.setFields(result.data)
      }

      return result
    },
    enabled: Boolean(collectionId)
  })

  useEffect(() => {
    const handleTransaction = (event: TransactionEvent) => {
      if (event.type === 'field_data_updated') {
        state?.updateField(event.data.id, event.data.data)
      }
    }

    if (collectionId) {
      cogfy.transactions.onTransaction(collectionId, handleTransaction)
    }

    return () => {
      if (collectionId) {
        cogfy.transactions.offTransaction(collectionId, handleTransaction)
      }
    }
  }, [state?.fields, collectionId])

  return getCollectionFields
}
