import { TextFieldData, TextRecordProperty, TextViewFieldConfig } from '@indigohive/cogfy-types'
import clsx from 'clsx'
import { FieldCellProps } from '../FieldCell'
import { FormattedText } from '../../../components'

export type TextCellProps = FieldCellProps<
TextRecordProperty, TextFieldData, TextViewFieldConfig
>

export function TextCell (props: TextCellProps) {
  const { viewField, property, textWrap } = props

  const formatText = viewField.config?.text?.textFormat

  const value = property?.text?.value

  return (
    <div
      className={clsx(
        'px-2 py-1',
        textWrap && 'whitespace-pre-wrap',
        !textWrap && 'truncate'
      )}
    >
      {formatText
        ? <FormattedText text={value ?? null} />
        : <>{value}</>}
    </div>
  )
}
