import clsx from 'clsx'
import { PropsWithChildren } from 'react'

export type ContainerProps = PropsWithChildren<{
  className?: Parameters<typeof clsx>[number]
  maxWidth?: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
}>

export function Container (props: ContainerProps) {
  const { maxWidth, className } = props

  return (
    <div
      className={clsx(
        'container',
        'mx-auto',
        'px-4',
        {
          'max-w-screen-sm': maxWidth === 'sm',
          'max-w-screen-md': maxWidth === 'md',
          'max-w-screen-lg': maxWidth === 'lg',
          'max-w-screen-xl': maxWidth === 'xl',
          'max-w-screen-2xl': maxWidth === '2xl'
        },
        className
      )}
    >
      {props.children}
    </div>
  )
}
