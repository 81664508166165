import { ChatMessageRole, UUID } from '@indigohive/cogfy-types'
import { keepPreviousData, useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useCogfy } from './use-cogfy'
import { useEffect } from 'react'
import { useRoom } from './use-room'

const getChatMessagesPageQuery = {
  pageSize: 100,
  roles: ['user', 'assistant', 'tool'] as ChatMessageRole[]
}

export function useChatMessagesPage (
  chatId: string
) {
  const cogfy = useCogfy()
  const queryClient = useQueryClient()
  useRoom(`chat:${chatId}`)

  const queryKey = ['getChatMessagesPage', chatId, getChatMessagesPageQuery]

  const getChatMessagesPage = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam, signal }) => cogfy.chats.getMessagesPage(
      chatId as UUID,
      { ...getChatMessagesPageQuery, pageNumber: pageParam },
      signal
    ),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.pageNumber + 1

      return nextPage * lastPage.pageSize < lastPage.totalSize
        ? nextPage
        : undefined
    },
    initialPageParam: 0,
    enabled: Boolean(chatId),
    placeholderData: keepPreviousData
  })

  useEffect(() => {
    const refetchChatMessagesPage = () => {
      getChatMessagesPage.refetch()
    }

    cogfy.chats.onWhatsAppMediaReceived.add(refetchChatMessagesPage)
    cogfy.chats.onWhatsAppMediaUploaded.add(refetchChatMessagesPage)
    cogfy.chats.onWhatsAppMessageReceived.add(refetchChatMessagesPage)
    cogfy.chats.onWhatsAppMessageReplied.add(refetchChatMessagesPage)
    cogfy.chats.onWhatsAppMessageStatusChanged.add(refetchChatMessagesPage)

    return () => {
      cogfy.chats.onWhatsAppMediaReceived.remove(refetchChatMessagesPage)
      cogfy.chats.onWhatsAppMediaUploaded.remove(refetchChatMessagesPage)
      cogfy.chats.onWhatsAppMessageReceived.remove(refetchChatMessagesPage)
      cogfy.chats.onWhatsAppMessageReplied.remove(refetchChatMessagesPage)
      cogfy.chats.onWhatsAppMessageStatusChanged.remove(refetchChatMessagesPage)
    }
  }, [chatId, queryClient])

  return getChatMessagesPage
}
