import { useEffect, useRef } from 'react'
import { XIcon } from 'lucide-react'
import { Button } from '../Button'

export type PreviewFileDialogProps = {
  filename: string
  fileUrl: string
  fileType: string
  fileSize: number
  imageDimensions?: { height: number, width: number }
  open: boolean
  onClose: () => void
}

const byteFormatter = new Intl.NumberFormat(
  'en-US',
  { style: 'unit', unit: 'byte', unitDisplay: 'narrow', notation: 'compact' }
)

export function PreviewFileDialog (props: PreviewFileDialogProps) {
  const { filename, open, fileType, fileSize, imageDimensions, fileUrl, onClose } = props
  const dialogRef = useRef<HTMLDialogElement>(null)

  const isImage = fileType.match('image')
  const isVideo = fileType.match('video')

  useEffect(() => {
    if (open) {
      dialogRef.current?.showModal()
    } else {
      dialogRef.current?.close()
    }
  }, [open])

  useEffect(() => {
    const dialog = dialogRef.current

    dialog?.addEventListener('close', onClose)

    return () => {
      dialog?.removeEventListener('close', onClose)
    }
  }, [])

  return (
    <dialog ref={dialogRef} className="modal cursor-default">
      <div className="modal-box max-h-full max-w-full w-5/12 h-2/3 flex flex-col gap-6">
        <div className="flex justify-between items-center">
          <span className="text-lg truncate">{filename}</span>
          <Button
            onClick={onClose}
            circle
            ghost
            size="sm"
          >
            <XIcon size={24} />
          </Button>
        </div>
        <div className="h-full w-full flex">
          {isImage && <img src={`${fileUrl}`} className="h-full object-contain aspect-video rounded-sm mx-auto" />}
          {isVideo && <video controls src={`${fileUrl}`} className="mx-auto my-auto"/> }
        </div>
        <span className="text-md text-slate-500"> {`${imageDimensions?.height}x${imageDimensions?.width}, ${byteFormatter.format(fileSize)}`}</span>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  )
}
