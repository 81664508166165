import clsx from 'clsx'
import { useRef, PropsWithChildren, useEffect, useState } from 'react'
import { OverlayContext } from './OverlayContext'

export type OverlayProviderProps = PropsWithChildren

export function OverlayProvider (props: OverlayProviderProps) {
  const ref = useRef<HTMLDivElement>(null)
  const [hasChildren, setHasChildren] = useState(false)

  useEffect(() => {
    if (ref.current) {
      const observer = new MutationObserver(() => {
        setHasChildren(ref.current !== null && ref.current.children.length > 0)
      })

      observer.observe(ref.current, { childList: true })

      return () => observer.disconnect()
    }
  }, [])

  return (
    <OverlayContext.Provider value={{ ref }}>
      <div className="relative h-screen">
        <div
          ref={ref}
          className={clsx(
            'w-full',
            'h-screen',
            'absolute',
            'top-0',
            'left-0',
            'z-50',
            !hasChildren && 'pointer-events-none'
          )}
        />
        {props.children}
      </div>
    </OverlayContext.Provider>
  )
}
