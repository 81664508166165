import { useEffect } from 'react'
import { useDrag, useDragDropManager, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import clsx from 'clsx'
import { CollectionStateViewField } from '../../../../../lib'

export type CollectionTableResizeColHandlerProps = {
  col: CollectionStateViewField
  onDrag?: (col: CollectionStateViewField, change: number) => void
  onDragStop?: (col: CollectionStateViewField, change: number) => void
}

const COLLECTION_TABLE_HANDLER = 'CollectionTableResizeColHandler'

export function CollectionTableResizeColHandler (props: CollectionTableResizeColHandlerProps) {
  const { col, onDrag, onDragStop } = props
  const dragDropManager = useDragDropManager()
  const monitor = dragDropManager.getMonitor()

  const [, drop] = useDrop<CollectionStateViewField>({
    accept: COLLECTION_TABLE_HANDLER,
    drop: (item, monitor) => {
      if (monitor.didDrop()) {
        return
      }

      const offset = monitor.getDifferenceFromInitialOffset()

      if (offset) {
        onDragStop?.(item, offset.x)
      }
    }
  }, [col, onDragStop])

  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: COLLECTION_TABLE_HANDLER,
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    item: col
  }, [col])

  // Usando drop no document.body ao invés do método end do useDrag
  // https://github.com/react-dnd/react-dnd/issues/3115
  useEffect(() => {
    drop(document.body)
    return () => { drop(null) }
  }, [])

  // Emitir o evento onDrag
  useEffect(() => {
    if (!isDragging) {
      return
    }

    monitor.subscribeToOffsetChange(() => {
      const offset = monitor.getDifferenceFromInitialOffset()
      const item: CollectionStateViewField = monitor.getItem()

      if (offset && item?.id === col.id) {
        onDrag?.(col, offset.x)
      }
    })
  }, [isDragging, monitor, col, onDrag])

  // Não renderizar o preview
  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true })
  }, [dragPreview])

  return (
    <div
      ref={drag}
      className={clsx(
        'absolute',
        'w-[5px]',
        'transition-colors',
        'cursor-col-resize',
        'active:bg-info',
        'hover:bg-info',
        'h-[calc(100%+2px)]',
        'top-[-1px]',
        'right-[-3px]',
        'z-10'
      )}
    />
  )
}
