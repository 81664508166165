import { useRef, useState } from 'react'
import { ChevronDownIcon, ChevronUpIcon, SearchIcon } from 'lucide-react'
import { useOnClickOutside } from '../../hooks'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '../Skeleton'
import clsx from 'clsx'

export type MultiSelectOption = {
  value: string
  label: string
  disabled?: boolean
}

export type MultiSelectProps = {
  options: MultiSelectOption[]
  labels: {
    selectItems: string
    allItems: string
    label?: string
    description?: string
    noResultsFound?: string
  }
  loading?: boolean
  selected?: string[]
  onChange?: (value: string) => void
  onClearSelections?: () => void
  onClose?: () => void
  onSelectAll?: () => void
}

export function MultiSelect (props: MultiSelectProps) {
  const { options, labels, loading, onChange, onClearSelections, onClose, onSelectAll } = props

  const [menuOpen, setMenuOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement>(null)

  useOnClickOutside(ref, () => {
    if (menuOpen) {
      setMenuOpen(false)
      onClose?.()
    }
  })

  const selected = props.selected ?? []
  const allSelected = selected.length === options.length
  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(searchQuery.toLowerCase())
  )
  const selectedLabels = options
    .filter(option => selected.includes(option.value))
    .map(option => option.label)

  return (
    <div className="flex flex-col">
      {labels.label && <span className="text-sm py-1">{labels.label}</span>}
      {labels.description && <span className="text-xs pb-2">{labels.description}</span>}
      <div className="relative" ref={ref}>
        <div
          className="cursor-pointer border border-base-300 px-4 py-2 rounded-lg flex items-center justify-between"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <span className="text-sm mr-1">
            {selected.length === 0 && !allSelected && labels.selectItems}
            {allSelected && labels.allItems}
            {!allSelected && selectedLabels.join(', ')}
          </span>
          <div>
            {menuOpen ? <ChevronUpIcon size={20} /> : <ChevronDownIcon size={20} />}
          </div>
        </div>
        {menuOpen && (
          <div
            className="card w-full max-h-64 bg-base-200 mt-1 flex p-2 absolute z-50"
          >
            {loading && new Array(5).fill(0).map((_, index) => (
              <Skeleton className="h-5 my-1" key={index} />
            ))}
            {!loading && (
              <>
                <label className="input input-bordered input-sm flex items-center gap-2 mb-2">
                  <input
                    type="text"
                    className="grow"
                    placeholder={t('Search')}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <SearchIcon size={20} />
                </label>
                <ul className='overflow-y-auto' style={{ scrollbarWidth: 'thin' }}>
                  {filteredOptions.length > 0 && filteredOptions.map(option => (
                    <li key={String(option.value)}>
                      <label
                        className={clsx(
                          'flex',
                          'items-center',
                          'text-sm',
                          'p-2',
                          'rounded-lg',
                          !option.disabled && 'cursor-pointer',
                          !option.disabled && 'hover:bg-base-300'
                        )}
                      >
                        <input
                          type="checkbox"
                          className="checkbox checkbox-sm checked:checkbox-info mr-2"
                          checked={selected.includes(option.value) || allSelected}
                          onChange={() => onChange?.(option.value)}
                          disabled={option.disabled}
                        />
                        {option.label}
                      </label>
                    </li>
                  ))}

                  {filteredOptions.length === 0 && (
                    <span className='mx-2 text-sm'>{labels.noResultsFound ?? t('No results found')}</span>
                  )}
                </ul>
                <div>
                  <div className="divider my-0" />
                  <div className='flex flex-row justify-between'>
                    {!allSelected && (
                      <button
                        className="btn btn-ghost btn-xs"
                        onClick={() => onSelectAll?.()}
                      >
                        {t('Select All')}
                      </button>
                    )}
                    {selected.length > 0 && (
                      <button
                        className="btn btn-ghost btn-xs"
                        onClick={() => onClearSelections?.()}
                      >
                        {t('Clear')}
                      </button>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
