import { FieldOperationConfigFieldsInput, FieldOperationConfigNumberInput, OperationInputType, OperationSchemaInput, UUID } from '@indigohive/cogfy-types'
import { CollectionState, useSelectedFieldToUpdate } from '../../../../../lib'
import { CollectionPageController } from '../../../collection-page-controller'
import { Select } from '../../../../../components'
import { useTranslation } from 'react-i18next'

export type FieldOperationNumberInputProps = {
  controller: CollectionPageController
  state: CollectionState
  inputName: string
  input: OperationSchemaInput<OperationInputType>
  fixedValue: boolean
}

export function FieldOperationNumberInput (props: FieldOperationNumberInputProps) {
  const { controller, state, inputName, input, fixedValue } = props
  const selectedField = useSelectedFieldToUpdate(state)

  const { t } = useTranslation()

  const fields = state.fields ?? []
  const options = [
    { value: '', label: t('Select a field'), disabled: true },
    ...fields
      .filter(option => option.type === 'number')
      .map(field => ({ label: field.name, value: field.id }))
  ]

  return (
    <div className='flex flex-col gap-1'>
      {fixedValue && (
        <input
          type="number"
          className="input input-sm input-bordered w-full"
          step={input.input?.step}
          min={input.input?.min}
          max={input.input?.max}
          value={(selectedField?.operationConfig?.[inputName] as FieldOperationConfigNumberInput | undefined)?.number?.value ?? 0}
          onChange={event => {
            const newValue = parseFloat(event.target.value)
            controller.onOperationChange(
              selectedField!.id,
              selectedField?.operation ?? null,
              selectedField!.recalculateStrategy,
              {
                ...selectedField?.operationConfig,
                [inputName]: { type: 'number', number: { value: newValue } }
              }
            )
          }}
        />
      )}
      {!fixedValue && (
        <Select
          size="sm"
          value={(selectedField?.operationConfig?.[inputName] as FieldOperationConfigFieldsInput | undefined)?.fields?.value[0]?.id ?? ''}
          onChange={event => {
            controller.onOperationChange(
              selectedField!.id,
              selectedField!.operation ?? null,
              selectedField!.recalculateStrategy,
              {
                ...selectedField!.operationConfig,
                [inputName]: { type: 'fields', fields: { value: [{ id: event.target.value as UUID }] } }
              }
            )
          }}
          options={options}
        />
      )}
      <div className="divider my-0" />
    </div>
  )
}
