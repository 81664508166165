import {
  BooleanFieldData,
  BooleanRecordProperty,
  BooleanViewFieldConfig
} from '@indigohive/cogfy-types'
import clsx from 'clsx'
import { FieldCellProps } from '../FieldCell'

const DEFAULT_VARIANT = 'checkbox'

export type BooleanCellProps = FieldCellProps<
BooleanRecordProperty, BooleanFieldData, BooleanViewFieldConfig
>

export function BooleanCell (props: BooleanCellProps) {
  const { record, field, viewField, controller, readonly, property } = props

  const checked = property?.boolean?.value ?? false
  const variant = viewField.config?.boolean?.variant ?? DEFAULT_VARIANT

  return (
    <label className="flex cursor-pointer px-2 py-1 w-full h-full">
      <input
        type="checkbox"
        checked={checked}
        disabled={readonly}
        onChange={event => {
          controller.onUpdateRecordProperties(record.id, {
            [field.id]: {
              type: 'boolean',
              boolean: { value: event.target.checked }
            }
          })
        }}
        className={clsx(
          variant === 'checkbox' && 'checkbox checkbox-sm checked:checkbox-info',
          variant === 'toggle' && 'toggle toggle-sm toggle-info'
        )}
      />
    </label>
  )
}
