import { Provider, ProviderName } from '@indigohive/cogfy-providers'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, Input, ProviderValuesInputs } from '../../../components'
import { useCogfy, useToasts } from '../../../hooks'

const dialogId = 'add_twilio_sendgrid_dialog'

export type CreateConnectionDialogProps = {
  provider: Provider | null
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

export function CreateConnectionDialog (props: CreateConnectionDialogProps) {
  const { open, onClose, provider } = props
  const { t } = useTranslation()
  const cogfy = useCogfy()
  const toasts = useToasts()
  const formik = useFormik({
    initialValues: {
      name: '',
      values: {}
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(t('Required field'))
    }),
    onSubmit: async (values, helpers) => {
      try {
        await cogfy.createConnection({
          provider: provider!.name as any,
          name: values.name,
          values: Object.entries(values.values).map(([key, value]) => ({
            name: key,
            value: value as string
          }))
        })
        props.onSuccess?.()
        helpers.resetForm()
      } catch {
        toasts.error(t('Unexpected error'))
      }
    }
  })

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <h3 className="font-bold text-lg">
        {provider?.label}
      </h3>

      <form
        id={dialogId + '_form'}
        className="flex flex-col gap-4 my-4"
        onSubmit={formik.handleSubmit}
      >
        <Input
          label={t('WorkspaceConnectionsPage:Connection name')}
          error={Boolean(formik.touched.name && formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          {...formik.getFieldProps('name')}
        />

        {provider && (
          <ProviderValuesInputs
            providerName={provider.name as ProviderName}
            values={formik.values.values}
            onChange={values => { formik.setFieldValue('values', values) }}
          />
        )}
      </form>

      <form method="dialog" id={dialogId + '_close_button'}>
        <div className="modal-action">
          <Button
            size="sm"
            form={dialogId + '_close_button'}
            disabled={formik.isSubmitting}
            onClick={() => {
              formik.resetForm()
            }}
          >
            {t('Close')}
          </Button>
          <Button
            type="submit"
            size="sm"
            color="primary"
            form={dialogId + '_form'}
            disabled={formik.isSubmitting}
          >
            {t('Confirm')}
          </Button>
        </div>
      </form>
    </Dialog>
  )
}
