import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAuthentication, useChat, useChatMessagesPage, useCogfy, useCollection, useTitle } from '../../hooks'
import { useInView } from 'react-intersection-observer'
import { useMutation } from '@tanstack/react-query'
import { UUID } from '@indigohive/cogfy-types'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { ChatBubble, ChatInput, ChatPageMessagesContainer, ChatPageNavbar, ChatWhatsAppInput, WhatsAppMessagesContainer } from './components'
import { SendChatMessageCommand } from '@indigohive/cogfy-types/endpoints/sendChatMessage'

const SIXTY_SECONDS = 60 * 1000

export type ChatPageProps = {
  chatId?: UUID
}

export function ChatPage (props: ChatPageProps) {
  const params = useParams<{ workspace: string, chatId: UUID }>()
  const workspace = params.workspace!
  const chatId: UUID = props.chatId ?? params.chatId!

  const [sendingContent, setSendingContent] = useState<string | null>(null)
  const chatMessagesEl = useRef<HTMLDivElement | null>(null)
  const { authenticatedUser } = useAuthentication()
  const getChat = useChat(chatId)
  const getCollection = useCollection(getChat.data?.collectionId)
  const { ref: inViewRef, inView } = useInView()
  const cogfy = useCogfy()
  const { t } = useTranslation()

  const getChatMessagesPage = useChatMessagesPage(chatId)

  const sendChatMessage = useMutation({
    mutationFn: async (data: SendChatMessageCommand) => {
      setSendingContent(data.messages[0].content)
      await cogfy.sendChatMessage(data, { timeout: SIXTY_SECONDS })
    },
    onSuccess: async () => {
      await getChatMessagesPage.refetch()
    },
    onSettled: () => {
      setSendingContent(null)
    }
  })

  useTitle({
    title: 'Chat ' + (getChat.data?.createDate ? format(getChat.data.createDate, 'dd/MM/yyyy kk:mm:ss') : ''),
    loading: getChat.isLoading,
    error: getChat.isError
  })

  useEffect(() => {
    if (inView) {
      getChatMessagesPage.fetchNextPage()
    }
  }, [getChatMessagesPage.fetchNextPage, inView])

  const chatMessages = getChatMessagesPage.data?.pages
    .flatMap(page => page.data)
    .filter(message => message.role !== 'tool') // TODO: Review this when tool confirmation is implemented
    .filter(message => (
      (message.content) ||
      (!message.content && message.files.length > 0) ||
      (!message.content && ['contacts', 'location'].includes(message.contentType))
    )) ?? []

  const isNotFound = cogfy.isNotFound(getChat.error)
  const isError = getChat.isError || getCollection.isError

  const confirmationRequired = chatMessages.some(message => message.status === 'confirming')
  const sendMessageDisabled =
    sendChatMessage.isPending ||
    getChatMessagesPage.isPending ||
    confirmationRequired

  return (
    <>
      <ChatPageNavbar
        workspaceSlug={workspace}
        chat={getChat.data}
        loading={getChat.isLoading}
      />
      <div className="flex flex-col items-center h-[calc(100vh-48px)] pt-4 bg-base-200">
        <div>
          {isNotFound && t('Chat not found')}
          {!isNotFound && isError && 'Unexpected error'}
        </div>

        <div
          className="overflow-y-auto flex flex-col-reverse max-w-screen-md w-full"
          ref={chatMessagesEl}
        >
          {sendChatMessage.isPending && (
            <ChatBubble
              color="neutral"
              position="start"
              avatarImageSrc="/cogfy-avatar.png"
            >
              <span className="loading loading-dots loading-xs" />
            </ChatBubble>
          )}

          {getChat.data?.provider === 'cogfy' && (
            <ChatPageMessagesContainer
              chat={getChat.data}
              collection={getCollection.data}
              messages={sendingContent
                ? [
                    {
                      id: 'sending' as UUID,
                      role: 'user',
                      user: {
                        id: authenticatedUser!.id as UUID,
                        name: authenticatedUser!.name
                      },
                      content: sendingContent,
                      contentType: 'text',
                      contentData: null,
                      sendDate: new Date().toISOString(),
                      updateDate: new Date().toISOString(),
                      status: 'sending',
                      data: {},
                      files: []
                    },
                    ...chatMessages
                  ]
                : chatMessages}
              authenticatedUser={authenticatedUser}
              translation={t}
              onMessageReaction={() => { getChatMessagesPage.refetch() }}
              onUpdateMessageStatus={() => {
                getChatMessagesPage.refetch()
              }}
            />
          )}

          {getChat.data?.provider === 'whatsapp' && (
            <WhatsAppMessagesContainer
              chat={getChat.data}
              messages={chatMessages}
              authenticatedUser={authenticatedUser}
            />
          )}

          {getChatMessagesPage.hasNextPage && (
            <button
              ref={inViewRef}
              className="btn btn-sm w-fit self-center"
              onClick={() => { getChatMessagesPage.fetchNextPage() }}
              disabled={!getChatMessagesPage.hasNextPage || getChatMessagesPage.isFetchingNextPage}
            >
              {getChatMessagesPage.isFetchingNextPage ? `${t('Loading')}...` : t('Load more')}
            </button>
          )}
        </div>
        <div className="grow" />

        <div className="px-2 pt-2 pb-6 w-full flex justify-center">
          {getChat.data?.provider === 'cogfy' && (
            <ChatInput
              chatId={chatId}
              sendMessageDisabled={sendMessageDisabled}
              onSendChatMessage={content => {
                if (chatMessagesEl?.current) {
                  chatMessagesEl.current.scrollTop = chatMessagesEl.current.scrollHeight
                }
                sendChatMessage.mutate({
                  chatId,
                  messages: [
                    {
                      role: 'user',
                      content,
                      contentType: 'text'
                    }
                  ]
                })
              }}
              onSubmitFileMessage={() => {
                getChatMessagesPage.refetch()
              }}
            />
          )}

          {getChat?.data?.provider === 'whatsapp' && (
            <ChatWhatsAppInput
              messages={chatMessages}
              collectionId={getChat.data.collectionId}
              chatId={chatId}
              onSubmitWhatsAppMessage={() => { getChatMessagesPage.refetch() }}
            />
          )}
        </div>
      </div>
    </>
  )
}
