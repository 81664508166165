import { UserEventCommand } from '@indigohive/cogfy-types/endpoints/userEvent'
import { useCogfy } from './use-cogfy'
import { useMutation } from '@tanstack/react-query'

export function useUserEvent () {
  const cogfy = useCogfy()

  return useMutation({
    mutationFn: (data: UserEventCommand) => cogfy.userEvent(data)
  })
}
