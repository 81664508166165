import { useEffect, useState } from 'react'
import { CollectionState } from '../collection-state'

export function useRecords (state: CollectionState) {
  const [records, setRecords] = useState(state.records)

  useEffect(() => {
    const recordsChanged = () => {
      setRecords(state.records)
    }

    state.addEventListener('recordsChanged', recordsChanged)

    return () => {
      state.removeEventListener('recordsChanged', recordsChanged)
    }
  })

  return records
}
