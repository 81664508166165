import { AndFilter, RecordOrder, RunTransactionCommand, RunTransactionCommandOperation, UUID } from '@indigohive/cogfy-types'
import { Command } from '../command-stack'
import { CollectionState, CollectionStateField, CollectionStateViewField } from '../collection-state'

export type DeleteFieldCommandData = {
  fieldId: UUID
}

export class DeleteFieldCommand implements Command<RunTransactionCommand> {
  private readonly _state: CollectionState
  private readonly _data: DeleteFieldCommandData
  private readonly _fieldToDelete: CollectionStateField | undefined
  private readonly _viewFieldToDelete: CollectionStateViewField | undefined
  private readonly _fieldToDeleteIndex: number | undefined
  private readonly _viewFieldToDeleteIndex: number | undefined
  private readonly _previousViewOrderBy: RecordOrder[] | null
  private readonly _previousViewFilter: AndFilter

  constructor (
    state: CollectionState,
    data: DeleteFieldCommandData
  ) {
    this._state = state
    this._data = data
    this._fieldToDeleteIndex = state.fields?.findIndex(field => field.id === data.fieldId)
    this._viewFieldToDeleteIndex = state.viewFields?.findIndex(viewField => viewField.fieldId === data.fieldId)
    this._fieldToDelete = this._fieldToDeleteIndex === undefined
      ? undefined
      : state.fields?.[this._fieldToDeleteIndex]
    this._viewFieldToDelete = this._viewFieldToDeleteIndex === undefined
      ? undefined
      : state.viewFields?.[this._viewFieldToDeleteIndex]
    this._previousViewOrderBy = this._state.views?.find(view => view.id === this._state.activeViewId)?.orderBy ?? null
    this._previousViewFilter = this._state.views?.find(view => view.id === this._state.activeViewId)?.filter as AndFilter ?? { and: [] }
  }

  run (): RunTransactionCommand {
    const fieldToDeleteId = this._fieldToDelete?.id
    const selectedFieldId = this._state.selectedField?.field.id
    const selectedFieldToUpdateId = this._state.selectedFieldToUpdate?.id

    const newFields = [...this._state.fields!]
    newFields.splice(this._fieldToDeleteIndex!, 1)
    this._state.setFields(newFields)

    const currentViewFields = [...this._state.viewFields!]
    currentViewFields.splice(this._viewFieldToDeleteIndex!, 1)
    this._state.setViewFields(currentViewFields)

    // Set selected field and selected field to update to null
    if (selectedFieldId === fieldToDeleteId || selectedFieldToUpdateId === fieldToDeleteId) {
      this._state.setSelectedField(null)
      this._state.setSelectedFieldToUpdate(null)
    }

    const operations: RunTransactionCommandOperation[] = [
      {
        type: 'soft_delete_field',
        data: {
          collectionId: this._state.id,
          fieldId: this._data.fieldId
        }
      }
    ]

    // Delete field from view order by
    if (this._previousViewOrderBy?.find(orderBy => orderBy.fieldId === fieldToDeleteId)) {
      const newViewOrderBy = this._previousViewOrderBy?.filter(currentOrderBy => currentOrderBy.fieldId !== fieldToDeleteId) ?? null

      const newViews = this._state.views?.map(view => {
        if (view.id === this._state.activeViewId) {
          return {
            ...view,
            orderBy: newViewOrderBy
          }
        }
        return view
      })

      this._state.setViews(newViews ?? [])

      operations.push({
        type: 'update_view_order_by',
        data: {
          collectionId: this._state.id,
          viewId: this._state.activeViewId!,
          orderBy: newViewOrderBy
        }
      })
    }

    // Delete field from view filter
    if (this._previousViewFilter.and.find(prevFilter => (prevFilter as any)?.property !== this._fieldToDelete?.id)) {
      const newViewFilter = {
        and: this._previousViewFilter.and.filter(prevFilter => (prevFilter as any)?.property !== this._fieldToDelete?.id)
      }

      const newViews = this._state.views?.map(view => {
        if (view.id === this._state.activeViewId) {
          return {
            ...view,
            filter: newViewFilter
          }
        }
        return view
      })

      this._state.setViews(newViews ?? [])

      operations.push({
        type: 'update_view_filter',
        data: {
          collectionId: this._state.id,
          viewId: this._state.activeViewId!,
          filter: newViewFilter
        }
      })
    }

    return { operations }
  }

  undo (): RunTransactionCommand {
    const fieldDeletedId = this._fieldToDelete?.id

    const newFields = [...this._state.fields!]
    newFields.splice(this._fieldToDeleteIndex!, 0, this._fieldToDelete!)
    this._state.setFields(newFields)

    const currentViewFields = [...this._state.viewFields!]
    currentViewFields.splice(this._viewFieldToDeleteIndex!, 0, this._viewFieldToDelete!)
    this._state.setViewFields(currentViewFields)

    const operations: RunTransactionCommandOperation[] = [
      {
        type: 'restore_field',
        data: {
          collectionId: this._state.id,
          fieldId: this._data.fieldId,
          order: this._fieldToDeleteIndex!
        }
      }
    ]

    // Restore view order by
    if (this._previousViewOrderBy?.find(orderBy => orderBy.fieldId === fieldDeletedId)) {
      const newViews = this._state.views?.map(view => {
        if (view.id === this._state.activeViewId) {
          return {
            ...view,
            orderBy: this._previousViewOrderBy
          }
        }
        return view
      })

      this._state.setViews(newViews ?? [])

      operations.push({
        type: 'update_view_order_by',
        data: {
          collectionId: this._state.id,
          viewId: this._state.activeViewId!,
          orderBy: this._previousViewOrderBy
        }
      })
    }

    // Restore view filter
    if (this._previousViewFilter.and.find(prevFilter => (prevFilter as any)?.property !== this._fieldToDelete?.id)) {
      const newViews = this._state.views?.map(view => {
        if (view.id === this._state.activeViewId) {
          return {
            ...view,
            filter: this._previousViewFilter
          }
        }
        return view
      })

      this._state.setViews(newViews ?? [])

      operations.push({
        type: 'update_view_filter',
        data: {
          collectionId: this._state.id,
          viewId: this._state.activeViewId!,
          filter: this._previousViewFilter
        }
      })
    }

    return { operations }
  }
}
