import { v4 as uuid } from 'uuid'
import { RunTransactionCommand, UUID } from '@indigohive/cogfy-types'
import { CollectionState, CollectionStateView } from '../collection-state'
import { Command } from '../command-stack'

export class CreateViewCommand implements Command<RunTransactionCommand> {
  private readonly _state: CollectionState
  private readonly _viewToCreate: CollectionStateView

  constructor (state: CollectionState) {
    this._state = state
    this._viewToCreate = {
      id: uuid() as UUID,
      name: null
    }
  }

  run (): RunTransactionCommand {
    const currentViews = this._state.views
    const newViews = currentViews ? [...currentViews, this._viewToCreate] : [this._viewToCreate]

    this._state.setViews(newViews)

    return {
      operations: [
        {
          type: 'create_view',
          data: {
            collectionId: this._state.id,
            viewId: this._viewToCreate.id,
            name: null
          }
        }
      ]
    }
  }

  undo (): RunTransactionCommand {
    const newViews = this._state.views?.filter(view => view.id !== this._viewToCreate.id) ?? []
    this._state.setViews(newViews)

    if (this._state.activeViewId === this._viewToCreate.id) {
      this._state.setActiveViewId(newViews[0].id)
    }

    return {
      operations: [
        {
          type: 'hard_delete_view',
          data: {
            collectionId: this._state.id,
            viewId: this._viewToCreate.id
          }
        }
      ]
    }
  }
}
