export type MemberViewProps = {
  name: string
  email?: string | null
}

export function MemberView (props: MemberViewProps) {
  const { name, email } = props

  return (
    <>
      <td className="border-solid border-base-200">
        <div className="flex items-center">
          <div className="pr-4">
            <div className="avatar placeholder">
              <div className="bg-gray-500 text-neutral-content rounded-full w-8">
                <span className="text-xs">{name[0]}</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <span className="text-sm">{name}</span>
          </div>
        </div>
      </td>
      {email && (
        <td>
          <span className="twxt-sm">{email}</span>
        </td>
      )}
    </>
  )
}
