import { RefObject } from 'react'
import { OverlayContent } from '../../../../components'
import { FIELD_OVERLAY_BY_TYPE, FieldOverlay } from '../../../../field-types'
import { CollectionState, useClickCell } from '../../../../lib'
import { CollectionPageController } from '../../collection-page-controller'

export type CollectionTableOverlayProps = {
  state: CollectionState
  controller: CollectionPageController
  tableRef: RefObject<HTMLDivElement>
}

export function CollectionTableOverlay (props: CollectionTableOverlayProps) {
  const { state, controller, tableRef } = props

  const clickedCell = useClickCell(state)

  return (
    <OverlayContent
      className="text-sm"
      anchorEl={clickedCell?.element}
      open={Boolean(clickedCell && Boolean(FIELD_OVERLAY_BY_TYPE[clickedCell.field.type]))}
      onClose={event => {
        if (clickedCell) {
          state.setSelectedCell({ row: clickedCell.row, col: clickedCell.col })
          state.setClickedCell(null)
          tableRef.current!.focus()
          event.preventDefault()
        }
      }}
    >
      {clickedCell && (
        <FieldOverlay
          state={state}
          record={clickedCell.record}
          field={clickedCell.field}
          viewField={clickedCell.viewField}
          element={clickedCell.element}
          controller={controller}
          onClose={reason => {
            controller.onCloseOverlay(reason)
            tableRef.current!.focus()
          }}
        />
      )}
    </OverlayContent>
  )
}
