import {
  CalculateRecordsCommand,
  GetRecordByIdResult,
  GetRecordReferencesByIdResult,
  UUID
} from '@indigohive/cogfy-types'
import { BaseClient, BaseClientOptions } from './base-client'

export class RecordsClient extends BaseClient {
  constructor (options: BaseClientOptions) {
    super(options)
  }

  async calculate (
    data: CalculateRecordsCommand
  ): Promise<void> {
    await this.axios.post('/records/calculate', data)
  }

  async getById (
    recordId: string,
    signal?: AbortSignal
  ): Promise<GetRecordByIdResult> {
    return (await this.axios.get(`/records/${recordId}`, { signal })).data
  }

  async getRecordReferencesById (
    recordId: UUID,
    signal?: AbortSignal
  ): Promise<GetRecordReferencesByIdResult> {
    return (await this.axios.get(`/records/${recordId}/record-references`, { signal })).data
  }
}
