import type {
  CreateDateFieldData,
  DateFieldData,
  AuthorRecordProperty,
  UpdateDateFieldData,
  BooleanRecordProperty,
  ChatRecordProperty,
  CreateDateRecordProperty,
  DateRecordProperty,
  FileRecordProperty,
  JsonRecordProperty,
  NumberRecordProperty,
  ReferenceRecordProperty,
  StopwatchRecordProperty,
  TextRecordProperty,
  UpdateDateRecordProperty,
  VectorRecordProperty
} from '@indigohive/cogfy-types'
import { format } from 'date-fns/format'
import { CollectionState } from '../../lib'

export function copyFieldValues (event: ClipboardEvent, state: CollectionState): void {
  if (!state.selectedRange || !state.records || !state.fieldsById || !state.viewFields) {
    return
  }

  event.preventDefault()

  const { start, end } = state.selectedRange

  const result: string[][] = []

  for (let row = start.row; row <= end.row; row++) {
    const record = state.records[row]
    const values: string[] = []
    result.push(values)

    for (let col = start.col; col <= end.col; col++) {
      const viewField = state.viewFields[col]
      const field = state.fieldsById[viewField.fieldId]

      switch (field.type) {
        case 'author':
          values.push((record.properties[field.id] as AuthorRecordProperty | undefined)?.author?.value?.name ?? '')
          break
        case 'boolean':
          values.push((record.properties[field.id] as BooleanRecordProperty | undefined)?.boolean?.value?.toString().toUpperCase() ?? '')
          break
        case 'chat':
          values.push((record.properties[field.id] as ChatRecordProperty | undefined)?.chat?.value?.chatId ?? '')
          break
        case 'createDate':
          values.push(formatDate(
            (record.properties[field.id] as CreateDateRecordProperty | undefined)?.createDate?.value,
            (viewField.config as CreateDateFieldData | null)?.createDate?.format
          ))
          break
        case 'date':
          values.push(formatDate(
            (record.properties[field.id] as DateRecordProperty | undefined)?.date?.value,
            (viewField.config as DateFieldData | null)?.date?.format
          ))
          break
        case 'file':
          values.push((record.properties[field.id] as FileRecordProperty | undefined)?.file?.value?.name ?? '')
          break
        case 'json':
          if ((record.properties[field.id] as JsonRecordProperty | undefined)?.json?.value !== undefined) {
            values.push(JSON.stringify((record.properties[field.id] as JsonRecordProperty | undefined)?.json?.value))
          } else {
            values.push('')
          }
          break
        case 'number':
          values.push((record.properties[field.id] as NumberRecordProperty | undefined)?.number?.value?.toString() ?? '')
          break
        case 'reference':
          values.push(
            (record.properties[field.id] as ReferenceRecordProperty | undefined)?.reference?.value?.map(ref => ref.title).join(', ') ?? ''
          )
          break
        case 'stopwatch':
          values.push((record.properties[field.id] as StopwatchRecordProperty | undefined)?.stopwatch?.value?.elapsed?.toString() ?? '')
          break
        case 'text':
          values.push((record.properties[field.id] as TextRecordProperty | undefined)?.text?.value ?? '')
          break
        case 'updateDate':
          values.push(formatDate(
            (record.properties[field.id] as UpdateDateRecordProperty | undefined)?.updateDate?.value,
            (viewField.config as UpdateDateFieldData | null)?.updateDate?.format
          ))
          break
        case 'vector':
          values.push((record.properties[field.id] as VectorRecordProperty | undefined)?.vector?.value?.count?.toString() ?? '')
          break
        default:
          values.push('')
      }
    }
  }

  // text/plain
  const text = result.map(row => row.join('\t')).join('\n')
  event.clipboardData?.setData('text/plain', text)

  // text/html
  const html =
    '\n<table>\n' +
    result.map(
      row =>
        '<tr>' +
        row.map(cell => '<td>' + cell + '</td>').join('') +
        '</tr>'
    ).join('\n') +
    '\n</table>\n'
  event.clipboardData?.setData('text/html', html)
}

function formatDate (
  date: string | null | undefined,
  dateFormat: string | null | undefined
): string {
  if (!date) {
    return ''
  }

  return format(new Date(date), dateFormat ?? 'Pp')
}
