// Record filters
export function isAndFilter(filter) {
    return filter.and !== undefined;
}
export function isOrFilter(filter) {
    return filter.or !== undefined;
}
export function isEqualsFilter(filter) {
    return filter.equals !== undefined && filter.property !== undefined;
}
export function isNotEqualsFilter(filter) {
    return filter.notEquals !== undefined && filter.property !== undefined;
}
export function isHasErrorFilter(filter) {
    return filter.hasError !== undefined && filter.property !== undefined;
}
export function isIsEmptyFilter(filter) {
    return filter.isEmpty !== undefined && filter.property !== undefined;
}
export function isIsPendingFilter(filter) {
    return filter.isPending !== undefined && filter.property !== undefined;
}
export function isLessThanFilter(filter) {
    return filter.lessThan !== undefined && filter.property !== undefined;
}
export function isLessThanOrEqualsFilter(filter) {
    return filter.lessThanOrEquals !== undefined && filter.property !== undefined;
}
export function isGreaterThanFilter(filter) {
    return filter.greaterThan !== undefined && filter.property !== undefined;
}
export function isGreaterThanOrEqualsFilter(filter) {
    return filter.greaterThanOrEquals !== undefined && filter.property !== undefined;
}
export function isFileTypeFilter(filter) {
    return filter.fileType !== undefined && filter.property !== undefined;
}
