import clsx from 'clsx'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CollectionState } from '../../../../lib'
import { useAppManager } from '../../../../hooks'

export type CollectionNameProps = {
  state: CollectionState
}

export function CollectionName (props: CollectionNameProps) {
  const { state } = props
  const appManager = useAppManager()
  const ref = useRef<HTMLHeadingElement>(null)
  const { t } = useTranslation()

  useEffect(() => {
    if (!state.name) {
      ref.current!.setAttribute('collection-name-empty', t('Untitled'))
    } else {
      ref.current!.removeAttribute('collection-name-empty')
    }

    ref.current!.textContent = state.name
  }, [state])

  useEffect(() => {
    const handleNameChange = () => {
      if (!state.name) {
        ref.current!.setAttribute('collection-name-empty', t('Untitled'))
      } else {
        ref.current!.removeAttribute('collection-name-empty')
      }

      ref.current!.textContent = state.name
    }

    state.addEventListener('nameChanged', handleNameChange)

    return () => {
      state.removeEventListener('nameChanged', handleNameChange)
    }
  }, [state])

  const handleBlur = () => {
    const name = ref.current!.textContent ?? ''
    appManager.renameCollection(state, { name })
  }

  return (
    <h1
      ref={ref}
      className={clsx(
        'text-2xl',
        'font-bold',
        'mx-2',
        'px-4',
        'py-2',
        'my-1',
        'empty:text-gray-300'
      )}
      contentEditable
      onBlur={handleBlur}
      onKeyDown={event => {
        if (event.key === 'Enter') {
          event.preventDefault()
          ref.current!.blur()
        }
      }}
      onInput={event => {
        if (event.currentTarget.textContent) {
          ref.current!.removeAttribute('collection-name-empty')
        } else {
          ref.current!.setAttribute('collection-name-empty', t('Untitled'))
        }
      }}
    />
  )
}
