import { useState } from 'react'
import useLocalStorage from 'use-local-storage'

export function useDrawerWidth (
  localStorageKey: string,
  defaultWidth: number,
  minWidth: number
) {
  const [width, setWidth] = useLocalStorage(localStorageKey, defaultWidth)
  const [widthChanged, setWidthChanged] = useState(0)

  const newWidth = (width + widthChanged) < minWidth ? minWidth : width + widthChanged

  const handleResizeFieldDrawer = (change: number) => {
    setWidthChanged(change)
  }

  const onStopResizeFieldDrawer = () => {
    setWidthChanged(0)
    setWidth(newWidth)
  }

  return {
    width: newWidth,
    handleResizeFieldDrawer,
    onStopResizeFieldDrawer
  }
}
