import * as Sentry from '@sentry/react'
import { PropsWithChildren, useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { Drawer } from '../Drawer'
import { Container } from '..'
import { AuthenticationGuard } from '../AuthenticationGuard'
import { useCogfy } from '../../hooks'

export type LayoutProps = PropsWithChildren

function Fallback () {
  return (
    <Container>
      Something went wrong
    </Container>
  )
}

export function Layout (props: LayoutProps) {
  const cogfy = useCogfy()
  const workspace = useParams().workspace

  useEffect(() => {
    if (workspace !== 'sign-in') {
      cogfy.getWorkspace = () => workspace ?? null

      return () => {
        cogfy.getWorkspace = null
      }
    }
  }, [workspace])

  return (
    <AuthenticationGuard>
      <Drawer id="layout-drawer">
        <Sentry.ErrorBoundary fallback={<Fallback />}>
          <div
            className="w-full min-h-screen overflow-auto bg-base-200"
            style={{ scrollbarWidth: 'thin' }}
          >
            <Outlet />
            {props.children}
          </div>
        </Sentry.ErrorBoundary>
      </Drawer>
    </AuthenticationGuard>
  )
}
