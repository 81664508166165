import clsx, { ClassValue } from 'clsx'
import { createElement, PropsWithChildren } from 'react'

export type HeadingProps = PropsWithChildren<{
  id?: string
  className?: ClassValue
  component?: 'h1' | 'h2'
}>

export function Heading (props: HeadingProps) {
  const {
    id,
    className,
    component = 'h1',
    children
  } = props

  return (
    createElement(
      component,
      {
        className: clsx(
          component === 'h1' && 'text-2xl',
          component === 'h2' && 'text-xl',
          'font-semibold',
          'text-base-content',
          className
        ),
        id
      },
      children
    )
  )
}
