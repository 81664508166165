export type WhatsAppTitleProps = {
  name?: string
  displayPhoneNumber?: string
  displayColor?: string
}

export function WhatsAppTitle (props: WhatsAppTitleProps) {
  const { name, displayPhoneNumber, displayColor } = props

  return (
    <>
      {(name || displayPhoneNumber) && (
        <div className="inline-flex items-center justify-between gap-2 w-full">
          {name && (
            <div style={{ color: displayColor }} className="text-sm font-semibold">~ {name}</div>
          )}
          {displayPhoneNumber && (
            <div className="text-xs text-gray-600">{displayPhoneNumber}</div>
          )}
        </div>
      )}
    </>
  )
}
