export type CogfyLogoProps = {
  size?: number
}

export function CogfyLogo (props: CogfyLogoProps) {
  const { size = 96 } = props

  return (
    <svg width={size} height={size} viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1785_537476)">
        <path d="M0 48C0 21.4903 21.4903 0 48 0C74.5097 0 96 21.4903 96 48C96 74.5097 74.5097 96 48 96C21.4903 96 0 74.5097 0 48Z" fill="white"/>
        <path d="M0 48C0 21.4903 21.4903 0 48 0C74.5097 0 96 21.4903 96 48C96 74.5097 74.5097 96 48 96C21.4903 96 0 74.5097 0 48Z" fill="url(#paint0_linear_1785_537476)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M48.0001 6.11582C24.8684 6.11582 6.11655 24.8677 6.11655 47.9993C6.11655 71.1309 24.8684 89.8828 48.0001 89.8828C71.1317 89.8828 89.8836 71.1309 89.8836 47.9993C89.8836 24.8677 71.1317 6.11582 48.0001 6.11582ZM5.88354 47.9993C5.88354 24.739 24.7397 5.88281 48.0001 5.88281C71.2604 5.88281 90.1166 24.739 90.1166 47.9993C90.1166 71.2596 71.2604 90.1158 48.0001 90.1158C24.7397 90.1158 5.88354 71.2596 5.88354 47.9993Z" fill="#D0D5DD"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M48 36.233C41.5013 36.233 36.233 41.5013 36.233 48C36.233 54.4987 41.5013 59.767 48 59.767C54.4987 59.767 59.767 54.4987 59.767 48C59.767 41.5013 54.4987 36.233 48 36.233ZM36 48C36 41.3726 41.3726 36 48 36C54.6274 36 60 41.3726 60 48C60 54.6274 54.6274 60 48 60C41.3726 60 36 54.6274 36 48Z" fill="#D0D5DD"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M47.9999 39.9596C43.5601 39.9596 39.961 43.5587 39.961 47.9984C39.961 52.4381 43.5601 56.0372 47.9999 56.0372C52.4396 56.0372 56.0387 52.4381 56.0387 47.9984C56.0387 43.5587 52.4396 39.9596 47.9999 39.9596ZM39.728 47.9984C39.728 43.43 43.4315 39.7266 47.9999 39.7266C52.5683 39.7266 56.2717 43.43 56.2717 47.9984C56.2717 52.5668 52.5683 56.2703 47.9999 56.2703C43.4315 56.2703 39.728 52.5668 39.728 47.9984Z" fill="#D0D5DD"/>
        <path d="M47.8835 0H48.1166V96H47.8835V0Z" fill="#D0D5DD"/>
        <path d="M96 47.8828L96 48.1158L-1.01852e-08 48.1158L0 47.8828L96 47.8828Z" fill="#D0D5DD"/>
        <path d="M79.8062 0H80.0392V96H79.8062V0Z" fill="#D0D5DD"/>
        <path d="M31.9226 0H32.1556V96H31.9226V0Z" fill="#D0D5DD"/>
        <path d="M63.8445 0H64.0775V96H63.8445V0Z" fill="#D0D5DD"/>
        <path d="M15.9609 0H16.1939V96H15.9609V0Z" fill="#D0D5DD"/>
        <path d="M96 79.8047L96 80.0377L-1.01852e-08 80.0377L0 79.8047L96 79.8047Z" fill="#D0D5DD"/>
        <path d="M96 31.9219L96 32.1549L-1.01852e-08 32.1549L0 31.9219L96 31.9219Z" fill="#D0D5DD"/>
        <path d="M96 63.8438L96 64.0768L-1.01852e-08 64.0768L0 63.8437L96 63.8438Z" fill="#D0D5DD"/>
        <path d="M96 15.9609L96 16.1939L-1.01852e-08 16.1939L0 15.9609L96 15.9609Z" fill="#D0D5DD"/>
        <path d="M27.9795 57.9155V55.7573C27.9794 55.3827 28.0781 55.0147 28.2656 54.6904C28.4531 54.3661 28.7227 54.097 29.0474 53.9102L43.4571 45.5884C43.6197 45.4948 43.7548 45.3601 43.8488 45.1977C43.9428 45.0353 43.9923 44.8511 43.9924 44.6635V37.8833C43.9911 37.6969 43.9409 37.5141 43.847 37.3531C43.7531 37.1921 43.6187 37.0585 43.4572 36.9655C43.2956 36.8726 43.1126 36.8235 42.9262 36.8232C42.7398 36.823 42.5566 36.8715 42.3948 36.9639L23.1754 48.0521C22.2016 48.6143 21.393 49.4229 20.8306 50.3965C20.2683 51.3702 19.972 52.4747 19.9717 53.5991V60.0709C19.9718 61.1954 20.2679 62.3 20.8302 63.2737C21.3926 64.2474 22.2014 65.0559 23.1754 65.6179C31.2701 70.2893 39.3658 74.9608 47.4624 79.6323C47.6248 79.726 47.8089 79.7754 47.9964 79.7754C48.1838 79.7754 48.368 79.726 48.5303 79.6323L54.4164 76.2436C54.5781 76.1493 54.7122 76.0143 54.8055 75.852C54.8987 75.6897 54.9478 75.5058 54.9478 75.3186C54.9478 75.1315 54.8987 74.9476 54.8055 74.7853C54.7122 74.623 54.5781 74.488 54.4164 74.3937L29.0474 59.7626C28.7227 59.5758 28.4531 59.3067 28.2656 58.9824C28.0781 58.6581 27.9794 58.2901 27.9795 57.9155Z" fill="url(#paint1_linear_1785_537476)"/>
        <path d="M68.013 28.6372V57.9161C68.0131 58.2907 67.9145 58.6587 67.727 58.983C67.5395 59.3073 67.2698 59.5764 66.9451 59.7633L65.0756 60.8424C64.7513 61.0296 64.3835 61.1281 64.0091 61.1281C63.6347 61.1281 63.2668 61.0296 62.9426 60.8424L48.5301 52.5234C48.3678 52.4297 48.1836 52.3803 47.9962 52.3803C47.8087 52.3803 47.6246 52.4297 47.4622 52.5234L41.5762 55.9121C41.4138 56.0058 41.2789 56.1406 41.1851 56.303C41.0914 56.4653 41.042 56.6495 41.042 56.837C41.042 57.0245 41.0914 57.2087 41.1851 57.3711C41.2789 57.5335 41.4138 57.6683 41.5762 57.762L60.79 68.8558C61.7637 69.419 62.8688 69.7156 63.9937 69.7156C65.1186 69.7156 66.2236 69.419 67.1974 68.8558L72.8032 65.6185C73.7815 65.0596 74.5949 64.2524 75.1614 63.2784C75.7278 62.3045 76.0272 61.1983 76.0293 60.0716V32.0427C76.0297 31.8553 75.9807 31.6711 75.8872 31.5087C75.7937 31.3463 75.659 31.2114 75.4967 31.1177L69.6135 27.715C69.4514 27.6218 69.2676 27.5727 69.0806 27.5728C68.8935 27.5728 68.7098 27.622 68.5478 27.7154C68.3857 27.8087 68.251 27.943 68.1572 28.1048C68.0633 28.2666 68.0136 28.4501 68.013 28.6372Z" fill="url(#paint2_linear_1785_537476)"/>
        <path d="M49.0636 25.0942L50.9331 26.1733C51.2574 26.3606 51.5268 26.6298 51.7142 26.954C51.9017 27.2782 52.0006 27.646 52.001 28.0204V44.664C52.0006 44.8514 52.0496 45.0356 52.1431 45.198C52.2366 45.3604 52.3713 45.4953 52.5336 45.5889L58.4196 48.9776C58.5816 49.0718 58.7655 49.1216 58.9529 49.122C59.1403 49.1223 59.3244 49.0733 59.4867 48.9797C59.6491 48.8862 59.7839 48.7515 59.8775 48.5892C59.9711 48.4269 60.0203 48.2428 60.0201 48.0555V25.865C60.0192 24.7391 59.7212 23.6333 59.1562 22.6594C58.5912 21.6855 57.7793 20.8779 56.8024 20.3181L51.1966 17.0808C50.2229 16.5189 49.1185 16.2231 47.9943 16.2231C46.8701 16.2231 45.7657 16.5189 44.792 17.0808L20.505 31.1036C20.3432 31.198 20.209 31.333 20.1156 31.4952C20.0222 31.6575 19.9728 31.8413 19.9724 32.0286V38.8088C19.9725 38.996 20.0219 39.18 20.1155 39.3421C20.2092 39.5043 20.3438 39.639 20.506 39.7327C20.6681 39.8264 20.852 39.8758 21.0393 39.876C21.2265 39.8762 21.4105 39.8271 21.5729 39.7337L46.9278 25.0942C47.2526 24.907 47.6208 24.8085 47.9957 24.8085C48.3706 24.8085 48.7388 24.907 49.0636 25.0942Z" fill="url(#paint3_linear_1785_537476)"/>
      </g>
      <path d="M0.3 48C0.3 21.656 21.656 0.3 48 0.3C74.344 0.3 95.7 21.656 95.7 48C95.7 74.344 74.344 95.7 48 95.7C21.656 95.7 0.3 74.344 0.3 48Z" stroke="#D0D5DD" strokeWidth="0.6"/>
      <defs>
        <linearGradient id="paint0_linear_1785_537476" x1="48" y1="0" x2="48" y2="96" gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="1" stopColor="#D0D5DD"/>
        </linearGradient>
        <linearGradient id="paint1_linear_1785_537476" x1="21.9141" y1="74.8338" x2="76.2369" y2="33.9256" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF85E3"/>
          <stop offset="1" stopColor="#FBC97F"/>
        </linearGradient>
        <linearGradient id="paint2_linear_1785_537476" x1="25.9192" y1="80.3981" x2="82.1368" y2="38.0661" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF85E3"/>
          <stop offset="1" stopColor="#FBC97F"/>
        </linearGradient>
        <linearGradient id="paint3_linear_1785_537476" x1="12.1327" y1="61.0189" x2="66.946" y2="19.7435" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF85E3"/>
          <stop offset="1" stopColor="#FBC97F"/>
        </linearGradient>
        <clipPath id="clip0_1785_537476">
          <path d="M0 48C0 21.4903 21.4903 0 48 0C74.5097 0 96 21.4903 96 48C96 74.5097 74.5097 96 48 96C21.4903 96 0 74.5097 0 48Z" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}
