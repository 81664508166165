import { Select } from '../../Select'
import { ChatMiddlewareConditionSource } from '../ChatMiddlewareConditionForm'

export type ChatMiddlewareConditionOperatorSelectProps = {
  source: ChatMiddlewareConditionSource
  value: string
  onChange: (source: string) => void
}

const OPERATOR_OPTIONS = {
  content_type: [
    { label: 'Equals', value: 'equals' },
    { label: 'Not equals', value: 'not_equals' }
  ],
  content: [
    { label: 'Equals', value: 'equals' },
    { label: 'Not equals', value: 'not_equals' },
    { label: 'Contains', value: 'contains' },
    { label: 'Not contains', value: 'not_contains' },
    { label: 'Starts with', value: 'starts_with' },
    { label: 'Ends with', value: 'ends_with' }
  ],
  field: [
    { label: 'Equals', value: 'equals' },
    { label: 'Not equals', value: 'not_equals' },
    { label: 'Is empty', value: 'is_empty' },
    { label: 'Is not empty', value: 'is_not_empty' }
  ]
}

export function ChatMiddlewareConditionOperatorSelect (props: ChatMiddlewareConditionOperatorSelectProps) {
  const { source, value, onChange } = props

  return (
    <Select
      className="w-32"
      size="sm"
      value={value}
      options={OPERATOR_OPTIONS[source.type]}
      onChange={event => {
        onChange(event.target.value)
      }}
    />
  )
}
