import { FieldOperationConfig, RecalculateStrategy, RunTransactionCommand, UUID } from '@indigohive/cogfy-types'
import { Command } from '../command-stack'
import { CollectionState, CollectionStateField } from '../collection-state'

export type UpdateFieldOperationCommandData = {
  fieldId: UUID
  operation: string | null
  operationConfig: FieldOperationConfig | null
  recalculateStrategy: RecalculateStrategy
}

export class UpdateFieldOperationCommand implements Command<RunTransactionCommand> {
  private readonly _state: CollectionState
  private readonly _data: UpdateFieldOperationCommandData
  private readonly _previousField: CollectionStateField
  private readonly _previousOperation: string | null
  private readonly _previousOperationConfig: FieldOperationConfig | null
  private readonly _previousRecalculateStrategy: RecalculateStrategy

  constructor (
    state: CollectionState,
    data: UpdateFieldOperationCommandData
  ) {
    this._state = state
    this._data = data
    this._previousField = { ...state.selectedFieldToUpdate! }
    this._previousOperation = state.selectedFieldToUpdate?.operation ?? null
    this._previousOperationConfig = state.selectedFieldToUpdate?.operationConfig ?? null
    this._previousRecalculateStrategy = state.selectedFieldToUpdate!.recalculateStrategy
  }

  run (): RunTransactionCommand {
    const newField = {
      ...this._state.selectedFieldToUpdate!,
      operation: this._data.operation,
      operationConfig: this._data.operationConfig,
      recalculateStrategy: this._data.recalculateStrategy
    }
    const newFields = this._state.fields!.map(field => {
      if (field.id === this._data.fieldId) {
        return newField
      }
      return field
    })
    this._state.setSelectedFieldToUpdate(newField)
    this._state.setFields(newFields)

    return {
      operations: [
        {
          type: 'update_field_operation',
          data: {
            collectionId: this._state.id,
            fieldId: this._data.fieldId,
            operation: this._data.operation,
            operationConfig: this._data.operationConfig,
            recalculateStrategy: this._data.recalculateStrategy
          }
        }
      ]
    }
  }

  undo (): RunTransactionCommand {
    const newField = {
      ...this._previousField,
      operation: this._previousOperation,
      operationConfig: this._previousOperationConfig,
      recalculateStrategy: this._previousRecalculateStrategy
    }
    const newFields = this._state.fields!.map(field => {
      if (field.id === this._data.fieldId) {
        return newField
      }
      return field
    })
    this._state.setSelectedFieldToUpdate(newField)
    this._state.setFields(newFields)

    return {
      operations: [
        {
          type: 'update_field_operation',
          data: {
            collectionId: this._state.id,
            fieldId: this._data.fieldId,
            operation: this._previousOperation,
            operationConfig: this._previousOperationConfig,
            recalculateStrategy: this._data.recalculateStrategy
          }
        }
      ]
    }
  }
}
