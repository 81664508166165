import clsx from 'clsx'
import { CameraIcon, CircleUserIcon, FileIcon, MapPinIcon, MicIcon, VideoIcon } from 'lucide-react'

export type ChatMessageContentType =
  'audio' |
  'contacts' |
  'document' |
  'image' |
  'location' |
  'text' |
  'video'

export type ChatMessageContentPreviewProps = {
  content?: string | null
  contentData?: any | null
  contentType: ChatMessageContentType
  textWrap?: boolean | null
}

export function ChatMessageContentPreview (props: ChatMessageContentPreviewProps) {
  const { content, contentData, contentType, textWrap } = props

  return (
    <span className="text-sm">
      {contentType === 'audio' && (
        <span className="inline-flex items-center gap-1">
          <MicIcon size={14} />
          <span>Audio</span>
        </span>
      )}
      {contentType === 'contacts' && (
        <span className="inline-flex items-center gap-1">
          <CircleUserIcon size={14} />
          <span>
            {Array.isArray(contentData) && (
              <>
                {contentData.length === 1 && contentData[0].name?.formatted_name}
                {contentData.length === 2 && (
                  <>
                    {contentData[0].name?.formatted_name} and {contentData.length - 1} other contact
                  </>
                )}
                {contentData.length > 2 && (
                  <>
                    {contentData[0].name?.formatted_name} and {contentData.length - 1} other contacts
                  </>
                )}
              </>
            )}
          </span>
        </span>
      )}
      {contentType === 'document' && (
        <span className="inline-flex items-center gap-1">
          <span>
            <FileIcon size={14} />
          </span>
          <span className={clsx(textWrap ? 'line-clamp-2' : 'line-clamp-1')}>
            {content ?? 'File'}
          </span>
        </span>
      )}
      {contentType === 'image' && (
        <span className="inline-flex items-center gap-1">
          <span>
            <CameraIcon size={14} />
          </span>
          <span className={clsx(textWrap ? 'line-clamp-2' : 'line-clamp-1')}>
            {content ?? 'Photo'}
          </span>
        </span>
      )}
      {contentType === 'location' && (
        <span className="inline-flex items-center gap-1">
          <MapPinIcon size={14} />
          <span>Location</span>
        </span>
      )}
      {contentType === 'text' && (
        <span className={clsx(textWrap ? 'line-clamp-2' : 'line-clamp-1')}>
          {content}
        </span>
      )}
      {contentType === 'video' && (
        <span className="flex items-center gap-1">
          <span>
            <VideoIcon size={14} />
          </span>
          <span className={clsx(textWrap ? 'line-clamp-2' : 'line-clamp-1')}>
            {content ?? 'Video'}
          </span>
        </span>
      )}
    </span>
  )
}
