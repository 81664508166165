import { RunTransactionCommand } from '@indigohive/cogfy-types'
import { Command } from '../command-stack'
import { CollectionState } from '../collection-state'

export class UnlockCollectionCommand implements Command<RunTransactionCommand> {
  private readonly _state: CollectionState

  constructor (state: CollectionState) {
    this._state = state
  }

  run (): RunTransactionCommand {
    this._state.setLocked(false)

    return {
      operations: [
        {
          type: 'unlock_collection',
          data: {
            collectionId: this._state.id
          }
        }
      ]
    }
  }

  undo (): RunTransactionCommand {
    this._state.setLocked(true)

    return {
      operations: [
        {
          type: 'lock_collection',
          data: {
            collectionId: this._state.id
          }
        }
      ]
    }
  }
}
