import clsx, { ClassValue } from 'clsx'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { Skeleton } from '../Skeleton'
import { Button } from '../Button'
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react'

export type Tab<T> = {
  value?: T
  label: ReactNode
  disabled?: boolean
}

export type TabsProps<T> = {
  tabs: Tab<T>[]
  activeTab: T
  onTabClick?: (tab: T) => void
  variant?: 'default' | 'bordered' | 'lifted' | 'boxed'
  size?: 'xs' | 'sm' | 'md' | 'lg'
  className?: ClassValue
  onContextMenu?: (event: React.MouseEvent, tab: Tab<T>) => void
  loading?: boolean
}

export function Tabs<T extends string> (props: TabsProps<T>) {
  const { tabs, activeTab, className, loading } = props
  const variant = props.variant ?? 'default'
  const size = props.size ?? 'md'
  const ref = useRef<HTMLDivElement>(null)
  const [isOverflowed, setIsOverflowed] = useState(false)

  useEffect(() => {
    if (ref.current) {
      const { scrollWidth, clientWidth } = ref.current
      setIsOverflowed(scrollWidth > clientWidth)
    }
  }, [tabs])

  return (
    <>
      {isOverflowed && (
        <Button
          ghost
          square
          size="xs"
          className="mx-1"
          onClick={() => ref.current?.scrollBy({ left: -200, behavior: 'smooth' })}
        >
          <ChevronLeftIcon size={16} />
        </Button>
      )}
      <div
        ref={ref}
        role="tablist"
        className={clsx(
          'tabs',
          variant === 'bordered' && 'tabs-bordered',
          variant === 'lifted' && 'tabs-lifted',
          variant === 'boxed' && 'tabs-boxed',
          size === 'xs' && 'tabs-xs',
          size === 'sm' && 'tabs-sm',
          size === 'md' && 'tabs-md',
          size === 'lg' && 'tabs-lg',
          'overflow-x-auto',
          'no-scrollbar',
          className
        )}
      >
        {loading && new Array(3).fill(0).map((_, index) => (
          <a
            role="tab"
            key={`tab-loading-${index}`}
            className="tab"
            style={{ borderBottomWidth: 0 }}
          >
            <Skeleton className="h-2 w-12" key={index} />
          </a>
        ))}
        {!loading && tabs.map(tab => (
          <a
            style={{ borderBottomWidth: activeTab === tab.value ? undefined : 0 }}
            key={tab.value}
            role="tab"
            className={clsx(
              'tab',
              'flex-shrink-0',
              'whitespace-nowrap',
              activeTab === tab.value && 'tab-active',
              tab.disabled && 'tab-disabled'
            )}
            onClick={() => {
              if (!tab.disabled && tab.value) {
                props.onTabClick?.(tab.value)
              }
            }}
            onContextMenu={event => {
              if (!tab.disabled) {
                props.onContextMenu?.(event, tab)
              }
            }}
          >
            {tab.label}
          </a>
        ))}
      </div>
      {isOverflowed && (
        <Button
          ghost
          square
          size="xs"
          className="mx-1"
          onClick={() => ref.current?.scrollBy({ left: 200, behavior: 'smooth' })}
        >
          <ChevronRightIcon size={16} />
        </Button>
      )}
    </>
  )
}
