import { InfoIcon } from 'lucide-react'

export type ToolTipProps = {
  description: string
  type: 'info'
}

export function ToolTip (props: ToolTipProps) {
  const { description, type } = props

  return (
    <span
      className={'tooltip tooltip-right pl-1 before:w-[200px] before:content-[attr(data-tip)]'}
      data-tip={description}
    >
      {type === 'info' && <InfoIcon size={20} className="text-slate-400" />}
    </span>
  )
}
