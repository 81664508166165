import { ConnectionField } from '@indigohive/cogfy-types'
import { providers } from '@indigohive/cogfy-providers'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { Select } from '../../../components'
import { useSelectedFieldToUpdate } from '../../../lib'
import { FieldConfigurationsProps } from '../FieldConfigurations'

export function ConnectionConfigurations (props: FieldConfigurationsProps) {
  const { state, controller } = props

  const { t } = useTranslation()
  const field = useSelectedFieldToUpdate(state) as ConnectionField
  const [provider, setProvider] = useState(field.data?.connection?.provider ?? '')

  useEffect(() => {
    setProvider(field.data?.connection?.provider ?? '')
  }, [field.data])

  return (
    <Select
      label={t('Provider')}
      size="sm"
      value={provider}
      options={[
        { value: '', label: t('Select an option'), disabled: true },
        ...providers.map(provider => ({
          value: provider.name,
          label: provider.label
        }))
      ]}
      onChange={event => setProvider(event.target.value)}
      onBlur={_ => {
        controller.onUpdateFieldData(
          field.id,
          { connection: { ...field.data?.connection, provider } }
        )
      }}
    />
  )
}
