import { useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useCogfy, useTitle } from '../../hooks'
import { AuthenticationGuard } from '../../components/AuthenticationGuard'
import { WorkspaceLogo } from '../../components'
import { UUID } from '@indigohive/cogfy-types'

export function RootPage () {
  const cogfy = useCogfy()
  const { t } = useTranslation()
  const getWorkspaces = useQuery({
    queryKey: ['getWorkspaces'],
    queryFn: ({ signal }) => cogfy.workspaces.getList(signal)
  })
  const registerWorkspaceSelected = useMutation({
    mutationFn: (workspaceId: UUID) => cogfy.userEvent({
      type: 'workspace_selected',
      data: {
        workspaceId,
        from: 'list'
      }
    })
  })

  useTitle({
    title: 'Workspaces',
    loading: getWorkspaces.isLoading,
    error: getWorkspaces.isError
  })

  return (
    <AuthenticationGuard>
      <div className="h-screen w-screen flex flex-col justify-center items-center bg-gray-100">
        <div className="text-center w-96 border rounded-lg p-6 shadow-md bg-white max-h-full overflow-y-auto">
          <h1>Workspaces</h1>
          {getWorkspaces.isLoading && `${t('Loading')}...`}
          {getWorkspaces.error && t('Error')}
          <ul className="menu">
            {getWorkspaces.data?.data.map(workspace => (
              <li key={workspace.id}>
                <Link
                  to={`/${workspace.slug}`}
                  onClick={() => {
                    registerWorkspaceSelected.mutate(workspace.id)
                    window.localStorage.setItem('last_workspace_visited', workspace.slug)
                  }}
                >
                  <WorkspaceLogo
                    className='w-8 rounded-lg'
                    name={workspace.name}
                    logoUrl={workspace.logoUrl}
                  />
                  {workspace.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </AuthenticationGuard>
  )
}
