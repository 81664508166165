import clsx, { type ClassValue } from 'clsx'

export type SkeletonProps = {
  className?: ClassValue
}

export function Skeleton (props: SkeletonProps) {
  return (
    <div className={clsx('skeleton', props.className)} />
  )
}
