import { RunTransactionCommand } from '@indigohive/cogfy-types'
import { Command } from '../command-stack'
import { CollectionState, CollectionStateViewField } from '../collection-state'

export type ReorderViewFieldCommandData = {
  viewField: CollectionStateViewField
  index: number
}

export class ReorderViewFieldCommand implements Command<RunTransactionCommand> {
  private readonly _state: CollectionState
  private readonly _data: ReorderViewFieldCommandData
  private readonly _previousIndex: number

  constructor (
    state: CollectionState,
    data: ReorderViewFieldCommandData
  ) {
    this._state = state
    this._data = data
    this._previousIndex = state.viewFields!.findIndex(
      viewField => viewField.id === data.viewField.id
    )
  }

  run (): RunTransactionCommand {
    const newViewFields = [...this._state.viewFields!]
    newViewFields.splice(this._previousIndex, 1)
    newViewFields.splice(this._data.index, 0, this._data.viewField)
    this._state.setViewFields(newViewFields)

    return {
      operations: [
        {
          type: 'reorder_view_field',
          data: {
            collectionId: this._state.id,
            viewFieldId: this._data.viewField.id,
            order: this._data.index
          }
        }
      ]
    }
  }

  undo (): RunTransactionCommand {
    const newViewFields = [...this._state.viewFields!]
    newViewFields.splice(this._data.index, 1)
    newViewFields.splice(this._previousIndex, 0, this._data.viewField)
    this._state.setViewFields(newViewFields)

    return {
      operations: [
        {
          type: 'reorder_view_field',
          data: {
            collectionId: this._state.id,
            viewFieldId: this._data.viewField.id,
            order: this._previousIndex
          }
        }
      ]
    }
  }
}
