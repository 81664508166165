import { ChevronLeftIcon, ChevronRightIcon, PlusIcon } from 'lucide-react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

export type CollectionTableFooterProps = {
  loading?: boolean
  onNewRowClick: () => void
  previousPageDisabled?: boolean
  nextPageDisabled?: boolean
  onPreviousPageClick?: () => void
  onNextPageClick?: () => void
  canEdit?: boolean
}

function _CollectionTableFooter (props: CollectionTableFooterProps) {
  const {
    loading,
    onNewRowClick,
    previousPageDisabled,
    nextPageDisabled,
    onPreviousPageClick,
    onNextPageClick,
    canEdit = false
  } = props
  const { t } = useTranslation()

  return (
    <div
      className="flex items-center w-full justify-between h-8 min-h-8 -mt-[1px] bg-base-100 border-y sticky bottom-0"
    >
      {canEdit && (
        <div
          onClick={onNewRowClick}
          role={loading ? 'none' : 'button'}
          className={clsx(
            loading && 'cursor-not-allowed',
            !loading && 'hover:bg-base-200',
            !loading && 'active:bg-base-300',
            'text-gray-600',
            'h-full',
            'px-4',
            'grow',
            'text-sm',
            'flex',
            'items-center'
          )}
        >
          <div className="flex gap-1.5 items-center">
            <PlusIcon size={16} />
            {t('New record')}
          </div>
        </div>
      )}
      <div className="flex gap-2 mx-4 py-1 absolute right-0">
        <button
          className="join-item btn btn-xs"
          disabled={previousPageDisabled}
          onClick={onPreviousPageClick}
        >
          <ChevronLeftIcon size={16} />
        </button>
        <button
          className="join-item btn btn-xs"
          disabled={nextPageDisabled}
          onClick={onNextPageClick}
        >
          <ChevronRightIcon size={16} />
        </button>
      </div>
    </div>
  )
}

export const CollectionTableFooter = memo(_CollectionTableFooter)
