import { GetCollectionRecordsPageQuery } from '@indigohive/cogfy-types'
import { useQuery } from '@tanstack/react-query'
import { useCogfy } from './use-cogfy'

export function useCollectionTemplatesPage (query: GetCollectionRecordsPageQuery) {
  const cogfy = useCogfy()

  const getCollectionTemplatesPage = useQuery({
    queryKey: ['getCollectionTemplatesPage', query],
    queryFn: ({ signal }) => cogfy.collectionTemplates.getPage(query, signal)
  })

  return getCollectionTemplatesPage
}
