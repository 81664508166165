import { UUID } from '@indigohive/cogfy-types'
import { useCogfy } from './use-cogfy'
import { useQuery } from '@tanstack/react-query'

export function useUserGroupMembers (userGroupId: string) {
  const cogfy = useCogfy()

  const getUserGroupMembers = useQuery({
    queryKey: ['getUserGroupMembers', userGroupId],
    queryFn: ({ signal }) => cogfy.userGroups.getMembers(userGroupId as UUID, signal)
  })

  return getUserGroupMembers
}
