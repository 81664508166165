import { useEffect, useState } from 'react'
import { CollectionState } from '../collection-state'

export function useViews (state: CollectionState) {
  const [views, setViews] = useState(state.views)

  useEffect(() => {
    const viewsChanged = () => {
      setViews(state.views)
    }

    state.addEventListener('viewsChanged', viewsChanged)

    return () => {
      state.removeEventListener('viewsChanged', viewsChanged)
    }
  })

  return views
}
