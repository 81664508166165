import { ChevronsLeftIcon } from 'lucide-react'
import { useDrawer } from '../../../../hooks'

export function DrawerHeader () {
  const { open, setOpen } = useDrawer()

  return (
    <div className="flex flex-row justify-between items-center w-full h-11">
      <div className="flex flex-row items-center">
        <img src="/cogfy-avatar-grey.svg" alt="cogfy-logo" />
        <span className="text-xl font-medium">Cogfy</span>
      </div>
      {open && (
        <div role="button" className="btn btn-sm btn-square btn-ghost" onClick={() => setOpen?.(false)}>
          <ChevronsLeftIcon size={24} />
        </div>
      )}
    </div>
  )
}
