import clsx from 'clsx'

export type OptionChipColorName =
  'blue' |
  'green' |
  'orange' |
  'pink' |
  'purple' |
  'red' |
  'yellow'

export type OptionChipProps = {
  label: string
  color?: OptionChipColorName
}

export const optionChipColorNames: OptionChipColorName[] = [
  'blue',
  'green',
  'orange',
  'pink',
  'purple',
  'red',
  'yellow'
]

export function OptionChip (props: OptionChipProps) {
  const { label, color } = props

  return (
    <div
      className={clsx(
        'w-fit',
        'rounded-lg',
        'py-1',
        'px-2',
        'font-semibold',
        'leading-3',
        'text-xs',
        'h-6',
        'whitespace-nowrap',
        `field-type-select-${color}`
      )}
    >
      <span className="text-xs text-ellipsis overflow-hidden p-0 m-0">
        {label}
      </span>
    </div>
  )
}
