/* eslint-disable @typescript-eslint/unbound-method */
import { Transaction } from './types'

export type TransactionQueueOptions = {
  retryDelayMs?: number
}

const DEFAULT_RETRY_DELAY_MS = 3000

export class TransactionQueue {
  private readonly _transactions: Transaction[]
  private readonly retryDelayMs: number

  constructor (options: TransactionQueueOptions = {}) {
    this._transactions = []

    this.retryDelayMs = options.retryDelayMs ?? DEFAULT_RETRY_DELAY_MS

    this._onFulfilled = this._onFulfilled.bind(this)
    this._onRejected = this._onRejected.bind(this)
  }

  get length (): number {
    return this._transactions.length
  }

  clear (): void {
    this._transactions.splice(0, this._transactions.length)
  }

  run (transaction: Transaction): void {
    if (this._transactions.length === 0) {
      this._transactions.push(transaction)
      transaction()
        .then(this._onFulfilled)
        .catch(this._onRejected)
    } else {
      this._transactions.push(transaction)
    }
  }

  private _onFulfilled () {
    this._transactions.shift()

    if (this._transactions.length > 0) {
      this._transactions[0]()
        .then(this._onFulfilled)
        .catch(this._onRejected)
    }
  }

  private _onRejected () {
    setTimeout(
      () => {
        if (this._transactions.length > 0) {
          this._transactions[0]()
            .then(this._onFulfilled)
            .catch(this._onRejected)
        }
      },
      this.retryDelayMs
    )
  }
}
