import { Toggle, Select } from '../../../components'
import { useSelectedFieldToUpdate } from '../../../lib'
import { useTranslation } from 'react-i18next'
import { ReferenceField, UUID } from '@indigohive/cogfy-types'
import { useCollections } from '../../../hooks'
import { FieldConfigurationsProps } from '../FieldConfigurations'

export function ReferenceConfigurations (props: FieldConfigurationsProps) {
  const { state, controller } = props
  const { t } = useTranslation()

  const field = useSelectedFieldToUpdate(state) as ReferenceField
  const collections = useCollections({ type: 'database' }).data?.data

  const options = collections?.filter(collection => collection.type === 'database')
    .map(collection => ({ value: collection.id, label: collection.name ?? t('Untitled') }))

  if (!field.data?.reference?.collectionId) {
    options?.unshift({ value: '' as UUID, label: t('Select collection') })
  }

  const referenceLimit = Boolean(field.data?.reference?.limit)

  return (
    <>
      <span className="text-sm pt-2">{t('editFieldDrawer:Collection to relate')}</span>
      <span className="text-xs pb-1">
        {t('editFieldDrawer:The collection that will be referenced in this field')}
      </span>
      <Select
        size="sm"
        value={field.data?.reference?.collectionId ?? ''}
        options={options ?? []}
        onChange={event => controller.onUpdateFieldData(field.id, { reference: { ...field.data?.reference, collectionId: event.target.value as UUID } })}
        disabled={Boolean(field.data?.reference?.collectionId)}
      />
      <Toggle
        color='primary'
        label={t('editFieldDrawer:Limit References')}
        checked={referenceLimit}
        onChange={e => controller.onUpdateFieldData(field.id, { reference: { ...field.data?.reference, limit: e.target.checked } })}
      />
    </>
  )
}
