import { useTranslation } from 'react-i18next'
import { Select } from '../../../components'
import { Locale } from '@indigohive/cogfy-types'
import i18n from '../../../i18n'
import { useCogfy, usePermissions, useToasts } from '../../../hooks'
import { useMutation } from '@tanstack/react-query'

const workspaceLocaleOptions: { value: Locale, label: string }[] = [
  { value: 'pt-BR', label: 'Português (Brasil)' },
  { value: 'en', label: 'English' }
]

export function WorkspaceLocale () {
  const { t } = useTranslation()
  const cogfy = useCogfy()
  const toasts = useToasts()
  const permissions = usePermissions()

  const isAdmin = permissions.isAdmin

  const updateWorkspaceLocale = useMutation({
    mutationFn: async (locale: Locale) => {
      try {
        await cogfy.workspaces.updateLocale({ locale })
        i18n.changeLanguage(locale)
      } catch (error) {
        toasts.error(t('Failed to edit locale'))
      }
    }
  })

  return (
    <div className='flex flex-col gap-2'>
      <span className='font-bold'>{t('SettingsPages:Workspace Locale')}<span className="text-error"> *</span></span>
      <Select
        className="w-full bg-background-default"
        size="md"
        value={i18n.language}
        options={workspaceLocaleOptions}
        onChange={event => updateWorkspaceLocale.mutate(event.target.value as Locale)}
        disabled={!isAdmin}
      />
    </div>

  )
}
