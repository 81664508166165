import { UUID } from '@indigohive/cogfy-types'
import { UpdateWorkspaceAssistantCommand } from '@indigohive/cogfy-types/endpoints/updateWorkspaceAssistant'
import { useMutation, useQuery } from '@tanstack/react-query'
import { ExternalLinkIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Select } from '../../../components'
import { useCogfy, useWorkspaceSlug } from '../../../hooks'

export function WorkspaceAssistantForm () {
  const { t } = useTranslation()
  const cogfy = useCogfy()
  const workspaceSlug = useWorkspaceSlug()
  const [collectionId, setCollectionId] = useState<UUID | undefined>(undefined)
  const [fieldId, setFieldId] = useState<UUID | undefined>(undefined)
  const getAssistant = useQuery({
    queryKey: ['getWorkspaceAssistant'],
    queryFn: ({ signal }) => cogfy.getWorkspaceAssistant({ signal })
  })
  const getCollections = useQuery({
    queryKey: ['getCollections', { hasChatField: true }] as const,
    queryFn: ({ queryKey, signal }) => cogfy.getCollections(queryKey[1], { signal })
  })
  const getFields = useQuery({
    queryKey: ['getFields', { collectionId, type: 'chat' }] as const,
    queryFn: ({ queryKey, signal }) => cogfy.getFields(queryKey[1], { signal }),
    enabled: Boolean(collectionId)
  })
  const updateWorkspaceAssistant = useMutation({
    mutationFn: (data: UpdateWorkspaceAssistantCommand) => cogfy.updateWorkspaceAssistant(data)
  })

  useEffect(() => {
    if (getAssistant.data) {
      setCollectionId(getAssistant.data.collectionId)
      setFieldId(getAssistant.data.fieldId)
    }
  }, [getAssistant.data])

  return (
    <>
      <div className="flex flex-col gap-6">
        <span className="font-bold">{t('assistantPage:Assistant information')}</span>
        <Select
          className="bg-background-default"
          disabled={getAssistant.isLoading}
          label={
            <div className="inline-flex items-center gap-1">
              <span className='font-bold'>{t('Collection')}</span>
              <Button
                to={`/${workspaceSlug}/${collectionId}`}
                target="_blank"
                disabled={!collectionId}
                size='xs'
                ghost
                square
              >
                <ExternalLinkIcon size={16} />
              </Button>
            </div>
          }
          value={collectionId ?? ''}
          onChange={e => {
            if (collectionId !== e.target.value) {
              setFieldId(undefined)
              setCollectionId(e.target.value as UUID)
              updateWorkspaceAssistant.mutate({
                collectionId: e.target.value || undefined,
                enabled: true
              })
            }
          }}
          options={[
            { label: t('Select a collection'), value: '' },
            ...getCollections.data?.data.map(collection => ({
              label: collection.name ?? t('Untitled'),
              value: collection.id
            })) ?? []
          ]}
        />
        <Select
          className="bg-background-default"
          disabled={getAssistant.isLoading}
          label={<span className='font-bold'>{t('Field')}</span>}
          value={fieldId ?? ''}
          onChange={e => {
            setFieldId(e.target.value as UUID)
            updateWorkspaceAssistant.mutate({
              collectionId,
              fieldId: e.target.value as UUID,
              enabled: true
            })
          }}
          options={[
            { label: t('Select a field'), value: '' },
            ...getFields.data?.data.map(field => ({
              label: field.name,
              value: field.id
            })) ?? []
          ]}
        />
      </div>
    </>
  )
}
