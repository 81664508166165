import { CircleArrowDown, FileIcon } from 'lucide-react'
import mime from 'mime-types'
import { useTranslation } from 'react-i18next'
import { WhatsAppContentProps } from './WhatsAppContent'

export function WhatsAppContentDocument (props: WhatsAppContentProps) {
  const { content, files } = props
  const { t } = useTranslation()

  // WhatsAppContentFile component expects only one file
  const file = files[0]

  const fileExtension = mime.extension(file.type)
  const byteValueNumberFormatter = Intl.NumberFormat('en', {
    notation: 'compact',
    style: 'unit',
    unit: 'byte',
    unitDisplay: 'narrow'
  })

  return (
    <div
      className="text-sm cursor-pointer"
      onClick={() => window.open(`${file.url}`, '_blank')}
    >
      <div className="bg-[#f5f6f6] rounded-[7.5px] px-2 py-1 flex gap-2 items-start">
        <FileIcon size={24} className="mt-1" />
        <div className="grow">
          <p className="overflow-ellipsis overflow-hidden">{file?.name ? file.name : t('Untitled')}</p>
          <p className="text-gray-400">
            {byteValueNumberFormatter.format(file.size)}
            {fileExtension && ` • ${fileExtension.toUpperCase()}`}
          </p>
        </div>
        <CircleArrowDown size={24} strokeWidth={1} className="mt-1" />
      </div>
      {content && (
        <div className="ml-1">{content}</div>
      )}
    </div>
  )
}
