import { XIcon } from 'lucide-react'
import {
  BooleanFilterMenu,
  ChatFilterMenu,
  DateFilterMenu,
  FileFilterMenu,
  NumberFilterMenu,
  SelectFilterMenu,
  TextFilterMenu,
  VectorFilterMenu
} from './components'
import { Button } from '../../components'
import { CollectionStateField } from '../../lib'
import { CollectionPageController } from '../../pages/CollectionPageV2/collection-page-controller'
import clsx from 'clsx'
import { createElement, useRef } from 'react'
import { filterIsActive, formatFilterLabel } from './methods'
import { AndFilter, FieldType, OrFilter, RecordFilter } from '@indigohive/cogfy-types'
import { FIELD_ICONS_BY_TYPE } from '../field-type-icons'
import { useOnClickOutside } from '../../hooks'

export type FieldFilterMenuProps = {
  controller: CollectionPageController
  field: CollectionStateField
  filter: Exclude<RecordFilter, AndFilter | OrFilter>
  viewFilter: AndFilter
}

export type FieldFilterMenuType = (props: FieldFilterMenuProps) => JSX.Element

export const FIELD_FILTER_MENU_BY_TYPE: Partial<Record<FieldType, FieldFilterMenuType>> = {
  boolean: BooleanFilterMenu,
  chat: ChatFilterMenu,
  date: DateFilterMenu,
  file: FileFilterMenu,
  number: NumberFilterMenu,
  select: SelectFilterMenu,
  text: TextFilterMenu,
  vector: VectorFilterMenu
}

export type FieldFilterChipProps = {
  controller: CollectionPageController
  field: CollectionStateField
  filter: Exclude<RecordFilter, AndFilter | OrFilter>
  viewFilter: AndFilter
}

export function FieldFilterChip (props: FieldFilterChipProps) {
  const { controller, field, filter } = props

  const ref = useRef<HTMLDetailsElement>(null)

  useOnClickOutside(ref, () => {
    ref.current!.open = false
  })

  const isFilterActive = filterIsActive(filter)
  const fieldFilterMenuComponent = FIELD_FILTER_MENU_BY_TYPE[field.type]

  return (
    <details ref={ref} className="dropdown dropdown-end">
      <summary
        className={clsx(
          'badge',
          'badge-lg',
          'hover:cursor-pointer',
          'flex',
          'h-5',
          isFilterActive && ['badge-outline', 'badge-primary', 'bg-primary-content']
        )}
      >
        <span className="mr-2">
          {createElement(
            FIELD_ICONS_BY_TYPE[field.type],
            { size: 14 }
          )}
        </span>
        <span className="text-xs truncate">
          {formatFilterLabel(filter, field)}
        </span>
        <div className="tooltip tooltip-bottom ml-2">
          <Button
            onClick={() => controller.onDeleteViewFilterClick(filter.property)}
            size="xs"
            ghost
            circle
          >
            <XIcon size={14} />
          </Button>
        </div>
      </summary>
      <ul className="p-2 shadow menu dropdown-content z-[2] bg-base-100 rounded-box w-max max-h-60 overflow-y-auto">
        {fieldFilterMenuComponent && createElement(fieldFilterMenuComponent, props)}
      </ul>
    </details>
  )
}
