import { v4 as uuid } from 'uuid'
import { RunTransactionCommand, UUID } from '@indigohive/cogfy-types'
import { CollectionState, CollectionStateRecord } from '../collection-state'
import { Command } from '../command-stack'

export class CreateRecordCommand implements Command<RunTransactionCommand> {
  private readonly _state: CollectionState
  private readonly _recordToCreate: CollectionStateRecord

  constructor (state: CollectionState) {
    this._state = state
    this._recordToCreate = {
      id: uuid() as UUID,
      properties: {}
    }
  }

  run (): RunTransactionCommand {
    const currentRecords = this._state.records
    const newRecords = currentRecords ? [...currentRecords, this._recordToCreate] : [this._recordToCreate]

    this._state.setRecords(newRecords)
    this._state.setCountRecords((this._state.countRecords ?? 0) + 1)

    return {
      operations: [
        {
          type: 'create_record',
          data: {
            collectionId: this._state.id,
            recordId: this._recordToCreate.id
          }
        }
      ]
    }
  }

  undo (): RunTransactionCommand {
    const newRecords = this._state.records?.filter(record => record.id !== this._recordToCreate.id) ?? []
    const recordsSelected = this._state.selectedRows
    this._state.setRecords(newRecords)
    this._state.setCountRecords((this._state.countRecords ?? 0) - 1)

    if (recordsSelected[this._recordToCreate.id]) {
      delete recordsSelected[this._recordToCreate.id]
    }

    return {
      operations: [
        {
          type: 'hard_delete_records',
          data: {
            collectionId: this._state.id,
            recordIds: [this._recordToCreate.id]
          }
        }
      ]
    }
  }
}
