import { GetChatMiddlewaresResultData } from '@indigohive/cogfy-types/endpoints/getChatMiddlewares'
import { OverlayContent } from '../../../../../../components'
import { CircleCheck, CircleX, CopyIcon, PencilIcon, TrashIcon } from 'lucide-react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

export type ChatMiddlewareContextMenuProps = {
  anchorEl?: HTMLElement
  middleware?: GetChatMiddlewaresResultData
  onClose: () => void
  onDeleteClick: (middleware: GetChatMiddlewaresResultData) => void
  onDisableClick: (middleware: GetChatMiddlewaresResultData) => void
  onEditClick?: (middleware: GetChatMiddlewaresResultData) => void
  onDuplicateClick?: (middleware: GetChatMiddlewaresResultData) => void
}

export function ChatMiddlewareContextMenu (props: ChatMiddlewareContextMenuProps) {
  const { anchorEl, middleware, onClose } = props

  const { t } = useTranslation()

  return (
    <OverlayContent
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom' }}
    >
      <ul className="menu rounded-box bg-base-100 border shadow min-w-64">
        <li
          onClick={() => {
            if (middleware) {
              props.onEditClick?.(middleware)
            }
            onClose()
          }}
        >
          <a>
            <PencilIcon size={16} />
            {t('Edit')}
          </a>
        </li>
        <li
          onClick={() => {
            if (middleware) {
              props.onDuplicateClick?.(middleware)
            }
            onClose()
          }}
        >
          <a>
            <CopyIcon size={16} />
            {t('Duplicate')}
          </a>
        </li>
        <li
          className={clsx(middleware?.enabled ? 'hover:text-error' : 'hover:text-success')}
          onClick={() => {
            if (middleware) {
              props.onDisableClick(middleware)
            }
            onClose()
          }}
        >
          <a>
            {middleware?.enabled ? <CircleX size={16} /> : <CircleCheck size={16} />}
            {middleware?.enabled ? t('Disable') : t('Enable')}
          </a>
        </li>
        <li
          className="hover:text-error"
          onClick={() => {
            if (middleware) {
              props.onDeleteClick(middleware)
            }
            onClose()
          }}
        >
          <a>
            <TrashIcon size={16} />
            {t('Delete')}
          </a>
        </li>
      </ul>
    </OverlayContent>
  )
}
