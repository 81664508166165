import { RecordOrder, RunTransactionCommand, RunTransactionCommandOperation, UUID } from '@indigohive/cogfy-types'
import { Command } from '../command-stack'
import { CollectionState, CollectionStateViewField } from '../collection-state'

export type DeleteViewFieldCommandData = {
  fieldId: UUID
}

export class DeleteViewFieldCommand implements Command<RunTransactionCommand> {
  private readonly _state: CollectionState
  private readonly _data: DeleteViewFieldCommandData
  private readonly _viewFieldToDelete: CollectionStateViewField | undefined
  private readonly _viewFieldToDeleteIndex: number | undefined
  private readonly _previousViewOrderBy: RecordOrder[] | null

  constructor (
    state: CollectionState,
    data: DeleteViewFieldCommandData
  ) {
    this._state = state
    this._data = data
    this._viewFieldToDeleteIndex = state.viewFields?.findIndex(viewField => viewField.viewId === state.activeViewId && viewField.fieldId === this._data.fieldId)
    this._viewFieldToDelete = this._viewFieldToDeleteIndex === undefined
      ? undefined
      : state.viewFields?.[this._viewFieldToDeleteIndex]
    this._previousViewOrderBy = this._state.views?.find(view => view.id === this._state.activeViewId)?.orderBy ?? null
  }

  run (): RunTransactionCommand {
    const newViewFields = [...this._state.viewFields!]
    newViewFields.splice(this._viewFieldToDeleteIndex!, 1)
    this._state.setViewFields(newViewFields)

    if (this._state.selectedFieldToUpdate?.id === this._data.fieldId) {
      this._state.setSelectedFieldToUpdate(null)
    }

    const operations: RunTransactionCommandOperation[] = [
      {
        type: 'delete_view_field',
        data: {
          collectionId: this._state.id,
          viewFieldId: this._viewFieldToDelete!.id
        }
      }
    ]

    // Delete field from view order by
    if (this._previousViewOrderBy?.find(orderBy => orderBy.fieldId === this._viewFieldToDelete?.fieldId)) {
      const newViewOrderBy = this._previousViewOrderBy?.filter(currentOrderBy => currentOrderBy.fieldId !== this._viewFieldToDelete?.fieldId) ?? null

      const newViews = this._state.views?.map(view => {
        if (view.id === this._state.activeViewId) {
          return {
            ...view,
            orderBy: newViewOrderBy
          }
        }
        return view
      })

      this._state.setViews(newViews ?? [])

      operations.push({
        type: 'update_view_order_by',
        data: {
          collectionId: this._state.id,
          viewId: this._state.activeViewId!,
          orderBy: newViewOrderBy
        }
      })
    }

    return { operations }
  }

  undo (): RunTransactionCommand {
    const newViewFields = [...this._state.viewFields!]
    newViewFields.splice(this._viewFieldToDeleteIndex!, 0, this._viewFieldToDelete!)
    this._state.setViewFields(newViewFields)

    // Restore view order by
    if (this._previousViewOrderBy?.find(orderBy => orderBy.fieldId === this._viewFieldToDelete?.fieldId)) {
      const newViews = this._state.views?.map(view => {
        if (view.id === this._state.activeViewId) {
          return {
            ...view,
            orderBy: this._previousViewOrderBy
          }
        }
        return view
      })

      this._state.setViews(newViews ?? [])
    }

    return {
      operations: [
        {
          type: 'create_view_field',
          data: {
            collectionId: this._state.id,
            viewFieldId: this._viewFieldToDelete!.id,
            viewId: this._viewFieldToDelete!.viewId,
            fieldId: this._viewFieldToDelete!.fieldId,
            order: this._viewFieldToDelete!.order
          }
        },
        {
          type: 'update_view_order_by',
          data: {
            collectionId: this._state.id,
            viewId: this._state.activeViewId!,
            orderBy: this._previousViewOrderBy
          }
        }
      ]
    }
  }
}
