import { Heading } from '..'
import { PropsWithChildren, ReactNode } from 'react'

type SettingsPagesHeaderProps = PropsWithChildren<{
  title: string
  description?: ReactNode
}>

export function SettingsPagesHeader (props: SettingsPagesHeaderProps) {
  const { title, description, children } = props

  return (
    <div className="relative">
      <div className="absolute inset-0 h-64 bg-gradient-to-b from-primary opacity-30 pointer-events-none" />
      <Heading className="relative mx-16 flex items-center gap-4">
        {children}
        <div className='flex flex-col gap-4 my-16'>
          <span className='text-[40px]'>{title}</span>
          <span className='text-sm font-normal'>{description}</span>
        </div>
      </Heading>
    </div>
  )
}
