import { createElement, ReactNode, useRef, useState } from 'react'
import { TreeView } from './TreeView'
import { TreeViewItemData } from './types/TreeViewItemData'
import { useDrag, useDrop } from 'react-dnd'
import clsx from 'clsx'
import { HoverPosition } from './types/HoverPosition'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TreeViewPlaceholder } from './types/TreeViewPlaceholder'

export type TreeViewItemProps<T extends TreeViewItemData<T>> = {
  item: T
  searchHighlight?: string
  parent?: T
  onDrop?: (
    drag: T,
    drop: T,
    position: HoverPosition
  ) => void
  onItemClick?: (item: T) => void
  onContextMenu?: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    item: T
  ) => void
  placeholder?: TreeViewPlaceholder<T>
  address: number[]
}

const itemType = 'TREE_VIEW_ITEM'

export function TreeViewItem<T extends TreeViewItemData<T>> (props: TreeViewItemProps<T>) {
  const { item } = props
  const ref = useRef<HTMLLIElement>(null)
  const detailsRef = useRef<HTMLDetailsElement>(null)
  const { t } = useTranslation()
  const [hoverPosition, setHoverPosition] = useState<HoverPosition>('none')
  const [{ isOver }, drop] = useDrop<T & { address: number[] }, unknown, { isOver: boolean }>({
    accept: itemType,
    collect: monitor => ({
      isOver: monitor.isOver({ shallow: true })
    }),
    drop (item, monitor) {
      if (monitor.didDrop()) {
        return
      }

      const drag = item
      const drop = props.item
      const isDescendant = drag.address.every(
        (address, index) => address === props.address[index]
      )
      if (hoverPosition === 'middle' && !drop.children) {
        return
      }

      if (isDescendant) {
        return
      }

      props.onDrop?.(drag, drop, hoverPosition)

      return {}
    },
    hover: (_, monitor) => {
      if (!ref.current) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverHeight = hoverBoundingRect.bottom - hoverBoundingRect.top
      const clientOffset = monitor.getClientOffset()!
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (item.canDropOver) {
        if (hoverClientY < hoverHeight * 0.28) {
          setHoverPosition('top')
        } else if (hoverClientY < hoverHeight * 0.72) {
          setHoverPosition('middle')
        } else {
          setHoverPosition('bottom')
        }
      } else {
        if (hoverClientY < hoverHeight * 0.5) {
          setHoverPosition('top')
        } else {
          setHoverPosition('bottom')
        }
      }
    }
  }, [props.item, props.address, hoverPosition])

  const [{ isDragging }, drag] = useDrag({
    type: itemType,
    item: {
      ...item,
      address: props.address
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  drag(drop(ref))

  const isOverMiddle = !isDragging && isOver && hoverPosition === 'middle'

  return (
    <li
      ref={ref}
      className={clsx(isDragging ? 'opacity-50' : 'opacity-100')}
      onContextMenu={event => {
        if (props.onContextMenu) {
          event.preventDefault()
          props.onContextMenu(event, item)
        }
      }}
    >
      {!isDragging && isOver && hoverPosition === 'top' && (
        <div className="p-0 absolute h-1 w-full bg-primary top-[-2px] rounded-none pointer-events-none" />
      )}
      {!item.children && (
        createElement(
          item.to ? Link : 'a',
          {
            className: clsx(isOverMiddle && 'bg-primary', 'py-2 px-3', item.disabled && 'opacity-40'),
            to: item.to!,
            onClick: () => props.onItemClick?.(item)
          },
          <>
            {item.icon}
            {item.label ? getHighlightedText(item.label, props.searchHighlight) : <span className="text-gray-400">{t('Untitled')}</span>}
          </>
        )
      )}
      {item.children && (
        <details ref={detailsRef} open={Boolean(props.searchHighlight)}>
          <summary
            onClick={() => props.onItemClick?.(item)}
            className={clsx(isOverMiddle && 'bg-primary', 'py-2 px-3', item.disabled && 'opacity-40')}
          >
            {item.icon}
            {item.label ? getHighlightedText(item.label, props.searchHighlight) : <span className="text-gray-400">{t('Untitled')}</span>}
          </summary>
          <TreeView<T>
            items={item.children}
            searchHighlight={props.searchHighlight}
            parent={item}
            onDrop={props.onDrop}
            onItemClick={props.onItemClick}
            onContextMenu={props.onContextMenu}
            placeholder={props.placeholder}
            address={props.address}
          />
        </details>
      )}
      {!isDragging && isOver && hoverPosition === 'bottom' && (
        <div className="z-10 p-0 absolute h-1 w-full bg-primary bottom-[-2px] rounded-none pointer-events-none" />
      )}
    </li>
  )
}

function getHighlightedText (label: ReactNode, searchHighlight?: string) {
  if (typeof label !== 'string' || !searchHighlight) return label

  const parts = label.split(new RegExp(`(${searchHighlight})`, 'gi'))

  return (
    <span>
      {parts.map((part, index) =>
        <span
          key={index}
          className={clsx(
            part.toLowerCase() === searchHighlight.toLowerCase() && 'text-[#2477D1]'
          )}
        >
          {part}
        </span>
      )}
    </span>
  )
}
