import { providers } from '@indigohive/cogfy-providers'
import { ConnectionRecordProperty } from '@indigohive/cogfy-types'
import clsx from 'clsx'
import { FieldCellProps } from '../FieldCell'

const PROVIDER_LABEL = Object.fromEntries(
  providers.map(provider => [provider.name, provider.label])
)

export function ConnectionCell (props: FieldCellProps<ConnectionRecordProperty>) {
  const { property, textWrap } = props

  return (
    <span
      className={clsx(
        'px-2 py-1',
        textWrap && 'whitespace-pre-wrap',
        !textWrap && 'truncate'
      )}
    >
      {property?.connection?.value && (
        <div className="flex gap-1 items-center">
          <div className="grow">
            {PROVIDER_LABEL[property?.connection?.value?.provider]}
          </div>
          <div className="text-xs opacity-40">
            {property?.connection?.value?.name}
          </div>
        </div>
      )}
    </span>
  )
}
