import { TransactionEvent, UUID } from '@indigohive/cogfy-types'
import { useBeforeUnload, useBlocker, useParams } from 'react-router-dom'
import { useAppManager, useCogfy, useCollection } from '../../hooks'
import { DatabaseCollectionPage } from './_DatabaseCollectionPage'
import { FolderCollectionPage } from './_FolderCollectionPage'
import { CollectionName, CollectionNavbar } from './components'
import { CollectionState, TransactionReducer } from '../../lib'
import { useEffect, useState } from 'react'
import { useCopyPaste, useUndoRedo } from './hooks'
import { CollectionPageController } from './collection-page-controller'
import { useQuery } from '@tanstack/react-query'

export type CollectionPageV2Props = {
  collectionId?: UUID
  currentViewFieldId?: UUID
}

const warningMessage = 'Reload site? Changes you made may not be saved.'

export function CollectionPageV2 (props: CollectionPageV2Props) {
  const cogfy = useCogfy()
  const params = useParams<{ collectionId: UUID }>()
  const collectionId = (params.collectionId ?? props.collectionId)!
  const manager = useAppManager()
  const [state, setState] = useState<CollectionState>(new CollectionState(collectionId))
  const [controller, setController] = useState(new CollectionPageController(state, manager))
  const getCollection = useCollection(collectionId, state)
  const getUserCollectionPermissions = useQuery({
    queryKey: ['getUserCollectionPermissions', { collectionId }] as const,
    queryFn: ({ queryKey, signal }) => cogfy[queryKey[0]](queryKey[1], { signal })
  })
  useEffect(() => {
    if (collectionId) {
      const reducer = new TransactionReducer()
      const handleTransaction = (event: TransactionEvent) => {
        reducer.reduce(state, event)
      }

      cogfy.transactions.onTransaction(collectionId, handleTransaction)

      return () => {
        cogfy.transactions.offTransaction(collectionId, handleTransaction)
      }
    }
  }, [state, collectionId])

  useUndoRedo()
  useCopyPaste(controller)

  useEffect(() => {
    if (state.id !== collectionId) {
      manager.clearUndoRedo()
      const newState = new CollectionState(collectionId)
      const newController = new CollectionPageController(newState, manager)
      setState(newState)
      setController(newController)
    }
  }, [collectionId])

  useBlocker(() => manager.hasUnsavedChanges && !window.confirm(warningMessage))
  useBeforeUnload(event => {
    if (manager.hasUnsavedChanges) {
      event.returnValue = warningMessage
      return warningMessage
    }
  })

  return (
    <div className="bg-base-100">
      <CollectionNavbar
        state={state}
        collection={getCollection.data}
        loading={getCollection.isLoading}
      />

      {getCollection.data && (
        <>
          <CollectionName state={state} />
          {getCollection.data.type === 'database' && (
            <DatabaseCollectionPage
              state={state}
              controller={controller}
              collection={getCollection.data}
              currentViewFieldId={props.currentViewFieldId}
              permissions={getUserCollectionPermissions.data}
            />
          )}
          {getCollection.data.type === 'folder' && (
            <FolderCollectionPage collection={getCollection.data} />
          )}
        </>
      )}
    </div>
  )
}
