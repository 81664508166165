import clsx from 'clsx'
import {
  DetailedHTMLProps,
  ForwardedRef,
  InputHTMLAttributes,
  forwardRef
} from 'react'

export type CheckboxSize =
  'xs' |
  'sm' |
  'md' |
  'lg'

export type CheckboxColor =
  'primary' |
  'secondary' |
  'accent' |
  'success' |
  'warning' |
  'info' |
  'error'

export type CheckboxProps =
  Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'size'> & {
    size?: CheckboxSize
    color?: CheckboxColor
  }

export const Checkbox = forwardRef(
  function Checkbox (props: CheckboxProps, ref: ForwardedRef<HTMLInputElement>) {
    const { className, color, size, ...checkboxProps } = props

    return (
      <input
        ref={ref}
        type="checkbox"
        className={clsx(
          'checkbox',
          size === 'xs' && 'checkbox-xs',
          size === 'sm' && 'checkbox-sm',
          size === 'md' && 'checkbox-md',
          size === 'lg' && 'checkbox-lg',
          color === 'primary' && ['checked:checkbox-primary', 'indeterminate:checkbox-primary'],
          color === 'secondary' && ['checked:checkbox-secondary', 'indeterminate:checkbox-secondary'],
          color === 'accent' && ['checked:checkbox-accent', 'indeterminate:checkbox-accent'],
          color === 'success' && ['checked:checkbox-success', 'indeterminate:checkbox-success'],
          color === 'warning' && ['checked:checkbox-warning', 'indeterminate:checkbox-warning'],
          color === 'info' && ['checked:checkbox-info', 'indeterminate:checkbox-info'],
          color === 'error' && ['checked:checkbox-error', 'indeterminate:checkbox-error'],
          className
        )}
        {...checkboxProps}
      />
    )
  }
)
