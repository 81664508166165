import { CheckIcon } from 'lucide-react'
import { FieldFilterMenuProps } from '../FieldFilterChip'
import { RecordFilter } from '@indigohive/cogfy-types'
import { getFilterOperator } from '../methods'
import { useTranslation } from 'react-i18next'

const selectOptions = [
  { value: 'equals', label: 'is' },
  { value: 'notEquals', label: 'is not' }
]

export function BooleanFilterMenu (props: FieldFilterMenuProps) {
  const { controller, field, filter, viewFilter } = props

  const { t } = useTranslation()
  const operator = getFilterOperator(filter, field) ?? 'equals'
  const filterValue = (filter as any)[operator] ?? null

  const onFilterValueChange = (value: boolean) => {
    const newFilter = {
      property: field.id,
      propertyType: 'boolean',
      [operator]: value
    }

    controller.onViewFilterChange(viewFilter, newFilter as RecordFilter)
  }

  const onUpdateOperator = (operator: string) => {
    const newFilter = {
      property: field.id,
      propertyType: 'boolean',
      [operator]: filterValue
    }

    controller.onViewFilterChange(viewFilter, newFilter as RecordFilter)
  }

  const onClearFilter = () => {
    const newFilter = {
      property: field.id,
      propertyType: 'boolean'
    }

    controller.onViewFilterChange(viewFilter, newFilter as RecordFilter)
  }

  return (
    <div className="space-y-2 p-2">
      <div className="flex items-center gap-x-2">
        <div className="whitespace-nowrap">{field.name}</div>
        <select
          value={operator}
          onChange={event => onUpdateOperator(event.target.value)}
          className="select select-xs select-ghost max-w-xs w-full"
        >
          {selectOptions.map(option => (
            <option
              key={option.label}
              value={option.value}
            >
              {t(`filters:${option.label}`)}
            </option>
          ))}
        </select>
      </div>
      <ul>
        <li onClick={() => onFilterValueChange(true)}>
          <a className="flex">
            <span className="grow">{t('filters:Checked')}</span>
            {filterValue === true && (<CheckIcon size={16} />)}
          </a>
        </li>
        <li onClick={() => onFilterValueChange(false)}>
          <a className="flex">
            <span className="grow">{t('filters:Unchecked')}</span>
            {filterValue === false && (<CheckIcon size={12} />)}
          </a>
        </li>
        <div className="divider my-0"></div>
        <li onClick={() => onClearFilter()}><a>{t('filters:Clear')}</a></li>
      </ul>
    </div>
  )
}
