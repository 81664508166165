import { v4 as uuid } from 'uuid'
import { CreateRecordReferenceOperationData, ReferenceRecordProperty, RunTransactionCommand, UUID } from '@indigohive/cogfy-types'
import { Command } from '../command-stack'
import { CollectionState } from '../collection-state'

export type CreateRecordReferenceCommandData = {
  recordId: UUID
  fieldId: UUID
  referencedRecordId: UUID
  title: string | null
}

export class CreateRecordReferenceCommand implements Command<RunTransactionCommand> {
  private readonly _state: CollectionState
  private readonly _data: CreateRecordReferenceCommandData
  private readonly _recordIndex: number | undefined
  private readonly _recordReferenceToCreate: CreateRecordReferenceOperationData

  constructor (state: CollectionState, data: CreateRecordReferenceCommandData) {
    this._state = state
    this._data = data
    this._recordIndex = state.records?.findIndex(record => record.id === data.recordId)
    this._recordReferenceToCreate = {
      collectionId: this._state.id,
      recordReferenceId: uuid() as UUID,
      recordId: data.recordId,
      fieldId: data.fieldId,
      referencedRecordId: data.referencedRecordId

    }
  }

  run (): RunTransactionCommand {
    if (this._state.records === null || this._recordIndex === undefined) {
      return { operations: [] }
    }

    const newRecords = [...this._state.records]
    const newRecordReferences = [
      ...(newRecords[this._recordIndex].properties[this._data.fieldId] as ReferenceRecordProperty | undefined)?.reference?.value ?? [],
      {
        id: this._recordReferenceToCreate.recordReferenceId,
        referencedRecordId: this._recordReferenceToCreate.referencedRecordId,
        title: this._data.title
      }
    ]

    newRecords[this._recordIndex].properties[this._data.fieldId] = {
      type: 'reference',
      reference: {
        value: newRecordReferences
      }
    }

    this._state.setRecords(newRecords)

    if (this._state.clickedCell?.record.id === this._data.recordId && this._state.clickedCell?.field.id === this._data.fieldId) {
      const newClickedCell = { ...this._state.clickedCell }
      newClickedCell.record.properties[this._data.fieldId] = {
        type: 'reference',
        reference: {
          value: newRecordReferences
        }
      }

      this._state.setClickedCell(newClickedCell)
    }

    return {
      operations: [
        {
          type: 'create_record_reference',
          data: this._recordReferenceToCreate
        }
      ]
    }
  }

  undo (): RunTransactionCommand {
    if (this._state.records === null || this._recordIndex === undefined) {
      return { operations: [] }
    }

    const newRecords = [...this._state.records]
    const newRecordReferences = [...(newRecords[this._recordIndex].properties[this._data.fieldId] as ReferenceRecordProperty).reference?.value ?? []]
    const recordReferenceIndex = newRecordReferences.findIndex(recordReference => recordReference.id === this._recordReferenceToCreate.recordReferenceId)
    newRecordReferences.splice(recordReferenceIndex, 1)

    newRecords[this._recordIndex].properties[this._data.fieldId] = {
      type: 'reference',
      reference: {
        value: newRecordReferences
      }
    }

    this._state.setRecords(newRecords)

    if (this._state.clickedCell?.record.id === this._data.recordId && this._state.clickedCell?.field.id === this._data.fieldId) {
      const newClickedCell = { ...this._state.clickedCell }
      newClickedCell.record.properties[this._data.fieldId] = {
        type: 'reference',
        reference: {
          value: newRecordReferences
        }
      }

      this._state.setClickedCell(newClickedCell)
    }

    return {
      operations: [
        {
          type: 'delete_record_reference',
          data: {
            collectionId: this._state.id,
            recordReferenceId: this._recordReferenceToCreate.recordReferenceId
          }
        }
      ]
    }
  }
}
