import clsx, { ClassValue } from 'clsx'
import { avatarInitials } from '../../helpers'

export type WorkspaceLogoProps = {
  name: string
  logoUrl?: string | null
  className?: ClassValue
}

export function WorkspaceLogo (props: WorkspaceLogoProps) {
  const { name, logoUrl, className } = props
  const baseApiUrl = import.meta.env.VITE_COGFY_INTERNAL_API_URL ?? 'http://localhost:3000'

  return (
    <div className="avatar placeholder">
      <div className={clsx('ring-1 ring-gray-200 rounded-full', className ?? 'w-8')}>
        {logoUrl && (
          <img src={`${baseApiUrl}${logoUrl}`} alt={`${name}-logo`} />
        )}
        {!logoUrl && (
          <span>{avatarInitials(name)}</span>
        )}
      </div>
    </div>
  )
}
