import type {
  Command,
  CommandStackEvent,
  CommandStackEventListener,
  CommandStackEventType
} from './types'

export class CommandStack<T> {
  private readonly undoStack: Command<T>[]
  private readonly redoStack: Command<T>[]
  private readonly _eventListeners: Record<string, CommandStackEventListener<T>[]>

  constructor () {
    this.undoStack = []
    this.redoStack = []
    this._eventListeners = {}
  }

  addEventListener (
    type: CommandStackEventType,
    listener: CommandStackEventListener<T>
  ): void {
    if (!this._eventListeners[type]) {
      this._eventListeners[type] = []
    }

    this._eventListeners[type].push(listener)
  }

  removeEventListener (
    type: CommandStackEventType,
    listener: CommandStackEventListener<T>
  ): void {
    if (this._eventListeners[type]) {
      const index = this._eventListeners[type].indexOf(listener)

      if (index !== -1) {
        this._eventListeners[type].splice(index, 1)
      }
    }
  }

  clear () {
    this.undoStack.splice(0, this.undoStack.length)
    this.redoStack.splice(0, this.redoStack.length)

    this._dispatch({ type: 'clear' })
  }

  run (command: Command<T>): T {
    const result = command.run(false)

    this.undoStack.push(command)
    this.redoStack.splice(0, this.redoStack.length)

    this._dispatch({ type: 'run', command, result })

    return result
  }

  undo (): T | undefined {
    const command = this.undoStack.pop()

    if (command) {
      const result = command.undo()

      this.redoStack.push(command)

      this._dispatch({ type: 'undo', command, result })

      return result
    }
  }

  redo (): T | undefined {
    const command = this.redoStack.pop()

    if (command) {
      const result = command.run(true)

      this.undoStack.push(command)

      this._dispatch({ type: 'redo', command, result })

      return result
    }
  }

  private _dispatch (event: CommandStackEvent<T>) {
    if (this._eventListeners[event.type]) {
      for (const listener of this._eventListeners[event.type]) {
        listener(event)
      }
    }
  }
}
