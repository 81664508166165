import { RunTransactionCommand } from '@indigohive/cogfy-types'
import { Command } from '../command-stack'
import { CollectionState, CollectionStateField } from '../collection-state'

export type RenameFieldCommandData = {
  field: CollectionStateField
  name: string
}

export class RenameFieldCommand implements Command<RunTransactionCommand> {
  private readonly _state: CollectionState
  private readonly _data: RenameFieldCommandData
  private readonly _fieldToRename: CollectionStateField
  private readonly _previousName: string

  constructor (
    state: CollectionState,
    data: RenameFieldCommandData
  ) {
    this._state = state
    this._data = data
    this._fieldToRename = data.field
    this._previousName = data.field.name
  }

  run (): RunTransactionCommand {
    const newFields = this._state.fields?.map(field => {
      if (field.id === this._fieldToRename.id) {
        field.name = this._data.name
      }
      return field
    })
    this._state.setSelectedFieldToUpdate({
      ...this._fieldToRename,
      name: this._data.name
    })
    this._state.setFields(newFields ?? [])

    return {
      operations: [
        {
          type: 'rename_field',
          data: {
            collectionId: this._state.id,
            fieldId: this._fieldToRename.id,
            name: this._data.name
          }
        }
      ]
    }
  }

  undo (): RunTransactionCommand {
    const newFields = this._state.fields?.map(field => {
      if (field.id === this._fieldToRename.id) {
        field.name = this._previousName
      }
      return field
    })
    this._state.setSelectedFieldToUpdate({
      ...this._fieldToRename,
      name: this._previousName
    })
    this._state.setFields(newFields ?? [])

    return {
      operations: [
        {
          type: 'rename_field',
          data: {
            collectionId: this._state.id,
            fieldId: this._fieldToRename.id,
            name: this._previousName
          }
        }
      ]
    }
  }
}
