import { XIcon, EyeIcon, EyeOffIcon } from 'lucide-react'
import { Button } from '../../../../components'
import { CollectionState, useActiveViewId, useFields, useViewFields } from '../../../../lib'
import { CollectionPageController } from '../../collection-page-controller'
import { useTranslation } from 'react-i18next'
import { createElement } from 'react'
import { FIELD_ICONS_BY_TYPE } from '../../../../field-types'

export type EditPropertiesDrawerProps = {
  state: CollectionState
  controller: CollectionPageController
  open: boolean
  onClose: () => void
}

export function EditPropertiesDrawer (props: EditPropertiesDrawerProps) {
  const { state, controller, open, onClose } = props

  const { t } = useTranslation()

  const fields = useFields(state)
  const activeViewId = useActiveViewId(state)
  const viewFields = useViewFields(state)

  if (!open) return null
  const viewFieldsIds = viewFields?.map(viewField => viewField.fieldId)

  const shownFields = fields?.filter(field => viewFieldsIds?.includes(field.id)) ?? []
  const hiddenFields = fields?.filter(field => !viewFieldsIds?.includes(field.id)) ?? []

  return (
    <div
      className="border p-3 w-80 min-w-80 bg-base-100 h-full overflow-y-auto z-[1]"
      style={{ scrollbarWidth: 'thin' }}
    >
      <div className="space-y-2">
        <div className="flex">
          <h3 className="font-bold grow">
            {t('Properties options')}
          </h3>
          <Button circle size="xs" onClick={() => onClose()}>
            <XIcon size={16} />
          </Button>
        </div>
        <div>
          <div className="text-xs italic">
            {t('Shown in table')}
          </div>
          {shownFields?.map(shownField => (
            <div key={shownField.id}>
              <div className='flex'>
                <div className="text-sm grow flex items-center">
                  <span className="mr-2">
                    {createElement(FIELD_ICONS_BY_TYPE[shownField.type], { size: 16 })}
                  </span>
                  {shownField.name}
                </div>
                <Button
                  size="xs"
                  square
                  ghost
                  onClick={() => controller.onHideField(shownField.id)}
                >
                  <EyeIcon className="w-4 h-4" />
                </Button>
              </div>
            </div>
          ))}
        </div>
        {hiddenFields?.length > 0 && (
          <div>
            <div className="text-xs italic">
              {t('Hidden in table')}
            </div>
            {hiddenFields?.map(hiddenField => (
              <div key={hiddenField.id}>
                <div className='flex'>
                  <div className="text-sm grow flex items-center">
                    <span className="mr-2">
                      {createElement(FIELD_ICONS_BY_TYPE[hiddenField.type], { className: 'w-4 h-4' })}
                    </span>
                    {hiddenField.name}
                  </div>
                  <Button
                    size="xs"
                    square
                    ghost
                    onClick={() => controller.onShowField(hiddenField.id, activeViewId!)}
                  >
                    <EyeOffIcon className="w-4 h-4" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div >
    </div >
  )
}
