import clsx from 'clsx'
import { DataTableCol } from '../DataTable'
import { DataTableHeadResizeHandler } from './DataTableHeadResizeHandler'
import { Skeleton } from '../../Skeleton'

export type DataTableColHeadProps<T> = {
  col?: DataTableCol<T>
  width?: number | string
  loading?: boolean
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, col: DataTableCol<T>) => void
  onDrag?: (col: DataTableCol<T>, change: number) => void
  onResize?: (col: DataTableCol<T>, change: number) => void
}

export function DataTableColHead<T> (props: DataTableColHeadProps<T>) {
  const { col, width = '100px', loading, onClick, onDrag, onResize } = props

  return (
    <div
      className={clsx(
        'relative',
        'box-border',
        'px-2',
        'border-r',
        'h-full',
        'flex',
        'gap-1.5',
        'items-center',
        !loading && onClick && 'cursor-pointer',
        !loading && onClick && 'hover:bg-base-200',
        !loading && onClick && 'active:bg-base-300'
      )}
      style={{ width, minWidth: width }}
      onClick={event => col && onClick?.(event, col)}
    >
      {loading && <Skeleton className="w-full h-3" />}
      {col && (
        <>
          <span className="text-gray-400">
            {col.icon}
          </span>
          <div className="text-gray-600 grow truncate">
            {col.label}
          </div>
          {col.endIcons?.map((icon, index) => (
            <span key={index} className="text-gray-400">
              {icon}
            </span>
          ))}
          <DataTableHeadResizeHandler
            col={col}
            onDrag={onDrag}
            onResize={onResize}
          />
        </>
      )}
    </div>
  )
}
