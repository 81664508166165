export function calculateTop (
  overlayRect: DOMRect | undefined,
  anchorRect: DOMRect | undefined,
  childRect: DOMRect | undefined,
  anchorOrigin: 'top' | 'center' | 'bottom' = 'top',
  transformOrigin: 'top' | 'center' | 'bottom' = 'top'
): number | undefined {
  if (!anchorRect || !childRect) {
    return undefined
  }

  let result = anchorRect.top - (overlayRect?.top ?? 0)

  if (anchorOrigin === 'center') {
    result += anchorRect.height / 2
  } else if (anchorOrigin === 'bottom') {
    result += anchorRect.height
  }

  if (transformOrigin === 'center') {
    result -= childRect.height / 2
  } else if (transformOrigin === 'bottom') {
    result -= childRect.height
  }

  return result
}

export function calculateLeft (
  overlayRect: DOMRect | undefined,
  anchorRect: DOMRect | undefined,
  childRect: DOMRect | undefined,
  anchorOrigin: 'left' | 'center' | 'right' = 'left',
  transformOrigin: 'left' | 'center' | 'right' = 'left'
): number | undefined {
  if (!anchorRect || !childRect) {
    return undefined
  }

  let result = anchorRect.left - (overlayRect?.left ?? 0)

  if (anchorOrigin === 'center') {
    result += anchorRect.width / 2
  } else if (anchorOrigin === 'right') {
    result += anchorRect.width
  }

  if (transformOrigin === 'center') {
    result -= childRect.width / 2
  } else if (transformOrigin === 'right') {
    result -= childRect.width
  }

  return result
}
