import { DateFieldData, DateRecordProperty } from '@indigohive/cogfy-types'
import clsx from 'clsx'
import { FormattedDate } from '../../../../components'
import { FieldCellProps } from '../../FieldCell'

export type DateCellProps = Pick<
FieldCellProps<DateRecordProperty, DateFieldData>,
'property' | 'field' | 'textWrap'
>

export function DateCell (props: DateCellProps) {
  const { field, textWrap, property } = props

  return (
    <span
      className={clsx(
        'px-2 py-1',
        textWrap && 'whitespace-pre-wrap',
        !textWrap && 'truncate'
      )}
    >
      <FormattedDate
        date={property?.date?.value}
        format={field.data?.date?.format ?? 'Pp'}
      />
    </span>
  )
}
