import { ReferenceRecordProperty, RunTransactionCommand, UUID } from '@indigohive/cogfy-types'
import { Command } from '../command-stack'
import { CollectionState } from '../collection-state'
import { v4 as uuid } from 'uuid'

export type UpdateRecordReferenceCommandData = {
  recordId: UUID
  fieldId: UUID
  recordReferenceIdToCreate: UUID
  recordReferenceIdToDelete: UUID
  title: string | null
}

type RecordReferenceToCreate = {
  recordReferenceId: UUID
  referencedRecordId: UUID
}

export class UpdateRecordReferenceCommand implements Command<RunTransactionCommand> {
  private readonly _state: CollectionState
  private readonly _data: UpdateRecordReferenceCommandData
  private readonly _recordIndex: number | undefined
  private readonly _recordReferenceToCreate: RecordReferenceToCreate
  private readonly _recordReferenceToDelete: NonNullable<ReferenceRecordProperty['reference']>['value'][number] | undefined
  private readonly _recordReferenceToDeleteIndex: number | undefined

  constructor (state: CollectionState, data: UpdateRecordReferenceCommandData) {
    this._state = state
    this._data = data
    this._recordIndex = state.records?.findIndex(record => record.id === data.recordId)
    this._recordReferenceToCreate = {
      recordReferenceId: uuid() as UUID,
      referencedRecordId: data.recordReferenceIdToCreate
    }
    this._recordReferenceToDeleteIndex = (state.records && this._recordIndex !== undefined)
      ? (state.records[this._recordIndex]
          ?.properties[data.fieldId] as ReferenceRecordProperty)
          ?.reference?.value
          ?.findIndex(reference => reference.id === data.recordReferenceIdToDelete)
      : undefined

    this._recordReferenceToDelete = (state.records && this._recordIndex !== undefined && this._recordReferenceToDeleteIndex !== undefined)
      ? (state.records[this._recordIndex]
          ?.properties[data.fieldId] as ReferenceRecordProperty)
          ?.reference?.value[this._recordReferenceToDeleteIndex]
      : undefined
  }

  run (): RunTransactionCommand {
    if (this._state.records === null || this._recordIndex === undefined || this._recordReferenceToDeleteIndex === undefined) {
      return { operations: [] }
    }

    const newRecords = [...this._state.records]
    let newRecordReferences = [...(newRecords[this._recordIndex].properties[this._data.fieldId] as ReferenceRecordProperty).reference?.value ?? []]
    newRecordReferences.splice(this._recordReferenceToDeleteIndex, 1)
    newRecordReferences = [
      {
        id: this._recordReferenceToCreate.recordReferenceId,
        referencedRecordId: this._recordReferenceToCreate.referencedRecordId,
        title: this._data.title
      }
    ]

    newRecords[this._recordIndex].properties[this._data.fieldId] = {
      type: 'reference',
      reference: {
        value: newRecordReferences
      }
    }

    this._state.setRecords(newRecords)
    if (this._state.clickedCell?.record.id === this._data.recordId && this._state.clickedCell?.field.id === this._data.fieldId) {
      const newClickedCell = { ...this._state.clickedCell }
      newClickedCell.record.properties[this._data.fieldId] = {
        type: 'reference',
        reference: {
          value: newRecordReferences
        }
      }

      this._state.setClickedCell(newClickedCell)
    }

    return {
      operations: [
        {
          type: 'delete_record_reference',
          data: {
            collectionId: this._state.id,
            recordReferenceId: this._data.recordReferenceIdToDelete
          }
        },
        {
          type: 'create_record_reference',
          data: {
            collectionId: this._state.id,
            fieldId: this._data.fieldId,
            recordId: this._data.recordId,
            recordReferenceId: this._recordReferenceToCreate.recordReferenceId,
            referencedRecordId: this._recordReferenceToCreate.referencedRecordId
          }
        }
      ]
    }
  }

  undo (): RunTransactionCommand {
    if (
      this._state.records === null ||
      this._recordIndex === undefined ||
      this._recordReferenceToDeleteIndex === undefined ||
      this._recordReferenceToDelete?.id === undefined ||
      this._recordReferenceToDelete?.referencedRecordId === undefined
    ) {
      return { operations: [] }
    }

    const newRecords = [...this._state.records]
    let newRecordReferences = [...(newRecords[this._recordIndex].properties[this._data.fieldId] as ReferenceRecordProperty).reference?.value ?? []]

    newRecordReferences = newRecordReferences.filter(reference => reference.id !== this._recordReferenceToCreate.recordReferenceId)

    newRecordReferences.splice(this._recordReferenceToDeleteIndex, 0, this._recordReferenceToDelete)

    newRecords[this._recordIndex].properties[this._data.fieldId] = {
      type: 'reference',
      reference: {
        value: newRecordReferences
      }
    }

    this._state.setRecords(newRecords)

    if (this._state.clickedCell?.record.id === this._data.recordId && this._state.clickedCell?.field.id === this._data.fieldId) {
      const newClickedCell = { ...this._state.clickedCell }
      newClickedCell.record.properties[this._data.fieldId] = {
        type: 'reference',
        reference: {
          value: newRecordReferences
        }
      }

      this._state.setClickedCell(newClickedCell)
    }

    return {
      operations: [
        {
          type: 'delete_record_reference',
          data: {
            collectionId: this._state.id,
            recordReferenceId: this._recordReferenceToCreate.recordReferenceId
          }
        },
        {
          type: 'create_record_reference',
          data: {
            collectionId: this._state.id,
            fieldId: this._data.fieldId,
            recordId: this._data.recordId,
            recordReferenceId: this._recordReferenceToDelete.id,
            referencedRecordId: this._recordReferenceToDelete.referencedRecordId
          }
        }
      ]
    }
  }
}
