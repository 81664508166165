import { SignInResult } from '@indigohive/cogfy-types'
import { createContext } from 'react'

export type AuthenticationContextValue = {
  authenticatedUser?: SignInResult | null
  onAuthenticatedUserChange?: (user: SignInResult | null) => void
  loading?: boolean
}

export const AuthenticationContext = createContext<AuthenticationContextValue>({})
