import { OptionItem } from './OptionItem'
import { SelectOption } from '../SelectConfigurations'
import { useTranslation } from 'react-i18next'
import { UUID } from '@indigohive/cogfy-types'

export type OptionsListProps = {
  options: SelectOption[]
  onDeleteClick?: (optionId: UUID) => void
  onDrop?: (updatedOptions: SelectOption[]) => void
  onEditClick?: (ref: HTMLDivElement | null, option: SelectOption) => void
}

export function OptionsList (props: OptionsListProps) {
  const { options, onDeleteClick, onDrop, onEditClick } = props

  const { t } = useTranslation()

  const handleDrop = (movedOption: SelectOption, hoveredOption: SelectOption) => {
    if (movedOption.id === hoveredOption.id) return

    const newOptions = [...options]
    const movedIndex = newOptions.findIndex(option => option.id === movedOption.id)
    const hoveredIndex = newOptions.findIndex(option => option.id === hoveredOption.id)

    if (movedIndex === -1 || hoveredIndex === -1) return

    newOptions.splice(movedIndex, 1)
    newOptions.splice(hoveredIndex, 0, movedOption)

    const updatedOptions = newOptions.map((option, index) => ({
      ...option,
      order: index
    }))

    onDrop?.(updatedOptions)
  }

  return (
    <>
      {options.length === 0 && (
        <span className="label-text py-1 px-2">{t('selectConfig:No option added to this field')}</span>
      )}
      <div className="flex flex-col justify-center">
        {options
          .sort((a, b) => a.order - b.order)
          .map(option => (
            <OptionItem
              key={option.id}
              option={option}
              handleDrop={handleDrop}
              onDeleteClick={() => onDeleteClick?.(option.id)}
              onEditClick={onEditClick}
            />
          ))
        }
      </div>
    </>
  )
}
