import { useState } from 'react'
import { ChatMiddlewareDataFormProps } from '../ChatMiddlewareDataFormProps'
import clsx from 'clsx'

export function DefaultForm (props: ChatMiddlewareDataFormProps) {
  const { data, onChange } = props
  const [json, setJson] = useState<string>(JSON.stringify(data, null, 2))
  const [error, setError] = useState(false)

  return (
    <>
      <textarea
        className={clsx(
          'w-full',
          'textarea',
          'textarea-bordered',
          'leading-5',
          'min-h-40',
          error && 'textarea-error'
        )}
        value={json}
        onChange={event => {
          setError(false)
          setJson(event.target.value)
          try {
            const parsed = JSON.parse(event.target.value)
            onChange(parsed)
          } catch {
            setError(true)
          }
        }}
      />
    </>
  )
}
