import {
  isEqualsFilter,
  isGreaterThanFilter,
  isGreaterThanOrEqualsFilter,
  isHasErrorFilter,
  isIsEmptyFilter,
  isIsPendingFilter,
  isLessThanFilter,
  isLessThanOrEqualsFilter,
  isNotEqualsFilter
} from '@indigohive/cogfy-common'
import { RecordFilter } from '@indigohive/cogfy-types'
import { CollectionStateField } from '../../../lib'

export function getFilterOperator (filter: RecordFilter, field: CollectionStateField) {
  if (['boolean', 'date', 'number', 'text', 'vector'].includes(field.type)) {
    if (isHasErrorFilter(filter)) {
      return `${filter.hasError ? 'hasError' : 'hasNoError'}`
    } else if (isIsPendingFilter(filter)) {
      return `${filter.isPending ? 'isPending' : 'notPending'}`
    }
  }

  if (['text', 'number', 'date', 'file', 'select'].includes(field.type)) {
    if (isIsEmptyFilter(filter)) {
      return `${filter.isEmpty ? 'isEmpty' : 'notEmpty'}`
    }
  }

  if (field.type === 'boolean') {
    if (isEqualsFilter(filter)) {
      return 'equals'
    } else if (isNotEqualsFilter(filter)) {
      return 'notEquals'
    }
  }

  if (field.type === 'date') {
    if (isEqualsFilter(filter)) {
      return 'equals'
    } else if (isLessThanFilter(filter)) {
      return 'lessThan'
    } else if (isGreaterThanFilter(filter)) {
      return 'greaterThan'
    } else if (isLessThanOrEqualsFilter(filter)) {
      return 'lessThanOrEquals'
    } else if (isGreaterThanOrEqualsFilter(filter)) {
      return 'greaterThanOrEquals'
    }
  }

  if (field.type === 'number') {
    if (isEqualsFilter(filter)) {
      return 'equals'
    } else if (isNotEqualsFilter(filter)) {
      return 'notEquals'
    } else if (isGreaterThanFilter(filter)) {
      return 'greaterThan'
    } else if (isGreaterThanOrEqualsFilter(filter)) {
      return 'greaterThanOrEquals'
    } else if (isLessThanFilter(filter)) {
      return 'lessThan'
    } else if (isLessThanOrEqualsFilter(filter)) {
      return 'lessThanOrEquals'
    }
  }

  if (field.type === 'text') {
    if (isEqualsFilter(filter)) {
      return 'equals'
    } else if (isNotEqualsFilter(filter)) {
      return 'notEquals'
    }
  }

  if (field.type === 'select') {
    if (isEqualsFilter(filter)) {
      return 'equals'
    } else if (isNotEqualsFilter(filter)) {
      return 'notEquals'
    }
  }

  return 'equals'
}
