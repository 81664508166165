import { UpdateDateField } from '@indigohive/cogfy-types'
import { Input } from '../../../components'
import { useSelectedFieldToUpdate } from '../../../lib'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { FieldConfigurationsProps } from '../FieldConfigurations'

export function UpdateDateConfigurations (props: FieldConfigurationsProps) {
  const { state, controller } = props

  const { t } = useTranslation()
  const field = useSelectedFieldToUpdate(state) as UpdateDateField
  const [format, setFormat] = useState(field.data?.updateDate?.format ?? '')

  useEffect(() => {
    setFormat(field.data?.updateDate?.format ?? '')
  }, [field.data])

  return (
    <Input
      label={t('Format')}
      size="sm"
      value={format}
      onChange={event => setFormat(event.target.value)}
      onBlur={_ => {
        controller.onUpdateFieldData(
          field.id,
          { updateDate: { ...field.data?.updateDate, format } }
        )
      }}
    />
  )
}
