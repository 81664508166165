import { useState } from 'react'
import { Button, Dialog, Input } from '../../../components'
import { useFormik } from 'formik'
import { useCogfy, useToasts } from '../../../hooks'
import { useTranslation } from 'react-i18next'
import { PlusIcon } from 'lucide-react'
import * as yup from 'yup'

export type CreateWorkspaceUserContactDialogProps = {
  onSuccess?: () => void
}

export function CreateWorkspaceUserContactDialog (props: CreateWorkspaceUserContactDialogProps) {
  const [open, setOpen] = useState(false)

  const { t } = useTranslation()
  const cogfy = useCogfy()
  const toasts = useToasts()
  const formik = useFormik({
    initialValues: {
      value: ''
    },
    validationSchema: yup.object().shape({
      value: yup.string().required(t('Required field'))
    }),
    onSubmit: async (values, helpers) => {
      try {
        await cogfy.createWorkspaceUserContact(values)
        props.onSuccess?.()
        setOpen(false)
        helpers.resetForm()
      } catch (error) {
        if (cogfy.isConflict(error)) {
          helpers.setFieldError('value', t('WorkspaceAssistantPage:Contact already exists'))
        } else {
          toasts.error(t('Unexpected error'))
        }
      }
    }
  })

  return (
    <>
      <Button
        size="sm"
        color="primary"
        onClick={() => setOpen(true)}
      >
        <PlusIcon size={16} />
        {t('assistantPage:Add contact')}
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          formik.setTouched({}, false)
          formik.resetForm()
          setOpen(false)
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Input
            label={t('editFieldDrawer:Phone number')}
            placeholder='+55 11 99999-9999'
            error={Boolean(formik.errors.value)}
            helperText={formik.errors.value}
            {...formik.getFieldProps('value')}
          />
          <div className="modal-action">
            <Button size="sm" type="submit" color="primary">
              {t('Confirm')}
            </Button>
          </div>
        </form>
      </Dialog>
    </>
  )
}
