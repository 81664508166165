import { PermissionActionName } from '@indigohive/cogfy-types'
import { FormikProps, useFormik } from 'formik'
import { Breadcrumbs, Button, Card, Container, Heading, Input, Navbar, Toggle } from '../../components'
import { useCogfy, usePermissions, useToasts, useWorkspaceSlug, useTitle } from '../../hooks'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ChatPermissionsCard, CollectionsPermissionsTable } from './components'

const permissionOptions: PermissionOption[] = [
  { action: 'api-key:create', label: 'Create API Key', subjectKey: null },
  { action: 'collection:create', label: 'Create collection', subjectKey: null },
  { action: 'collection:delete', label: 'Delete collection', subjectKey: '*' },
  { action: 'collection:read', label: 'Read all collections', subjectKey: '*' },
  { action: 'collection:update', label: 'Update all collections', subjectKey: '*' }
]

export type PermissionOption = {
  action: PermissionActionName
  subjectKey: string | null
  label?: string
}

export type FormValues = {
  name: string
  options: PermissionOption[]
}

export function CreateApiKeyPage () {
  const { t } = useTranslation()
  const cogfy = useCogfy()
  const workspaceSlug = useWorkspaceSlug()
  const navigate = useNavigate()
  const toasts = useToasts()
  const permissions = usePermissions()
  const formik: FormikProps<FormValues> = useFormik({
    initialValues: {
      name: '',
      options: [] as PermissionOption[]
    },
    onSubmit: async values => {
      try {
        const result = await cogfy.createApiKey({
          name: values.name || null,
          permissions: values.options.map(option => ({
            action: option.action,
            subjectKey: option.subjectKey
          }))
        })
        toasts.success(t('API Key created'))
        navigate(
          `/${workspaceSlug}/api-keys`,
          {
            state: {
              createdApiKey: result
            }
          }
        )
      } catch {
        window.alert(t('Error creating API Key'))
      }
    }
  })

  const canCreateApiKey = permissions.hasPermission?.('api-key:create', null)
  if (!canCreateApiKey) navigate(`/${workspaceSlug}`)

  useTitle({
    title: t('Create API Key')
  })

  return (
    <>
      <Navbar>
        <Breadcrumbs
          items={[
            { label: t('API Keys'), to: `/${workspaceSlug}/api-keys` },
            { label: t('Create API Key') }
          ]}
        />
      </Navbar>
      <Container maxWidth="sm" className="my-4">
        <div>
          <Heading className="mb-2">
            {t('Create API Key')}
          </Heading>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <Card compact>
            <div className="card-body">
              <Input
                label={t('Name')}
                {...formik.getFieldProps('name')}
              />
            </div>
          </Card>

          <Heading
            component="h2"
            className="mt-8 mb-2"
          >
            {t('createApiKeyPage:General permissions')}
          </Heading>
          <Card compact>
            <div className="flex flex-col gap-2 py-4 card-body">
              {permissionOptions.map(option => (
                <Toggle
                  key={option.action}
                  color="success"
                  label={`${t(option.label ?? '')} (${option.action})`}
                  checked={formik.values.options.includes(option)}
                  onChange={event => {
                    if (event.target.checked) {
                      formik.setFieldValue('options', [...formik.values.options, option])
                    } else {
                      formik.setFieldValue('options', formik.values.options.filter(a => a.action !== option.action))
                    }
                  }}
                />
              ))}
            </div>
          </Card>

          <ChatPermissionsCard formik={formik} />

          <Heading
            className="my-8 mb-2"
            component="h2"
          >
            {t('createApiKeyPage:Permissions by collections')}
          </Heading>
          <Card className="overflow-hidden">
            <CollectionsPermissionsTable formik={formik} />
          </Card>

          <div className="text-right mt-4">
            <Button type="submit" color="primary" className="w-full">
              {t('Confirm')}
            </Button>
          </div>
        </form>
      </Container>
    </>
  )
}
