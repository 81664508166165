import { CollectionState } from '../../../lib'
import { useEffect, useState } from 'react'

export function useSelectedField (state: CollectionState) {
  const [selectedField, setSelectedField] = useState(state.selectedField)

  useEffect(() => {
    const handleSelectedFieldChanged = () => setSelectedField(state.selectedField)

    state.addEventListener('selectedFieldChanged', handleSelectedFieldChanged)

    return () => state.removeEventListener('selectedFieldChanged', handleSelectedFieldChanged)
  }, [state])

  return selectedField
}
