const PHONE_NUMBER_REGEX_1 = /\+[0-9]{13}/
const PHONE_NUMBER_REGEX_2 = /\+[0-9]{12}/

export const formatPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.match(PHONE_NUMBER_REGEX_1)) {
    return phoneNumber.slice(0, 3) + ' ' + phoneNumber.slice(3, 5) + ' ' + phoneNumber.slice(5, 6) + ' ' + phoneNumber.slice(6, 10) + '-' + phoneNumber.slice(10)
  }

  if (phoneNumber.match(PHONE_NUMBER_REGEX_2)) {
    return phoneNumber.slice(0, 3) + ' ' + phoneNumber.slice(3, 5) + ' ' + phoneNumber.slice(5, 9) + '-' + phoneNumber.slice(9)
  }

  return phoneNumber
}
