import { RunTransactionCommand } from '@indigohive/cogfy-types'
import { Command } from '../command-stack'
import { CollectionState, CollectionStateViewField } from '../collection-state'

export type ReorderViewFieldBeforeCommandData = {
  viewField: CollectionStateViewField
  before: CollectionStateViewField
}

export class ReorderViewFieldBeforeCommand implements Command<RunTransactionCommand> {
  private readonly _state: CollectionState
  private readonly _data: ReorderViewFieldBeforeCommandData
  private readonly _previousIndex: number

  constructor (
    state: CollectionState,
    data: ReorderViewFieldBeforeCommandData
  ) {
    this._state = state
    this._data = data
    this._previousIndex = state.viewFields!.findIndex(
      viewField => viewField.id === data.viewField.id
    )
  }

  run (): RunTransactionCommand {
    const newViewFields = [...this._state.viewFields!]
    newViewFields.splice(this._previousIndex, 1)

    const newIndex = newViewFields.findIndex(viewField => viewField.id === this._data.before.id)
    newViewFields.splice(newIndex, 0, this._data.viewField)
    this._state.setViewFields(newViewFields)

    return {
      operations: [
        {
          type: 'reorder_view_field_before',
          data: {
            collectionId: this._state.id,
            viewFieldId: this._data.viewField.id,
            beforeId: this._data.before.id
          }
        }
      ]
    }
  }

  undo (): RunTransactionCommand {
    const newViewFields = [...this._state.viewFields!]
    const currentIndex = newViewFields.findIndex(viewField => viewField.id === this._data.viewField.id)
    newViewFields.splice(currentIndex, 1)
    newViewFields.splice(this._previousIndex, 0, this._data.viewField)
    this._state.setViewFields(newViewFields)

    if (this._previousIndex === 0) {
      return {
        operations: [
          {
            type: 'reorder_view_field_before',
            data: {
              collectionId: this._state.id,
              viewFieldId: this._data.viewField.id,
              beforeId: newViewFields[1].id
            }
          }
        ]
      }
    } else {
      return {
        operations: [
          {
            type: 'reorder_view_field_after',
            data: {
              collectionId: this._state.id,
              viewFieldId: this._data.viewField.id,
              afterId: newViewFields[this._previousIndex - 1].id
            }
          }
        ]
      }
    }
  }
}
