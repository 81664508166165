import { ReactNode, createContext } from 'react'

export type ToastsContextValue = {
  info: (message: ReactNode) => void
  success: (message: ReactNode) => void
  warning: (message: ReactNode) => void
  error: (message: ReactNode) => void
}

export const ToastsContext = createContext<ToastsContextValue>({
  info: () => {},
  success: () => {},
  warning: () => {},
  error: () => {}
})
