import { useTranslation } from 'react-i18next'
import { useCollections } from '../../../../hooks'
import clsx from 'clsx'
import { FormikProps } from 'formik'
import { FormValues } from '../../CreateApiKeyPage'
import { PermissionActionName } from '@indigohive/cogfy-types'

export type CollectionsPermissionsTableProps = {
  formik: FormikProps<FormValues>
}

export function CollectionsPermissionsTable (props: CollectionsPermissionsTableProps) {
  const { formik } = props

  const getCollectionsPage = useCollections()
  const { t } = useTranslation()

  const permissionSelected = (action: PermissionActionName, subjectKey: string) => {
    return formik.values.options.some(permission =>
      permission.action === action &&
      permission.subjectKey === subjectKey) ?? false
  }

  const onHandlePermision = (checked: boolean, action: PermissionActionName, subjectKey: string) => {
    if (checked) {
      const newPermission = {
        action,
        subjectKey
      }
      formik.setFieldValue('options', [...formik.values.options, newPermission])
    } else {
      const newPermissions = formik.values.options.filter(a => !(a.action === action && a.subjectKey === subjectKey))
      formik.setFieldValue('options', newPermissions)
    }
  }

  return (
    <>
      {getCollectionsPage.isLoading && (
        <progress className="progress w-full" />
      )}
      {getCollectionsPage.data && (
        <>
          <div className="overflow-y-auto max-h-72">
            <table className="table table-pin-cols">
              <thead>
                <tr>
                  <th>{t('Collection')}</th>
                  <th>{t('Read')}</th>
                  <th>{t('Update')}</th>
                </tr>
              </thead>
              <tbody>
                {getCollectionsPage.data.data.map(collection => (
                  <tr key={collection.id}>
                    <td className={clsx(!collection.name && 'text-gray-300')}>
                      {collection.name ?? t('Untitled')}
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="checkbox checkbox-sm checked:checkbox-info"
                        onChange={event => onHandlePermision(event.target.checked, 'collection:read', collection.id)}
                        checked={permissionSelected('collection:read', '*') || permissionSelected('collection:read', collection.id)}
                        disabled={permissionSelected('collection:read', '*')}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        className="checkbox checkbox-sm checked:checkbox-info"
                        onChange={event => onHandlePermision(event.target.checked, 'collection:update', collection.id)}
                        checked={permissionSelected('collection:update', '*') || permissionSelected('collection:update', collection.id)}
                        disabled={permissionSelected('collection:update', '*')}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  )
}
