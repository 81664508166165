import { ScheduleField, ScheduleFieldData, UUID } from '@indigohive/cogfy-types'
import { useEffect, useState } from 'react'
import { Select } from '../../../../components'
import { useFields, useSelectedFieldToUpdate } from '../../../../lib'
import { FieldConfigurationsProps } from '../../FieldConfigurations'

export function ScheduleConfigurations (props: FieldConfigurationsProps) {
  const { state, controller } = props

  const field = useSelectedFieldToUpdate(state) as ScheduleField
  const [recalculateFieldId, setRecalculateFieldId] = useState(field.data?.schedule?.recalculateField?.id ?? '')

  const fields = useFields(state) ?? []

  useEffect(() => {
    setRecalculateFieldId(field.data?.schedule?.recalculateField?.id ?? '')
  }, [field.data])

  return (
    <Select
      label="Field to recalculate"
      size="sm"
      options={[
        { label: 'None', value: '' },
        ...fields.map(field => ({
          label: field.name,
          value: field.id
        }))
      ]}
      value={recalculateFieldId}
      onChange={event => {
        if (event.target.value) {
          const newData: ScheduleFieldData = {
            schedule: {
              recalculateField: {
                id: event.target.value as UUID
              }
            }
          }
          setRecalculateFieldId(event.target.value)
          controller.onUpdateFieldData(field.id, newData)
        } else {
          setRecalculateFieldId('')
          controller.onUpdateFieldData(field.id, { schedule: {} })
        }
      }}
    />
  )
}
