import { ScheduleRecordProperty } from '@indigohive/cogfy-types'
import { FieldCellProps } from '../../FieldCell'
import { FormattedDate } from '../../../../components'

export type ScheduleCellProps = Pick<FieldCellProps<ScheduleRecordProperty>, 'property'>

export function ScheduleCell (props: ScheduleCellProps) {
  const { property } = props

  const value = property?.schedule?.value

  return (
    <div className="flex gap-2 items-center w-full px-2 py-1">
      <div className="grow">
        {value && (
          <>
            Next at <FormattedDate date={property?.schedule?.value?.nextDate} format="Pp" />
          </>
        )}
      </div>
      <div className="opacity-70 text-xs">
        {value?.unit === 'hours' && value.interval === 1 && 'Every hour'}
        {value?.unit === 'hours' && value.interval > 1 && `Every ${value.interval} hours`}
        {value?.unit === 'days' && value.interval === 1 && 'Every day'}
        {value?.unit === 'days' && value.interval > 1 && `Every ${value.interval} days`}
        {value?.unit === 'weeks' && value.interval === 1 && 'Every week'}
        {value?.unit === 'weeks' && value.interval > 1 && `Every ${value.interval} weeks`}
        {value?.unit === 'months' && value.interval === 1 && 'Every month'}
        {value?.unit === 'months' && value.interval > 1 && `Every ${value.interval} months`}
      </div>
    </div>
  )
}
