import { useState } from 'react'
import { Select } from '../../Select'
import { ChatMiddlewareDataFormProps } from '../ChatMiddlewareDataFormProps'
import { useTranslation } from 'react-i18next'

const inputOptions = [
  { label: 'Select a transform type', value: '', disabled: true },
  { label: 'Transcribe', value: 'transcribeAudio' },
  { label: 'Generate audio', value: 'generateAudio' }
]

export function TransformForm (props: ChatMiddlewareDataFormProps) {
  const { data, onChange } = props
  const { t } = useTranslation('editFieldDrawer')

  const [transformType, setTransformType] = useState(data?.name ?? '')

  const options = inputOptions.map(option => ({
    ...option,
    label: t(option.label)
  }))

  return (
    <Select
      label={t('Transform type')}
      size="sm"
      options={options}
      value={transformType}
      onChange={event => {
        setTransformType(event.target.value)
        onChange({
          ...data,
          name: event.target.value
        })
      }}
    />
  )
}
