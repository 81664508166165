import { MemberRole } from '@indigohive/cogfy-types'
import { Select } from '../../../../components'
import { useTranslation } from 'react-i18next'

export type MemberRoleSelectProps = {
  onChange: (value: MemberRole | undefined) => void
  selectedRole: MemberRole | undefined
}

export function MemberRoleSelect (props: MemberRoleSelectProps) {
  const { selectedRole, onChange } = props
  const { t } = useTranslation()

  const handleChange = (value: MemberRole | 'all') => {
    if (value === 'all') {
      onChange(undefined)
    } else {
      onChange(value)
    }
  }

  const options: { value: MemberRole | 'all', label: string }[] = [
    { value: 'all', label: t('Todos') },
    { value: 'admin', label: 'Admin' },
    { value: 'member', label: t('Member') }
  ]

  return (
    <Select
      size="sm"
      className="border-none"
      options={options}
      value={selectedRole}
      onChange={event => handleChange(event.target.value as MemberRole | 'all')}
    />
  )
}
