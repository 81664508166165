import { PropsWithChildren } from 'react'
import { Outlet } from 'react-router-dom'
import { SettingsDrawer } from './subcomponents/SettingsDrawer'

export type SettingsLayoutProps = PropsWithChildren

export function SettingsLayout (props: SettingsLayoutProps) {
  return (
    <SettingsDrawer>
      <div
        className="w-full min-h-screen overflow-auto"
        style={{ scrollbarWidth: 'thin' }}
      >
        <Outlet />
        {props.children}
      </div>
    </SettingsDrawer>
  )
}
