import { FieldData, RunTransactionCommand, UUID } from '@indigohive/cogfy-types'
import { Command } from '../command-stack'
import { CollectionState, CollectionStateField } from '../collection-state'

export type UpdateFieldDataCommandData = {
  fieldId: UUID
  data: FieldData
}

export class UpdateFieldDataCommand implements Command<RunTransactionCommand> {
  private readonly _state: CollectionState
  private readonly _data: UpdateFieldDataCommandData
  private readonly _previousData: FieldData | null

  constructor (
    state: CollectionState,
    data: UpdateFieldDataCommandData
  ) {
    this._state = state
    this._data = data
    this._previousData = this._state.fields?.find(field => field.id === this._data.fieldId)?.data ?? null
  }

  run (): RunTransactionCommand {
    const newData = { ...this._previousData, ...this._data.data }
    const newFields = this._state.fields?.map(field => {
      if (field.id === this._data.fieldId) {
        field.data = newData
      }

      return field
    })
    const newSelectedFieldToUpdate: CollectionStateField = {
      ...this._state.selectedFieldToUpdate!,
      data: { ...newData }
    }

    this._state.setSelectedFieldToUpdate(newSelectedFieldToUpdate)
    this._state.setFields(newFields ?? [])

    return {
      operations: [
        {
          type: 'update_field_data',
          data: {
            collectionId: this._state.id,
            fieldId: this._data.fieldId,
            data: this._data.data
          }
        }
      ]
    }
  }

  undo (): RunTransactionCommand {
    const previousData = { ...this._previousData }
    const newFields = this._state.fields?.map(field => {
      if (field.id === this._data.fieldId) {
        field.data = previousData
      }

      return field
    })
    const newSelectedFieldToUpdate: CollectionStateField = {
      ...this._state.selectedFieldToUpdate!,
      data: { ...previousData }
    }

    this._state.setSelectedFieldToUpdate(newSelectedFieldToUpdate)
    this._state.setFields(newFields ?? [])

    return {
      operations: [
        {
          type: 'update_field_data',
          data: {
            collectionId: this._state.id,
            fieldId: this._data.fieldId,
            data: this._previousData ?? {}
          }
        }
      ]
    }
  }
}
