import { GetCollectionFieldsResultData } from '@indigohive/cogfy-types'
import { MoveDownIcon, MoveUpIcon, EyeIcon, EyeOffIcon } from 'lucide-react'
import { Menu } from '../../../components'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

export type RecordPageFieldMenuProps = {
  field: GetCollectionFieldsResultData | null
  moveUpDisabled?: boolean
  moveDownDisabled?: boolean
  onHideClick?: () => void
  onShowClick?: () => void
  onMoveUpClick?: () => void
  onMoveDownClick?: () => void
  hasFullAccess?: boolean
}

export function RecordPageFieldMenu (props: RecordPageFieldMenuProps) {
  const {
    field,
    onHideClick,
    onShowClick,
    onMoveUpClick,
    onMoveDownClick,
    moveUpDisabled,
    moveDownDisabled,
    hasFullAccess
  } = props
  const { t } = useTranslation()

  return (
    <Menu>
      {field?.visibility === 'hidden' && (
        <li
          onClick={() => hasFullAccess && onShowClick?.()}
          className={clsx(!hasFullAccess && 'disabled')}
        >
          <a>
            <EyeIcon size={16} />
            {t('RecordPage:Show field')}
          </a>
        </li>
      )}
      {field?.visibility === 'visible' && (
        <li
          onClick={() => hasFullAccess && onHideClick?.()}
          className={clsx(!hasFullAccess && 'disabled')}
        >
          <a>
            <EyeOffIcon size={16} />
            {t('RecordPage:Hide field')}
          </a>
        </li>
      )}
      <li
        onClick={() => (hasFullAccess && !moveUpDisabled) && onMoveUpClick?.()}
        className={clsx((!hasFullAccess || moveUpDisabled) && 'disabled')}
      >
        <a>
          <MoveUpIcon size={16} />
          {t('RecordPage:Move up')}
        </a>
      </li>
      <li
        onClick={() => (hasFullAccess && !moveDownDisabled) && onMoveDownClick?.()}
        className={clsx((!hasFullAccess || moveDownDisabled) && 'disabled')}
      >
        <a>
          <MoveDownIcon size={16} />
          {t('RecordPage:Move down')}
        </a>
      </li>
    </Menu>
  )
}
