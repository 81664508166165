import type {
  FieldType,
  RecordProperty,
  RunTransactionCommand,
  RunTransactionCommandOperation,
  UUID
} from '@indigohive/cogfy-types'
import { v4 as uuid } from 'uuid'
import { Command } from '../command-stack'
import { CollectionState, CollectionStateRecord } from '../collection-state'
import { parseFieldValues } from '../../field-types'

const allowedTypes = new Set<FieldType>([
  'boolean',
  'json',
  'number',
  'text'
])

export class PasteRecordsCommand implements Command<RunTransactionCommand> {
  private readonly state: CollectionState

  private readonly originalRecords: CollectionStateRecord[]

  private readonly recordsToUpdate: {
    id: UUID
    properties: Record<UUID, RecordProperty | undefined>
  }[]

  private readonly recordsToCreate: {
    id: UUID
    properties: Record<UUID, RecordProperty | undefined>
  }[]

  private readonly startRow: number

  constructor (
    state: CollectionState,
    clipboardData: DataTransfer
  ) {
    this.state = state

    this.recordsToCreate = []

    const rawValues = getRawValues(clipboardData)
    const cols = rawValues[0]?.length ?? 0
    const startCol = state.selectedCell?.col ?? 0
    const endCol = startCol + cols
    const fields = state.viewFields!
      .slice(startCol, endCol)
      .map(viewField => state.fieldsById![viewField.fieldId])
    const parsedValues = parseFieldValues(rawValues, fields)

    this.startRow = state.selectedCell!.row

    this.originalRecords = state.records!
      .slice(this.startRow, this.startRow + rawValues.length)

    this.recordsToUpdate = state.records!
      .slice(this.startRow, this.startRow + rawValues.length)
      .map((record, index) => ({
        id: record.id,
        properties: Object.fromEntries(parsedValues[index].map((p, index) => [fields[index]?.id, p]))
      }))

    this.recordsToCreate = parsedValues
      .slice(this.recordsToUpdate.length)
      .map(values => ({
        id: uuid() as UUID,
        properties: Object.fromEntries(values.map((p, index) => [fields[index].id, p]))
      }))
  }

  run (): RunTransactionCommand {
    const newRecords = [...this.state.records!]

    for (let i = 0; i < this.recordsToUpdate.length; i++) {
      newRecords[this.startRow + i] = {
        id: this.recordsToUpdate[i].id,
        properties: {
          ...newRecords[this.startRow + i].properties,
          ...this.recordsToUpdate[i].properties
        }
      }
    }

    newRecords.push(...this.recordsToCreate.map(record => ({
      id: record.id,
      properties: record.properties
    })))

    this.state.setRecords(newRecords)

    return {
      operations: [
        ...this.recordsToUpdate.map<RunTransactionCommandOperation>(record => ({
          type: 'update_record_properties',
          data: {
            collectionId: this.state.id,
            recordId: record.id,
            properties: record.properties
          }
        })),
        ...this.recordsToCreate.map<RunTransactionCommandOperation[]>(record => ([
          {
            type: 'create_record',
            data: {
              collectionId: this.state.id,
              recordId: record.id
            }
          },
          {
            type: 'update_record_properties',
            data: {
              collectionId: this.state.id,
              recordId: record.id,
              properties: record.properties
            }
          }
        ])).flat()
      ]
    }
  }

  undo (): RunTransactionCommand {
    const newRecords = this.state.records!.slice(
      0, this.state.records!.length - this.recordsToCreate.length)

    for (let i = 0; i < this.recordsToUpdate.length; i++) {
      newRecords[this.startRow + i] = {
        id: this.recordsToUpdate[i].id,
        properties: {
          ...this.originalRecords[i].properties
        }
      }
    }

    this.state.setRecords(newRecords)

    return {
      operations: [
        ...this.originalRecords
          .map<RunTransactionCommandOperation>(record => ({
          type: 'update_record_properties',
          data: {
            collectionId: this.state.id,
            recordId: record.id,
            properties: Object.fromEntries(
              Object.entries(record.properties)
                .filter(entry => allowedTypes.has(this.state.fieldsById![entry[0] as UUID].type))
                .map(entry => ([entry[0], entry[1]]))
            ) as Record<UUID, RecordProperty | undefined>
          }
        })),
        {
          type: 'hard_delete_records',
          data: {
            collectionId: this.state.id,
            recordIds: this.recordsToCreate.map(record => record.id)
          }
        }
      ]
    }
  }
}

function getRawValues (clipboardData: DataTransfer): string[][] {
  const formats = clipboardData.types

  const values: string[][] = []

  if (formats.includes('text/html')) {
    const html = clipboardData.getData('text/html')
    const div = document.createElement('div')
    div.innerHTML = html ?? ''

    const table = div.querySelector('table')

    if (!table) {
      return values
    }

    const tableRows = Array.from(table?.querySelectorAll('tr'))

    for (const tr of tableRows) {
      const row: string[] = []
      values.push(row)

      const tableCells = Array.from(tr.querySelectorAll('td'))

      for (const td of tableCells) {
        row.push(td.innerText)
      }
    }
  }

  return values
}
