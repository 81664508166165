import type {
  FieldType,
  OperationInputType,
  OperationSchemaInput,
  UUID
} from '@indigohive/cogfy-types'
import { CollectionState, useSelectedFieldToUpdate } from '../../../../../lib'
import { CollectionPageController } from '../../../collection-page-controller'
import { useEffect, useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { Mentions, MentionsValue } from '../../../../../components/Mentions'

export type FieldOperationTextInputProps = {
  controller: CollectionPageController
  state: CollectionState
  inputName: string
  input: OperationSchemaInput<OperationInputType>
  fields: { id: UUID, name: string, type: FieldType }[]
}

export function FieldOperationTextInput (props: FieldOperationTextInputProps) {
  const { controller, state, inputName, input, fields } = props
  const [value, setValue] = useState<MentionsValue>({
    parts: [],
    options: []
  })
  const selectedField = useSelectedFieldToUpdate(state)
  const operationConfigInput = selectedField?.operationConfig?.[inputName]

  useEffect(() => {
    if (operationConfigInput?.type === 'text') {
      setValue({
        parts: [String(operationConfigInput.text.value) ?? (input.default as string | undefined) ?? ''],
        options: []
      })
    } else if (operationConfigInput?.type === 'template') {
      setValue({
        parts: operationConfigInput.template.strings,
        options: operationConfigInput.template.args.map(arg => ({
          id: arg.fieldId,
          name: fields.find(field => field.id === arg.fieldId)?.name ?? '???'
        }))
      })
    } else {
      setValue({
        parts: [],
        options: []
      })
    }
  }, [operationConfigInput])

  const onChange = useDebouncedCallback((newValue: MentionsValue) => {
    controller.onOperationChange(
      selectedField!.id,
      selectedField?.operation ?? null,
      selectedField!.recalculateStrategy,
      {
        ...selectedField?.operationConfig,
        [inputName]: {
          type: 'template',
          template: {
            strings: newValue.parts,
            args: newValue.options.map(option => ({ fieldId: option.id as UUID }))
          }
        }
      }
    )
  }, 300)

  const options = useMemo(() => {
    return fields
      .filter(field =>
        field.type === 'text' ||
        field.type === 'json' ||
        field.type === 'number' ||
        field.type === 'reference' ||
        field.type === 'boolean' ||
        field.type === 'select'
      )
      .filter(field => field.id !== selectedField?.id)
      .map(field => ({ id: field.id, name: field.name }))
  }, fields)

  return (
    <Mentions
      error={input.required && !input.default && value.parts.length === 1 && !value.parts[0]}
      placeholder={input.default as string | undefined}
      options={options}
      value={value}
      onChange={value => {
        setValue(value)
        onChange(value)
      }}
    />
  )
}
