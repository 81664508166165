import { SearchIcon } from 'lucide-react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

export type DrawerSearchCollectionsInputProps = {
  searchCollectionName: string
  onCollectionNameChange?: (collectionName: string) => void
}

export function DrawerSearchCollectionsInput (props: DrawerSearchCollectionsInputProps) {
  const { searchCollectionName, onCollectionNameChange } = props
  const { t } = useTranslation()

  return (
    <label
      className={clsx(
        'input',
        'input-bordered',
        'flex',
        'items-center',
        'gap-2',
        'h-11',
        'font-semibold',
        'text-sm',
        searchCollectionName ? 'drawer-search-collections-input-active' : 'drawer-search-collections-input-inactive'
      )}
      style={{ outline: 'unset' }}
    >
      <SearchIcon size={16} className="text-[#1C569A]" />
      <input
        type="text"
        className="grow"
        placeholder={t('Search')}
        value={searchCollectionName}
        onChange={event => onCollectionNameChange?.(event.target.value)}
      />
    </label>
  )
}
