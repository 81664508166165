import { ChatRetrievalSource } from '@indigohive/cogfy-types/src/chat-messages/chat-retrieval-source'
import { Button } from '../../../components'
import { Link } from 'react-router-dom'

export type ChatMessageSourceProps = {
  workspace: string
  order: number
  source: ChatRetrievalSource
  showFile?: (data: { fileId: string, pageNumber: number }) => void
}

export function ChatMessageSource (props: ChatMessageSourceProps) {
  const { workspace, order, source, showFile } = props

  return (
    <div className="dropdown dropdown-hover dropdown-top dropdown-start">
      <div tabIndex={0} role="button">
        <Button
          size="xs"
          circle
          className="bg-primary bg-opacity-5 text-primary border-primary border-opacity-30 hover:border-primary hover:border-opacity-30 mt-2 mr-2"
        >
          {order}
        </Button>
      </div>
      <div
        tabIndex={0}
        className="dropdown-content menu p-4 shadow bg-base-100 rounded-box text-wrap max-h-40 overflow-y-auto w-80 overflow-x-hidden"
      >
        <div className="flex flex-col">
          <span className="text-slate-600 w-full break-words max-w-72">
            {source.type === 'embedding' && source.embedding.content}
            {source.type === 'record' && source.record.content}
          </span>
          <div className="flex pt-2 gap-2 items-center">
            {source.type === 'record' && (
              <Link
                className="link break-words max-w-72"
                to={`/${workspace}/${source.record.collectionId}/${source.record.id}`}
              >
                {source.record.title}
              </Link>
            )}

            {source.type === 'embedding' && (
              <>
                {!source.embedding.fileId && (
                  <Link
                    className="link"
                    to={`/${workspace}/${source.embedding.collectionId}/${source.embedding.recordId}`}
                  >
                    {source.embedding.content}
                  </Link>
                )}

                {source.embedding.fileId && (
                  <a
                    className="link w-full truncate pt-2"
                    onClick={event => {
                      event.preventDefault()
                      showFile?.({ fileId: source.embedding.fileId!, pageNumber: source.embedding.pageNumber ?? 0 })
                    }}
                  >
                    {source.embedding.fileName}
                  </a>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
