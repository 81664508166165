import { DateRecordProperty } from '@indigohive/cogfy-types'
import { FieldOverlayProps } from '../FieldOverlay'
import { format } from 'date-fns'

export function DateOverlay (props: FieldOverlayProps) {
  const { field, record, controller } = props

  const recordProperty = record.properties[field.id] as DateRecordProperty | undefined

  return (
    <input
      type="datetime-local"
      className="input input-sm input-bordered"
      onFocus={event => {
        if (recordProperty?.date?.value) {
          event.currentTarget.value = format(new Date(recordProperty.date.value), "yyyy-MM-dd'T'HH:mm")
        }
      }}
      onBlur={event => {
        const currentValue = recordProperty?.date?.value
        const newValue = event.currentTarget.value
          ? new Date(event.currentTarget.value).toISOString()
          : undefined

        if (currentValue !== newValue) {
          controller.onUpdateRecordProperties(
            record.id,
            {
              [field.id]: newValue === undefined
                ? undefined
                : { type: 'date', date: { value: newValue } }
            }
          )
        }
        props.onClose('blur')
      }}
      autoFocus
    />
  )
}
