import { PlusIcon, TrashIcon } from 'lucide-react'
import type {
  FieldOperationConfigFieldsInput,
  FieldType,
  OperationInputType,
  OperationSchemaInput,
  UUID
} from '@indigohive/cogfy-types'
import { useTranslation } from 'react-i18next'
import { Button, Select } from '../../../../../components'
import { CollectionPageController } from '../../../collection-page-controller'
import { CollectionState, useSelectedFieldToUpdate } from '../../../../../lib'

export type FieldOperationFieldsInputValue = {
  id: UUID
  name: string
  type: FieldType
}

export type FieldOperationFieldsInputProps = {
  controller: CollectionPageController
  state: CollectionState
  inputName: string
  input: OperationSchemaInput<OperationInputType>
  fields: FieldOperationFieldsInputValue[]
  type?: FieldType
}

export function FieldOperationFieldsInput (props: FieldOperationFieldsInputProps) {
  const { controller, state, input, inputName, fields, type } = props
  const { t } = useTranslation()
  const selectedField = useSelectedFieldToUpdate(state)

  const options = [
    { value: '', label: t('Select a field'), disabled: true },
    ...fields
      .filter(option => !props.type || option.type === type)
      .filter(option => selectedField?.operation === 'compress-image' || selectedField?.id !== option.id)
      .filter(option => selectedField?.operation === 'resize-image' || selectedField?.id !== option.id)
      .map(field => ({ label: field.name, value: field.id }))
  ]

  const configInput = selectedField?.operationConfig?.[inputName] as FieldOperationConfigFieldsInput | undefined
  const values = configInput?.fields.value ?? []
  const multiple = Boolean(input.multiple)

  if (!multiple && values.length === 0) {
    values.push({})
  }

  return (
    <div>
      {values?.map((value, index) => (
        <div key={index} className="flex gap-2 items-center">
          <Select
            className="my-1"
            size="sm"
            value={value.id ?? ''}
            onChange={event => {
              const newValues = [...values]
              if (event.target.value) {
                newValues[index] = { id: event.target.value as UUID }
              } else {
                newValues[index] = {}
              }
              controller.onOperationChange(
                selectedField!.id,
                selectedField!.operation ?? null,
                selectedField!.recalculateStrategy,
                {
                  ...selectedField!.operationConfig,
                  [inputName]: { type: 'fields', fields: { value: newValues } }
                }
              )
            }}
            options={options}
          />
          {multiple && (
            <Button
              square
              ghost
              size="sm"
              onClick={() => {
                const newValues = [...values]
                newValues.splice(index, 1)
                controller.onOperationChange(
                  selectedField!.id,
                  selectedField!.operation ?? null,
                  selectedField!.recalculateStrategy,
                  {
                    ...selectedField!.operationConfig,
                    [inputName]: { type: 'fields', fields: { value: newValues } }
                  }
                )
              }}
            >
              <TrashIcon size={16} />
            </Button>
          )}
        </div>
      ))}
      {multiple && (
        <Button
          className="mt-1"
          size="xs"
          onClick={() => {
            const newValues = values ? [...values] : []
            newValues.push({})
            controller.onOperationChange(
              selectedField!.id,
              selectedField!.operation ?? null,
              selectedField!.recalculateStrategy,
              {
                ...selectedField!.operationConfig,
                [inputName]: { type: 'fields', fields: { value: newValues } }
              }
            )
          }}
        >
          <PlusIcon size={16} />
          {t('Add field')}
        </Button>
      )}
    </div>
  )
}
