import { useState } from 'react'
import { Select } from '../../../Select'
import { ChatMiddlewareDataFormProps } from '../../ChatMiddlewareDataFormProps'
import { useCollectionFields } from '../../../../hooks'

export function ToolFormGetGoogleAuthUrl (props: ChatMiddlewareDataFormProps) {
  const { collectionId, data, onChange } = props

  const [fieldId, setFieldId] = useState(data.method?.config?.field?.id ?? '')
  const getCollectionFields = useCollectionFields(undefined, collectionId)
  const fields = getCollectionFields.data?.data.filter(f => f.type === 'connection') ?? []

  const handleConfigChange = (key: string, value: any) => {
    const updatedConfig = { ...data.method?.config, [key]: value }
    onChange({ ...data, method: { ...data.method, config: updatedConfig } })
  }

  return (
    <>
      <Select
        label="Field"
        size="sm"
        options={[
          { label: 'Select a field', value: '', disabled: true },
          ...fields.map(field => ({ label: field.name, value: field.id }))
        ]}
        value={fieldId}
        onChange={event => {
          const value = event.target.value
          setFieldId(value)
          handleConfigChange('field', { id: value })
        }}
      />
    </>
  )
}
