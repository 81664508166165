import { ChatField, UUID } from '@indigohive/cogfy-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'
import { Mentions, MentionsValue, ToolTip } from '../../../../../components'
import { CollectionState, useFields, useSelectedFieldToUpdate } from '../../../../../lib'
import { CollectionPageController } from '../../../../../pages/CollectionPageV2/collection-page-controller'
import { ChatConfigurationsValues } from '..'

const defaultInitialMessage = 'Hello! How may I help you?'

export type ChatConfigurationsBaseProps = {
  controller: CollectionPageController
  state: CollectionState
  values: ChatConfigurationsValues
  onChange: (prop: keyof ChatConfigurationsValues, value: any) => void
}

export function ChatConfigurationsBase (props: ChatConfigurationsBaseProps) {
  const { controller, state, values, onChange } = props

  const { t } = useTranslation()
  const selectedField = useSelectedFieldToUpdate(state) as ChatField
  const fields = useFields(state)

  const onChangeInitialMessage = useDebouncedCallback((newValue: MentionsValue) => {
    controller.onUpdateFieldData(
      selectedField.id,
      {
        chat: {
          ...selectedField?.data?.chat,
          initialMessage: {
            type: 'template',
            template: {
              strings: newValue.parts,
              args: newValue.options.map(option => ({ fieldId: option.id as UUID }))
            }
          }
        }
      }
    )
  }, 300)

  const mentionOptions = useMemo(() => {
    return fields!
      .filter(field => field.type === 'text' || field.type === 'json' || field.type === 'number')
      .filter(field => field.id !== selectedField?.id)
      .map(field => ({ id: field.id, name: field.name }))
  }, fields!)

  const initialMessage = values.initialMessage

  return (
    <label className="form-control w-full">
      <div className='flex items-center'>
        <span className="label-text py-1">{t('editFieldDrawer:Set custom initial message')}</span>
        <ToolTip
          description={t('ToolTipDescription:Initial message shown in chat')}
          type="info"
        />
      </div>
      <Mentions
        placeholder={defaultInitialMessage}
        options={mentionOptions}
        value={initialMessage}
        onChange={value => {
          onChange('initialMessage', value)
          onChangeInitialMessage(value)
        }}
      />
    </label>
  )
}
