import { GetChatResult } from '@indigohive/cogfy-types'
import clsx from 'clsx'
import { MessagesSquareIcon, ChevronLeftIcon } from 'lucide-react'
import { Link } from 'react-router-dom'
import { siWhatsapp } from 'simple-icons'
import { Breadcrumbs, FormattedDate, Navbar, SimpleIcon, Skeleton } from '../../../components'
import { formatPhoneNumber } from '../../../helpers'

export type ChatPageNavbarProps = {
  workspaceSlug: string
  chat?: GetChatResult
  loading?: boolean
}

export function ChatPageNavbar (props: ChatPageNavbarProps) {
  const { workspaceSlug, loading, chat } = props

  const collectionId = chat?.collectionId
  const isWhatsApp = chat?.provider === 'whatsapp'

  return (
    <Navbar>
      <div>
        {collectionId && (
          <Link
            to={`/${workspaceSlug}/${collectionId}`}
          >
            <ChevronLeftIcon size={20} />
          </Link>
        )}
        <div
          className={clsx(
            'mx-2',
            'rounded-full',
            'w-8',
            'h-8',
            'flex',
            'justify-center',
            'items-center',
            isWhatsApp ? 'bg-[#E5FBE5]' : 'bg-[#E5F2FB]'
          )}
        >
          {isWhatsApp ? <SimpleIcon icon={siWhatsapp} size={16} /> : <MessagesSquareIcon size={16} />}
        </div>
        <Breadcrumbs
          items={[
            {
              label: loading ? <Skeleton className="w-32 h-4" /> : <ChatBreadcrumb {...props} />
            }
          ]}
        />
      </div>
    </Navbar>
  )
}

function ChatBreadcrumb (props: ChatPageNavbarProps) {
  const { chat } = props

  const whatsAppPhoneNumber = chat?.data?.whatsApp?.contactPhoneNumber
  const contactName = chat?.data?.whatsApp?.contactName

  return (
    <>
      <span className='font-semibold'>
        {whatsAppPhoneNumber
          ? `${contactName ? `${contactName}: ` : ''}${formatPhoneNumber(whatsAppPhoneNumber)}`
          : 'Chat'}
      </span>
      <span className='text-gray-600'><FormattedDate date={chat?.createDate} format="Ppp" /></span>
    </>
  )
}
