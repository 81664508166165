import ReactMarkdown, { Components } from 'react-markdown'
import remarkGfm from 'remark-gfm'

export type FormattedTextProps = {
  text: string | null
}

const components: Partial<Components> = {
  p: ({ ...props }) => <p {...props} />,
  h3: ({ ...props }) => <h3 className="text-md font-bold" {...props} />,
  ul: ({ ...props }) => <ul className="list-disc mb-2 list-inside [&_p]:inline" {...props} />,
  ol: ({ ...props }) => <ol className="list-decimal mb-2 list-inside [&_p]:inline" {...props} />,
  a: ({ ...props }) => <a className="font-medium link link-primary link-hover" target="_blank mb-2" {...props} />,
  pre: ({ ...props }) => <code className="pb-2 overflow-x-auto" {...props} />
}

export function FormattedText (props: FormattedTextProps) {
  const { text } = props

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={components}
    >
      {text}
    </ReactMarkdown>
  )
}
