import { SparklesIcon } from 'lucide-react'
import { GetCollectionTemplatesPageData } from '@indigohive/cogfy-types'
import { ReactNode } from 'react'
import { Button, Card } from '../../../components'
import { useTranslation } from 'react-i18next'

export type TemplateCardProps = {
  name: ReactNode
  description?: ReactNode
  template?: GetCollectionTemplatesPageData
  onClick?: (template?: GetCollectionTemplatesPageData) => void
  disabled?: boolean
}

export function TemplateCard (props: TemplateCardProps) {
  const { t } = useTranslation()

  return (
    <Card className="w-full p-4 group">
      <h3 className="text-lg font-medium">
        {props.name}
      </h3>
      <p className="text-gray-600 text-sm my-2">
        {props.description ?? t('No description')}
      </p>
      <div className="flex justify-end mt-4 opacity-0 group-hover:opacity-100 transition-opacity">
        <Button
          disabled={props.disabled}
          size="sm"
          color="primary"
          onClick={() => props.onClick?.(props.template)}
        >
          <SparklesIcon size={16} />
          {t('Use template')}
        </Button>
      </div>
    </Card>
  )
}
