import { GetWorkspaceResult, WorkspaceLogoUpdatedEvent, WorkspaceThemeUpdatedEvent } from '@indigohive/cogfy-types'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { applyWorkspaceTheme, DEFAULT_THEME_COLOR } from '../helpers'
import { useCogfy } from './use-cogfy'
import { useRoom } from './use-room'

export function useWorkspace (slug: string) {
  const cogfy = useCogfy()

  useRoom(`workspace:${slug}`)

  const getWorkspace = useQuery({
    queryKey: ['getWorkspaceBySlug', slug],
    queryFn: ({ signal }) => cogfy.workspaces.getBySlug(slug, signal),
    enabled: Boolean(slug)
  })
  const queryClient = useQueryClient()

  useEffect(() => {
    const onWorkspaceLogoUpdated = (event: WorkspaceLogoUpdatedEvent) => {
      queryClient.setQueryData<GetWorkspaceResult>(
        ['getWorkspaceBySlug', slug],
        (oldData) => {
          if (event.workspace.id === oldData?.id) {
            return {
              ...oldData,
              logoUrl: event.workspace.logoUrl
            }
          }

          return oldData
        }
      )
    }

    const onWorkspaceThemeUpdated = (event: WorkspaceThemeUpdatedEvent) => {
      queryClient.setQueryData<GetWorkspaceResult>(
        ['getWorkspaceBySlug', slug],
        (oldData) => {
          if (event.workspace.id === oldData?.id) {
            return {
              ...oldData,
              theme: event.workspace.theme
            }
          }

          return oldData
        }
      )
    }

    cogfy.workspaces.onWorkspaceLogoUpdated.add(onWorkspaceLogoUpdated)
    cogfy.workspaces.onWorkspaceThemeUpdated.add(onWorkspaceThemeUpdated)

    return () => {
      cogfy.workspaces.onWorkspaceLogoUpdated.remove(onWorkspaceLogoUpdated)
      cogfy.workspaces.onWorkspaceThemeUpdated.remove(onWorkspaceThemeUpdated)
    }
  }, [queryClient, slug])

  // TODO: This should be moved to a provider in the future.
  useEffect(() => {
    if (getWorkspace.data) {
      applyWorkspaceTheme(getWorkspace.data.theme ?? DEFAULT_THEME_COLOR)
    }
  }, [getWorkspace.data])

  return getWorkspace
}
