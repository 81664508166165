import { GetWorkspaceAssistantResult } from '@indigohive/cogfy-types/endpoints/getWorkspaceAssistant'
import { FieldType } from '@indigohive/cogfy-types'
import clsx from 'clsx'
import { BookmarkIcon, TriangleAlertIcon, SparklesIcon, BotIcon } from 'lucide-react'
import { createElement, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import { Button, Menu, OverlayContent } from '../../../../../components'
import { FIELD_ICONS_BY_TYPE } from '../../../../../field-types'
import { useWorkspaceSlug } from '../../../../../hooks'
import { CollectionState, CollectionStateField, CollectionStateViewField, useSelectedCollectionTitleFieldId } from '../../../../../lib'
import { CollectionPageController } from '../../../collection-page-controller'
import { CollectionTableResizeColHandler } from './CollectionTableResizeColHandler'

export type CollectionTableHeadProps = {
  viewField: CollectionStateViewField
  field?: CollectionStateField
  index: number
  locked?: boolean | null
  draggedCol?: { col: CollectionStateViewField, change: number } | null
  controller: CollectionPageController
  state: CollectionState
  workspaceAssistant?: GetWorkspaceAssistantResult
  onDrag: (col: CollectionStateViewField, change: number) => void
  onDragStop: (col: CollectionStateViewField, change: number) => void
  canDrag?: boolean
}

const DEFAULT_FIELD_WIDTH = 180
const MIN_FIELD_WIDTH = 100

export function CollectionTableHead (props: CollectionTableHeadProps) {
  const {
    viewField,
    field,
    index,
    locked,
    draggedCol,
    controller,
    state,
    workspaceAssistant,
    onDrag,
    onDragStop,
    canDrag = true
  } = props
  const ref = useRef<HTMLDivElement>(null)
  const selectedCollectionTitleFieldId = useSelectedCollectionTitleFieldId(state)
  const showWarning = false
  const showAssistantButton = viewField.fieldId === workspaceAssistant?.fieldId

  const [{ isDragging }, drag] = useDrag({
    type: 'CollectionTableHead',
    canDrag,
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    item: { viewField, index }
  }, [viewField])

  const [{ isOver }, drop] = useDrop({
    accept: 'CollectionTableHead',
    collect: monitor => ({
      isOver: monitor.isOver()
    }),
    drop: (item: { viewField: CollectionStateViewField, index: number }) => {
      controller.onDropViewField(item.viewField, viewField)
    }
  }, [viewField])

  drag(drop(ref))

  return (
    <div
      ref={ref}
      className={clsx(
        'relative',
        'h-full',
        'border-r',
        'border-base-200',
        isDragging && 'opacity-10',
        isOver && 'bg-base-200',
        !locked && [
          'cursor-pointer',
          'hover:bg-base-200',
          'active:bg-base-300'
        ]
      )}
      onClick={event => !locked && field && controller.onFieldClick?.(event, field)}
      onContextMenu={event => {
        if (!locked && field) {
          controller.onFieldClick?.(event, field)
          event.preventDefault()
        }
      }}
      style={{
        width: draggedCol?.col.id === viewField.id
          ? (viewField.config?.ui?.width ?? DEFAULT_FIELD_WIDTH) + draggedCol.change + 'px'
          : viewField.config?.ui?.width ?? DEFAULT_FIELD_WIDTH + 'px',
        minWidth: MIN_FIELD_WIDTH + 'px'
      }}
    >
      <div className="flex justify-between items-center h-full px-2 overflow-hidden max-w-full whitespace-nowrap">
        <span className='inline-flex items-center gap-1.5'>
          {field && createElement(
            FIELD_ICONS_BY_TYPE[field.type as FieldType],
            { className: 'w-4 h-4', size: 20 }
          )}
          {field?.name}
        </span>
        <span className="flex gap-1 text-gray-400">
          {field?.id === selectedCollectionTitleFieldId && <BookmarkIcon size={16} />}
          {field?.operation && <SparklesIcon size={16} />}
        </span>
        {showWarning && (
          <Button
            ghost
            size="xs"
            circle
            onClick={event => {
              if (field && !locked) {
                controller.onEditFieldClick(field)
                event.stopPropagation()
              }
            }}
          >
            <TriangleAlertIcon size={16} className="text-warning" />
          </Button>
        )}
        {showAssistantButton && <AssistantButton />}
      </div>
      <CollectionTableResizeColHandler
        col={viewField}
        onDrag={onDrag}
        onDragStop={onDragStop}
      />
    </div>
  )
}

function AssistantButton () {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const { t } = useTranslation()
  const workspaceSlug = useWorkspaceSlug()

  const whatsAppLink = import.meta.env.MODE === 'production'
    ? 'https://wa.me/5511935028768'
    : 'https://wa.me/'

  const configurationLink = `/${workspaceSlug}/assistant`

  return (
    <>
      <Button
        size="xs"
        ghost
        circle
        onClick={event => {
          event.stopPropagation()
          setAnchorEl(event.currentTarget)
        }}
      >
        <BotIcon size={16} />
      </Button>

      <OverlayContent
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom' }}
        onClose={() => setAnchorEl(null)}
      >
        <Menu>
          <li className="menu-title">Cogfy Assistant</li>
          <li>
            <a href={configurationLink} target='_blank' rel="noreferrer">
              {t('Open configuration')}
            </a>
          </li>
          <li>
            <a href={whatsAppLink} target='_blank' rel="noreferrer">
              {t('Open WhatsApp')}
            </a>
          </li>
        </Menu>
      </OverlayContent>
    </>
  )
}
