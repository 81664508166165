import clsx from 'clsx'
import { TreeViewItem, TreeViewItemProps } from './TreeViewItem'
import { TreeViewItemData } from './types/TreeViewItemData'
import { TreeViewPlaceholder } from './types/TreeViewPlaceholder'

export type TreeViewProps<T extends TreeViewItemData<T>> = {
  items?: T[]
  searchHighlight?: string
  parent?: T
  loading?: boolean
  placeholder?: TreeViewPlaceholder<T>
  onItemClick?: (item: T) => void
  onDrop?: TreeViewItemProps<T>['onDrop']
  onContextMenu?: TreeViewItemProps<T>['onContextMenu']
  address?: number[]
}

export function TreeView<T extends TreeViewItemData<T>> (props: TreeViewProps<T>) {
  const { items, searchHighlight, parent, loading, placeholder } = props

  return (
    <ul className={clsx(!parent && 'menu', 'px-0 remove-before-class')}>
      {items?.map((item, index) => (
        <TreeViewItem
          key={item.id}
          item={item}
          searchHighlight={searchHighlight}
          onDrop={props.onDrop}
          onContextMenu={props.onContextMenu}
          placeholder={placeholder}
          onItemClick={props.onItemClick}
          address={props.address ? props.address.concat(index) : [index]}
        />
      ))}
      {items?.length === 0 && placeholder && (
        <li>
          <a onClick={event => placeholder.onClick?.(event, parent)}>
            {placeholder.icon}
            {placeholder.label}
          </a>
        </li>
      )}
      {loading && new Array(5).fill(0).map((_, index) => (
        <li key={index}>
          <a>
            <div className="skeleton h-4 w-40" />
          </a>
        </li>
      ))}
    </ul>
  )
}
