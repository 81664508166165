import type {
  CollectionDeletedEvent,
  CollectionRenamedEvent,
  UUID
} from '@indigohive/cogfy-types'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useCogfy } from './use-cogfy'
import { useNavigate, useParams } from 'react-router-dom'
import { GetCollectionsTreeResult, GetCollectionsTreeResultData } from '@indigohive/cogfy-types/endpoints/getCollectionsTree'

const queryKey = ['getCollectionsPage'] as const

export function useCollectionsTree () {
  const cogfy = useCogfy()

  const getCollectionsTree = useQuery({
    queryKey,
    queryFn: ({ signal }) => cogfy.getCollectionsTree({ signal })
  })
  const queryClient = useQueryClient()
  const urlParams = useParams<{ workspace: string, collectionId: UUID }>()
  const navigate = useNavigate()

  const collectionId = urlParams.collectionId
  const workspace = urlParams.workspace

  useEffect(() => {
    const onCollectionCreated = () => {
      getCollectionsTree.refetch()
    }

    const onCollectionDeleted = (event: CollectionDeletedEvent) => {
      getCollectionsTree.refetch()

      if (event.data.collectionId === collectionId) {
        navigate(`/${workspace}`)
      }
    }

    const onCollectionNameUpdated = (event: CollectionRenamedEvent) => {
      queryClient.setQueryData<GetCollectionsTreeResult>(
        queryKey,
        (oldData) => {
          if (oldData) {
            const newData = [...oldData.data]

            for (let index = 0; index < newData.length; index++) {
              newData[index] = updateCollectionNameRecursive(newData[index], event.data.collectionId, event.data.name)
            }

            return { data: newData }
          }
        }
      )
    }

    cogfy.collections.onCollectionCreated.add(onCollectionCreated)
    cogfy.transactions.onCollectionDeleted.add(onCollectionDeleted)
    cogfy.transactions.onCollectionRenamed.add(onCollectionNameUpdated)

    return () => {
      cogfy.collections.onCollectionCreated.remove(onCollectionCreated)
      cogfy.transactions.onCollectionDeleted.remove(onCollectionDeleted)
      cogfy.transactions.onCollectionRenamed.remove(onCollectionNameUpdated)
    }
  }, [collectionId, queryClient])

  return getCollectionsTree
}

const updateCollectionNameRecursive = (
  collection: GetCollectionsTreeResultData,
  id: string,
  name: string | null
): GetCollectionsTreeResultData => {
  if (collection.id === id) {
    return {
      ...collection,
      name
    }
  }

  if (collection.children && collection.children.length > 0) {
    return {
      ...collection,
      children: collection.children.map(child =>
        updateCollectionNameRecursive(child, id, name)
      )
    }
  }

  return collection
}
