import { Info, Pencil, PlusIcon, Tag, Trash2, Users } from 'lucide-react'
import { GetUserGroupsPageResultData, UUID } from '@indigohive/cogfy-types'
import { useMutation } from '@tanstack/react-query'
import clsx from 'clsx'
import { useState, useRef, useEffect } from 'react'
import { Button, Checkbox, ConfirmDeleteDialog, Container, SettingsPagesHeader } from '../../components'
import { useCogfy, usePermissions, useUserGroupsPage, useTitle } from '../../hooks'
import { useTranslation } from 'react-i18next'
import { EditUserGroupModal } from './components'

const headers = [
  {
    title: 'Name',
    icon: Tag
  },
  {
    title: 'Description',
    icon: Tag
  },
  {
    title: 'Default group',
    icon: Info
  }
]

export function UserGroupsIndexPage () {
  const cogfy = useCogfy()
  const permissions = usePermissions()
  const getUserGroupsPage = useUserGroupsPage({ pageSize: 100 })
  const [selectedGroups, setSelectedGroups] = useState<UUID[]>([])
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [groupToEdit, setGroupToEdit] = useState<GetUserGroupsPageResultData | null>(null)

  const groups = getUserGroupsPage.data?.data ?? []

  const ref = useRef<HTMLInputElement>(null)

  const indeterminate = Object.keys(selectedGroups).length > 0 && Object.keys(selectedGroups).length < groups.length

  useEffect(() => {
    if (ref.current) {
      if (indeterminate) {
        ref.current.indeterminate = true
      } else {
        ref.current.indeterminate = false
      }
    }
  }, [indeterminate])

  const isAdmin = permissions.isAdmin

  const { t } = useTranslation()

  const createUserGroup = useMutation({
    mutationFn: async () => {
      await cogfy.createUserGroup({})
      getUserGroupsPage.refetch()
    }
  })
  const deleteUserGroup = useMutation({
    mutationFn: async (userGroupId: UUID) => {
      await cogfy.deleteUserGroup({ userGroupId })
      getUserGroupsPage.refetch()
      setSelectedGroups([])
    }
  })
  const updateUserGroupIsDefault = useMutation({
    mutationFn: async (data: { userGroupId: UUID, isDefault: boolean }) => {
      await cogfy.userGroups.updateIsDefault(data.userGroupId, { isDefault: data.isDefault })
      getUserGroupsPage.refetch()
    }
  })

  const handleSelectGroup = (groupId: UUID, checked: boolean) => {
    if (checked) {
      setSelectedGroups?.(prev => [...prev, groupId])
    } else {
      setSelectedGroups?.(prev => prev.filter(id => id !== groupId))
    }
  }

  const handleHeadCheckboxChange = () => {
    const newSelections: UUID[] = []

    if (selectedGroups.length === 0) {
      for (const group of groups) {
        newSelections.push(group.id)
      }
    }
    setSelectedGroups(newSelections)
  }

  const handleConfirmDelete = () => {
    if (selectedGroups.length > 0) {
      for (const groupId of selectedGroups) {
        deleteUserGroup.mutate(groupId)
      }
    }
    setDeleteDialogOpen(false)
  }

  const handleDeleteRow = (groupId: UUID) => {
    setSelectedGroups([groupId])
    setDeleteDialogOpen(true)
  }

  useTitle({
    title: t('User Groups'),
    loading: getUserGroupsPage.isLoading,
    error: getUserGroupsPage.isError
  })

  return (
    <>
      <SettingsPagesHeader
        title={t('Groups')}
        description={t('SettingsPages:Create and manage groups')}
      >
        <Users size={46} />
      </SettingsPagesHeader>
      <Container>
        {isAdmin && (
          <div className='flex mt-8 justify-between items-center'>
            {!getUserGroupsPage.isLoading && getUserGroupsPage && selectedGroups.length > 0 && <span className="text-sm">{t('Groups selected', { count: selectedGroups.length })}</span>}
            {!getUserGroupsPage.isLoading && getUserGroupsPage && selectedGroups.length === 0 && <span className="text-sm">{t('Groups found', { count: groups.length })}</span>}
            {selectedGroups.length === 0
              ? (
                <Button
                  size="sm"
                  color="primary"
                  disabled={createUserGroup.isPending}
                  onClick={() => createUserGroup.mutate()}
                >
                  <PlusIcon size={16} />
                  {t('Create')}
                </Button>
                // eslint-disable-next-line @typescript-eslint/indent
              )
              : (
                <Button
                  size="sm"
                  onClick={() => setDeleteDialogOpen(true)}
                >
                  {t('Delete')}
                </Button>)}

          </div>
        )}
        {getUserGroupsPage.data && (
          <>
            <div className="overflow-x-auto rounded-lg my-4">
              <table
                className="table"
                style={{ borderCollapse: 'separate', borderSpacing: 0 }}
              >
                <thead className='bg-background-default w-full'>
                  <tr>
                    <th className='w-4'>
                      <label className="settings-table-header-element justify-center">
                        <Checkbox
                          ref={ref}
                          checked={groups.length > 0 && Object.keys(selectedGroups).length === groups.length}
                          disabled={getUserGroupsPage.isLoading || groups.length === 0}
                          onChange={handleHeadCheckboxChange}
                          size='sm'
                        />
                      </label>
                    </th>
                    {headers.map(header => (
                      <th key={header.title} className="text-left">
                        <div className='settings-table-header-element'>
                          <header.icon size={16} />
                          {t(header.title)}
                        </div>
                      </th>
                    ))}
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {groups.length === 0 && (
                    <tr>
                      <td colSpan={1000} className="text-gray-400">
                        {t('userGroupsPage:No groups found')}
                      </td>
                    </tr>
                  )}
                  {groups.map(group => (
                    <>
                      <tr key={group.id} className="h-16">
                        <td>
                          <label className="h-full flex items-center justify-center">
                            <Checkbox
                              checked={Boolean(selectedGroups.find(selectedGroupId => selectedGroupId === group.id))}
                              disabled={getUserGroupsPage.isLoading}
                              onChange={event => handleSelectGroup(group.id, event.target.checked)}
                              size='sm'
                            />
                          </label>
                        </td>
                        <td className={clsx('max-w-64 min-w-1/3 truncate', !group.name && 'text-gray-400')}>
                          {group.name ?? t('Unnamed')}
                        </td>
                        <td
                          className={clsx('max-w-64 min-w-1/3 break-words', !group.description && 'text-gray-400')}
                        >
                          {group.description ?? t('No description')}
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            className="toggle toggle-primary toggle-sm"
                            color="primary"
                            checked={group.isDefault}
                            onChange={event => {
                              if (isAdmin) {
                                updateUserGroupIsDefault.mutate({ userGroupId: group.id, isDefault: event.target.checked })
                              }
                            }}
                          />
                        </td>
                        <td className='w-[80px]'>
                          <div className="flex items-center gap-8">
                            <button
                              className={`${!(selectedGroups.length > 1)
                                ? 'hover:border hover:rounded-lg hover:bg-background-default'
                                : 'cursor-not-allowed'}
                           w-7 h-7 flex items-center justify-center`}
                              disabled={selectedGroups.length > 1}
                              onClick={() => {
                                setGroupToEdit(group)
                                setEditModalOpen(true)
                              }}
                            >
                              <Pencil size={16} />
                            </button>
                            <button
                              className={`${!(selectedGroups.length > 1)
                                ? 'hover:border hover:rounded-lg hover:bg-background-default'
                                : 'cursor-not-allowed'}
                         w-7 h-7 flex items-center justify-center`}
                              disabled={selectedGroups.length > 1}
                              onClick={() => handleDeleteRow(group.id)}
                            >
                              <Trash2 size={16} />
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={1000} className='p-0'>
                          <div className='divider my-0' />
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </Container >
      <ConfirmDeleteDialog
        title={t('userGroupsPage:Delete all selected groups?')}
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleConfirmDelete}
      />
      <EditUserGroupModal
        open={editModalOpen}
        group={groupToEdit}
        onClose={() => {
          getUserGroupsPage.refetch()
          setEditModalOpen(false)
        }}
      />
    </>
  )
}
