import { RunTransactionCommand, UUID } from '@indigohive/cogfy-types'
import { CollectionState, CollectionStateView } from '../collection-state'
import { Command } from '../command-stack'

export type DeleteViewCommandData = {
  viewId: UUID
}

export class DeleteViewCommand implements Command<RunTransactionCommand> {
  private readonly _state: CollectionState
  private readonly _data: DeleteViewCommandData
  private readonly _viewToDelete: CollectionStateView | undefined
  private readonly _viewToDeleteIndex: number | undefined
  private readonly _wasActive: boolean

  constructor (
    state: CollectionState,
    data: DeleteViewCommandData
  ) {
    this._state = state
    this._data = data
    this._viewToDeleteIndex = state.views?.findIndex(view => view.id === data.viewId)
    this._viewToDelete = this._viewToDeleteIndex === undefined
      ? undefined
      : state.views?.[this._viewToDeleteIndex]
    this._wasActive = state.activeViewId === data.viewId
  }

  run (): RunTransactionCommand {
    const newViews = [...this._state.views!]
    newViews.splice(this._viewToDeleteIndex!, 1)
    this._state.setViews(newViews)

    if (this._state.activeViewId === this._data.viewId) {
      this._state.setActiveViewId(this._state.views![0].id)
    }

    return {
      operations: [
        {
          type: 'soft_delete_view',
          data: {
            collectionId: this._state.id,
            viewId: this._data.viewId
          }
        }
      ]
    }
  }

  undo (): RunTransactionCommand {
    const newViews = [...this._state.views!]
    newViews.splice(this._viewToDeleteIndex!, 0, this._viewToDelete!)
    this._state.setViews(newViews)

    if (this._wasActive) {
      this._state.setActiveViewId(this._data.viewId)
    }

    return {
      operations: [
        {
          type: 'restore_view',
          data: {
            collectionId: this._state.id,
            viewId: this._data.viewId
          }
        }
      ]
    }
  }
}
