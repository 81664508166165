import { useEffect } from 'react'
import { CollectionPageController } from '../collection-page-controller'

export function useCopyPaste (controller: CollectionPageController) {
  useEffect(() => {
    const handleCopy = (event: ClipboardEvent) => {
      controller.onCopy(event)
    }
    const handlePaste = (event: ClipboardEvent) => {
      controller.onPaste(event)
    }

    document.addEventListener('copy', handleCopy)
    document.addEventListener('paste', handlePaste)

    return () => {
      document.removeEventListener('copy', handleCopy)
      document.removeEventListener('paste', handlePaste)
    }
  }, [controller])
}
