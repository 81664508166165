import type {
  GetViewFieldsResult,
  GetViewResult,
  QueryViewRecordsPageQuery,
  QueryViewRecordsPageResult,
  UUID
} from '@indigohive/cogfy-types'
import { BaseClient } from './base-client'

export class ViewsClient extends BaseClient {
  async getById (
    viewId: UUID,
    signal?: AbortSignal
  ): Promise<GetViewResult> {
    return (await this.axios.get(`/views/${viewId}`, { signal })).data
  }

  async getFields (
    viewId: UUID,
    signal?: AbortSignal
  ): Promise<GetViewFieldsResult> {
    return (await this.axios.get(`/views/${viewId}/fields`, { signal })).data
  }

  async queryRecordsPage (
    viewId: UUID,
    data: QueryViewRecordsPageQuery = {},
    signal?: AbortSignal
  ): Promise<QueryViewRecordsPageResult> {
    return (await this.axios.post(`/views/${viewId}/query`, data, { signal })).data
  }
}
