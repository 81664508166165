import clsx from 'clsx'
import { useDrag, useDragDropManager, useDrop } from 'react-dnd'
import { useEffect } from 'react'
import { getEmptyImage } from 'react-dnd-html5-backend'

const DRAWER_RESIZE_HANDLER = 'DrawerResizeHandler'

export type DrawerResizeHandlerProps = {
  workspace: string
  onDrag?: (change: number) => void
  onDragStop?: () => void
}

export function DrawerResizeHandler (props: DrawerResizeHandlerProps) {
  const { workspace, onDrag, onDragStop } = props

  const dragDropManager = useDragDropManager()
  const monitor = dragDropManager.getMonitor()

  const [, drop] = useDrop({
    accept: DRAWER_RESIZE_HANDLER,
    drop: (_, monitor) => {
      if (monitor.didDrop()) {
        return
      }

      onDragStop?.()
    }
  }, [workspace, onDragStop])

  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: DRAWER_RESIZE_HANDLER,
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  }, [workspace])

  useEffect(() => {
    if (!isDragging) {
      return
    }

    monitor.subscribeToOffsetChange(() => {
      const offset = monitor.getDifferenceFromInitialOffset()
      const itemType = monitor.getItemType()

      if (offset && itemType === DRAWER_RESIZE_HANDLER) {
        onDrag?.(offset.x)
      }
    })
  }, [workspace, isDragging, monitor, onDrag])

  useEffect(() => {
    drop(document.body)

    return () => {
      drop(null)
    }
  }, [])

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true })
  }, [dragPreview])

  return (
    <div
      ref={drag}
      className={clsx(
        'w-[5px]',
        'transition-colors',
        'cursor-col-resize',
        'active:bg-info',
        'hover:bg-info',
        'h-[100vh]',
        'top-[-1px]',
        'right-[-3px]',
        'z-10'
      )}
    />
  )
}
