import { WhatsAppContentProps } from './WhatsAppContent'

export function WhatsAppContentImage (props: WhatsAppContentProps) {
  const { content, files } = props

  // WhatsAppContentImage component expects only one file
  const file = files[0]

  return (
    <div className="text-sm">
      <div className="rounded-[6px] overflow-hidden">
        <img src={file.url} alt={file.name ?? ''} />
      </div>
      {content && (
        <div className="px-1">
          {content}
        </div>
      )}
    </div>
  )
}
