import type {
  FieldType,
  OperationInputType,
  OperationSchemaInput,
  UUID
} from '@indigohive/cogfy-types'
import { useEffect, useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { CollectionPageController } from '../../../collection-page-controller'
import { Mentions, MentionsValue } from '../../../../../components/Mentions'
import { CollectionState, useSelectedFieldToUpdate } from '../../../../../lib'
import { Button } from '../../../../../components'
import { PlusIcon, TrashIcon } from 'lucide-react'

export type FieldOperationMultipleTextInputProps = {
  controller: CollectionPageController
  state: CollectionState
  inputName: string
  input: OperationSchemaInput<OperationInputType>
  fields: { id: UUID, name: string, type: FieldType }[]
}

export function FieldOperationMultipleTextInput (props: FieldOperationMultipleTextInputProps) {
  const { controller, state, inputName, fields } = props
  const [values, setValues] = useState<MentionsValue[]>([{ parts: [], options: [] }])
  const selectedField = useSelectedFieldToUpdate(state)
  const operationConfigInput = selectedField?.operationConfig?.[inputName]

  useEffect(() => {
    if (operationConfigInput?.type === 'templates') {
      setValues(operationConfigInput.template.map(template => ({
        parts: template.strings,
        options: template.args.map(arg => ({
          id: arg.fieldId,
          name: fields.find(field => field.id === arg.fieldId)?.name ?? '???'
        }))
      })))
    } else {
      setValues([{ parts: [], options: [] }])
    }
  }, [operationConfigInput])

  const onChange = useDebouncedCallback((newValues: MentionsValue[]) => {
    controller.onOperationChange(
      selectedField!.id,
      selectedField?.operation ?? null,
      selectedField!.recalculateStrategy,
      {
        ...selectedField?.operationConfig,
        [inputName]: {
          type: 'templates',
          template: newValues.map(newValue => ({
            strings: newValue.parts,
            args: newValue.options.map(option => ({ fieldId: option.id as UUID }))
          }))
        }
      }
    )
  }, 300)

  const options = useMemo(() => {
    return fields
      .filter(field =>
        field.type === 'text' ||
        field.type === 'json' ||
        field.type === 'number' ||
        field.type === 'reference' ||
        field.type === 'boolean' ||
        field.type === 'select'
      )
      .filter(field => field.id !== selectedField?.id)
      .map(field => ({ id: field.id, name: field.name }))
  }, fields)

  return (
    <div className="flex flex-col gap-2">
      {values.map((value, index) => (
        <div key={index} className="flex items-center gap-2">
          <Mentions
            key={index}
            options={options}
            value={value}
            onChange={value => {
              const newValues = [...values]
              newValues[index] = value
              setValues(newValues)
              onChange(newValues)
            }}
          />
          <Button
            square
            ghost
            size="sm"
            onClick={() => {
              const newValues = [...values]
              newValues.splice(index, 1)
              setValues(newValues)
              onChange(newValues)
            }}
          >
            <TrashIcon size={16} />
          </Button>
        </div>
      ))}
      <div>
        <Button
          size="xs"
          onClick={() => {
            const newValues = [...values, { parts: [], options: [] }]
            setValues(newValues)
            onChange(newValues)
          }}
        >
          <PlusIcon size={16} />
          Add
        </Button>
      </div>
    </div>
  )
}
