import { useTranslation } from 'react-i18next'
import { CollectionStateField } from '../../lib'
import { CollectionPageController } from '../../pages/CollectionPageV2/collection-page-controller'
import { MoveDownIcon, MoveUpIcon } from 'lucide-react'
import { FieldType } from '@indigohive/cogfy-types'
import clsx from 'clsx'

export type FieldMenuSortType = typeof FieldMenuSort

export const fields = new Set<Partial<FieldType>>([
  'boolean',
  'createDate',
  'date',
  'number',
  'text',
  'updateDate'
])

export type FieldMenuSortProps = {
  field: CollectionStateField
  controller: CollectionPageController
}

export function FieldMenuSort (props: FieldMenuSortProps) {
  const { field, controller } = props

  const { t } = useTranslation()

  const fieldMenuSortComponent = fields.has(field.type)
  const disabled = !fieldMenuSortComponent

  return (
    <>
      <li className={clsx(disabled && 'disabled')}>
        <a
          onClick={() => !disabled && controller.onSortField({ fieldId: field.id, direction: 'asc' })}
        >
          <MoveUpIcon size={16} />
          {t('collectionFieldMenu:Sort ascending')}
        </a>
      </li>
      <li className={clsx(disabled && 'disabled')}>
        <a
          onClick={() => !disabled && controller.onSortField({ fieldId: field.id, direction: 'desc' })}
        >
          <MoveDownIcon className="w-4 h-4" />
          {t('collectionFieldMenu:Sort descending')}
        </a>
      </li>
    </>
  )
}
