import clsx from 'clsx'
import { FieldCellProps } from '../FieldCell'

export function BillingCell (props: FieldCellProps) {
  const { viewField } = props

  const textWrap = viewField.config?.ui?.textWrap

  return (
    <span
      className={clsx(
        'px-2 py-1',
        textWrap && 'whitespace-pre-wrap',
        !textWrap && 'truncate'
      )}
    >
    </span>
  )
}
