import { useState } from 'react'
import { ChatMiddlewareDataFormProps } from '../../ChatMiddlewareDataFormProps'
import { useCollectionFields } from '../../../../hooks'
import { Select } from '../../../Select'
import { Button } from '../../../Button'
import { PlusIcon, TrashIcon } from 'lucide-react'
import { FieldType } from '@indigohive/cogfy-types'
import { useTranslation } from 'react-i18next'

const allowedFields: FieldType[] = ['boolean', 'date', 'file', 'number', 'select', 'text']

export function ToolFormCreateCogfyRecord (props: ChatMiddlewareDataFormProps) {
  const { data, onChange, collections = [] } = props

  const [collectionId, setCollectionId] = useState(data?.method?.config?.collection?.id ?? '')
  const [fields, setFields] = useState<{ id: string, required?: boolean }[]>(data?.method?.config?.collection?.fields ?? [])

  const { t } = useTranslation()
  const getCollectionFields = useCollectionFields(undefined, collectionId)
  const collectionFields = getCollectionFields.data?.data.filter(f => allowedFields.includes(f.type)) ??
  []

  return (
    <>
      <Select
        label={t('Collection')}
        size="sm"
        options={[
          { label: t('Select a collection'), value: '', disabled: true },
          ...collections.map(collection => ({ label: collection.name, value: collection.id }))
        ]}
        value={collectionId}
        onChange={event => {
          const value = event.target.value
          setCollectionId(value)
          setFields([])
          onChange({ ...data, method: { ...data.method, config: { collection: { id: value, fields: [] } } } })
        }}
      />

      <div className="my-2">
        <div className="flex items-center pb-1">
          <div className="grow text-sm">
            {t('Fields')}
          </div>
          <Button
            ghost
            square
            size="sm"
            disabled={!collectionId || fields.length === collectionFields.length}
            onClick={() => {
              const field = collectionFields.find(field => !fields.some(f => f.id === field.id))
              if (field) {
                setFields([...fields, { id: field.id }])
                onChange({ ...data, method: { ...data.method, config: { collection: { id: collectionId, fields: [...fields, { id: field.id }] } } } })
              }
            }}
          >
            <PlusIcon size={16} />
          </Button>
        </div>

        {fields.length === 0 && (
          <div className="text-sm opacity-40">
            {t('editFieldDrawer:No fields set')}
          </div>
        )}

        <div className="flex flex-col gap-2">
          {fields.map((field, index) => (
            <div
              key={field.id}
              className="flex gap-2"
            >
              <Select
                size="sm"
                value={field.id}
                options={[
                  { label: t('Select a field'), value: '', disabled: true },
                  ...collectionFields.map(field => ({ label: field.name, value: field.id, disabled: fields.some(f => f.id === field.id) }))
                ]}
                onChange={event => {
                  const newFields = [...fields]
                  newFields[index] = { id: event.target.value }
                  setFields(newFields)
                  onChange({ ...data, method: { ...data.method, config: { collection: { id: collectionId, fields: newFields } } } })
                }}
              />
              <div className="flex gap-1">
                <Select
                  size="sm"
                  value={field.required ? 'required' : 'optional'}
                  disabled={!field.id}
                  options={[
                    { label: t('Optional'), value: 'optional' },
                    { label: t('Required'), value: 'required' }
                  ]}
                  onChange={event => {
                    const newFields = [...fields]
                    newFields[index] = { ...newFields[index], required: event.target.value === 'required' }
                    setFields(newFields)
                    onChange({ ...data, method: { ...data.method, config: { collection: { id: collectionId, fields: newFields } } } })
                  }}
                />
                <Button
                  type="button"
                  square
                  ghost
                  size="sm"
                  onClick={() => {
                    const newFields = [...fields]
                    newFields.splice(index, 1)
                    setFields(newFields)
                    onChange({ ...data, method: { ...data.method, config: { collection: { id: collectionId, fields: newFields } } } })
                  }}
                >
                  <TrashIcon size={16} />
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
