import { WhatsAppContentProps } from './WhatsAppContent'
import { WhatsAppContactsMessage } from '@indigohive/cogfy-types'
import { useTranslation } from 'react-i18next'
import { UserRoundIcon } from 'lucide-react'
import { Fragment, useState } from 'react'
import { OverlayContent } from '../../../components'

export function WhatsAppContentContacts (props: WhatsAppContentProps) {
  const { contentData, fromAuthenticatedUser }: { contentData: WhatsAppContactsMessage['contacts'], fromAuthenticatedUser: boolean } = props
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [clickedContact, setClickedContact] = useState<WhatsAppContactsMessage['contacts'][number] | null>(null)
  const { t } = useTranslation()

  return (
    <>
      <div className="flex flex-col">
        {contentData.map((contact, index) => (
          <div
            key={index}
            className="flex items-center gap-2 px-2 py-1 cursor-pointer"
            onClick={event => {
              setAnchorEl(event.currentTarget)
              setClickedContact(contact)
            }}
          >
            <div
              className="chat-image avatar placeholder"
              title={contact.name.formatted_name || t('Unknown user')}
            >
              <div
                className="w-10 rounded-full text-white bg-[#dfe5e7]"
              >
                <UserRoundIcon
                  size={24}
                />
              </div>
            </div>

            <div className="text-sm font-semibold">
              {contact.name.formatted_name}
            </div>
          </div>
        ))}
      </div>

      <OverlayContent
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: fromAuthenticatedUser ? 'left' : 'right' }}
        onClose={() => {
          setAnchorEl(null)
          setClickedContact(null)
        }}
      >
        <div className="w-64 bg-base-300 flex flex-col items-center gap-2 px-2 py-3 rounded-box">
          <div
            className="avatar placeholder"
            title={clickedContact?.name.formatted_name || t('Unknown user')}
          >
            <div
              className="w-10 rounded-full text-white bg-[#dfe5e7]"
            >
              <UserRoundIcon
                size={24}
              />
            </div>
          </div>

          <div className="text-md font-semibold">
            {clickedContact?.name.formatted_name}
          </div>

          <div className="w-full bg-white rounded-btn p-2">
            {clickedContact?.phones.map((phone, index) => (
              <Fragment key={index}>
                <div className="w-full">
                  <div className="text-xs text-gray-600">
                    {phone.type === 'CELL' ? t('Phone') : phone.type}
                  </div>
                  <div className="text-sm">
                    {phone.phone}
                  </div>
                </div>
                <div className="[&:not(:last-child)]:divider [&:not(:last-child)]:m-0" />
              </Fragment>
            ))}
          </div>
        </div>
      </OverlayContent>
    </>
  )
}
