import { PropsWithChildren, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DrawerCollectionsMenu } from './subcomponents/DrawerCollectionsMenu'
import { DrawerSearchCollectionsInput } from './subcomponents/DrawerSearchCollectionsInput'
import { DrawerWorkspacesSection } from './subcomponents/DrawerWorkspacesSection'
import { DrawerHeader } from './subcomponents/DrawerHeader'
import { useCollectionsTree, useDrawer, useWorkspace, useWorkspacesList } from '../../hooks'
import { DrawerResizeHandler } from './subcomponents/DrawerResizeHandler'
import { useDrawerWidth } from '../../hooks/use-drawer-width'
import { DrawerCollapsed } from './subcomponents/DrawerCollapsed'

const DEFAULT_WIDTH = 320
const MIN_WIDTH = 250
const MAX_WIDTH = 450

export type DrawerProps = PropsWithChildren<{ id: string }>

export function Drawer (props: DrawerProps) {
  const { children, id } = props

  const [searchCollectionName, setSearchCollectionName] = useState('')
  const params = useParams()
  const navigate = useNavigate()
  const workspace = params.workspace!
  const getCollectionsPage = useCollectionsTree()
  const getWorkspace = useWorkspace(workspace)
  const getWorkspacesList = useWorkspacesList()
  const currentWorkspace = getWorkspace.data
  const { open, setOpen } = useDrawer()
  const { width, handleResizeFieldDrawer, onStopResizeFieldDrawer } = useDrawerWidth('drawer-width', DEFAULT_WIDTH, MIN_WIDTH)

  const calculatedWidth = width >= MAX_WIDTH ? MAX_WIDTH : width

  return (
    <div className="drawer drawer-open">
      <input id={id} type="checkbox" className="drawer-toggle" />
      <div className="drawer-content border-l">
        {children}
      </div>
      <div className="drawer-side" style={{ scrollbarWidth: 'thin' }}>
        <label htmlFor={id} aria-label="close sidebar" className="drawer-overlay"></label>
        <div className="flex flew-row">
          {!open && currentWorkspace && (
            <DrawerCollapsed
              workspace={currentWorkspace}
              collections={getCollectionsPage.data?.data ?? []}
              workspaces={getWorkspacesList.data}
              workspacesLoading={getWorkspacesList.isLoading}
              collectionsLoading={getCollectionsPage.isLoading}
              onCollectionsChange={() => { getCollectionsPage.refetch() }}
            />
          )}

          {open && (
            <div
              className="flex flex-col gap-6 min-h-full px-3 py-6"
              style={{
                width: calculatedWidth,
                minWidth: MIN_WIDTH
              }}
            >
              <DrawerHeader />
              <DrawerWorkspacesSection
                drawerWidth={calculatedWidth}
                workspaces={getWorkspacesList.data}
                loading={getWorkspacesList.isLoading}
                onSettingsClick={() => {
                  setOpen?.(false)
                  navigate(`/${workspace}/settings`)
                }}
              />
              <DrawerSearchCollectionsInput
                searchCollectionName={searchCollectionName}
                onCollectionNameChange={name => setSearchCollectionName(name)}
              />
              <DrawerCollectionsMenu
                workspace={workspace}
                collections={getCollectionsPage.data?.data ?? []}
                searchCollectionName={searchCollectionName}
                isLoading={getCollectionsPage.isLoading}
                onCollectionsChange={() => { getCollectionsPage.refetch() }}
              />
            </div>
          )}

          {open && (
            <DrawerResizeHandler
              workspace={workspace}
              onDrag={change => handleResizeFieldDrawer(change)}
              onDragStop={onStopResizeFieldDrawer}
            />
          )}
        </div>
      </div>
    </div>
  )
}
