import { CheckIcon } from 'lucide-react'
import { ChatMessageStatus } from '@indigohive/cogfy-types'
import clsx from 'clsx'
import { parseISO } from 'date-fns'
import { format } from 'date-fns/format'

export type WhatsAppFooterProps = {
  sendDate: string
  messageStatus?: ChatMessageStatus | null
  hideStatusIcon?: boolean
}

export function WhatsAppFooter (props: WhatsAppFooterProps) {
  const { sendDate, messageStatus, hideStatusIcon } = props

  return (
    <div className="flex items-end justify-end gap-1">
      <div className="text-xs text-gray-600">
        {format(parseISO(sendDate), 'HH:mm')}
      </div>
      {!hideStatusIcon && messageStatus && (
        <div
          className={clsx(
            'flex',
            messageStatus === 'read' && 'text-sky-400',
            messageStatus !== 'read' && 'text-gray-600'
          )}
        >
          <CheckIcon size={16} />
          <CheckIcon size={16} className={clsx('w-4 h-4 ml-[-12px]', messageStatus === 'sent' && 'invisible')} />
        </div>
      )}
    </div>
  )
}
