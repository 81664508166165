import clsx, { ClassValue } from 'clsx'
import { PropsWithChildren } from 'react'

export type CardProps = PropsWithChildren<{
  className?: ClassValue
  compact?: boolean
}>

export function Card (props: CardProps) {
  const { className, compact, children } = props

  return (
    <div
      className={clsx(
        'card',
        'card-bordered',
        'bg-base-100',
        'shadow-sm',
        compact && 'card-compact',
        className
      )}
    >
      {children}
    </div>
  )
}
