export function incrementSuffix (
  newValue: string,
  existingValues: string[]
) {
  if (!existingValues.includes(newValue)) {
    return newValue
  }

  let index = 1

  while (true) {
    const incremented = `${newValue} ${index}`

    if (!existingValues.includes(incremented)) {
      return incremented
    }

    index++
  }
}
