import { Command } from '../command-stack'
import { CollectionState } from '../collection-state'

export type UpdateSearchRecordsCommandData = {
  search: string | null
}

export class UpdateSearchRecordsCommand implements Command<null> {
  private readonly _state: CollectionState
  private readonly _data: UpdateSearchRecordsCommandData
  private readonly _previousSearch: string | null

  constructor (
    state: CollectionState,
    data: UpdateSearchRecordsCommandData
  ) {
    this._state = state
    this._data = data
    this._previousSearch = this._state.searchRecords
  }

  run () {
    this._state.setSearchRecords(this._data.search)

    return null
  }

  undo () {
    this._state.setSearchRecords(this._previousSearch)

    return null
  }
}
