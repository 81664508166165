import { useEffect, useState } from 'react'
import { CollectionState } from '../collection-state'

export function useSelectedCell (state: CollectionState) {
  const [selectedCell, setSelectedCell] = useState(state.selectedCell)

  useEffect(() => {
    const selectedCellChanged = () => {
      setSelectedCell(state.selectedCell)
    }

    state.addEventListener('selectedCellChanged', selectedCellChanged)

    return () => {
      state.removeEventListener('selectedCellChanged', selectedCellChanged)
    }
  })

  return selectedCell
}
