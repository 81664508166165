import type {
  BooleanRecordProperty,
  DateRecordProperty,
  JsonRecordProperty,
  NumberRecordProperty,
  RecordProperty
} from '@indigohive/cogfy-types'
import { parse as dateFnsParse } from 'date-fns/parse'
import { CollectionStateField } from '../../lib'

export function parseFieldValues (
  rawValues: string[][],
  fields: CollectionStateField[]
): (RecordProperty | undefined)[][] {
  return rawValues.map(row => {
    return row.map<RecordProperty | undefined>((value, fieldIndex) => {
      const field = fields[fieldIndex]

      switch (field?.type) {
        case 'author':
          break
        case 'boolean':
          return parseBoolean(value)
        case 'chat':
          break
        case 'createDate':
          break
        case 'date':
          return parseDate(value)
        case 'file':
          break
        case 'json':
          return parseJson(value)
        case 'number':
          return parseNumber(value)
        case 'reference':
          break
        case 'stopwatch':
          break
        case 'text':
          return { type: 'text', text: { value } }
        case 'updateDate':
          break
        case 'vector':
          break
      }

      return undefined
    })
  })
}

function parseBoolean (value: string): BooleanRecordProperty {
  return {
    type: 'boolean',
    boolean: {
      value: (
        value.toUpperCase() === 'TRUE' ||
        value.toUpperCase() === 'VERDADEIRO' ||
        value === '1'
      )
    }
  }
}

function parseDate (value: string): DateRecordProperty | undefined {
  let date: Date | null = null

  try {
    if (value.match(/^\d\d\/\d\d\/\d\d\d\d$/)) {
      date = dateFnsParse(value, 'dd/MM/yyyy', new Date())
    } else if (value.match(/^\d\d\/\d\d\/\d\d$/)) {
      date = dateFnsParse(value, 'dd/MM/yy', new Date())
    }
  } catch {
  }

  if (date === null) {
    return undefined
  } else {
    return { type: 'date', date: { value: date.toISOString() } }
  }
}

function parseNumber (value: string): NumberRecordProperty {
  const parsedValue = parseFloat(value)

  return {
    type: 'number',
    number: {
      value: isNaN(parsedValue) ? 0 : parsedValue
    }
  }
}

function parseJson (value: string): JsonRecordProperty {
  try {
    return {
      type: 'json',
      json: {
        value: JSON.parse(value)
      }
    }
  } catch {
    return {
      type: 'json',
      json: {
        value: null
      }
    }
  }
}
