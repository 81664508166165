import type {
  GetFileResult,
  UploadFileCommand,
  UploadFileResult
} from '@indigohive/cogfy-types'
import { BaseClient } from './base-client'

export class FilesClient extends BaseClient {
  async upload (
    data: UploadFileCommand
  ): Promise<UploadFileResult> {
    return (await this.axios.post('/files', data)).data
  }

  async delete (
    fileId: string
  ): Promise<void> {
    await this.axios.delete(`/files/${fileId}`)
  }

  async finishUpload (
    fileId: string
  ): Promise<void> {
    await this.axios.patch(`/files/${fileId}/finish-upload`)
  }

  async getById (
    fileId: string,
    signal?: AbortSignal
  ): Promise<GetFileResult> {
    return (await this.axios.get(`/files/${fileId}`, { signal })).data
  }
}
