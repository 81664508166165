import { useState } from 'react'
import { ChatMiddlewareDataFormProps } from '../../ChatMiddlewareDataFormProps'
import { Select } from '../../../Select'
import { Input } from '../../../Input'
import { Textarea } from '../../../Textarea'

export function ToolFormHttpRequest (props: ChatMiddlewareDataFormProps) {
  const { data, onChange } = props

  const [method, setMethod] = useState(data.method?.config?.method ?? '')
  const [url, setUrl] = useState(data.method?.config?.url ?? '')
  const [httpParameters, setHttpParameters] = useState(JSON.stringify(data.method?.parameters ?? {}))

  const handleConfigChange = (key: string, value: any) => {
    const updatedConfig = { ...data.method?.config, [key]: value }
    onChange({ ...data, method: { ...data.method, config: updatedConfig } })
  }

  return (
    <>
      <Select
        label="Method"
        size="sm"
        options={[
          { label: 'Select a method', value: '', disabled: true },
          { label: 'GET', value: 'get' },
          { label: 'POST', value: 'post' }
        ]}
        value={method}
        onChange={event => {
          const value = event.target.value
          setMethod(value)
          handleConfigChange('method', value)
        }}
      />
      <Input
        label="URL"
        size="sm"
        value={url}
        onChange={event => {
          const value = event.target.value
          setUrl(value)
          handleConfigChange('url', value)
        }}
      />
      <Textarea
        label="JSON schema"
        size="sm"
        value={httpParameters}
        className="min-h-32"
        onChange={event => {
          const value = event.target.value
          setHttpParameters(value)
          try {
            onChange({ ...data, method: { ...data.method, parameters: JSON.parse(value) } })
          } catch {}
        }}
      />
    </>
  )
}
