import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useTitle, useWorkspace } from '../../hooks'
import { Container, SettingsPagesHeader } from '../../components'
import { WorkspaceProfile, WorkspaceTheme } from './components'
import { SettingsIcon } from 'lucide-react'

export function WorkspaceSettingsPage () {
  const params = useParams<{ workspace: string }>()
  const getWorkspace = useWorkspace(params.workspace!)
  const { t } = useTranslation()

  useTitle({
    title: t('Workspace'),
    loading: getWorkspace.isLoading,
    error: getWorkspace.isError
  })

  return (
    <>
      <SettingsPagesHeader
        title={'Workspace'}
        description={t('SettingsPages:Customize and configure your Workspace')}
      >
        <SettingsIcon size={48} />
      </SettingsPagesHeader>
      <Container className='relative z-10 pt-8'>
        {getWorkspace.data && (
          <div className='flex flex-col items-center gap-6'>
            <div className="w-[500px] min-h-[286px] card-body p-6 rounded-2xl bg-background-default">
              <WorkspaceTheme workspace={getWorkspace.data} />
            </div>
            <div className="w-[500px] min-h-[312px] card-body p-6 rounded-2xl bg-background-default">
              <WorkspaceProfile workspace={getWorkspace.data} />
            </div>
          </div>
        )}
      </Container>
    </>
  )
}
