import {
  ChatConfigurations,
  CreateDateConfigurations,
  DateConfigurations,
  FileConfigurations,
  JsonConfigurations,
  NumberConfigurations,
  ReferenceConfigurations,
  SelectConfigurations,
  TextConfigurations,
  UpdateDateConfigurations
} from './components'
import { FieldType } from '@indigohive/cogfy-types'
import { CollectionState, CollectionStateField } from '../../lib'
import { CollectionPageController } from '../../pages/CollectionPageV2/collection-page-controller'
import { createElement } from 'react'
import { ConnectionConfigurations } from './components/ConnectionConfigurations'
import { ScheduleConfigurations } from './components/ScheduleConfigurations'

export type FieldConfigurationsType = typeof ChatConfigurations

export type FieldConfigurationsProps = {
  controller: CollectionPageController
  state: CollectionState
  field: CollectionStateField
}

export const FIELD_CONFIGURATIONS_BY_TYPE: Partial<Record<FieldType, FieldConfigurationsType>> = {
  chat: ChatConfigurations,
  connection: ConnectionConfigurations,
  createDate: CreateDateConfigurations,
  date: DateConfigurations,
  file: FileConfigurations,
  json: JsonConfigurations,
  number: NumberConfigurations,
  reference: ReferenceConfigurations,
  schedule: ScheduleConfigurations,
  select: SelectConfigurations,
  text: TextConfigurations,
  updateDate: UpdateDateConfigurations
}

export function FieldConfigurations (props: FieldConfigurationsProps) {
  const { field } = props

  const fieldConfigurationsComponent = FIELD_CONFIGURATIONS_BY_TYPE[field.type]

  return (
    <>
      {fieldConfigurationsComponent && createElement(fieldConfigurationsComponent, props)}
    </>
  )
}
