import clsx from 'clsx'
import { WhatsAppContentProps } from './WhatsAppContent'

// TODO: Add WhatsApp text formatter
export function WhatsAppContentText (props: WhatsAppContentProps) {
  const { content, className } = props

  return (
    <div className={clsx(className, 'text-sm px-1')}>
      {content}
    </div>
  )
}
