import { useState } from 'react'
import { ChatMiddlewareDataFormProps } from '../ChatMiddlewareDataFormProps'
import { useTranslation } from 'react-i18next'
import { Select } from '../../Select'

export function AzureForm (props: ChatMiddlewareDataFormProps) {
  const { data, onChange } = props

  const { t } = useTranslation()
  const [model, setModel] = useState<string | null>(
    typeof props.data.model === 'string' ? props.data.model : null
  )
  const [temperature, setTemperature] = useState<number | null>(
    typeof props.data.temperature === 'number' ? props.data.temperature : null
  )
  const [maxCompletionTokens, setMaxCompletionTokens] = useState<number | null>(
    typeof props.data.maxCompletionTokens === 'number' ? props.data.maxCompletionTokens : null
  )

  return (
    <>
      <Select
        label={t('Model')}
        size="sm"
        value={model ?? ''}
        onChange={event => {
          if (event.target.value === '') {
            setModel(null)
            onChange({ ...data, model: null })
          } else {
            setModel(event.target.value)
            onChange({ ...data, model: event.target.value })
          }
        }}
        options={[
          { label: 'Select a model', value: '' },
          { label: 'o1-mini', value: 'o1-mini' },
          { label: 'o1-preview', value: 'o1-preview' },
          { label: 'gpt-4o', value: 'gpt-4o' },
          { label: 'gpt-4o-mini', value: 'gpt-4o-mini' },
          { label: 'gpt-4o-realtime-preview', value: 'gpt-4o-realtime-preview' },
          { label: 'gpt-4', value: 'gpt-4' },
          { label: 'gpt-3.5-turbo', value: 'gpt-35-turbo' }
        ]}
      />

      <div className="label">
        <span className="label-text">
          {t('Temperature')}
        </span>
      </div>
      <input
        className="input input-bordered input-sm w-full"
        min={0}
        max={2}
        step={0.1}
        type="number"
        value={temperature ?? ''}
        onChange={event => {
          if (event.target.value === '') {
            setTemperature(null)
            onChange({ ...data, temperature: null })
          } else {
            setTemperature(parseFloat(event.target.value))
            onChange({ ...data, temperature: parseFloat(event.target.value) })
          }
        }}
      />

      <div className="label">
        <span className="label-text">
          {t('Max completion tokens')}
        </span>
      </div>
      <input
        className="input input-bordered input-sm w-full"
        min={0}
        type="number"
        value={maxCompletionTokens ?? ''}
        onChange={event => {
          if (event.target.value === '') {
            setMaxCompletionTokens(null)
            onChange({ ...data, maxCompletionTokens: null })
          } else {
            setMaxCompletionTokens(parseFloat(event.target.value))
            onChange({ ...data, maxCompletionTokens: parseFloat(event.target.value) })
          }
        }}
      />
    </>
  )
}
