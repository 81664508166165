import { useEffect, useState } from 'react'
import { CollectionState } from '../collection-state'

export function useActiveViewId (state: CollectionState) {
  const [fields, setActiveViewId] = useState(state.activeViewId)

  useEffect(() => {
    const activeViewIdChanged = () => {
      setActiveViewId(state.activeViewId)
    }

    state.addEventListener('activeViewIdChanged', activeViewIdChanged)

    return () => {
      state.removeEventListener('activeViewIdChanged', activeViewIdChanged)
    }
  })

  return fields
}
