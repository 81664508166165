import { PropsWithChildren, useState } from 'react'
import { DrawerContext } from '../../contexts'

export type DrawerProviderProps = PropsWithChildren

export function DrawerProvider (props: DrawerProviderProps) {
  const [open, setOpen] = useState(true)

  return (
    <DrawerContext.Provider
      value={{
        open,
        setOpen
      }}
    >
      {props.children}
    </DrawerContext.Provider>
  )
}
