import { EllipsisIcon, TrashIcon } from 'lucide-react'
import { Button, ConfirmDeleteDialog, Menu, OverlayContent } from '../../../components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCogfy, useToasts } from '../../../hooks'
import { useMutation } from '@tanstack/react-query'
import { RunTransactionCommandOperation, UUID } from '@indigohive/cogfy-types'
import { useNavigate } from 'react-router-dom'

export type RecordPageNavbarMenuProps = {
  workspace: string
  collectionId: UUID
  recordId: UUID
}

export function RecordPageNavbarMenu (props: RecordPageNavbarMenuProps) {
  const { workspace, collectionId, recordId } = props

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
  const [deleteRecordModalOpen, setDeleteRecordModalOpen] = useState(false)

  const cogfy = useCogfy()
  const { t } = useTranslation('RecordPage')
  const navigate = useNavigate()
  const toast = useToasts()

  const deleteRecord = useMutation({
    mutationFn: async () => {
      const data: RunTransactionCommandOperation = {
        type: 'soft_delete_records',
        data: {
          collectionId,
          recordIds: [recordId]
        }
      }
      await cogfy.transactions.create({ operations: [data] })
    },
    onSuccess: () => navigate(`/${workspace}/${collectionId}`),
    onError: () => toast.error(t('Failed to delete record'))
  })

  return (
    <>
      <Button
        ghost
        square
        size="sm"
        onClick={event => setMenuAnchorEl(event.currentTarget)}
      >
        <EllipsisIcon size={16} />
      </Button>
      <OverlayContent
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
        anchorEl={menuAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Menu>
          <li>
            <a onClick={() => setDeleteRecordModalOpen(true)}>
              <TrashIcon size={16} />
              {t('Delete record')}
            </a>
          </li>
        </Menu>
      </OverlayContent>

      <ConfirmDeleteDialog
        title={`${t('Delete record')}?`}
        open={deleteRecordModalOpen}
        onConfirm={() => deleteRecord.mutate()}
        onClose={() => setDeleteRecordModalOpen(false)}
      />
    </>
  )
}
