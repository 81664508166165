import { InfoIcon, SearchIcon, MinusIcon, PlusIcon } from 'lucide-react'
import { DEFAULT_FIELD_WIDTH } from '../../../pages/CollectionPageV2/components'
import { FieldOverlayProps } from '../FieldOverlay'
import { useTranslation } from 'react-i18next'
import { SelectFieldData, SelectFieldDataOption, SelectRecordProperty } from '@indigohive/cogfy-types'
import { Button, OptionChip, OptionChipColorName } from '../../../components'
import { useEffect, useState } from 'react'
import { useRecords } from '../../../lib'

const MIN_WIDTH = 200

export function SelectOverlay (props: FieldOverlayProps) {
  const { state, field, viewField, controller } = props

  const { t } = useTranslation()
  const record = useRecords(state)?.find(record => record.id === props.record.id) ?? props.record
  const recordProperties = record?.properties[field.id] as SelectRecordProperty
  const fieldData = field.data as SelectFieldData | undefined
  const multiple = Boolean(fieldData?.select?.multiple ?? false)
  const options = fieldData?.select?.options ?? []
  const [optionsSelected, setOptionsSelected] = useState(() => {
    const _optionsSelected = options
      .filter(option => recordProperties?.select?.value.find(_selected => _selected.id === option.id))
      .sort((a, b) => a.order - b.order)

    if (!multiple) {
      return _optionsSelected.slice(0, 1)
    }

    return _optionsSelected
  })
  const [searchText, setSearchText] = useState('')
  const width = Math.max(viewField.config?.ui?.width ?? DEFAULT_FIELD_WIDTH, MIN_WIDTH)

  useEffect(() => {
    setOptionsSelected(() => {
      const _optionsSelected = options
        .filter(option => recordProperties?.select?.value.find(_selected => _selected.id === option.id))
        .sort((a, b) => a.order - b.order)

      if (!multiple) {
        return _optionsSelected.slice(0, 1)
      }

      return _optionsSelected
    })
  }, [record.properties])

  const updateRecordProperties = (newOptionsSelected: SelectFieldDataOption[]) => {
    setOptionsSelected(newOptionsSelected)
    controller.onUpdateRecordProperties(record.id, {
      [field.id]: {
        type: 'select',
        select: { value: newOptionsSelected.map(({ id }) => ({ id })) }
      }
    })
  }

  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(searchText.toLowerCase())
  )

  const filteredOptionsSelected = optionsSelected.filter(option =>
    option.label.toLowerCase().includes(searchText.toLowerCase())
  )

  return (
    <div
      className="menu-sm bg-base-100 rounded-btn border shadow-lg over max-w-full"
      style={{ width }}
    >
      <div className="px-3 pt-4 pb-2">
        <label className="input input-xs input-bordered flex items-center gap-2">
          <SearchIcon size={14} />
          <input
            autoFocus
            type="input"
            className="w-full h-min"
            placeholder={t('Search')}
            onChange={event => setSearchText(event.target.value)}
          />
        </label>
      </div>
      {(filteredOptions.length === 0 && filteredOptionsSelected.length === 0) && (
        <div className="px-3 py-1 text-xs">
          {t('selectOverlay:No options found')}
        </div>
      )}
      <ul
        className="overflow-y-auto overflow-x-hidden w-full max-h-96"
        style={{ width, scrollbarWidth: 'thin' }}
      >
        {filteredOptionsSelected.length > 0 && (
          <li className="menu-title">
            {t('selectOverlay:Selected', { count: filteredOptionsSelected.length })}
          </li>
        )}
        {filteredOptionsSelected
          .sort((a, b) => a.order - b.order)
          .map(optionSelected => (
            <li
              key={optionSelected.id}
              className="menu-item"
            >
              <a className="flex items-center justify-between">
                <OptionChip
                  label={optionSelected.label}
                  color={optionSelected.color as OptionChipColorName}
                />
                <Button
                  ghost
                  size="xs"
                  square
                  onClick={() => {
                    if (!multiple) {
                      return updateRecordProperties([])
                    }
                    updateRecordProperties(optionsSelected.filter(({ id }) => optionSelected.id !== id))
                  }}
                >
                  <MinusIcon size={16} className="cursor-pointer" />
                </Button>
              </a>
            </li>
          ))
        }
        {filteredOptionsSelected.length !== filteredOptions.length && (
          <li className="menu-title">
            {t('selectOverlay:Options')}
          </li>
        )}
        {filteredOptions
          .filter(option => !optionsSelected.find(optionSelected => optionSelected.id === option.id))
          .sort((a, b) => a.order - b.order)
          .map(option => (
            <li
              key={option.id}
              className="menu-item"
            >
              <a className="flex items-center justify-between gap-1">
                <OptionChip
                  label={option.label}
                  color={option.color as OptionChipColorName}
                />
                <Button
                  ghost
                  size="xs"
                  square
                  onClick={() => {
                    if (!multiple) {
                      return updateRecordProperties([option])
                    }
                    updateRecordProperties([...optionsSelected, option])
                  }}
                >
                  <PlusIcon size={16} className="cursor-pointer" />
                </Button>
              </a>
            </li>
          ))
        }
      </ul>
      <div className="flex gap-1 items-center px-3 py-2 text-slate-400">
        <InfoIcon size={14} />
        <span className="text-xs">
          {multiple ? t('selectOverlay:Multiple selection') : t('selectOverlay:Single selection')}
        </span>
      </div>
    </div>
  )
}
