import { JsonField, JsonViewFieldConfig } from '@indigohive/cogfy-types'
import { FieldConfigurationsProps } from '..'
import { useActiveViewId, useSelectedFieldToUpdate, useViewFields } from '../../../lib'
import { Toggle } from '../../../components'
import { t } from 'i18next'

export function JsonConfigurations (props: FieldConfigurationsProps) {
  const { state, controller } = props

  const viewFields = useViewFields(state)
  const activeViewId = useActiveViewId(state)
  const selectedField = useSelectedFieldToUpdate(state) as JsonField

  const viewField = viewFields?.find(viewField => viewField.fieldId === selectedField?.id && viewField.viewId === activeViewId)
  const viewFieldConfig = viewField?.config as JsonViewFieldConfig

  return (
    <>
      <Toggle
        color="primary"
        label={t('editFieldDrawer:Format content')}
        onChange={({ target }) => {
          if (!viewField) return
          controller.onUpdateViewFieldConfig(viewField.id, { json: { textFormat: target.checked } })
        }}
        checked={Boolean(viewFieldConfig?.json?.textFormat)}
      />
    </>
  )
}
