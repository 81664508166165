import { StopwatchRecordProperty } from '@indigohive/cogfy-types'
import { differenceInSeconds } from 'date-fns/differenceInSeconds'
import { useEffect, useState } from 'react'
import { FieldCellProps } from '../FieldCell'
import { intervalToDuration } from 'date-fns/intervalToDuration'
import { Button } from '../../../components'
import clsx from 'clsx'
import { PauseIcon, PlayIcon } from 'lucide-react'

export function StopwatchCell (props: FieldCellProps) {
  const { record, field, controller } = props

  const elapsed = (record.properties[field.id] as StopwatchRecordProperty)?.stopwatch?.value?.elapsed
  const startDate = (record.properties[field.id] as StopwatchRecordProperty)?.stopwatch?.value?.startDate
  const isRunning = Boolean(startDate)

  const [totalElapsed, setTotalElapsed] = useState(
    (elapsed ?? 0) + differenceInSeconds(new Date(), startDate ?? new Date())
  )

  const parts = intervalToDuration({
    start: new Date(0),
    end: new Date(totalElapsed * 1000)
  })
  const hours = (parts.hours ?? 0).toString().padStart(2, '0')
  const minutes = (parts.minutes ?? 0).toString().padStart(2, '0')
  const seconds = (parts.seconds ?? 0).toString().padStart(2, '0')

  useEffect(() => {
    const timeout = setInterval(() => {
      setTotalElapsed((elapsed ?? 0) + differenceInSeconds(new Date(), startDate ?? new Date()))
    }, 1000)

    return () => clearInterval(timeout)
  }, [elapsed, startDate])

  return (
    <div className="flex items-center gap-1 group min-h-6 px-2 py-1">
      <span className="w-16">
        {hours}:{minutes}:{seconds}
      </span>
      <Button
        circle
        size="xs"
        color={isRunning ? 'info' : undefined}
        onClick={() => {
          const elapsed = totalElapsed
          const startDate = isRunning ? null : new Date().toISOString()

          controller.onUpdateRecordProperties(
            record.id,
            {
              [field.id]: {
                type: 'stopwatch',
                stopwatch: { value: { elapsed, startDate } }
              }
            }
          )
        }}
        className={clsx(isRunning && ['hidden', 'group-hover:flex'])}
      >
        {isRunning && <PauseIcon size={16} />}
        {!isRunning && <PlayIcon size={16} className="ml-1" />}
      </Button>
      {isRunning && (
        <span className="loading loading-ring loading-xs text-success group-hover:hidden ml-1" />
      )}
    </div>
  )
}
