import { RunTransactionCommand, UUID } from '@indigohive/cogfy-types'
import { Command } from '../command-stack'
import { CollectionState, CollectionStateViewField, CollectionStateField } from '../collection-state'
import { v4 as uuid } from 'uuid'

export type CreateViewFieldCommandData = {
  fieldId: UUID
  viewId: UUID
}

export class CreateViewFieldCommand implements Command<RunTransactionCommand> {
  private readonly _state: CollectionState
  private readonly _data: CreateViewFieldCommandData
  private readonly _fieldToAdd?: CollectionStateField
  private readonly _viewFieldToCreate: CollectionStateViewField

  constructor (
    state: CollectionState,
    data: CreateViewFieldCommandData
  ) {
    this._state = state
    this._data = data
    this._fieldToAdd = state.fields?.find(field => field.id === data.fieldId)
    this._viewFieldToCreate = {
      id: uuid() as UUID,
      viewId: this._data.viewId,
      fieldId: this._fieldToAdd!.id,
      order: this._fieldToAdd!.order!
    }
  }

  run (): RunTransactionCommand {
    const newViewFields = [...this._state.viewFields!]
    newViewFields.splice(this._fieldToAdd!.order!, 0, this._viewFieldToCreate)
    this._state.setViewFields(newViewFields)

    return {
      operations: [
        {
          type: 'create_view_field',
          data: {
            collectionId: this._state.id,
            viewFieldId: this._viewFieldToCreate.id,
            viewId: this._viewFieldToCreate.viewId,
            fieldId: this._viewFieldToCreate.fieldId,
            order: this._fieldToAdd!.order!
          }
        }
      ]
    }
  }

  undo (): RunTransactionCommand {
    const newViewFields = this._state.viewFields?.filter(viewField => viewField.id !== this._viewFieldToCreate.id) ?? []
    this._state.setViewFields(newViewFields)

    return {
      operations: [
        {
          type: 'delete_view_field',
          data: {
            collectionId: this._state.id,
            viewFieldId: this._viewFieldToCreate.id
          }
        }
      ]
    }
  }
}
