import clsx, { ClassValue } from 'clsx'
import { DataTableCol } from '../DataTable'
import { PropsWithChildren } from 'react'

export type DataTableCellProps<T> = PropsWithChildren<{
  className?: ClassValue
  row?: T
  col?: DataTableCol<T>
  rowIndex?: number
  colIndex?: number
  wrap?: boolean
  width?: number | string
  selected?: boolean
  selectedRange?: boolean
  onClick?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    row: T,
    col: DataTableCol<T>,
    rowIndex: number,
    colIndex: number
  ) => void
}>

export function DataTableCell<T> (props: DataTableCellProps<T>) {
  const { className, row, col, rowIndex, colIndex, wrap, width, selected, selectedRange, onClick, children } = props

  return (
    <div
      className={clsx(
        'flex',
        'items-start',
        'content-stretch',
        'overflow-x-hidden',
        'has-[.tooltip]:overflow-visible',
        'relative',
        onClick && 'cursor-pointer',
        !wrap && 'truncate',
        className
      )}
      onClick={event => row && col && onClick?.(event, row, col, rowIndex!, colIndex!)}
      style={{ width, minWidth: width }}
    >
      {selectedRange && (
        <div
          className={clsx(
            'w-full',
            'h-full',
            'bg-info',
            'absolute',
            'bg-opacity-10',
            selected && 'border border-info'
          )}
        />
      )}
      {children}
    </div>
  )
}
