import { DownloadIcon, FileIcon, XCircleIcon } from 'lucide-react'
import { FileUploadStatus, UUID } from '@indigohive/cogfy-types'
import mime from 'mime-types'
import { useTranslation } from 'react-i18next'
import { Button, Skeleton } from '../../../components'
import { useCogfy } from '../../../hooks'
import { useMutation } from '@tanstack/react-query'

export type ChatMessageFileProps = {
  file: {
    id: UUID
    name: string | null
    size: number
    type: string
    uploadStatus?: FileUploadStatus
  }
  onDelete?: (name: string | null) => void
}

export function ChatMessageFile (props: ChatMessageFileProps) {
  const { file, onDelete } = props

  const cogfy = useCogfy()
  const fileExtension = mime.extension(file.type)
  const { t } = useTranslation()

  const showFile = useMutation({
    mutationFn: async (chatMessageFileId: UUID) => {
      const file = await cogfy.getChatMessageFileById({ chatMessageFileId })

      window.open(`${file.url}`, '_blank')
    }
  })

  const byteValueNumberFormatter = Intl.NumberFormat('en', {
    notation: 'compact',
    style: 'unit',
    unit: 'byte',
    unitDisplay: 'narrow'
  })

  return (
    <div className="flex flex-row justify-between items-center rounded-lg bg-white p-2 group">
      <div className="flex flex-row gap-2 items-center">
        <span className="bg-rose-400 p-2 rounded-lg text-white flex">
          {file.uploadStatus === 'pending' && <div className="loading loading-spinner loading-sm h-5" />}
          {file.uploadStatus !== 'pending' && <FileIcon size={16} />}
        </span>
        <div className="flex flex-col gap-1 max-w-48 text-xs overflow-hidden text-nowrap">
          {file.uploadStatus !== 'pending' && (
            <>
              <p className="overflow-ellipsis overflow-hidden">{file?.name ? file.name : t('Untitled')}</p>
              <p className="text-gray-400">
                {byteValueNumberFormatter.format(file.size)}
                {fileExtension && ` • ${fileExtension.toUpperCase()}`}
              </p>
            </>
          )}
          {file.uploadStatus === 'pending' && (
            <>
              <Skeleton className="h-2 w-24" />
              <div className="flex gap-2">
                <Skeleton className="h-2 w-8" />
                <Skeleton className="h-2 w-8" />
              </div>
            </>
          )}
        </div>
        {file.uploadStatus === 'sent' && (
          <Button
            ghost
            size="xs"
            square
            onClick={event => {
              event.preventDefault()
              showFile.mutate(file.id)
            }}
          >
            <DownloadIcon size={16} />
          </Button>
        )}
      </div>
      {onDelete && (
        <button
          onClick={() => onDelete(file.id)}
          className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 p-0 opacity-0 group-hover:opacity-100 text-error"
        >
          <XCircleIcon size={16} />
        </button>
      )}
    </div>
  )
}
