import type { CogfyClient } from '@indigohive/cogfy-api-client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useState, type PropsWithChildren, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { CogfyClientContext, WorkspaceSlugContext } from '../../contexts'

const unauthenticatedRoutes = [
  '',
  'sign-in',
  'forgot-password',
  'reset-password',
  'totp-setup',
  'totp-sign-in'
]

export type CogfyClientProviderProps = PropsWithChildren<{
  client: CogfyClient
}>

export function CogfyClientProvider (props: CogfyClientProviderProps) {
  const { pathname } = useLocation()
  const [workspace, setWorkspace] = useState(() => {
    const slug = pathname.split('/')[1]

    if (unauthenticatedRoutes.includes(slug)) {
      return null
    } else {
      return slug
    }
  })

  const [queryClient] = useState(() => new QueryClient({
    defaultOptions: {
      queries: {
        retry: false
      }
    }
  }))

  useEffect(() => {
    const workspaceSlug = pathname.split('/')[1]

    if (workspaceSlug !== workspace && !unauthenticatedRoutes.includes(workspaceSlug)) {
      setWorkspace(workspaceSlug)
      queryClient.resetQueries({
        predicate: (query) => !['getAuthenticatedUser'].includes(query.queryKey[0] as string)
      })
    }
  }, [pathname])

  return (
    <CogfyClientContext.Provider value={props.client}>
      <WorkspaceSlugContext.Provider value={workspace ?? ''}>
        <QueryClientProvider client={queryClient}>
          {props.children}
        </QueryClientProvider>
      </WorkspaceSlugContext.Provider>
    </CogfyClientContext.Provider>
  )
}
