import { GetCollectionResult } from '@indigohive/cogfy-types'
import {
  DownloadIcon,
  EllipsisIcon,
  LockIcon,
  LockOpenIcon,
  TrashIcon
} from 'lucide-react'
import { Button, ConfirmDeleteDialog, Menu, Navbar, OverlayContent } from '../../../../components'
import { CollectionBreadcrumb } from '../CollectionBreadcrumb'
import { useState } from 'react'
import { CollectionState, useLocked } from '../../../../lib'
import { useAppManager, useCogfy, useToasts, useWorkspaceSlug } from '../../../../hooks'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { CollectionAccessControlEditor } from '../CollectionAccessControlEditor'

export type CollectionNavbarProps = {
  state: CollectionState
  collection?: GetCollectionResult
  loading?: boolean
}

export function CollectionNavbar (props: CollectionNavbarProps) {
  const { state, collection, loading } = props

  const [deleteCollectionModalOpen, setDeleteCollectionModalOpen] = useState(false)
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null)
  const [accessControlAnchorEl, setAccessControlAnchorEl] = useState<HTMLElement | null>(null)
  const collectionId = state.id
  const activeViewId = state.activeViewId
  const { t } = useTranslation()
  const cogfy = useCogfy()
  const locked = useLocked(state)
  const appManager = useAppManager()
  const workspaceSlug = useWorkspaceSlug()
  const navigate = useNavigate()
  const toasts = useToasts()

  const handleLock = () => {
    appManager.lockCollection(state)
    setMenuAnchorEl(null)
  }

  const handleUnlock = () => {
    appManager.unlockCollection(state)
    setMenuAnchorEl(null)
  }

  const handleExportCsv = () => {
    window.open(
      cogfy.collections.exportCsvUrl(workspaceSlug, collectionId, activeViewId),
      '_blank'
    )
  }

  const handleExportXlsx = () => {
    window.open(
      cogfy.collections.exportXlsxUrl(workspaceSlug, collectionId, activeViewId),
      '_blank'
    )
  }

  const handleDeleteCollection = useMutation({
    mutationFn: async () => {
      await cogfy.transactions.create({ operations: [{ type: 'soft_delete_collection', data: { collectionId } }] })
    },
    onError: () => toasts.error(t('Failed to delete collection')),
    onSuccess: () => navigate(`/${workspaceSlug}`)
  })

  return (
    <Navbar>
      <div className="flex items-center w-full">
        <div className="grow">
          <CollectionBreadcrumb collection={collection} loading={loading} />
        </div>
        <div className="flex items-center gap-2">
          {!loading && (
            <div className="flex items-center gap-2 text-gray-600">
              {locked ? <LockIcon size={16} /> : <LockOpenIcon size={16} />}
            </div>
          )}
          <Button
            ghost
            size="sm"
            onClick={event => setAccessControlAnchorEl(event.currentTarget)}
          >
            Share
          </Button>
          <Button
            ghost
            square
            size="sm"
            disabled={loading}
            onClick={event => setMenuAnchorEl(event.currentTarget)}
          >
            <EllipsisIcon size={16} />
          </Button>
        </div>
      </div>
      <OverlayContent
        open={Boolean(accessControlAnchorEl)}
        onClose={() => setAccessControlAnchorEl(null)}
        anchorEl={accessControlAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <CollectionAccessControlEditor collectionId={collectionId} />
      </OverlayContent>
      <OverlayContent
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
        anchorEl={menuAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Menu>
          {locked && (
            <li>
              <a onClick={handleUnlock}>
                <LockOpenIcon size={16} />
                {t('Unlock')}
              </a>
            </li>
          )}
          {!locked && (
            <li>
              <a onClick={handleLock}>
                <LockIcon size={16} />
                {t('Lock')}
              </a>
            </li>
          )}
          <li>
            <a onClick={handleExportCsv}>
              <DownloadIcon size={16} />
              {t('Export CSV')}
            </a>
          </li>
          <li>
            <a onClick={handleExportXlsx}>
              <DownloadIcon size={16} />
              {t('Export XLSX')}
            </a>
          </li>
          <li>
            <a onClick={() => setDeleteCollectionModalOpen(true)}>
              <TrashIcon size={16} />
              {t('Delete collection')}
            </a>
          </li>
        </Menu>
      </OverlayContent>
      <ConfirmDeleteDialog
        title={`${t('Delete collection')}${collection?.name ? ` ${collection.name}` : ''}?`}
        open={deleteCollectionModalOpen}
        onConfirm={() => handleDeleteCollection.mutate()}
        onClose={() => setDeleteCollectionModalOpen(false)}
      />
    </Navbar>
  )
}
