export function avatarInitials (name: string): string {
  const words = name.split(' ')

  if (words.length === 0) {
    return ''
  } else if (words.length === 1) {
    return words[0].substring(0, 1).toUpperCase()
  } else {
    const firstWordInitial = words.at(0)!.substring(0, 1).toUpperCase()
    const lastWordInitial = words.at(-1)!.substring(0, 1).toUpperCase()

    return firstWordInitial + lastWordInitial
  }
}
