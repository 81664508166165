import type {
  GetCollectionTemplatesPageQuery,
  GetCollectionTemplatesPageResult
} from '@indigohive/cogfy-types'
import { BaseClient } from './base-client'

export class CollectionTemplatesClient extends BaseClient {
  async getPage (
    params: GetCollectionTemplatesPageQuery = {},
    signal?: AbortSignal
  ): Promise<GetCollectionTemplatesPageResult> {
    return (await this.axios.get('/collection-templates', { params, signal })).data
  }
}
