import { PropsWithChildren, useEffect } from 'react'
import { useAuthentication, useCogfy } from '../../hooks'
import { useLocation, useNavigate } from 'react-router-dom'

export type AuthenticationGuardProps = PropsWithChildren

export function AuthenticationGuard (props: AuthenticationGuardProps) {
  const authentication = useAuthentication()
  const navigate = useNavigate()
  const cogfy = useCogfy()
  const location = useLocation()

  useEffect(() => {
    if (!authentication.loading && !authentication.authenticatedUser) {
      if (location.pathname === '/') {
        navigate('/sign-in')
      } else if (location.pathname === '/totp-sign-in') {
        // Do nothing
      } else {
        navigate(`/sign-in?redirectTo=${location.pathname + location.search}`)
      }
    }
  }, [authentication, location.pathname])

  useEffect(() => {
    if (authentication.authenticatedUser && cogfy.disconnected()) {
      cogfy.connect()
    }
  }, [authentication.authenticatedUser])

  return (
    <>
      {authentication.loading && <progress className="progress w-full" />}
      {authentication.authenticatedUser && props.children}
    </>
  )
}
