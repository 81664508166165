import { MemberRole } from '@indigohive/cogfy-types'
import { Select } from '../../Select'
import { useTranslation } from 'react-i18next'

export type MemberRoleSelectProps = {
  onChange: (value: MemberRole) => void
  selectedRole: MemberRole
}

export function MemberRoleSelect (props: MemberRoleSelectProps) {
  const { selectedRole, onChange } = props
  const { t } = useTranslation()

  const options: { value: MemberRole, label: string }[] = [
    { value: 'admin', label: 'Admin' },
    { value: 'member', label: t('Member') }
  ]

  return (
    <Select
      size="xs"
      className="w-24"
      options={options}
      value={selectedRole}
      onChange={event => onChange(event.target.value as MemberRole)}
    />
  )
}
