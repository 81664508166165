import { ClipboardIcon, XIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { CollectionState, useActiveViewId, useFields, useSelectedFieldToUpdate, useViewFields } from '../../../../lib'
import { CollectionPageController } from '../../collection-page-controller'
import { Button, Input, Toggle } from '../../../../components'
import { useEffect, useState } from 'react'
import { FieldConfigurations } from '../../../../field-types'
import { EditFieldDrawerResizeHandler } from './subcomponents'
import { OperationForm } from '../OperationForm'
import { useDrawerWidth } from '../../../../hooks'

const DEFAULT_MIN_WIDTH = 320

export type EditFieldDrawerProps = {
  state: CollectionState
  controller: CollectionPageController
}

export function EditFieldDrawer (props: EditFieldDrawerProps) {
  const { state, controller } = props

  const { width, handleResizeFieldDrawer, onStopResizeFieldDrawer } = useDrawerWidth('field-drawer-width', DEFAULT_MIN_WIDTH, DEFAULT_MIN_WIDTH)
  const { t } = useTranslation()
  const [recentlyCopiedFieldId, setRecentlyCopiedFieldId] = useState(false)
  const selectedField = useSelectedFieldToUpdate(state)
  const viewFields = useViewFields(state)
  const fields = useFields(state)
  const activeViewId = useActiveViewId(state)
  const [name, setName] = useState(selectedField?.name ?? '')

  useEffect(() => {
    setName(selectedField?.name ?? '')
  }, [selectedField?.name])

  const viewField = viewFields?.find(viewField => viewField.fieldId === selectedField?.id && viewField.viewId === activeViewId)
  const fieldNameIsRepeated = fields?.some(field => field?.name.trim() === name.trim() && selectedField?.id !== field.id)
  const fieldNameIsEmpty = !name.trim()
  const fieldNameIsInvalid = name.includes('[') || name.includes(']')

  const handleCopyFieldId = () => {
    if (selectedField) {
      setRecentlyCopiedFieldId(true)
      navigator.clipboard.writeText(selectedField.id)
      setTimeout(() => {
        setRecentlyCopiedFieldId(false)
      }, 2000)
    }
  }

  return (
    <div className="z-[1]">
      {selectedField && viewField && (
        <div
          className="flex flex-row border h-full bg-base-100 overflow-y-hidden"
          style={{
            width,
            minWidth: width
          }}
        >
          <EditFieldDrawerResizeHandler
            state={state}
            onDrag={change => handleResizeFieldDrawer(-change)}
            onDragStop={onStopResizeFieldDrawer}
          />
          <div
            className="flex flex-col gap-2 overflow-y-auto w-full p-4 overflow-x-hidden"
            style={{ scrollbarWidth: 'thin' }}
          >
            <div className="flex">
              <h3 className="font-bold grow">
                {t('editFieldDrawer:Field options')}
              </h3>
              <Button circle size="xs" onClick={() => controller.onCloseEditFieldDrawer()}>
                <XIcon size={16} />
              </Button>
            </div>
            <div className="flex text-sm">
              <div className="grow">{t('Type')}</div>
              <span>{t(`${selectedField.type.charAt(0).toUpperCase() + selectedField.type.slice(1)}`)}</span>
            </div>
            <Input
              value={name}
              label={t('Name')}
              size="sm"
              onChange={event => setName(event.target.value)}
              onBlur={() => {
                if (!fieldNameIsRepeated && !fieldNameIsEmpty && !fieldNameIsInvalid) {
                  controller.onFieldRenamed(selectedField, name)
                }
              }}
              error={fieldNameIsRepeated || fieldNameIsEmpty || fieldNameIsInvalid}
              helperText={
                (fieldNameIsRepeated && t('editFieldDrawer:Field name already exists')) ||
                (fieldNameIsEmpty && t('editFieldDrawer:Field name is empty')) ||
                (fieldNameIsInvalid && t('editFieldDrawer:Field name is invalid'))
              }
            />
            <Toggle
              color="primary"
              label={t('editFieldDrawer:Wrap content')}
              onChange={({ target }) => {
                controller.onUpdateViewFieldConfig(viewField.id, { ui: { textWrap: target.checked } })
              }}
              checked={Boolean(viewField?.config?.ui?.textWrap)}
            />
            <FieldConfigurations
              controller={controller}
              state={state}
              field={selectedField}
            />

            <div className="divider h-1 my-2" />

            <OperationForm state={state} controller={controller} />

            <span className="text-xs">
              <span className="opacity-70">
                Field Id: {selectedField.id}
              </span>
              <div
                className="tooltip"
                data-tip={recentlyCopiedFieldId ? 'Copied!' : 'Copy field id'}
              >
                <button
                  className="btn btn-circle btn-ghost btn-xs ml-1 opacity-70"
                  onClick={handleCopyFieldId}
                >
                  <ClipboardIcon size={12} />
                </button>
              </div>
            </span>
          </div>
        </div>
      )}
    </div>
  )
}
