import { UUID } from '@indigohive/cogfy-types'
import { BaseClient } from './base-client'

export class FieldsClient extends BaseClient {
  async clearWhatsApp (
    fieldId: UUID
  ): Promise<void> {
    await this.axios.post(`/fields/${fieldId}/clear-whatsapp`)
  }
}
