import type {
  FieldOperationConfigConnectionInput,
  FieldOperationConfigFieldsInput,
  FieldType,
  OperationInputType,
  OperationSchemaInput,
  UUID
} from '@indigohive/cogfy-types'
import { GetConnectionsListQuery } from '@indigohive/cogfy-types/endpoints/getConnectionsList'
import { ProviderName } from '@indigohive/cogfy-providers'
import { useQuery } from '@tanstack/react-query'
import { Select } from '../../../../../components'
import { CollectionPageController } from '../../../collection-page-controller'
import { useCogfy } from '../../../../../hooks'
import { CollectionState, useSelectedFieldToUpdate } from '../../../../../lib'

export type FieldOperationConnectionInputValue = {
  id: UUID
  name: string
  type: FieldType
}

export type FieldOperationConnectionInputProps = {
  controller: CollectionPageController
  state: CollectionState
  inputName: string
  input: OperationSchemaInput<OperationInputType>
  fields: FieldOperationConnectionInputValue[]
  type: 'connection' | ProviderName
}

export function FieldOperationConnectionInput (props: FieldOperationConnectionInputProps) {
  const { controller, state, input, inputName, fields, type } = props
  const selectedField = useSelectedFieldToUpdate(state)

  // Connections
  const cogfy = useCogfy()
  const query: GetConnectionsListQuery = {
    providers: type === 'connection' ? input.providers : [type]
  }
  const getConnections = useQuery({
    queryKey: ['getConnectionsList', query] as const,
    queryFn: ({ queryKey, signal }) => cogfy.getConnectionsList(queryKey[1], { signal })
  })
  const connections = getConnections.data?.data ?? []

  // Fields
  const connectionFields = fields.filter(field => field.type === 'connection')

  // Merge options
  const options: { value: string, label: string }[] = [
    ...connections.map(connection => ({
      value: `connection:${connection.id}`,
      label: `Connection: ${connection.name}`
    })),
    ...connectionFields.map(field => ({
      value: `field:${field.id}`,
      label: `Field: ${field.name}`
    }))
  ]

  if (input.providers?.length === 1 || type !== 'connection') {
    options.unshift({ value: '', label: 'Default connection' })
  }

  const configInput = selectedField?.operationConfig?.[inputName] as
    FieldOperationConfigFieldsInput |
    FieldOperationConfigConnectionInput |
    undefined

  const value = configInput
    ? (configInput.type === 'fields'
        ? `field:${configInput.fields.value[0].id}`
        : `connection:${configInput.connection.value.id}`
      )
    : ''

  return (
    <div>
      <Select
        className="my-1"
        size="sm"
        value={value}
        onChange={event => {
          const value = event.target.value

          if (value.startsWith('connection:')) {
            const connectionId = value.replace('connection:', '')
            const connection = connections.find(connection => connection.id === connectionId)

            if (connection) {
              controller.onOperationChange(
                selectedField!.id,
                selectedField!.operation ?? null,
                selectedField!.recalculateStrategy,
                {
                  ...selectedField!.operationConfig,
                  [inputName]: {
                    type: 'connection',
                    connection: {
                      value: {
                        id: connectionId as UUID,
                        provider: connection.provider
                      }
                    }
                  }
                }
              )
            }
          } else if (value.startsWith('field:')) {
            const fieldId = value.replace('field:', '')
            const field = connectionFields.find(field => field.id === fieldId)
            if (field) {
              controller.onOperationChange(
                selectedField!.id,
                selectedField!.operation ?? null,
                selectedField!.recalculateStrategy,
                {
                  ...selectedField!.operationConfig,
                  [inputName]: {
                    type: 'fields',
                    fields: {
                      value: [{ id: fieldId as UUID }]
                    }
                  }
                }
              )
            }
          } else if (value === '') {
            const newConfig = { ...selectedField!.operationConfig }
            delete newConfig[inputName]
            controller.onOperationChange(
              selectedField!.id,
              selectedField!.operation ?? null,
              selectedField!.recalculateStrategy,
              newConfig
            )
          }
        }}
        options={options}
      />
    </div>
  )
}
