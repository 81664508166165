import { Socket } from 'socket.io-client'

export class SocketEvent<T> {
  private readonly socket: Socket
  private readonly event: string

  constructor (socket: Socket, event: string) {
    this.socket = socket
    this.event = event
  }

  add (callback: (data: T) => void) {
    this.socket.on(this.event, callback)
  }

  remove (callback: (data: T) => void) {
    this.socket.off(this.event, callback)
  }
}
