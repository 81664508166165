import { CreateWebhookCommand, DeleteWebhooksCommand, GetWebhooksPageQuery, GetWebhooksPageResult } from '@indigohive/cogfy-types'
import { BaseClient, BaseClientOptions } from './base-client'

export class WebhooksClient extends BaseClient {

  constructor (options: BaseClientOptions) {
    super(options)
  }

  /**
   * Calls the `POST /webhooks` endpoint.
   * @param data The request body.
   * @returns The response body.
   */
  async create (
    data: CreateWebhookCommand
  ): Promise<void> {
    await this.axios.post('/webhooks', data)
  }

  /**
   * Calls the `DELETE /webhooks` endpoint.
   * @param data The request body.
   */
  async delete (
    data: DeleteWebhooksCommand
  ): Promise<void> {
    await this.axios.post('/webhooks/delete', data)
  }

  /**
   * Calls the `GET /webhooks` endpoint.
   * @param params The request query params.
   * @param signal The abort signal.
   */
  async getPage (
    params: GetWebhooksPageQuery,
    signal?: AbortSignal
  ): Promise<GetWebhooksPageResult> {
    return (await this.axios.get('/webhooks', { params, signal })).data
  }
}
