import { FieldOperationConfigTextInput, OperationInputType, OperationSchemaInput } from '@indigohive/cogfy-types'
import { CollectionState, useSelectedFieldToUpdate } from '../../../../../lib'
import { CollectionPageController } from '../../../collection-page-controller'
import { MultiSelect, MultiSelectOption } from '../../../../../components/MultiSelect'
import { useTranslation } from 'react-i18next'

export type FieldOperationMultipleTextOptionsInputProps = {
  controller: CollectionPageController
  state: CollectionState
  inputName: string
  input: OperationSchemaInput<OperationInputType>
}

export function FieldOperationMultipleTextOptionsInput (props: FieldOperationMultipleTextOptionsInputProps) {
  const { controller, state, inputName, input } = props

  const { t } = useTranslation('operationForm')
  const selectedField = useSelectedFieldToUpdate(state)

  const options: MultiSelectOption[] = input.options?.map(({ value, label }) => (
    { value, label }
  )) ?? []
  const configInput = selectedField?.operationConfig?.[inputName] as FieldOperationConfigTextInput | undefined
  const defaultValue = Array.isArray(input.default) ? input.default : []
  const values = [...configInput?.text.value ?? defaultValue]

  return (
    <MultiSelect
      options={options}
      labels={{
        selectItems: t('Select options'),
        allItems: t('All selected'),
        noResultsFound: t('No results found')
      }}
      selected={values}
      onChange={(selected: string) => {
        const newValues = values.some(value => value === selected)
          ? values.filter(item => item !== selected)
          : [...values, selected]
        controller.onOperationChange(
          selectedField!.id,
          selectedField!.operation ?? null,
          selectedField!.recalculateStrategy ?? 'always',
          {
            ...selectedField!.operationConfig,
            [inputName]: { type: 'text', text: { value: newValues } }
          }
        )
      }}
      onClearSelections={() => {
        controller.onOperationChange(
          selectedField!.id,
          selectedField!.operation ?? null,
          selectedField!.recalculateStrategy ?? 'always',
          {
            ...selectedField!.operationConfig,
            [inputName]: { type: 'text', text: { value: [] } }
          }
        )
      }}
      onSelectAll={() => {
        const newValues = options.map(option => option.value)
        controller.onOperationChange(
          selectedField!.id,
          selectedField!.operation ?? null,
          selectedField!.recalculateStrategy ?? 'always',
          {
            ...selectedField!.operationConfig,
            [inputName]: { type: 'text', text: { value: newValues } }
          }
        )
      }}
    />
  )
}
