import { useQuery } from '@tanstack/react-query'
import { PropsWithChildren, useEffect } from 'react'
import { AuthorizationContext } from '../../contexts'
import { useAuthentication, useCogfy, useWorkspace, useWorkspaceSlug } from '../../hooks'
import i18n from 'i18next'

export type AuthorizationProviderProps = PropsWithChildren

export function AuthorizationProvider (props: AuthorizationProviderProps) {
  const authentication = useAuthentication()
  const workspaceSlug = useWorkspaceSlug()
  const workspace = useWorkspace(workspaceSlug).data
  const workspaceId = workspace?.id

  useEffect(() => {
    i18n.changeLanguage(workspace?.locale)
  }, [workspace?.locale])

  const cogfy = useCogfy()
  const getCurrentUserRole = useQuery({
    queryKey: ['getCurrentUserRole', workspaceSlug, authentication.authenticatedUser],
    queryFn: async ({ signal }) => {
      if (authentication.authenticatedUser == null) {
        return null
      } else {
        const result = await cogfy.getCurrentUserRole({ signal })
        return result
      }
    },
    enabled: Boolean(workspaceSlug && workspaceId && cogfy.getWorkspace)
  })

  return (
    <AuthorizationContext.Provider
      value={{
        loading: getCurrentUserRole.isLoading,
        refetch: () => { getCurrentUserRole.refetch() },
        isAdmin: getCurrentUserRole.data?.role === 'admin',
        hasPermission: () => (
          getCurrentUserRole.data?.role === 'admin'
        )
      }}
    >
      {props.children}
    </AuthorizationContext.Provider>
  )
}
