import {
  CheckCircleIcon,
  AlertCircleIcon,
  XCircleIcon
} from 'lucide-react'
import type { ChatMessageStatus as ChatMessageStatusType, GetChatMessagesPageResultData } from '@indigohive/cogfy-types'
import clsx from 'clsx'
import { createElement } from 'react'
import { useTranslation } from 'react-i18next'

const ErrorMessageIconComponent = () => <span className="text-error"><AlertCircleIcon size={16} /></span>
const LoadingMessageIconComponent = () => <span className="text-slate-400 loading loading-ring loading-xs" />
const SuccessMessageIconComponent = () => <span className="text-success"><CheckCircleIcon size={16} /></span>
const PendingMessageIconComponent = () => <span className="text-warning"><AlertCircleIcon size={16} /></span>
const CanceledMessageIconComponent = () => <span className="text-error"><XCircleIcon size={16} /></span>

export const CHAT_MESSAGE_STATUS_ICON_BY_STATUS: Partial<Record<ChatMessageStatusType, () => JSX.Element>> = {
  queued: LoadingMessageIconComponent,
  sending: LoadingMessageIconComponent,
  failed: ErrorMessageIconComponent,
  undelivered: ErrorMessageIconComponent,
  receiving: LoadingMessageIconComponent,
  confirmed: SuccessMessageIconComponent,
  confirming: PendingMessageIconComponent,
  canceled: CanceledMessageIconComponent,
  error: ErrorMessageIconComponent
}

export type ChatMessageStatusProps = {
  message: GetChatMessagesPageResultData
  position?: 'start' | 'end'
}

export function ChatMessageStatus (props: ChatMessageStatusProps) {
  const { message, position } = props

  const status = ((message.data as any)?.status ?? message.status) as ChatMessageStatusType

  if (!status) {
    return
  }

  const { t } = useTranslation('chatPage')

  const CHAT_MESSAGE_STATUS_LABEL_BY_STATUS: Partial<Record<ChatMessageStatusType, JSX.Element>> = {
    queued: <span className="text-slate-400">{t('Sending message')}</span>,
    sending: <span className="text-slate-400">{t('Sending message')}</span>,
    failed: <span className="text-error">{t('Message not sent')}</span>,
    undelivered: <span className="text-error">{t('Message not sent')}</span>,
    receiving: <span className="text-slate-400">{t('Sending message')}</span>,
    canceled: <span className="text-error">{t('Canceled')}</span>,
    confirmed: <span className="text-success">{t('Confirmed')}</span>,
    confirming: <span className="text-warning">{t('Pending')}</span>,
    error: <span className="text-error">{t('Error')}</span>
  }

  return (
    <>
      {CHAT_MESSAGE_STATUS_ICON_BY_STATUS[status] && (
        <div
          className={clsx(
            'flex',
            'items-center',
            'gap-1',
            'mt-1',
            'text-sm',
            position === 'start' ? 'justify-start ml-14' : 'justify-end mr-4'
          )}
        >
          {createElement(
            CHAT_MESSAGE_STATUS_ICON_BY_STATUS[status]
          )}
          {CHAT_MESSAGE_STATUS_LABEL_BY_STATUS[status]}
        </div>
      )}
    </>
  )
}
