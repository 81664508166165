import { CreateDateFieldData, CreateDateRecordProperty } from '@indigohive/cogfy-types'
import clsx from 'clsx'
import { FormattedDate } from '../../../../components'
import { FieldCellProps } from '../../FieldCell'

export type CreateDateCellProps = Pick<
FieldCellProps<CreateDateRecordProperty, CreateDateFieldData>,
'property' | 'field' | 'textWrap'
>

export function CreateDateCell (props: CreateDateCellProps) {
  const { field, property, textWrap } = props

  return (
    <span
      className={clsx(
        'px-2 py-1',
        textWrap && 'whitespace-pre-wrap',
        !textWrap && 'truncate'
      )}
    >
      <FormattedDate
        date={property?.createDate?.value}
        format={field.data?.createDate?.format ?? 'Pp'}
      />
    </span>
  )
}
