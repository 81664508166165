import clsx, { ClassValue } from 'clsx'
import { PropsWithChildren, ReactNode, useContext, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { OverlayContext } from '../../contexts'
import { calculateLeft, calculateTop } from './_methods'

export type OverlayContentProps = PropsWithChildren<{
  className?: ClassValue
  open?: boolean
  anchorEl?: Element | null
  anchorPosition?: { top: number, left: number }
  anchorOrigin?: {
    vertical?: 'top' | 'center' | 'bottom'
    horizontal?: 'left' | 'center' | 'right'
  }
  transformOrigin?: {
    vertical?: 'top' | 'center' | 'bottom'
    horizontal?: 'left' | 'center' | 'right'
  }
  onClose?: (event: MouseEvent) => void
}>

export function OverlayContent (props: OverlayContentProps): ReactNode {
  const { anchorEl, anchorPosition, anchorOrigin, transformOrigin, open } = props
  const { ref: overlayProviderRef } = useContext(OverlayContext)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      const handler = (event: MouseEvent) => {
        if (!ref.current?.contains(event.target as Node)) {
          props.onClose?.(event)
        }
      }

      document.body.addEventListener('mousedown', handler)

      return () => document.body.removeEventListener('mousedown', handler)
    }
  }, [props])

  if (!overlayProviderRef?.current || !open) {
    return null
  }

  const overlayRect = overlayProviderRef.current?.getBoundingClientRect()
  const anchorRect = anchorEl?.getBoundingClientRect()
  const childRect = ref.current?.getBoundingClientRect()

  let top = anchorPosition
    ? anchorPosition.top
    : calculateTop(
      overlayRect, anchorRect, childRect, anchorOrigin?.vertical, transformOrigin?.vertical
    )
  let left = anchorPosition
    ? anchorPosition.left
    : calculateLeft(
      overlayRect, anchorRect, childRect, anchorOrigin?.horizontal, transformOrigin?.horizontal
    )

  if (childRect && top !== undefined && (top + childRect.height) > window.innerHeight) {
    top = window.innerHeight - childRect.height
  }

  if (childRect && left !== undefined && (left + childRect.width) > window.innerWidth) {
    left = window.innerWidth - childRect.width
  }

  return (
    createPortal(
      <div
        ref={ref}
        className={clsx('absolute', props.className)}
        style={{ top, left }}
      >
        {props.children}
      </div>,
      overlayProviderRef.current
    )
  )
}
