import { GetChatMessagesPageResultData, GetChatResult, GetCollectionResult, SignInResult, UserChatMessageData, UUID } from '@indigohive/cogfy-types'
import { isSameDay, parseISO } from 'date-fns'
import { ChatBubble, ChatDate, ChatMessageFile, ChatMessageReaction, ChatMessageStatus, ChatSourcesBubbleContent, ChatToolConfirmation } from '.'
import clsx from 'clsx'
import { FormattedText } from '../../../components'
import { TFunction } from 'i18next'

export type ChatPageMessagesContainerProps = {
  messages: GetChatMessagesPageResultData[]
  chat?: GetChatResult
  collection?: GetCollectionResult
  authenticatedUser?: SignInResult | null
  translation: TFunction<'translation', undefined>
  onMessageReaction?: () => void
  onUpdateMessageStatus?: (messageId: UUID) => void
}

export function ChatPageMessagesContainer (props: ChatPageMessagesContainerProps) {
  const { messages, chat, collection, authenticatedUser, translation } = props

  if (!chat || !collection) return <></>

  return (
    <>
      {messages.map((message, index) => {
        const prevMessage = messages[index - 1]
        const nextMessage = messages[index + 1]
        const showDate = !nextMessage || !isSameDay(parseISO(nextMessage.sendDate), parseISO(message.sendDate))
        const messageSender = getSenderName(translation, message)
        const hideAvatar =
          prevMessage &&
          messageSender === getSenderName(translation, prevMessage) &&
          isSameDay(parseISO(prevMessage.sendDate), parseISO(message.sendDate))

        return (
          <div
            key={message.id}
            className="group"
          >
            {showDate && <ChatDate sendDate={message.sendDate} />}

            {message.role !== 'tool' && (
              <ChatBubble
                className={clsx(messages?.[index - 1]?.role !== message.role && 'mt-4 leading-8')}
                color={message.user?.id === authenticatedUser?.id ? 'primary' : 'neutral'}
                position={message.user?.id === authenticatedUser?.id ? 'end' : 'start'}
                avatarImageSrc={message.role === 'assistant' ? '/cogfy-avatar.png' : undefined}
                user={message.user?.id === authenticatedUser?.id ? undefined : message.user}
                sendDate={message.sendDate}
                fieldType={chat.field?.type}
                hideAvatar={hideAvatar}
              >
                <div className="max-w-lg">
                  <p className='font-semibold text-base'>
                    {message.user?.id !== authenticatedUser?.id && (
                      messageSender
                    )}
                  </p>
                  {message.files.length > 0 && (
                    <div className="flex flex-col gap-1 max-w-72 mb-4">
                      {message.files.map(file => (
                        <ChatMessageFile key={file.id} file={{ ...file }} />
                      ))}
                    </div>
                  )}
                  <FormattedText text={message.content} />
                  <ChatSourcesBubbleContent message={message} />
                </div>
              </ChatBubble>
            )}

            {message.role === 'tool' && (
              <ChatToolConfirmation
                message={message}
                chatId={chat.id}
                messageId={message.id}
                collectionId={collection.id}
                onUpdateMessageStatus={props.onUpdateMessageStatus}
              />
            )}

            <ChatMessageStatus
              message={message}
              position={message.user?.id === authenticatedUser?.id ? 'end' : 'start'}
            />

            {message.role === 'assistant' && (
              <ChatMessageReaction
                chatId={chat.id}
                messageId={message.id}
                reaction={message.reaction?.type}
                onMessageReaction={props.onMessageReaction}
              />
            )}
          </div>
        )
      })}
    </>
  )
}

function getSenderName (
  translation: TFunction<'translation', undefined>,
  message: GetChatMessagesPageResultData
) {
  if (message.role === 'assistant') {
    return translation('Assistant')
  } else if (message.role === 'user' && (message.data as UserChatMessageData)?.user?.name) {
    return (message.data as UserChatMessageData).user?.name
  }

  return message.user?.name
}
