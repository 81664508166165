import { FormikProps } from 'formik'
import { FormValues } from '../..'
import { Card, Heading, Toggle } from '../../../../components'
import { useTranslation } from 'react-i18next'
import { useFields } from '../../../../hooks'
import { PermissionActionName } from '@indigohive/cogfy-types'
import clsx from 'clsx'
import { useState } from 'react'

export type ChatPermissionsCardProps = {
  formik: FormikProps<FormValues>
}

export function ChatPermissionsCard (props: ChatPermissionsCardProps) {
  const { formik } = props
  const [openTable, setOpenTable] = useState<boolean>(false)
  const { t } = useTranslation()
  const { data: fields, isLoading: isFieldsLoading } = useFields({ type: 'chat' })

  const permissionSelected = (action: PermissionActionName, subjectKey: string) => {
    return formik.values.options.some(permission =>
      permission.action === action &&
      permission.subjectKey === subjectKey) ?? false
  }

  const onHandlePermision = (checked: boolean, subjectKey: string) => {
    const action: PermissionActionName = 'chat:start'

    if (checked) {
      const newPermission = {
        action,
        subjectKey
      }
      formik.setFieldValue('options', [...formik.values.options, newPermission])
    } else {
      const newPermissions = formik.values.options.filter(a => !(a.action === action && a.subjectKey === subjectKey))
      formik.setFieldValue('options', newPermissions)
    }
  }

  return (
    <>
      <Heading
        component='h2'
        className='mt-8 mb-2'
      >
        {t('createApiKeyPage:Chat permissions')}
      </Heading>
      <Card compact>
        <div className="flex flex-col gap-2 py-4 card-body">
          <Toggle
            color="success"
            label={`${t('Start chat')} (chat:start)`}
            onChange={event => {
              if (!event.target.checked) {
                formik.setFieldValue('options', formik.values.options.filter(a => a.action !== 'chat:start'))
              }
              setOpenTable(event.target.checked)
            }}
          />
          {openTable && (
            <>
              {isFieldsLoading && (
                <progress className="progress w-full" />
              )}
              {fields && (
                <>
                  <div className="overflow-y-auto max-h-72">
                    <Toggle
                      color="success"
                      label={t('Select All')}
                      onChange={event => {
                        if (!event.target.checked) {
                          formik.setFieldValue('options', formik.values.options.filter(a => a.action !== 'chat:start'))
                        } else {
                          formik.setFieldValue('options', fields.data.map(() => ({
                            action: 'chat:start',
                            subjectKey: '*'
                          })))
                        }
                      }}
                    />
                    <table className="table table-pin-cols">
                      <thead>
                        <tr>
                          <th>{t('Field')}</th>
                          <th>{t('Enable')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fields.data.map(field => (
                          <tr key={field.id}>
                            <td className={clsx(!field.name && 'text-gray-300')}>
                              {field.name ?? t('Untitled')}
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                className="checkbox checkbox-sm checked:checkbox-info"
                                onChange={event => onHandlePermision(event.target.checked, field.id)}
                                checked={permissionSelected('chat:start', '*') || permissionSelected('chat:start', field.id)}
                                disabled={permissionSelected('chat:start', '*')}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </Card>
    </>
  )
}
