import { useEffect, useState } from 'react'
import { CollectionState } from '../collection-state'

export function useSelectedRows (state: CollectionState) {
  const [selectedRows, setSelectedRows] = useState(state.selectedRows)

  useEffect(() => {
    const selectedRowsChanged = () => {
      setSelectedRows(state.selectedRows)
    }

    state.addEventListener('selectedRowsChanged', selectedRowsChanged)

    return () => {
      state.removeEventListener('selectedRowsChanged', selectedRowsChanged)
    }
  })

  return selectedRows
}
