import { ChevronLeftIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'

export type SettingsDrawerHeaderProps = {
  handleClose?: () => void
}

export function SettingsDrawerHeader (props: SettingsDrawerHeaderProps) {
  const { t } = useTranslation()

  return (
    <div className="flex items-center gap-1 h-11">
      <div
        role="button"
        className="btn btn-sm btn-square btn-ghost"
        onClick={props.handleClose}
      >
        <ChevronLeftIcon size={20} />
      </div>
      <span className="text-lg font-medium">{t('Settings')}</span>
    </div>
  )
}
