import { Button } from '../../../../components'
import { useTranslation } from 'react-i18next'

export type MembersSelectionBarProps = {
  setDialogOpen: (state: boolean) => void
  count: number
}

export function MembersSelectionBar (props: MembersSelectionBarProps) {
  const { setDialogOpen, count } = props
  const { t } = useTranslation()

  return (
    <div className="flex items-center justify-between w-full mb-1">
      <div className="badge">{t('Members selected', { count })}</div>
      <Button
        size="sm"
        onClick={() => setDialogOpen(true)}
      >
        {t('Delete')}
      </Button>
    </div>
  )
}
