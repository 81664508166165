import { SimpleIcon as SimpleIconType } from 'simple-icons'

export type SimpleIconProps = {
  icon: SimpleIconType
  size?: number
  color?: string
}

export function SimpleIcon (props: SimpleIconProps) {
  const { icon, size, color } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill={color === 'brand'
        ? `#${icon.hex}`
        : color
      }
      viewBox='0 0 24 24'
      role="img"
    >
      <title>{icon.title}</title>
      <path d={`${icon.path}`} />
    </svg>
  )
}
