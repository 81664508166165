import { FieldData, FieldType, RecordProperty, UUID, ViewFieldConfig } from '@indigohive/cogfy-types'
import { createElement } from 'react'
import { CollectionStateRecord } from '../../lib'
import { CollectionPageController } from '../../pages/CollectionPageV2/collection-page-controller'
import {
  AuthorCell,
  BillingCell,
  BooleanCell,
  ChatCell,
  ChatMessageCell,
  ConnectionCell,
  CreateDateCell,
  DateCell,
  FileCell,
  SendgridInboundEmailCell,
  JsonCell,
  NumberCell,
  ReferenceCell,
  SelectCell,
  StopwatchCell,
  TextCell,
  UpdateDateCell,
  VectorCell,
  ScheduleCell
} from './components'
import { ErrorCell, PendingCell, StaleIndicator } from './states'

export const FIELD_CELL_BY_TYPE: Record<FieldType, ((props: FieldCellProps<any, any, any>) => JSX.Element) | undefined> = {
  author: AuthorCell,
  billing: BillingCell,
  boolean: BooleanCell,
  chat: ChatCell,
  chatMessage: ChatMessageCell,
  connection: ConnectionCell,
  createDate: CreateDateCell,
  date: DateCell,
  file: FileCell,
  json: JsonCell,
  number: NumberCell,
  reference: ReferenceCell,
  schedule: ScheduleCell,
  'sendgrid.inboundEmail': SendgridInboundEmailCell,
  select: SelectCell,
  stopwatch: StopwatchCell,
  text: TextCell,
  updateDate: UpdateDateCell,
  vector: VectorCell
}

export type FieldCellProps<T1 = RecordProperty, T2 = FieldData, T3 = ViewFieldConfig> = {
  property: T1 | undefined
  collectionId: UUID
  record: CollectionStateRecord
  field: {
    id: UUID
    type: FieldType
    data?: T2 | null
  }
  viewField: { config?: T3 | null }
  controller: CollectionPageController
  textWrap?: boolean
  readonly?: boolean
}

export type CellProps<T = unknown> = {
  value?: T
  recordId?: UUID
  fieldId?: UUID
}

export function FieldCell (props: FieldCellProps) {
  const { field, record } = props

  const fieldCellComponent = FIELD_CELL_BY_TYPE[field.type]

  const hasError = Boolean(record.properties[field.id]?.error?.message) || Boolean(record.properties[field.id]?.error?.code)
  const isLoading = Boolean(record.properties[field.id]?.pending)
  const isStale = Boolean(record.properties[field.id]?.stale)

  return (
    <>
      {fieldCellComponent && !hasError && !isLoading && createElement(fieldCellComponent, props)}
      {isLoading && !hasError && <PendingCell />}
      {isStale && <StaleIndicator {...props} />}
      {hasError && <ErrorCell {...props} />}
    </>
  )
}
