import { useEffect, useState } from 'react'
import { CollectionState } from '../collection-state'

export function useLocked (state: CollectionState) {
  const [locked, setLocked] = useState(state.locked)

  useEffect(() => {
    const lockedChanged = () => {
      setLocked(state.locked)
    }

    state.addEventListener('lockedChanged', lockedChanged)

    return () => {
      state.removeEventListener('lockedChanged', lockedChanged)
    }
  })

  return locked
}
