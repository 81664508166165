import { FieldType } from '@indigohive/cogfy-types'
import { Button } from '../Button'
import { PlusIcon, TrashIcon } from 'lucide-react'
import { ChatMiddlewareConditionSourceSelect } from './subcomponents/ChatMiddlewareConditionSourceSelect'
import { ChatMiddlewareConditionOperatorSelect } from './subcomponents/ChatMiddlewareConditionOperatorSelect'
import { ChatMiddlewareConditionValueInput } from './subcomponents/ChatMiddlewareConditionValueInput'
import { useTranslation } from 'react-i18next'

export type ChatMiddlewareConditionSource =
  { type: 'content' } |
  { type: 'content_type' } |
  { type: 'field', field: { id: string, type: FieldType } }

export type ChatMiddlewareConditionItem = {
  source: ChatMiddlewareConditionSource
  operator: string
  value: string | number | boolean | null
}

export type ChatMiddlewareConditionFormProps = {
  fields?: {
    id: string
    name: string
    type: FieldType
  }[]
  value: Record<string, any>
  onChange: (value: Record<string, any>) => void
}

function mapConditionToItems (condition: Record<string, any>): ChatMiddlewareConditionItem[] {
  if (condition.type !== 'and' || !Array.isArray(condition.data?.value)) {
    return []
  }

  return condition.data.value.map((item: Record<string, any>) => {
    if (item.type === 'content_type') {
      return {
        source: { type: 'content_type' },
        operator: item.data?.operator ?? 'equals',
        value: item.data?.value
      }
    } else if (item.type === 'content') {
      return {
        source: { type: 'content' },
        operator: item.data?.operator ?? 'equals',
        value: item.data?.value
      }
    } else {
      return {
        source: {
          type: 'field',
          field: {
            id: item.data?.id,
            type: item.data?.type
          }
        },
        operator: item.data?.operator ?? 'equals',
        value: item.data?.value
      }
    }
  })
}

function mapItemsToCondition (items: ChatMiddlewareConditionItem[]): Record<string, any> {
  return {
    type: 'and',
    data: {
      value: items.map(item => ({
        type: item.source.type,
        data: {
          id: item.source.type === 'field' ? item.source.field.id : undefined,
          type: item.source.type === 'field' ? item.source.field.type : undefined,
          operator: item.operator,
          value: item.value
        }
      }))
    }
  }
}

export function ChatMiddlewareConditionForm (props: ChatMiddlewareConditionFormProps) {
  const { fields = [], value, onChange } = props
  const { t } = useTranslation()

  const items: ChatMiddlewareConditionItem[] = mapConditionToItems(value)

  const handleChange = (items: ChatMiddlewareConditionItem[]) => {
    onChange(mapItemsToCondition(items))
  }

  const handleAdd = () => {
    handleChange([
      ...items,
      {
        source: { type: 'content_type' },
        operator: 'equals',
        value: 'text'
      }
    ])
  }

  return (
    <div className="my-2">
      <div className="flex items-center">
        <div className="grow text-sm">
          {t('editFieldDrawer:Conditions')}
        </div>
        <Button
          ghost
          square
          size="sm"
          onClick={() => { handleAdd() }}
        >
          <PlusIcon size={16} />
        </Button>
      </div>
      {items.length === 0 && (
        <div className="text-sm opacity-40">
          {t('editFieldDrawer:No conditions set')}
        </div>
      )}
      {items.map((item, index) => (
        <div key={index}>
          <div className="flex gap-2 py-1 items-center">
            <div>
              <ChatMiddlewareConditionSourceSelect
                fields={fields}
                value={item.source}
                onChange={source => {
                  const newValue = [...items]
                  newValue[index] = {
                    ...newValue[index],
                    source,
                    value: source.type === 'content_type' ? 'text' : ''
                  }
                  handleChange(newValue)
                }}
              />
            </div>
            <div>
              <ChatMiddlewareConditionOperatorSelect
                source={item.source}
                value={item.operator}
                onChange={operator => {
                  const newValue = [...items]
                  newValue[index] = { ...newValue[index], operator }
                  handleChange(newValue)
                }}
              />
            </div>
            <div>
              {item.operator !== 'is_empty' && item.operator !== 'is_not_empty' && (
                <ChatMiddlewareConditionValueInput
                  source={item.source}
                  value={item.value}
                  onChange={value => {
                    const newValue = [...items]
                    newValue[index] = { ...newValue[index], value }
                    handleChange(newValue)
                  }}
                />
              )}
            </div>
            <div>
              <Button
                ghost
                square
                size="sm"
                onClick={() => {
                  handleChange(items.filter((_, i) => i !== index))
                }}
              >
                <TrashIcon size={16} />
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
