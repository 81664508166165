import clsx from 'clsx'
import { WhatsAppContentProps } from './WhatsAppContent'

export function WhatsAppcontentInteractive (props: WhatsAppContentProps) {
  const { content, className } = props

  return (
    <div className={clsx(className, 'text-sm px-1')}>
      <div className="text-sm text-gray-400 italic">
        Interactive
      </div>
      {content}
    </div>
  )
}
