import { PlusIcon } from 'lucide-react'
import { AWS_REGIONS, AWS_REGIONS_OPTIONS } from '@indigohive/cogfy-common'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { Button, Dialog, Input, Select } from '../../../components'
import { useCogfy, useToasts } from '../../../hooks'

export type AddAwsButtonProps = {
  onSuccess?: () => void
}

export function AddAwsButton (props: AddAwsButtonProps) {
  const { onSuccess } = props
  const { t } = useTranslation()
  const cogfy = useCogfy()
  const toasts = useToasts()
  const formik = useFormik({
    initialValues: {
      name: '',
      accessKeyId: '',
      secretAccessKey: '',
      region: ''
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(t('Required field')),
      accessKeyId: yup.string().required(t('Required field')),
      secretAccessKey: yup.string().required(t('Required field')),
      region: yup.string().oneOf(AWS_REGIONS).required(t('Required field'))
    }),
    onSubmit: async (values, helpers) => {
      try {
        await cogfy.createConnection({
          provider: 'aws',
          name: values.name,
          values: [
            { name: 'accessKeyId', value: values.accessKeyId },
            { name: 'secretAccessKey', value: values.secretAccessKey },
            { name: 'region', value: values.region }
          ]
        })
        onSuccess?.()
        setOpen(false)
        helpers.resetForm()
      } catch (error) {
        toasts.error(t('Unexpected error'))
      }
    }
  })
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button
        color="primary"
        size="sm"
        onClick={() => setOpen(true)}
      >
        <PlusIcon size={16} />
        {t('WorkspaceConnectionsPage:Connect')}
      </Button>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
      >
        <h3 className="font-bold text-lg">
          {t('WorkspaceConnectionsPage:Configure AWS account')}
        </h3>
        <form
          className="flex flex-col gap-4 my-4"
          onSubmit={formik.handleSubmit}
        >
          <Input
            label={t('WorkspaceConnectionsPage:Connection name')}
            error={Boolean(formik.touched.name && formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            {...formik.getFieldProps('name')}
          />

          <Input
            label="Access Key ID"
            error={Boolean(formik.touched.accessKeyId && formik.errors.accessKeyId)}
            helperText={formik.touched.accessKeyId && formik.errors.accessKeyId}
            {...formik.getFieldProps('accessKeyId')}
          />

          <Input
            label="Secret Access Key"
            type="password"
            error={Boolean(formik.touched.secretAccessKey && formik.errors.secretAccessKey)}
            helperText={formik.touched.secretAccessKey && formik.errors.secretAccessKey}
            {...formik.getFieldProps('secretAccessKey')}
          />

          <Select
            label={t('Region')}
            options={[
              { label: t('WorkspaceConnectionsPage:Select a region'), value: '' },
              ...AWS_REGIONS_OPTIONS
            ]}
            error={Boolean(formik.touched.region && formik.errors.region)}
            helperText={formik.touched.region && formik.errors.region}
            {...formik.getFieldProps('region')}
          />

          <div className="modal-action">
            <Button
              size="sm"
              disabled={formik.isSubmitting}
              onClick={() => {
                formik.resetForm()
              }}
            >
              {t('Close')}
            </Button>
            <Button
              color="primary"
              size="sm"
              type="submit"
              disabled={formik.isSubmitting}
            >
              {t('Confirm')}
            </Button>
          </div>
        </form>
      </Dialog>
    </>
  )
}
