import { useQuery } from '@tanstack/react-query'
import { useFormik } from 'formik'
import QRCode from 'qrcode'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, CogfyLogo } from '../../components'
import { useAuthentication, useCogfy, useToasts } from '../../hooks'
import { OneTimePasswordInput } from '../../components/OneTimePasswordInput'

export function TotpSetupPage () {
  const location = useLocation()
  const accessToken: string | undefined = location.state?.accessToken
  const navigate = useNavigate()
  const authentication = useAuthentication()
  const { t } = useTranslation()
  const cogfy = useCogfy()
  const toast = useToasts()
  const formik = useFormik({
    initialValues: {
      token: ''
    },
    onSubmit: async values => {
      try {
        const result = await cogfy.authentication.totpFinishSetup({
          accessToken: accessToken!,
          token: values.token
        })
        authentication.onAuthenticatedUserChange?.(result)
        navigate('/')
        toast.success('2FA enabled')
      } catch (error) {
        if (cogfy.isConflict(error)) {
          toast.error('TOTP already enabled')
        } else if (cogfy.isBadRequest(error)) {
          toast.error('Invalid token')
        } else {
          toast.error('An error occurred')
        }
      }
    }
  })
  const [dataURL, setDataURL] = useState<string | null>(null)
  const getTotpSetup = useQuery({
    queryKey: ['totp-setup'],
    queryFn: () => cogfy.authentication.totpStartSetup({
      accessToken: accessToken!
    })
  })

  useEffect(() => {
    if (getTotpSetup.data?.otpAuthUrl) {
      QRCode.toDataURL(getTotpSetup.data.otpAuthUrl)
        .then(data => setDataURL(data))
    }
  }, [getTotpSetup.data?.otpAuthUrl])

  useEffect(() => {
    if (!accessToken) {
      navigate('/sign-in', { replace: true })
    }
  }, [accessToken])

  return (
    <div className="w-full flex flex-col items-center">
      <div className="navbar max-w-screen-xl">
        <CogfyLogo size={48} />
      </div>

      <div className="w-96 p-6 mt-[8vh] mb-[16vh]">
        {cogfy.isConflict(getTotpSetup.error) && (
          <p>TOTP is already enabled</p>
        )}

        {getTotpSetup.data && (
          <>
            <h1 className="text-2xl font-semibold mb-2">
              {t('Two-factor authentication')}
            </h1>

            <p>{t('TotpSetupPage:Instructions')}</p>

            {dataURL && <img className="block mx-auto" src={dataURL} />}

            <div className="divider" />

            <form onSubmit={formik.handleSubmit}>
              <p>
                {t('Enter the verification code generated by your mobile application.')}
              </p>
              <div className="my-8">
                <OneTimePasswordInput
                  value={formik.values.token}
                  onChange={token => { void formik.setFieldValue('token', token) }}
                />
              </div>
              <Button
                type="submit"
                color="primary"
                className="w-full mt-4"
                disabled={formik.isSubmitting}
              >
                {t('Confirm')}
              </Button>
            </form>
          </>
        )}
      </div>
    </div>
  )
}
