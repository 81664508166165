import { useEffect, useState } from 'react'
import { CollectionState } from '../collection-state'

export function useFields (state: CollectionState) {
  const [fields, setFields] = useState(state.fields)

  useEffect(() => {
    const fieldsChanged = () => {
      setFields(state.fields)
    }

    state.addEventListener('fieldsChanged', fieldsChanged)

    return () => {
      state.removeEventListener('fieldsChanged', fieldsChanged)
    }
  })

  return fields
}
