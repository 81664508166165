import { FieldType } from '@indigohive/cogfy-types'
import { Select } from '../../Select'
import { ChatMiddlewareConditionSource } from '../ChatMiddlewareConditionForm'

export type ChatMiddlewareConditionSourceSelectProps = {
  fields?: {
    id: string
    name: string
    type: FieldType
  }[]
  value: ChatMiddlewareConditionSource
  onChange: (source: ChatMiddlewareConditionSource) => void
}

export function ChatMiddlewareConditionSourceSelect (props: ChatMiddlewareConditionSourceSelectProps) {
  const { fields = [], value, onChange } = props

  return (
    <Select
      size="sm"
      value={value.type === 'field' ? value.field.id : value.type}
      options={[
        { label: 'Content type', value: 'content_type' },
        { label: 'Content', value: 'content' },
        ...fields?.map(field => ({
          label: `Field: ${field.name}`,
          value: field.id
        }))
      ]}
      onChange={event => {
        const field = fields.find(field => field.id === event.target.value)

        if (field) {
          onChange({ type: 'field', field: { id: field.id, type: field.type } })
        } else {
          onChange({ type: event.target.value as 'content' | 'content_type' })
        }
      }}
    />
  )
}
