import { ChatField, ChatFieldData, UUID } from '@indigohive/cogfy-types'
import { ChevronDownIcon, ChevronUpIcon, CircleCheckIcon } from 'lucide-react'
import { useState } from 'react'
import { CollectionState, CollectionStateField } from '../../../../../../lib'
import { CollectionPageController } from '../../../../../../pages/CollectionPageV2/collection-page-controller'
import { WhatsAppParameters } from './WhatsAppParameters'

export type ChatWhatsAppConnectionProps = {
  controller: CollectionPageController
  state: CollectionState
  field: ChatField
}

export function ChatWhatsAppConnection (props: ChatWhatsAppConnectionProps) {
  const { controller, state, field } = props
  const [open, setOpen] = useState(false)

  const handleWhatsAppFieldDataChange = (fieldId: UUID, data: ChatFieldData) => {
    controller.onUpdateFieldData(fieldId, data)
  }

  const handleClearWhatsApp = (field: CollectionStateField) => {
    state.setSelectedFieldToUpdate(field)
  }

  const whatsapp = field.data?.chat?.whatsApp

  return (
    <>
      <div
        className="text-sm font-small flex items-center cursor-pointer"
        onClick={() => setOpen(prev => !prev)}
      >
        <div className="flex items-center gap-1 grow">
          WhatsApp
          {whatsapp && (
            <CircleCheckIcon size={16} className='text-success' />
          )}
        </div>
        {open && <ChevronUpIcon size={16} />}
        {!open && <ChevronDownIcon size={16} />}
      </div>
      {open && (
        <div className="px-1 py-2">
          <WhatsAppParameters
            field={field}
            onChange={(fieldId, data) => handleWhatsAppFieldDataChange(fieldId, data)}
            onClear={handleClearWhatsApp}
          />
        </div>
      )}
    </>
  )
}
