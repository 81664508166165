import { SelectFieldData, SelectRecordProperty } from '@indigohive/cogfy-types'
import { FieldCellProps } from '../FieldCell'
import { OptionChip, OptionChipColorName } from '../../../components'
import clsx from 'clsx'

export function SelectCell (props: FieldCellProps<SelectRecordProperty, SelectFieldData>) {
  const { field, property, textWrap } = props

  const multiple = Boolean(field.data?.select?.multiple ?? false)
  const selected = field.data?.select?.options
    ?.filter(option => property?.select?.value.find(_selected => _selected.id === option.id))
    ?.sort((a, b) => a.order - b.order)

  return (
    <div
      className={clsx(
        'flex',
        'gap-2',
        'px-2',
        'py-1',
        'w-full',
        'whitespace-pre-wrap',
        textWrap && 'flex-wrap'
      )}
    >
      {(multiple ? selected : selected?.slice(0, 1))?.map(option => (
        <OptionChip
          key={option.id}
          label={option.label}
          color={option.color as OptionChipColorName}
        />
      ))}
    </div>
  )
}
