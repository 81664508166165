import { GetFieldsQuery } from '@indigohive/cogfy-types/endpoints/getFields'
import { useCogfy } from './use-cogfy'
import { useQuery } from '@tanstack/react-query'

export function useFields (data: GetFieldsQuery = {}) {
  const cogfy = useCogfy()

  return useQuery({
    queryKey: ['getFieldsPage', data],
    queryFn: ({ signal }) => cogfy.getFields(data, { signal })
  })
}
