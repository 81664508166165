import { AndFilter, GetCollectionFieldsResultData } from '@indigohive/cogfy-types'
import { PlusIcon } from 'lucide-react'
import { createElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CollectionState, CollectionStateField, useActiveViewId, useFields } from '../../../../../lib'
import { Button, Menu, OverlayContent } from '../../../../../components'
import { FIELD_FILTER_MENU_BY_TYPE, FIELD_ICONS_BY_TYPE } from '../../../../../field-types'
import { CollectionPageController } from '../../../collection-page-controller'

export type AddFilterButtonProps = {
  state: CollectionState
  controller: CollectionPageController
  onAddFilterClick?: (field: GetCollectionFieldsResultData) => void
}

export function AddFilterButton (props: AddFilterButtonProps) {
  const { state, controller } = props

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const { t } = useTranslation()
  const fields = useFields(state)
  const activeViewId = useActiveViewId(state)
  const view = state.views?.find(view => view.id === activeViewId)
  const filter: AndFilter = view?.filter as AndFilter ?? { and: [] }

  const availableFields = fields?.filter(field => !filter?.and?.some(
    filter => (filter as any)?.property === field.id) && FIELD_FILTER_MENU_BY_TYPE[field.type]
  )

  const handleItemClick = (field: CollectionStateField) => {
    controller.onAddViewFilterClick(field)
    setAnchorEl(null)
    const elem = document.activeElement as any
    if (elem) {
      elem?.blur()
    }
  }

  return (
    <>
      <Button
        size="xs"
        ghost
        onClick={event => setAnchorEl(event.currentTarget)}
      >
        <PlusIcon size={16} />
        {t('Add filter')}
      </Button>

      <OverlayContent
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Menu className="max-h-72">
          {availableFields?.length === 0 && ('No more filter to add.')}
          {availableFields?.map(field => (
            <li key={field.id}>
              <a onClick={() => handleItemClick(field)}>
                {createElement(
                  FIELD_ICONS_BY_TYPE[field.type],
                  { size: 16 }
                )}
                {field.name}
              </a>
            </li>
          ))}
        </Menu>
      </OverlayContent>
    </>
  )
}
