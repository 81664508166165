import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ChatMiddlewareDataFormProps } from '../ChatMiddlewareDataFormProps'

export function HistoryForm (props: ChatMiddlewareDataFormProps) {
  const { data, onChange } = props
  const { t } = useTranslation()
  const [maxCount, setMaxCount] = useState<number>(
    typeof data.maxCount === 'number' ? data.maxCount : 0
  )

  return (
    <>
      <div className="label">
        <span className="label-text">
          {t('Max count')}
        </span>
      </div>
      <input
        type="number"
        className="input input-bordered input-sm w-full"
        value={maxCount}
        max={20}
        min={0}
        onChange={event => {
          const value = parseInt(event.target.value)
          setMaxCount(value)
          onChange({ maxCount: value })
        }}
      />
    </>
  )
}
