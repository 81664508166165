import clsx from 'clsx'
import { Input, Menu, optionChipColorNames } from '../../../../../components'
import { SelectOption } from '../SelectConfigurations'
import { useTranslation } from 'react-i18next'

export type EditOptionMenuProps = {
  option: SelectOption | null
  onColorClick?: (color: string) => void
  onInputNameBlur?: () => void
  onInputNameChange?: (value: string) => void
  onInputNameKeyDown?: (key: string) => void
}

export function EditOptionMenu (props: EditOptionMenuProps) {
  const { option, onColorClick, onInputNameBlur, onInputNameChange, onInputNameKeyDown } = props

  const { t } = useTranslation()

  return (
    <Menu className="w-72 flex flex-col gap-2">
      <Input
        autoFocus
        size="sm"
        value={option?.label}
        onChange={event => onInputNameChange?.(event.target.value)}
        onFocus={event => event.currentTarget.select()}
        onBlur={() => onInputNameBlur?.()}
        onKeyDown={event => onInputNameKeyDown?.(event.key)}
      />
      <div className="text-sm px-2 mt-2 overflow-y-auto max-h-40">
        <span>{t('selectConfig:Colors')}</span>
        <div className="mt-2">
          {Object.entries(optionChipColorNames).map(([color, name]) => (
            <ul
              key={color}
              className="menu rounded-box p-0"
            >
              <li
                className="flex flex-row items-center w-full"
                onClick={() => onColorClick?.(name)}
              >
                <a className="w-full p-2">
                  <div
                    className={clsx(
                      'w-4',
                      'h-4',
                      'rounded-sm',
                      'border',
                      option?.color === color && 'border-primary',
                      `field-type-select-${name}`
                    )}
                    style={{ backgroundColor: color }}
                  />
                  {t(`selectConfig:${name}`)}
                </a>
              </li>
            </ul>
          ))}
        </div>
      </div>
    </Menu>
  )
}
