import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ChatMiddlewareDataFormProps } from '../../ChatMiddlewareDataFormProps'
import { Select } from '../../../Select'
import { ActionFormSaveAudioToCogfyCollection } from './_ActionFormSaveAudioToCogfyCollection'

const ACTION_OPTIONS = [
  { label: 'Select an action', value: '', disabled: true },
  { label: 'Save audio', value: 'saveAudioToCogfyCollection' },
  { label: 'Transcribe audio', value: 'transcribeAudio' },
  { label: 'Consensus', value: 'consensus' }
]

const ACTION_FORMS_BY_NAME: Record<string, typeof ActionFormSaveAudioToCogfyCollection> = {
  saveAudioToCogfyCollection: ActionFormSaveAudioToCogfyCollection
}

export function ActionForm (props: ChatMiddlewareDataFormProps) {
  const { data, onChange } = props
  const { t } = useTranslation()
  const [action, setAction] = useState<string | null>(
    typeof props.data.action === 'string' ? props.data.action : null
  )

  const ActionFormComponent = ACTION_FORMS_BY_NAME[action ?? '']

  return (
    <>
      <Select
        label={t('Action')}
        size="sm"
        value={action ?? ''}
        onChange={event => {
          if (event.target.value === '') {
            setAction(null)
            onChange({ ...data, action: null })
          } else {
            setAction(event.target.value)
            onChange({ ...data, action: event.target.value })
          }
        }}
        options={ACTION_OPTIONS}
      />

      {ActionFormComponent && <ActionFormComponent {...props} />}
    </>
  )
}
