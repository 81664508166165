import { useTranslation } from 'react-i18next'
import { Button } from '../../../components'
import { useCogfy, useToasts } from '../../../hooks'
import { useMutation } from '@tanstack/react-query'

type FacebookLoginResponse = {
  authResponse: {
    code: string
    expiresIn: number
    userId: string
  }
  status: string
}

export type AddFacebookButtonProps = {
  onSuccess?: () => void
}

export function AddFacebookButton (props: AddFacebookButtonProps) {
  const { onSuccess } = props
  const { t } = useTranslation()
  const cogfy = useCogfy()
  const toasts = useToasts()

  const createConnectionWhatsApp = useMutation({
    mutationFn: (data: { code: string }) => cogfy.connections.createWhatsApp(data),
    onSuccess: () => {
      onSuccess?.()
    }
  })

  const handleClick = () => {
    // Launch Facebook login
    // @ts-expect-error Added by Facebook script
    FB.login(
      (response: FacebookLoginResponse) => {
        if (response.authResponse) {
          const code = response.authResponse.code

          createConnectionWhatsApp.mutate({ code })
        } else {
          toasts.error(t('Failed to connect with Facebook'))
        }
      }, {
        config_id: '1735877850482858',
        response_type: 'code',
        override_default_response_type: true
      }
    )
  }

  return (
    <Button
      onClick={handleClick}
      size="sm"
      color="primary"
    >
      {t('Login with Facebook')}
    </Button>
  )
}
