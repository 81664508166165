import { AxiosInstance } from 'axios'
import { Socket } from 'socket.io-client'

export type BaseClientOptions = {
  axios: AxiosInstance
  socket: Socket
}

export abstract class BaseClient {
  protected readonly axios: AxiosInstance
  protected readonly socket: Socket

  constructor (options: BaseClientOptions) {
    this.axios = options.axios
    this.socket = options.socket
  }
}
