import { GetWorkspaceResult } from '@indigohive/cogfy-types'
import { useTranslation } from 'react-i18next'
import { useCogfy, useToasts } from '../../../hooks'
import { useDebouncedCallback } from 'use-debounce'
import { Info, PencilIcon, Trash2 } from 'lucide-react'
import { useMutation } from '@tanstack/react-query'
import { WorkspaceLogo } from '../../../components'
import { toBase64 } from '../../../helpers'

const colorsOptions = ['#000000', '#4D7C0F', '#0F766E', '#059669', '#0891B2', '#2563EB', '#7C3AED', '#CA8A04', '#C2410C', '#BE185D']

export type WorkspaceThemeProps = {
  workspace: GetWorkspaceResult
}

export function WorkspaceTheme (props: WorkspaceThemeProps) {
  const { workspace } = props
  const cogfy = useCogfy()
  const { t } = useTranslation()
  const toasts = useToasts()

  const handleColorChange = useDebouncedCallback(
    (value: string) => {
      const command = {
        theme: {
          ...workspace.theme,
          '--p': value
        }
      }

      cogfy.workspaces.setTheme(command)
    }, 500)

  const showErrorMessage = (errorMessage: string) => {
    toasts.error(t(errorMessage))
  }

  const handleLogoChange = useMutation({
    mutationFn: async (file: File | null) => {
      if (file) {
        const base64 = await toBase64(file)
        const logoBase64 = base64.split(',')[1]

        await cogfy.workspaces.setLogo({ logoBase64 })
      } else {
        await cogfy.workspaces.setLogo({ logoBase64: null })
      }
    }
  })

  return (
    <>
      <div className='flex flex-col gap-6'>
        <div className="flex gap-4">
          <div className="indicator group">
            <div className="indicator-item btn btn-xs btn-circle opacity-0 group-hover:opacity-100">
              <PencilIcon size={12} />
            </div>
            <label className="hover:cursor-pointer hover:contrast-50">
              <WorkspaceLogo
                className="w-[76px]"
                name={workspace.name}
                logoUrl={workspace.logoUrl}
              />
              <input
                type="file"
                className="hidden"
                onChange={(event) => {
                  const file = event.target.files?.[0]
                  const error = file ? validateLogoImage(file) : null

                  if (error) {
                    showErrorMessage(error)
                  }

                  if (file && !error) {
                    handleLogoChange.mutate(file)
                  }
                }}
              />
            </label>
          </div>
          <div>
            <p className="font-bold">{t('SettingsPages:Workspace Image')}<span className="text-error"> *</span></p>
            <div className="hover:cursor-pointer hover:contrast-50 flex mt-2 p-3 border w-10 rounded-lg items-center">
              <Trash2 size={16} onClick={() => {
                handleLogoChange.mutate(null)
              }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <p className="font-bold">{t('SettingsPages:Primary Workspace color')}<span className="text-error"> *</span></p>
          <div className='flex justify-around'>
            {colorsOptions.map((color, index) => (
              <span
                key={index}
                className={'w-8 h-8 rounded-full inline-block cursor-pointer'}
                style={{ backgroundColor: color }}
                onClick={() => handleColorChange(color)}
              >
              </span>
            ))}
          </div>
          <div className="text-xs bg-[#D4E7FC] p-2 rounded-md mt-1 flex gap-3 px-4 py-3 items-center">
            <Info size={24} color='#2A89EF' />
            {t('SettingsPages:The Workspace primary color is applied to buttons and highlighted texts')}
          </div>
        </div>
      </div>
    </>
  )
}

function validateLogoImage (file: File) {
  const allowedTypes = ['image/png', 'image/jpeg']
  const maxSize = 5 * 1024 * 1024

  if (!allowedTypes.includes(file.type)) {
    return 'Invalid file type'
  }

  if (file.size > maxSize) {
    return 'File is too large'
  }

  return null
}
