import { JsonFieldData, JsonRecordProperty, JsonViewFieldConfig } from '@indigohive/cogfy-types'
import clsx from 'clsx'
import { FieldCellProps } from '../FieldCell'

export type JsonCellProps = FieldCellProps<
JsonRecordProperty, JsonFieldData, JsonViewFieldConfig
>

export function JsonCell (props: JsonCellProps) {
  const { property, textWrap, viewField } = props

  const value = property?.json?.value
  const format = viewField.config?.json?.textFormat

  const formattedValue = format
    ? JSON.stringify(value, null, 2)
    : JSON.stringify(value)

  return (
    <code
      className={clsx(
        'px-1 py-1',
        textWrap && 'whitespace-pre-wrap',
        !textWrap && 'whitespace-nowrap truncate'
      )}
    >
      {formattedValue}
    </code>
  )
}
