/* eslint-disable no-useless-return */
import type {
  FieldCreatedEvent,
  FieldDataUpdatedEvent,
  FieldDeletedEvent,
  FieldRenamedEvent,
  TransactionEvent,
  ViewCreatedEvent,
  ViewDeletedEvent,
  ViewFieldConfigUpdatedEvent,
  ViewFieldCreatedEvent,
  ViewFieldDeletedEvent,
  ViewFieldReorderedAfterEvent,
  ViewFieldReorderedBeforeEvent,
  ViewFieldReorderedEvent,
  ViewOrderByUpdatedEvent,
  ViewRenamedEvent,
  ViewReorderedEvent
} from '@indigohive/cogfy-types'
import { CollectionState, CollectionStateField, CollectionStateView } from '../collection-state'

export class TransactionReducer {
  reduce (state: CollectionState, event: TransactionEvent) {
    switch (event.type) {
      case 'collection_locked':
        return state.setLocked(true)
      case 'collection_moved':
        return
      case 'collection_renamed':
        return state.setName(event.data.name)
      case 'collection_unlocked':
        return state.setLocked(false)
      case 'field_created':
        return this.onFieldCreated(state, event)
      case 'field_data_updated':
        return this.onFieldDataUpdated(state, event)
      case 'field_deleted':
        return this.onFieldDeleted(state, event)
      case 'field_renamed':
        return this.onFieldRenamed(state, event)
      case 'record_created':
        return
      case 'record_properties_updated':
        return
      case 'records_deleted':
        return
      case 'view_created':
        return this.onViewCreated(state, event)
      case 'view_deleted':
        return this.onViewDeleted(state, event)
      case 'view_field_config_updated':
        return this.onViewFieldConfigUpdated(state, event)
      case 'view_field_created':
        return this.onViewFieldCreated(state, event)
      case 'view_field_deleted':
        return this.onViewFieldDeleted(state, event)
      case 'view_field_reordered_after':
        return this.onViewFieldReorderedAfter(state, event)
      case 'view_field_reordered_before':
        return this.onViewFieldReorderedBefore(state, event)
      case 'view_field_reordered':
        return this.onViewFieldReordered(state, event)
      case 'view_order_by_updated':
        return this.onViewOrderByUpdated(state, event)
      case 'view_renamed':
        return this.onViewRenamed(state, event)
      case 'view_reordered':
        return this.onViewReordered(state, event)
      default:
        return
    }
  }

  private onFieldCreated (state: CollectionState, event: FieldCreatedEvent) {
    if (!state.fields || state.fields.some(field => field.id === event.data.id)) {
      return
    }

    const newFields = [...state.fields]
    const newField: CollectionStateField = {
      id: event.data.id,
      name: event.data.name,
      type: event.data.type,
      recalculateStrategy: event.data.recalculateStrategy
    }
    newFields.splice(event.data.order, 0, newField)

    state.setFields(newFields)
  }

  private onFieldDataUpdated (state: CollectionState, event: FieldDataUpdatedEvent) {
    if (!state.fields) {
      return
    }

    const newFields = state.fields.map(field => {
      if (field.id === event.data.id) {
        return { ...field, data: event.data.data }
      }

      return field
    })

    state.setFields(newFields)
  }

  private onFieldDeleted (state: CollectionState, event: FieldDeletedEvent) {
    if (state.viewFields) {
      const newViewFields = state.viewFields.filter(viewField => viewField.fieldId !== event.data.id)
      state.setViewFields(newViewFields)
    }

    if (state.fields) {
      const newFields = state.fields.filter(field => field.id !== event.data.id)
      state.setFields(newFields)
    }
  }

  private onFieldRenamed (state: CollectionState, event: FieldRenamedEvent) {
    if (!state.fields) {
      return
    }

    const newFields = state.fields.map(field => {
      if (field.id === event.data.id) {
        return { ...field, name: event.data.name }
      }

      return field
    })

    state.setFields(newFields)
  }

  private onViewCreated (state: CollectionState, event: ViewCreatedEvent) {
    if (!state.views || state.views.some(view => view.id === event.data.id)) {
      return
    }

    const newView: CollectionStateView = {
      id: event.data.id,
      name: event.data.name
    }

    const newViews = [...state.views]
    newViews.splice(event.data.order, 0, newView)

    state.setViews(newViews)
  }

  private onViewDeleted (state: CollectionState, event: ViewDeletedEvent) {
    if (!state.views) {
      return
    }

    const index = state.views.findIndex(view => view.id === event.data.id)

    if (index > -1) {
      const newViews = [...state.views]
      newViews.splice(index, 1)
      state.setViews(newViews)
    }
  }

  private onViewFieldConfigUpdated (state: CollectionState, event: ViewFieldConfigUpdatedEvent) {
    if (!state.viewFields || state.activeViewId !== event.data.viewId) {
      return
    }

    const newViewFields = state.viewFields.map(viewField => {
      if (viewField.id === event.data.viewFieldId) {
        return { ...viewField, config: event.data.config }
      }

      return viewField
    })

    state.setViewFields(newViewFields)
  }

  private onViewFieldCreated (state: CollectionState, event: ViewFieldCreatedEvent) {
    if (!state.viewFields || state.activeViewId !== event.data.viewId) {
      return
    }
  }

  private onViewFieldDeleted (state: CollectionState, event: ViewFieldDeletedEvent) {
    if (!state.viewFields || state.activeViewId !== event.data.viewId) {
      return
    }

    const newViewFields = state.viewFields
      .filter(viewField => viewField.id !== event.data.viewFieldId)

    state.setViewFields(newViewFields)
  }

  private onViewFieldReorderedAfter (state: CollectionState, event: ViewFieldReorderedAfterEvent) {
    if (!state.viewFields || state.activeViewId !== event.data.viewId) {
      return
    }

    const index = state.viewFields.findIndex(viewField => viewField.id === event.data.viewFieldId)

    if (index === -1) {
      return
    }

    const newViewFields = [...state.viewFields]
    const [removed] = newViewFields.splice(index, 1)

    const newIndex = newViewFields.findIndex(viewField => viewField.id === event.data.afterId)

    if (newIndex === -1) {
      return
    }

    newViewFields.splice(newIndex + 1, 0, removed)

    state.setViewFields(newViewFields)
  }

  private onViewFieldReorderedBefore (state: CollectionState, event: ViewFieldReorderedBeforeEvent) {
    if (!state.viewFields || state.activeViewId !== event.data.viewId) {
      return
    }

    const index = state.viewFields.findIndex(viewField => viewField.id === event.data.viewFieldId)

    if (index === -1) {
      return
    }

    const newViewFields = [...state.viewFields]
    const [removed] = newViewFields.splice(index, 1)

    const newIndex = newViewFields.findIndex(viewField => viewField.id === event.data.beforeId)

    if (newIndex === -1 || index === newIndex - 1) {
      return
    }

    newViewFields.splice(newIndex, 0, removed)

    state.setViewFields(newViewFields)
  }

  private onViewFieldReordered (state: CollectionState, event: ViewFieldReorderedEvent) {
    if (!state.viewFields || state.activeViewId !== event.data.viewId) {
      return
    }

    const index = state.viewFields.findIndex(viewField => viewField.id === event.data.viewFieldId)

    if (index === -1) {
      return
    }

    const newViewFields = [...state.viewFields]
    const [removed] = newViewFields.splice(index, 1)
    newViewFields.splice(event.data.order, 0, removed)

    state.setViewFields(newViewFields)
  }

  private onViewOrderByUpdated (state: CollectionState, event: ViewOrderByUpdatedEvent) {
    if (!state.views || state.activeViewId !== event.data.viewId) {
      return
    }

    const index = state.views.findIndex(view => view.id === event.data.viewId)

    if (index > -1) {
      const newViews = [...state.views]
      const view = newViews[index]
      newViews[index] = { ...view, orderBy: event.data.orderBy }
      state.setViews(newViews)
    }
  }

  private onViewRenamed (state: CollectionState, event: ViewRenamedEvent) {
    if (!state.views) {
      return
    }

    const index = state.views.findIndex(view => view.id === event.data.id)

    if (index > -1) {
      const newViews = [...state.views]
      const view = newViews[index]
      newViews[index] = { ...view, name: event.data.name }
      state.setViews(newViews)
    }
  }

  private onViewReordered (state: CollectionState, event: ViewReorderedEvent) {
    if (!state.views) {
      return
    }

    const index = state.views.findIndex(view => view.id === event.data.id)

    if (index === -1) {
      return
    }

    const newViews = [...state.views]
    const [removed] = newViews.splice(index, 1)
    newViews.splice(event.data.order, 0, removed)

    state.setViews(newViews)
  }
}
