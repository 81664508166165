import { PencilIcon, TrashIcon } from 'lucide-react'
import { Button, OptionChip, OptionChipColorName } from '../../../../../components'
import { useRef } from 'react'
import { SelectOption } from '../SelectConfigurations'
import { useDrag, useDrop } from 'react-dnd'
import clsx from 'clsx'

export type OptionItemProps = {
  option: SelectOption
  handleDrop?: (movedOption: SelectOption, hoveredOption: SelectOption) => void
  onDeleteClick?: () => void
  onEditClick?: (ref: HTMLDivElement | null, option: SelectOption) => void
}

export function OptionItem (props: OptionItemProps) {
  const { option, handleDrop, onDeleteClick, onEditClick } = props

  const ref = useRef<HTMLDivElement | null>(null)

  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: 'option',
    item: { option },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }), [option])

  const [{ isOver }, dropRef] = useDrop({
    accept: 'option',
    collect: monitor => ({
      isOver: monitor.isOver({ shallow: true })
    }),
    drop (item: any, monitor) {
      const movedOption = item?.option

      if (monitor.didDrop() || !movedOption) {
        return
      }

      handleDrop?.(movedOption, option)
    }
  })

  dragRef(dropRef(ref))

  return (
    <div
      ref={ref}
      className={clsx(
        'flex',
        'items-center',
        'justify-between',
        'gap-2',
        'cursor-grab',
        'p-1',
        'border-2',
        isDragging && 'opacity-40',
        isOver ? 'ring-1 ring-blue-500' : 'border-transparent'
      )}
    >
      <OptionChip label={option.label} color={option.color as OptionChipColorName} />
      <div className="flex gap-2">
        <Button
          size="xs"
          square
          onClick={() => onEditClick?.(ref.current, option)}
        >
          <PencilIcon size={16} />
        </Button>
        <Button
          size="xs"
          square
          onClick={() => onDeleteClick?.()}
        >
          <TrashIcon size={16} />
        </Button>
      </div>
    </div>
  )
}
