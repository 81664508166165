import { useEffect } from 'react'

export type UseTitleParams = {
  title?: string | null
  loading?: boolean
  error?: unknown
}

const prefixByEnv: Record<string, string> = {
  development: '[DEV] ',
  staging: '[STAGING] ',
  production: ''
}

const prefix = prefixByEnv[import.meta.env.MODE] ?? ''
const suffix = ' | Cogfy'

export function useTitle (params: UseTitleParams) {
  const { title, loading, error } = params

  if (!document) {
    return
  }

  useEffect(() => {
    if (title) {
      document.title = [prefix, title, suffix].join('')
    } else if (loading) {
      document.title = [prefix, 'Loading...', suffix].join('')
    } else if (error) {
      document.title = [prefix, 'Error', suffix].join('')
    } else {
      document.title = [prefix, 'Cogfy'].join('')
    }

    return () => {
      document.title = 'Cogfy'
    }
  }, [title, loading, error])
}
