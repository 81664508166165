import { PropsWithChildren } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDrawer, useWorkspaceSlug } from '../../../../hooks'
import { SettingsDrawerHeader } from '../SettingsDrawerHeader'
import { SettingsDrawerItems } from '../SettingsDrawerItems'

const DRAWER_ID = 'settings-drawer'

export type SettingsDrawerProps = PropsWithChildren

export function SettingsDrawer (props: SettingsDrawerProps) {
  const { children } = props

  const workspace = useWorkspaceSlug()
  const navigate = useNavigate()
  const { setOpen } = useDrawer()

  return (
    <div className="drawer drawer-open bg-white">
      <input id={DRAWER_ID} type="checkbox" className="drawer-toggle" />
      <div className="drawer-content border-l">
        {children}
      </div>
      <div className="drawer-side" style={{ scrollbarWidth: 'thin' }}>
        <div className="flex flex-col gap-3 w-72 px-3 py-6 h-screen">
          <SettingsDrawerHeader
            handleClose={() => {
              setOpen?.(true)
              navigate(`/${workspace}`)
            }}
          />
          <SettingsDrawerItems workspace={workspace} />
        </div>
      </div>
    </div>
  )
}
