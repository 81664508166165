import { RunTransactionCommand, UUID } from '@indigohive/cogfy-types'
import { Command } from '../command-stack'
import { CollectionState } from '../collection-state'

export type UpdateCollectionTitleFieldCommandData = {
  fieldId: UUID
}

export class UpdateCollectionTitleFieldCommand implements Command<RunTransactionCommand> {
  private readonly _state: CollectionState
  private readonly _data: UpdateCollectionTitleFieldCommandData
  private readonly _previousFieldId: UUID | null

  constructor (
    state: CollectionState,
    data: UpdateCollectionTitleFieldCommandData
  ) {
    this._state = state
    this._data = data
    this._previousFieldId = this._state.selectedTitleFieldId
  }

  run (): RunTransactionCommand {
    this._state.setSelectedCollectionTitleFieldId(this._data.fieldId)

    return {
      operations: [
        {
          type: 'update_collection_title_field',
          data: {
            collectionId: this._state.id,
            fieldId: this._data.fieldId
          }
        }
      ]
    }
  }

  undo (): RunTransactionCommand {
    if (this._previousFieldId !== null) {
      this._state.setSelectedCollectionTitleFieldId(this._previousFieldId)
    }

    return {
      operations: [
        {
          type: 'update_collection_title_field',
          data: {
            collectionId: this._state.id,
            fieldId: this._previousFieldId ?? null
          }
        }
      ]
    }
  }
}
