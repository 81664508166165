import { ReactNode, memo } from 'react'
import { Link } from 'react-router-dom'
import { useScreenSize } from '../../hooks'

const MIN_SCREEN_SIZE = 768

export type BreadcrumbItem = {
  label: ReactNode
  icon?: ReactNode
  to?: string
}

export type BreadcrumbsProps = {
  items: BreadcrumbItem[]
}

function _Breadcrumbs (props: BreadcrumbsProps) {
  const { width } = useScreenSize()

  let items: BreadcrumbItem[] = props.items

  const itemsCount = items.length

  if (itemsCount > 6 || (width < MIN_SCREEN_SIZE && itemsCount > 4)) {
    items = [items[0], { label: '...' }, items[itemsCount - 2], items[itemsCount - 1]]
  }

  return (
    <div className="text-sm breadcrumbs">
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            {item.to
              ? <Link to={item.to} className="flex items-center gap-1">{item.icon} {item.label}</Link>
              : <span className="flex items-center gap-1">{item.icon} {item.label}</span>}
          </li>
        ))}
      </ul>
    </div>
  )
}

export const Breadcrumbs = memo(_Breadcrumbs)
