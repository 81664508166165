import { useMutation, useQuery } from '@tanstack/react-query'
import { GetWorkspaceUserContactsResultData } from '@indigohive/cogfy-types/endpoints/getWorkspaceUserContacts'
import { useTranslation } from 'react-i18next'
import { Info, Trash2 } from 'lucide-react'
import { useState } from 'react'
import { CreateWorkspaceUserContactDialog } from './CreateWorkspaceUserContactDialog'
import { useCogfy } from '../../../hooks'
import { ConfirmDeleteDialog } from '../../../components'
import { DeleteWorkspaceUserContactCommand } from '@indigohive/cogfy-types/endpoints/deleteWorkspaceUserContact'

export function WorkspaceUserContactsTable () {
  const { t } = useTranslation()
  const cogfy = useCogfy()
  const [contactToDelete, setContactToDelete] = useState<GetWorkspaceUserContactsResultData | null>(null)

  const getUserContacts = useQuery({
    queryKey: ['getWorkspaceUserContacts', {}] as const,
    queryFn: ({ queryKey, signal }) => cogfy.getWorkspaceUserContacts(queryKey[1], { signal })
  })
  const deleteUserContact = useMutation({
    mutationFn: async (data: DeleteWorkspaceUserContactCommand) => {
      await cogfy.deleteWorkspaceUserContact(data)
      getUserContacts.refetch()
    }
  })

  const contacts = getUserContacts.data?.data

  return (
    <div className='flex flex-col gap-6'>
      <div className="flex">
        <h2 className="grow font-bold flex items-center gap-2">
          {t('Contacts')}
          <div className="tooltip" data-tip={t('assistantPage:Add contacts to allow the assistant to share collection information with the right people. Only added contacts will receive responses from the assistant.')}>
            <Info size={20} />
          </div>
        </h2>
        <CreateWorkspaceUserContactDialog
          onSuccess={() => { getUserContacts.refetch() }}
        />
      </div>
      <div className='flex flex-col gap-2'>
        {(!contacts || contacts?.length === 0) && (<span>{t('No contacts found')}</span>)}
        {contacts && contacts.length > 0 && contacts.map(contact => (
          <div
            key={contact.id}
            className='flex justify-between items-center px-4 h-16 mx-6 rounded-lg border border-border-default bg-white'
          >
            <div className="flex items-center gap-4">
              {contact.user && (
                <span className="text-sm max-w-32 truncate">
                  {contact.user.name}
                </span>
              )}
              {contact.user === null && (
                <span className="opacity-40 italic text-sm">
                  {t('WorkspaceAssistantPage:User not set')}
                </span>
              )}
              <span className="text-sm">{contact.value}</span>
            </div>
            <button
              className={'hover:border hover:rounded-lg hover:bg-background-default w-7 h-7 flex items-center justify-center'}
              onClick={() => setContactToDelete(contact)}
            >
              <Trash2 size={16} />
            </button>
          </div>
        ))}
      </div>
      <ConfirmDeleteDialog
        title={t('assistantPage:Delete contact?')}
        open={contactToDelete !== null}
        onClose={() => setContactToDelete(null)}
        onConfirm={() => {
          deleteUserContact.mutate({ userContactId: contactToDelete!.id })
          setContactToDelete(null)
        }}
      />
    </div>
  )
}
