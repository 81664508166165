import { CogfyClient } from '@indigohive/cogfy-api-client'
import { FileFieldData, UUID } from '@indigohive/cogfy-types'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { validFile } from '../../../../../helpers'
import { useToasts } from '../../../../../hooks'

export function useUpload (
  cogfy: CogfyClient,
  params: {
    data?: FileFieldData | null
    collectionId: UUID
    fieldId: UUID
    recordId: UUID
    onProgress: (progress: number | null) => void
  }
) {
  const toast = useToasts()
  const { t } = useTranslation()

  return useMutation({
    mutationFn: async (file: File) => {
      const error = validFile(params.data, file)

      if (error) return toast.error(t(error))

      const result = await cogfy.files.upload({
        collectionId: params.collectionId,
        fieldId: params.fieldId,
        recordId: params.recordId,
        type: file.type,
        size: file.size,
        name: file.name
      })

      await axios.post(
        result.signedUrl,
        { ...result.fields, file },
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (event) => {
            if (event.total) {
              const progress = Math.ceil(Math.round(event.loaded * 100) / event.total)
              params.onProgress(progress)
            }
          }
        }
      )

      await cogfy.files.finishUpload(result.id)

      params.onProgress(null)
    },
    onError: () => toast.error(t('Error on uploading document'))
  })
}
