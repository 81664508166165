import { useEffect, useRef } from 'react'
import { Button } from '../Button'
import { useTranslation } from 'react-i18next'

export type ConfirmDeleteDialogProps = {
  title: string
  open: boolean
  message?: string
  onClose: () => void
  onConfirm: () => void
}

export function ConfirmDeleteDialog (props: ConfirmDeleteDialogProps) {
  const { title, open, message, onClose, onConfirm } = props
  const ref = useRef<HTMLDialogElement>(null)
  const { t } = useTranslation()

  useEffect(() => {
    if (open) {
      ref.current?.showModal()
    } else {
      ref.current?.close()
    }
  }, [open])

  useEffect(() => {
    const dialog = ref.current

    dialog?.addEventListener('close', onClose)

    return () => {
      dialog?.removeEventListener('close', onClose)
    }
  }, [])

  return (
    <dialog ref={ref} className="modal">
      <div className="modal-box">
        <form method="dialog">
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
        </form>
        <div>
          <h2 className="text-1xl font-bold mb-4">{title}</h2>
          <p className="text-sm text-gray-700 mb-6">
            {message ?? t('confirmDeleteDialog:This action is irreversible and you will lose all of your data.')}
          </p>
          <div className="flex gap-4 justify-end">
            <Button
              size="sm"
              onClick={onClose}
            >
              {t('Cancel')}
            </Button>
            <Button
              size="sm"
              className="bg-red-200 border-red-200 hover:bg-red-300 hover:border-red-300"
              onClick={onConfirm}
            >
              {t('Confirm')}
            </Button>
          </div>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  )
}
