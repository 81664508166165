import { FolderIcon, TableIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Menu } from '../../../Menu'

export type DrawerCreateCollectionMenuProps = {
  workspace?: string
  onCreateCollectionClick?: () => void
  onCreateFolderClick?: () => void
}

export function DrawerCreateCollectionMenu (props: DrawerCreateCollectionMenuProps) {
  const { workspace, onCreateCollectionClick, onCreateFolderClick } = props

  const { t } = useTranslation()

  return (
    <Menu>
      <li>
        <a onClick={() => onCreateFolderClick?.()}>
          <FolderIcon size={20} />
          {t('drawerCollectionsMenu:New folder')}
        </a>
      </li>
      <li>
        <Link
          to={`/${workspace}/templates`}
          onClick={() => onCreateCollectionClick?.()}
        >
          <TableIcon size={20} />
          {t('drawerCollectionsMenu:New collection')}
        </Link>
      </li>
    </Menu>
  )
}
