import type {
  CreateWorkspaceCommand,
  CreateWorkspaceResult,
  GetWorkspaceResult,
  GetWorkspacesListResult,
  SetWorkspaceLogoCommand,
  SetWorkspaceThemeCommand,
  UpdateWorkspaceLocaleCommand,
  UpdateWorkspaceRequire2FACommand,
  WorkspaceLogoUpdatedEvent,
  WorkspaceThemeUpdatedEvent
} from '@indigohive/cogfy-types'
import { SocketEvent } from '../socket'
import { BaseClient, BaseClientOptions } from './base-client'

export class WorkspacesClient extends BaseClient {
  onWorkspaceLogoUpdated: SocketEvent<WorkspaceLogoUpdatedEvent>
  onWorkspaceThemeUpdated: SocketEvent<WorkspaceThemeUpdatedEvent>

  constructor (options: BaseClientOptions) {
    super(options)

    this.onWorkspaceLogoUpdated = new SocketEvent(this.socket, 'workspace_logo_updated')
    this.onWorkspaceThemeUpdated = new SocketEvent(this.socket, 'workspace_theme_updated')
  }

  /**
   * Calls the `POST /workspaces` endpoint.
   * @param data The request body.
   * @returns The response body.
   */
  async create (
    data: CreateWorkspaceCommand
  ): Promise<CreateWorkspaceResult> {
    return (await this.axios.post('/workspaces', data)).data
  }


  /**
   * Calls the `GET /workspaces/slug/{slug}` endpoint.
   * @param slug The workspace slug.
   * @param signal The abort signal.
   * @returns The response body.
   */
  async getBySlug (
    slug: string,
    signal?: AbortSignal
  ): Promise<GetWorkspaceResult> {
    return (await this.axios.get(`/workspaces/slug/${slug}`, { signal })).data
  }

  /**
   * Calls the `GET /workspaces` endpoint.
   * @param signal The abort signal.
   * @returns The response body.
   */
  async getList (
    signal?: AbortSignal
  ): Promise<GetWorkspacesListResult> {
    return (await this.axios.get('/workspaces', { signal })).data
  }

  /**
   * Calls the `PATCH /workspaces/locale` endpoint.
   * @param data The request body.
   * @param signal The abort signal.
   */
  async updateLocale (
    data: UpdateWorkspaceLocaleCommand,
    signal?: AbortSignal
  ): Promise<void> {
    await this.axios.patch('/workspaces/locale', data, { signal })
  }

  /**
   * Calls the `PATCH /workspaces/two-factor-authentication` endpoint.
   * @param data The request body.
   * @param signal The abort signal.
   */
  async updateRequire2FA (
    data: UpdateWorkspaceRequire2FACommand,
    signal?: AbortSignal
  ): Promise<void> {
    await this.axios.patch('/workspaces/two-factor-authentication', data, { signal })
  }

  /**
   * Calls the `PATCH /workspaces/logo` endpoint.
   * @param data The request body.
   * @returns The response body.
   */
  async setLogo (
    data: SetWorkspaceLogoCommand,
    signal?: AbortSignal
  ): Promise<void> {
    await this.axios.patch('/workspaces/logo', data, { signal })
  }

  /**
   * Calls the `PATCH /workspaces/theme` endpoint.
   * @param data The request body.
   * @returns The response body.
   */
  async setTheme (
    data: SetWorkspaceThemeCommand,
    signal?: AbortSignal
  ): Promise<void> {
    await this.axios.patch('/workspaces/theme', data, { signal })
  }
}
