import { JsonRecordProperty } from '@indigohive/cogfy-types'
import { FieldOverlayProps } from '../FieldOverlay'
import { DEFAULT_FIELD_WIDTH, MIN_FIELD_WIDTH } from '../../../pages/CollectionPageV2/components'
import { Textarea } from '../../../components'
import { useState } from 'react'

const VERTICAL_MARGIN = 160

export function JsonOverlay (props: FieldOverlayProps) {
  const { record, field, viewField, element, controller } = props

  const [currentInvalidValue, setCurrentInvalidValue] = useState((record.properties[field.id] as JsonRecordProperty)?.json?.invalidValue)

  const currentValue = (record.properties[field.id] as JsonRecordProperty)?.json?.value

  const elementRect = element.getBoundingClientRect()

  const height = Math.min(elementRect.height, window.innerHeight - VERTICAL_MARGIN)
  const width = Math.max(viewField.config?.ui?.width ?? DEFAULT_FIELD_WIDTH, MIN_FIELD_WIDTH)

  const onBlur = (newValue: string) => {
    if (JSON.stringify(currentValue) !== newValue || JSON.stringify(currentInvalidValue) !== newValue) {
      const _isJson = isJson(newValue)
      const jsonProperty: Partial<JsonRecordProperty['json']> = {}

      if (_isJson) {
        jsonProperty.value = JSON.parse(newValue)
      } else {
        jsonProperty.value = currentValue
        jsonProperty.invalidValue = newValue
      }

      controller.onUpdateRecordProperties(
        record.id,
        {
          [field.id]: {
            type: 'json',
            json: {
              value: jsonProperty.value,
              invalidValue: jsonProperty.invalidValue
            }
          }
        }
      )
    }

    props.onClose('blur')
  }

  return (
    <Textarea
      size="sm"
      style={{ height, width, resize: 'both' }}
      error={currentInvalidValue && !isJson(currentInvalidValue)}
      autoFocus
      onFocus={event => { event.currentTarget.value = currentInvalidValue ?? JSON.stringify(currentValue) }}
      onBlur={event => onBlur(event.target.value)}
      onKeyDown={event => {
        if (event.key === 'Escape' || (event.key === 'Enter' && !event.shiftKey) || event.key === 'Tab') {
          if (event.key === 'Tab') {
            event.preventDefault()
          }

          props.onClose(event.key)
        }
      }}
      onChange={event => setCurrentInvalidValue(event.target.value)}
    />
  )
}

function isJson (value: string) {
  try {
    JSON.parse(value)
    return true
  } catch {
    return false
  }
}
