import OtpInput from 'react-otp-input'

export type OneTimePasswordInputProps = {
  value: string
  onChange: (value: string) => void
}

export function OneTimePasswordInput (props: OneTimePasswordInputProps) {
  return (
    <OtpInput
      value={props.value}
      onChange={props.onChange}
      numInputs={6}
      inputStyle="input input-bordered w-12 text-xl text-center mx-1"
      renderInput={({ style, ...props }) => <input {...props} />}
    />
  )
}
