import { GetApiKeysPageQuery } from '@indigohive/cogfy-types'
import { useCogfy } from './use-cogfy'
import { useQuery } from '@tanstack/react-query'

export function useApiKeysPage (params: GetApiKeysPageQuery = {}) {
  const cogfy = useCogfy()

  const getApiKeysPage = useQuery({
    queryKey: ['getApiKeysPage', params],
    queryFn: ({ signal }) => cogfy.apiKeys.getPage(params, signal)
  })

  return getApiKeysPage
}
