import { RunTransactionCommand, ViewFieldConfig, UUID } from '@indigohive/cogfy-types'
import merge from 'deepmerge'
import { Command } from '../command-stack'
import { CollectionState } from '../collection-state'

export type UpdateViewFieldConfigCommandData = {
  viewFieldId: UUID
  config: ViewFieldConfig
}

export class UpdateViewFieldConfigCommand implements Command<RunTransactionCommand> {
  private readonly _state: CollectionState
  private readonly _data: UpdateViewFieldConfigCommandData
  private readonly _previousViewFieldConfig: ViewFieldConfig | null

  constructor (
    state: CollectionState,
    data: UpdateViewFieldConfigCommandData
  ) {
    this._state = state
    this._data = data
    this._previousViewFieldConfig = this._state.viewFields?.find(viewField => viewField.id === this._data.viewFieldId)?.config ?? null
  }

  run (): RunTransactionCommand {
    const newViewFieldConfig = merge(this._previousViewFieldConfig ?? {}, this._data.config)

    const viewFields = this._state.viewFields?.map(viewField => {
      if (viewField.id === this._data.viewFieldId) {
        return {
          ...viewField,
          config: newViewFieldConfig
        }
      }

      return viewField
    })

    this._state.setViewFields(viewFields ?? [])

    return {
      operations: [
        {
          type: 'update_view_field_config',
          data: {
            collectionId: this._state.id,
            viewFieldId: this._data.viewFieldId,
            config: newViewFieldConfig
          }
        }
      ]
    }
  }

  undo (): RunTransactionCommand {
    const viewFields = this._state.viewFields?.map(viewField => {
      if (viewField.id === this._data.viewFieldId) {
        return {
          ...viewField,
          config: this._previousViewFieldConfig
        }
      }

      return viewField
    })

    this._state.setViewFields(viewFields ?? [])

    return {
      operations: [
        {
          type: 'update_view_field_config',
          data: {
            collectionId: this._state.id,
            viewFieldId: this._data.viewFieldId,
            config: this._previousViewFieldConfig ?? {}
          }
        }
      ]
    }
  }
}
