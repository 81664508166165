import type {
  FieldData,
  FieldType,
  RecordProperty,
  ViewFieldConfig,
  UUID,
  RecordOrder,
  AndFilter,
  RecordFilter,
  FieldOperationConfig,
  RecalculateStrategy
} from '@indigohive/cogfy-types'
import { AppManager, CollectionState, CollectionStateField, CollectionStateRecord, CollectionStateView, CollectionStateViewField } from '../../lib'
import { copyFieldValues } from '../../field-types'

function clamp (min: number, max: number, value: number): number {
  return Math.min(Math.max(min, value), max)
}

export class CollectionPageController {
  private readonly state: CollectionState
  private readonly manager: AppManager

  constructor (
    state: CollectionState,
    manager: AppManager
  ) {
    this.state = state
    this.manager = manager

    this.onCellClick = this.onCellClick.bind(this)
    this.onCopy = this.onCopy.bind(this)
    this.onDeleteTabClick = this.onDeleteTabClick.bind(this)
    this.onHeadCheckboxChange = this.onHeadCheckboxChange.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.onNewRowClick = this.onNewRowClick.bind(this)
    this.onNewViewClick = this.onNewViewClick.bind(this)
    this.onRenameViewClick = this.onRenameViewClick.bind(this)
    this.onRowCheckboxChange = this.onRowCheckboxChange.bind(this)
    this.onTabClick = this.onTabClick.bind(this)
  }

  onAddFieldClick (fieldType: FieldType, viewId: UUID) {
    this.manager.createField(this.state, { fieldType, viewId })
  }

  onAddViewFilterClick (field: CollectionStateField) {
    const currentView = this.state.views?.find(view => view.id === this.state.activeViewId)
    const currentFilter: AndFilter = currentView?.filter as AndFilter ?? { and: [] }
    const newFilter = { and: [...currentFilter.and, { property: field.id }] }

    this.manager.updateViewFilter(this.state, { filter: newFilter })
  }

  onBlur (_event: React.FocusEvent<HTMLDivElement, Element>) {
    this.state.setSelectedCell(null)
    this.state.setSelectedRange(null)
  }

  onCellClick (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    record: CollectionStateRecord,
    field: CollectionStateField,
    viewField: CollectionStateViewField,
    row: number,
    col: number
  ) {
    const element = event.currentTarget

    this.state.setClickedCell({ element, record, field, viewField, row, col })
    this.state.setSelectedCell(null)
    this.state.setSelectedRange(null)
    this.state.setSelectedRows({})
  }

  onCloseEditFieldDrawer () {
    this.state.setSelectedFieldToUpdate(null)
  }

  onCloseEditFieldMenu () {
    this.state.setSelectedField(null)
  }

  onCloseOverlay (reason: 'Escape' | 'Tab' | 'Enter' | 'blur' | null) {
    // TODO: Improve with event to move selection
    if (this.state.clickedCell) {
      const { row, col } = this.state.clickedCell
      this.state.setSelectedCell({ row, col })
      this.state.setSelectedRange({
        start: { row, col },
        end: { row, col }
      })

      if (reason === 'Enter') {
        this.moveCellSelection(1, 0)
      } else if (reason === 'Tab') {
        this.moveCellSelection(0, 1)
      }
      this.state.setClickedCell(null)
    }
  }

  onCopy (event: ClipboardEvent): void {
    copyFieldValues(event, this.state)
  }

  onCreateRecordReferenceClick (recordId: UUID, fieldId: UUID, referencedRecordId: UUID, title: string | null) {
    this.manager.createRecordReference(this.state, { recordId, fieldId, referencedRecordId, title })
  }

  onDeleteFieldClick (fieldId: UUID) {
    this.manager.deleteField(this.state, { fieldId })
    this.state.setSelectedField(null)
  }

  onDeleteRecordReferenceClick (recordId: UUID, fieldId: UUID, recordReferenceId: UUID) {
    this.manager.deleteRecordReference(this.state, { recordId, fieldId, recordReferenceId })
  }

  onDeleteRowsClick (recordIds: UUID[]) {
    this.manager.deleteRecord(this.state, { recordIds })
  }

  onDeleteSortField (fieldId: UUID) {
    const currentView = this.state.views?.find(view => view.id === this.state.activeViewId)
    const newViewOrderBy = currentView?.orderBy?.filter(currentOrderBy => currentOrderBy.fieldId !== fieldId)

    this.manager.updateViewOrderBy(this.state, { orderBy: newViewOrderBy ?? null })
  }

  onDeleteTabClick (view: CollectionStateView) {
    this.manager.deleteView(this.state, { viewId: view.id })
  }

  onDeleteViewFilterClick (fieldId: UUID) {
    const currentView = this.state.views?.find(view => view.id === this.state.activeViewId)
    const currentFilter: AndFilter = currentView?.filter as AndFilter ?? { and: [] }
    const newFilter = {
      and: currentFilter.and.filter(prevFilter => (prevFilter as any)?.property !== fieldId)
    }

    this.manager.updateViewFilter(this.state, { filter: newFilter })
  }

  onDropViewField (viewField: CollectionStateViewField, droppedOn: CollectionStateViewField) {
    if (!this.state.viewFields || viewField.id === droppedOn.id) {
      return
    }

    const index = this.state.viewFields.findIndex(field => field.id === viewField.id)
    const droppedIndex = this.state.viewFields.findIndex(field => field.id === droppedOn.id)

    if (index === -1 || droppedIndex === -1) {
      return
    }

    if (index > droppedIndex) {
      this.manager.reorderViewFieldBefore(this.state, { viewField, before: droppedOn })
    } else {
      this.manager.reorderViewFieldAfter(this.state, { viewField, after: droppedOn })
    }
  }

  onDuplicateFieldClick (fieldToDuplicateId: UUID) {
    this.manager.duplicateField(this.state, { fieldToDuplicateId })
  }

  onEditFieldClick (field: CollectionStateField) {
    this.state.setSelectedField(null)
    this.state.setSelectedFieldToUpdate(field)
  }

  onFieldClick (event: React.MouseEvent<HTMLDivElement>, field: CollectionStateField): void {
    this.state.setSelectedField({
      element: event.currentTarget,
      field
    })
  }

  onFieldRenamed (field: CollectionStateField, name: string): void {
    this.manager.renameField(this.state, { field, name })
  }

  onHeadCheckboxChange (event: React.ChangeEvent<HTMLInputElement>) {
    if (!this.state.records) {
      return
    }

    if (event.currentTarget.checked) {
      this.state.setSelectedRows(
        Object.fromEntries(
          new Map(this.state.records.map(record => [record.id, record]))
        )
      )
    } else {
      this.state.setSelectedRows({})
    }
  }

  onHideField (fieldId: UUID): void {
    this.manager.deleteViewField(this.state, { fieldId })
    this.state.setSelectedField(null)
  }

  onKeyDown (event: React.KeyboardEvent): void {
    if (event.key === 'ArrowDown') {
      this.onArrowDown(event)
    } else if (event.key === 'ArrowLeft') {
      this.onArrowLeft(event)
    } else if (event.key === 'ArrowRight') {
      this.onArrowRight(event)
    } else if (event.key === 'ArrowUp') {
      this.onArrowUp(event)
    } else if (event.key === 'Enter') {
      this.onEnter(event)
    } else if (event.key === 'Escape') {
      this.onEscape(event)
    } else if (event.key === 'Tab') {
      this.onTab(event)
    }
  }

  onNewRowClick () {
    this.manager.createRecord(this.state)
  }

  onNewViewClick () {
    this.manager.createView(this.state)
  }

  onOperationChange (
    fieldId: UUID,
    operation: string | null,
    recalculateStrategy: RecalculateStrategy,
    operationConfig: FieldOperationConfig | null
  ) {
    this.manager.updateFieldOperation(
      this.state,
      {
        fieldId,
        operation,
        recalculateStrategy,
        operationConfig
      }
    )
  }

  onPaginationChange () {
    this.state.setSelectedRows({})
  }

  onPaste (event: ClipboardEvent): void {
    this.manager.paste(this.state, event.clipboardData!)
  }

  onRecalculateRecords () {
    this.state.setSelectedField(null)
  }

  onRenameViewClick (view: CollectionStateView) {
    const newName = prompt('New view name', view.name ?? '')
    this.manager.renameView(this.state, { id: view.id, name: newName })
  }

  onRowCheckboxChange (event: React.ChangeEvent<HTMLInputElement>, row: CollectionStateRecord) {
    const newSelectedRows = { ...this.state.selectedRows }

    if (event.currentTarget.checked) {
      newSelectedRows[row.id] = row
    } else {
      delete newSelectedRows[row.id]
    }

    this.state.setSelectedRows(newSelectedRows)
  }

  onSearchRecordsChanged (search: string | null) {
    this.manager.updateSearchRecords(this.state, { search })
  }

  onShowField (fieldId: UUID, viewId: UUID): void {
    this.manager.createViewField(this.state, { fieldId, viewId })
  }

  onSortField (orderBy: RecordOrder) {
    const currentViewOrderBy = this.state.views
      ?.find(view => view.id === this.state.activeViewId)?.orderBy
      ?.filter(currentOrderBy => currentOrderBy.fieldId !== orderBy.fieldId)
    const newViewOrderBy = currentViewOrderBy ? [...currentViewOrderBy, orderBy] : [orderBy]

    this.state.setSelectedField(null)
    this.manager.updateViewOrderBy(this.state, { orderBy: newViewOrderBy })
  }

  onTabClick (viewId: UUID) {
    if (this.state.views) {
      if (this.state.views.some(view => view.id === viewId)) {
        this.state.setActiveViewId(viewId)
        this.state.setSearchRecords(null)
      } else {
        this.state.setActiveViewId(this.state.views[0].id)
        this.state.setSearchRecords(null)
      }
    }
  }

  onUpdateCollectionTitleField (fieldId: UUID) {
    this.manager.updateCollectionTitleField(this.state, { fieldId })
    this.state.setSelectedField(null)
  }

  onUpdateFieldData (fieldId: UUID, data: FieldData) {
    this.manager.updateFieldData(this.state, { fieldId, data })
  }

  onUpdateSortField (fieldId: UUID, direction: 'asc' | 'desc') {
    const currentView = this.state.views?.find(view => view.id === this.state.activeViewId)
    const newViewOrderBy = currentView?.orderBy?.map(currentOrderBy => {
      if (currentOrderBy.fieldId === fieldId) {
        return { ...currentOrderBy, direction }
      }
      return currentOrderBy
    })

    this.manager.updateViewOrderBy(this.state, { orderBy: newViewOrderBy ?? null })
  }

  onUpdateRecordReferenceClick (
    recordId: UUID,
    fieldId: UUID,
    referencedRecordIdToCreate: UUID,
    referencedRecordIdToDelete: UUID,
    title: string | null
  ) {
    this.manager.updateRecordRefence(this.state, { recordId, fieldId, recordReferenceIdToCreate: referencedRecordIdToCreate, recordReferenceIdToDelete: referencedRecordIdToDelete, title })
  }

  onUpdateRecordProperties (recordId: UUID, properties: Record<UUID, RecordProperty | undefined>) {
    this.manager.updateRecordProperties(this.state, { recordId, properties })
  }

  onUpdateViewFieldConfig (viewFieldId: UUID, config: ViewFieldConfig) {
    this.manager.updateViewFieldConfig(this.state, { viewFieldId, config })
  }

  onViewFilterChange (filter: AndFilter, changedFilter: RecordFilter) {
    const index = filter.and.findIndex(prevFilter => (prevFilter as any).property === (changedFilter as any).property)
    const newFilter = index === -1
      ? { and: [...filter.and, changedFilter] }
      : { and: [...filter.and.slice(0, index), changedFilter, ...filter.and.slice(index + 1)] }

    this.manager.updateViewFilter(this.state, { filter: newFilter })
  }

  private moveCellSelection (rows: number, cols: number) {
    const state = this.state

    if (state.selectedCell) {
      const row = clamp(0, (state.records ?? []).length - 1, state.selectedCell.row + rows)
      const col = state.selectedCell.col + cols

      if (col >= (state.viewFields ?? []).length && row < (state.records ?? []).length - 1) {
        state.setSelectedCell({ row: row + 1, col: 0 })
        state.setSelectedRange({
          start: { row: row + 1, col: 0 },
          end: { row: row + 1, col: 0 }
        })
      } else if (col < 0 && row > 0) {
        state.setSelectedCell({ row: row - 1, col: (state.viewFields ?? []).length - 1 })
        state.setSelectedRange({
          start: { row: row - 1, col: (state.viewFields ?? []).length - 1 },
          end: { row: row - 1, col: (state.viewFields ?? []).length - 1 }
        })
      } else {
        state.setSelectedCell({ row, col: clamp(0, (state.viewFields ?? []).length - 1, col) })
        state.setSelectedRange({
          start: { row, col: clamp(0, (state.viewFields ?? []).length - 1, col) },
          end: { row, col: clamp(0, (state.viewFields ?? []).length - 1, col) }
        })
      }
    }
  }

  private moveRangeSelection (rows: number, cols: number) {
    if (!this.state.selectedRange || !this.state.selectedCell) {
      return
    }

    const cellIsInFirstRow = this.state.selectedCell.row === this.state.selectedRange.start.row
    const cellIsInFirstCol = this.state.selectedCell.col === this.state.selectedRange.start.col
    const cellIsInLastRow = this.state.selectedCell.row === this.state.selectedRange.end.row
    const cellIsInLastCol = this.state.selectedCell.col === this.state.selectedRange.end.col

    let startRow = this.state.selectedRange.start.row
    let startCol = this.state.selectedRange.start.col
    let endRow = this.state.selectedRange.end.row
    let endCol = this.state.selectedRange.end.col

    if (cellIsInFirstRow && cellIsInLastRow) {
      if (rows < 0) {
        startRow += rows
      } else {
        endRow += rows
      }
    } else if (cellIsInFirstRow) {
      endRow += rows
    } else {
      startRow += rows
    }

    if (cellIsInFirstCol && cellIsInLastCol) {
      if (cols < 0) {
        startCol += cols
      } else {
        endCol += cols
      }
    } else if (cellIsInFirstCol) {
      endCol += cols
    } else {
      startCol += cols
    }

    startRow = clamp(0, (this.state.records ?? []).length - 1, startRow)
    startCol = clamp(0, (this.state.viewFields ?? []).length - 1, startCol)
    endRow = clamp(0, (this.state.records ?? []).length - 1, endRow)
    endCol = clamp(0, (this.state.viewFields ?? []).length - 1, endCol)

    this.state.setSelectedRange({
      start: { row: startRow, col: startCol },
      end: { row: endRow, col: endCol }
    })
  }

  private onArrowDown (event: React.KeyboardEvent) {
    if (event.shiftKey) {
      this.moveRangeSelection(1, 0)
    } else {
      this.moveCellSelection(1, 0)
    }
  }

  private onArrowLeft (event: React.KeyboardEvent): void {
    if (event.shiftKey) {
      this.moveRangeSelection(0, -1)
    } else {
      this.moveCellSelection(0, -1)
    }
  }

  private onArrowRight (event: React.KeyboardEvent): void {
    if (event.shiftKey) {
      this.moveRangeSelection(0, 1)
    } else {
      this.moveCellSelection(0, 1)
    }
  }

  private onArrowUp (event: React.KeyboardEvent) {
    if (event.shiftKey) {
      this.moveRangeSelection(-1, 0)
    } else {
      this.moveCellSelection(-1, 0)
    }
  }

  private onEnter (event: React.KeyboardEvent): void {
    if (!this.state.selectedCell) {
      return
    }

    const { row, col } = this.state.selectedCell

    const cellToEdit = document.querySelector<HTMLDivElement>(
      `[data-row-index="${row}"][data-col-index="${col}"]`
    )

    if (cellToEdit) {
      cellToEdit.click()
      event.preventDefault()
    }
  }

  private onEscape (event: React.KeyboardEvent): void {
    this.state.setSelectedCell(null)
    this.state.setSelectedRange(null)
    this.state.setSelectedRows({})
    event.preventDefault()
  }

  private onTab (event: React.KeyboardEvent): void {
    if (!this.state.selectedCell) {
      return
    }

    const selectedCell = this.state.selectedCell

    if (event.shiftKey) {
      const hasPreviousCol = selectedCell.col > 0
      const hasPreviousRow = selectedCell.row > 0

      if (hasPreviousCol) {
        const row = selectedCell.row
        const col = selectedCell.col - 1
        this.state.setSelectedCell({ row, col })
        this.state.setSelectedRange({
          start: { row, col },
          end: { row, col }
        })
      } else if (hasPreviousRow) {
        const row = selectedCell.row - 1
        const col = (this.state.viewFields ?? []).length - 1
        this.state.setSelectedCell({ row, col })
        this.state.setSelectedRange({
          start: { row, col },
          end: { row, col }
        })
      }
    } else {
      const hasNextCol = selectedCell.col < (this.state.viewFields ?? []).length - 1
      const hasNextRow = selectedCell.row < (this.state.records ?? []).length - 1

      if (hasNextCol) {
        const row = selectedCell.row
        const col = selectedCell.col + 1
        this.state.setSelectedCell({ row, col })
        this.state.setSelectedRange({
          start: { row, col },
          end: { row, col }
        })
      } else if (hasNextRow) {
        const row = selectedCell.row + 1
        const col = 0
        this.state.setSelectedCell({ row, col })
        this.state.setSelectedRange({
          start: { row, col },
          end: { row, col }
        })
      }
    }

    event.preventDefault()
  }
}
