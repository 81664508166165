import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App.tsx'
import './index.css'

Sentry.init({
  dsn: 'https://e8fdc61b720284b0b32119b66a9a4850@o1087479.ingest.us.sentry.io/4507052117393408',
  integrations: [],
  enabled: import.meta.env.MODE === 'production',
  environment: import.meta.env.MODE
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
