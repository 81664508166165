import {
  BookmarkIcon,
  CopyIcon,
  EyeOffIcon,
  TrashIcon,
  PencilIcon,
  RefreshCwIcon,
  UploadIcon
} from 'lucide-react'
import { UUID } from '@indigohive/cogfy-types'
import { useMutation } from '@tanstack/react-query'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Menu, OverlayContent } from '../../../../components'
import { CollectionState, useSelectedField, useSelectedRows } from '../../../../lib'
import { CollectionPageController } from '../../collection-page-controller'
import { FieldMenuSort } from '../../../../field-types'
import { useCogfy } from '../../../../hooks'
import { GetUserCollectionPermissionsResult } from '@indigohive/cogfy-types/endpoints/getUserCollectionPermissions'

export type EditFieldMenuProps = {
  state: CollectionState
  controller: CollectionPageController
  onUploadFiles?: (files: File[], fieldId: UUID) => void
  permissions?: GetUserCollectionPermissionsResult
}

export function EditFieldMenu (props: EditFieldMenuProps) {
  const { controller, state, onUploadFiles, permissions } = props

  const cogfy = useCogfy()
  const { t } = useTranslation()
  const selectedField = useSelectedField(state)
  const selectedRecords = useSelectedRows(state)

  const handleCalculateRecords = useMutation({
    mutationFn: async () => {
      const command = {
        fieldId: selectedField!.field.id,
        recordIds: Object.keys(selectedRecords) as UUID[]
      }

      controller.onRecalculateRecords()
      await cogfy.records.calculate(command)
    }
  })

  const recordRecalculationEnabled = Boolean(selectedField?.field.operation && Object.keys(selectedRecords).length > 0)
  const hasFullAccess = permissions?.type === 'full_access'

  return (
    <OverlayContent
      open={Boolean(selectedField)}
      anchorEl={selectedField?.element}
      anchorOrigin={{
        vertical: 'bottom'
      }}
      onClose={() => controller.onCloseEditFieldMenu()}
    >
      <Menu>
        {selectedField?.field.type === 'file' && (
          <li>
            <label>
              <input
                type="file"
                className="hidden"
                multiple
                onChange={({ target }) => {
                  if (target.files) {
                    onUploadFiles?.(Array.from(target.files), selectedField.field.id)
                  }
                }}
              />
              <UploadIcon size={16} />
              {t('collectionFieldMenu:Upload files')}
            </label>
          </li>
        )}
        {hasFullAccess && selectedField?.field.type === 'text' && (
          <li>
            <a onClick={() => controller.onUpdateCollectionTitleField(selectedField.field.id)}>
              <BookmarkIcon size={16} />
              {t('collectionFieldMenu:Set as title field')}
            </a>
          </li>
        )}
        {hasFullAccess && (
          <li>
            <a onClick={() => controller.onEditFieldClick(selectedField!.field)}>
              <PencilIcon size={16} />
              {t('common:Edit field')}
            </a>
          </li>
        )}
        {hasFullAccess && (
          <li>
            <a onClick={() => controller.onHideField(selectedField!.field.id)}>
              <EyeOffIcon size={16} />
              {t('collectionFieldMenu:Hide field')}
            </a>
          </li>
        )}
        {selectedField?.field && <FieldMenuSort field={selectedField.field} controller={controller} />}
        <li className={clsx(!recordRecalculationEnabled && 'disabled')}>
          <a onClick={() => { if (recordRecalculationEnabled) handleCalculateRecords.mutate() }}>
            <RefreshCwIcon size={16} />
            {t('collectionFieldMenu:Recalculate selected records')}
          </a>
        </li>
        {hasFullAccess && (
          <li>
            <a
              onClick={() => controller.onDuplicateFieldClick(selectedField!.field.id)}
            >
              <CopyIcon size={16} />
              {t('common:Duplicate')}
            </a>
          </li>
        )}
        {hasFullAccess && (
          <li className="hover:text-red-400">
            <a
              onClick={() => controller.onDeleteFieldClick(selectedField!.field.id)}
            >
              <TrashIcon size={16} />
              {t('common:Delete')}
            </a>
          </li>
        )}
      </Menu>
    </OverlayContent>
  )
}
