import { useMutation } from '@tanstack/react-query'
import { usePostHog } from 'posthog-js/react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAuthentication, useCogfy, useToasts } from '../../../../hooks'
import { applyWorkspaceTheme, DEFAULT_THEME_COLOR } from '../../../../helpers'
import { LogOutIcon } from 'lucide-react'
import { Button } from '../../../Button'

export function DrawerAuthenticatedUserView () {
  const authentication = useAuthentication()
  const cogfy = useCogfy()
  const navigate = useNavigate()
  const posthog = usePostHog()
  const toast = useToasts()
  const { t } = useTranslation()

  const signOut = useMutation({
    mutationFn: async () => {
      await cogfy.authentication.signOut()
    },
    onSuccess: () => {
      cogfy.disconnect()
      authentication.onAuthenticatedUserChange?.(null)
      posthog.reset()
      applyWorkspaceTheme(DEFAULT_THEME_COLOR)
      navigate('/sign-in')
    },
    onError: () => {
      toast.error('Failed to sign out')
    }
  })

  const name = authentication.authenticatedUser?.name
  const email = authentication.authenticatedUser?.email

  return (
    <div className="p-4 flex justify-between items-center">
      <div className="flex items-center">
        <div className="pr-4">
          <div className="avatar placeholder">
            <div className="bg-gray-500 text-neutral-content rounded-full w-8">
              <span className="text-xs">{name?.[0]}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <span className="text-sm">{name}</span>
          <span className="text-gray-400 text-xs text-ellipsis overflow-hidden max-w-40">{email}</span>
        </div>
      </div>
      <div className="tooltip tooltip-top group" data-tip={t('Sign out')}>
        <Button
          size="sm"
          square
          ghost
          onClick={() => signOut.mutate()}
        >
          <LogOutIcon size={20} className="text-error" />
        </Button>
      </div>
    </div>
  )
}
