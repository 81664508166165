import { FolderIcon, PlusIcon, TableIcon } from 'lucide-react'
import { GetCollectionResult } from '@indigohive/cogfy-types'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { useCollections, usePermissions, useUserEvent } from '../../hooks'

export type FolderCollectionPageProps = {
  collection: GetCollectionResult
}

export function FolderCollectionPage (props: FolderCollectionPageProps) {
  const { collection } = props
  const params = useParams<{ workspace: string }>()
  const workspace = params.workspace!
  const { t } = useTranslation()
  const permissions = usePermissions()
  const canCreateCollection = permissions.hasPermission?.('collection:create', null)
  const registerUserEvent = useUserEvent()

  const getCollectionChildren = useCollections({ parentId: collection.id })

  return (
    <div className="px-4">
      {getCollectionChildren.error && 'Error'}
      {getCollectionChildren.isLoading && <progress className="progress" />}
      <ul className="menu">
        {getCollectionChildren.data?.data.length === 0 && (
          <li>
            <Link to={`/${workspace}/templates?folder=${collection.id}`}>
              <PlusIcon size={20} />
              {canCreateCollection ? t('Create new collection') : t('No collections found')}
            </Link>
          </li>
        )}
        {getCollectionChildren.data && getCollectionChildren.data.data.length > 0 && (
          <>
            {getCollectionChildren.data.data.map(collection => (
              <li key={collection.id}>
                <Link
                  to={`/${workspace}/${collection.id}`}
                  onClick={() => {
                    registerUserEvent.mutate({
                      type: 'collection_selected',
                      data: {
                        collectionId: collection.id,
                        name: collection.name,
                        type: collection.type,
                        from: 'list'
                      }
                    })
                  }}
                >
                  {collection.type === 'database'
                    ? <TableIcon size={16} />
                    : <FolderIcon size={16} />}
                  {collection.name ?? t('Untitled')}
                </Link>
              </li>
            ))}
          </>
        )}
      </ul>
    </div>
  )
}
