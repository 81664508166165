import { useState } from 'react'
import { ChatMiddlewareDataFormProps } from '../../ChatMiddlewareDataFormProps'
import { useCollectionFields } from '../../../../hooks'
import { Select } from '../../../Select'

export function ActionFormSaveAudioToCogfyCollection (props: ChatMiddlewareDataFormProps) {
  const { data, onChange, collections = [] } = props

  const [collectionId, setCollectionId] = useState(data.collection?.id ?? '')
  const [fieldId, setFieldId] = useState(data.field?.id ?? '')
  const getCollectionFields = useCollectionFields(undefined, collectionId)
  const fields = getCollectionFields.data?.data.filter(f => f.type === 'file') ?? []

  return (
    <>
      <Select
        label="Collection"
        size="sm"
        options={[
          { label: 'Select a collection', value: '', disabled: true },
          ...collections.map(collection => ({ label: collection.name, value: collection.id }))
        ]}
        value={collectionId}
        onChange={event => {
          const value = event.target.value
          setCollectionId(value)
          setFieldId('')
          onChange({ ...data, collection: { id: value }, field: { id: '' } })
        }}
      />
      <Select
        label="Field"
        size="sm"
        options={[
          { label: 'Select a field', value: '', disabled: true },
          ...fields.map(field => ({ label: field.name, value: field.id }))
        ]}
        value={fieldId}
        onChange={event => {
          const value = event.target.value
          setFieldId(value)
          onChange({ ...data, field: { id: value } })
        }}
      />
    </>
  )
}
