import type {
  GetMemberNameByUserIdResult,
  GetMembersPageQuery,
  GetMembersPageResult,
  UpdateMemberRoleCommand,
  UUID,
} from '@indigohive/cogfy-types'
import { BaseClient } from './base-client'

export class MembersClient extends BaseClient {
  /**
   * Calls the `GET /members/:userId/name` endpoint.
   * @param userId The userId param.
   * @param signal The abort signal.
   * @returns The response body.
   */
  async getName (
    userId: UUID,
    signal?: AbortSignal
  ): Promise<GetMemberNameByUserIdResult> {
    return (await this.axios.get(`/members/${userId}/name`, { signal })).data
  }

  /**
   * Calls the `GET /members` endpoint.
   * @param params The request query params.
   * @param signal The abort signal.
   * @returns The response body.
   */
  async getPage (
    params: GetMembersPageQuery = {},
    signal?: AbortSignal
  ): Promise<GetMembersPageResult> {
    return (await this.axios.get('/members', { params, signal })).data
  }

  /**
   * Calls the `PATCH /members/:userId/role` endpoint.
   * @param userId The userId param.
   * @param data The request body.
   * @param signal The abort signal.
   */
  async updateRole (
    userId: UUID,
    data: UpdateMemberRoleCommand,
    signal?: AbortSignal
  ): Promise<void> {
    await this.axios.patch(`/members/${userId}/role`, data, { signal })
  }
}
