import { GetWorkspaceResult } from '@indigohive/cogfy-types'
import { WorkspaceLocale } from './WorkspaceLocale'
import { WorkspaceRequire2FA } from './WorkspaceRequire2FA'
import { useTranslation } from 'react-i18next'

export type WorkspaceProfileProps = {
  workspace: GetWorkspaceResult
}

export function WorkspaceProfile (props: WorkspaceProfileProps) {
  const { workspace } = props

  const { t } = useTranslation()

  return (
    <div className='flex flex-col gap-6'>
      <div className='flex flex-col gap-2'>
        <span className='font-bold'>{t('SettingsPages:Workspace name')}</span>
        <span className='w-full border rounded-lg p-3 text-sm border-gray-300 bg-background-default'>{workspace.name}</span>
      </div>
      <WorkspaceLocale />
      <WorkspaceRequire2FA workspace={workspace} />
    </div>
  )
}
