import type {
  CreateUserCommand,
  CreateUserResult,
  GetUsersPageQuery,
  GetUsersPageResult,
  GetUserByEmailResult
} from '@indigohive/cogfy-types'
import { BaseClient } from './base-client'

export class UsersClient extends BaseClient {
  /**
   * Calls the `POST /users` endpoint.
   * @param data The request body.
   * @returns The response body.
   */
  async create (
    data: CreateUserCommand
  ): Promise<CreateUserResult> {
    return (await this.axios.post('/users', data)).data
  }
  /**
   * Calls the `GET /users` endpoint.
   * @param params The request query params.
   * @param signal The abort signal.
   * @returns The response body.
   */
  async getPage (
    params: GetUsersPageQuery,
    signal?: AbortSignal
  ): Promise<GetUsersPageResult> {
    return (await this.axios.get('/users', { params, signal })).data
  }

  /**
   * Calls the `GET /users/email/:email endpoint`.
   * @param email The user email.
   * @returns The response body.
   */
  async getByEmail(
    email: string,
    signal?: AbortSignal
  ): Promise<GetUserByEmailResult> {
    return (await this.axios.get(`/users/email/${email}`, { signal })).data
  }
}
