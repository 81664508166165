import { useFormik } from 'formik'
import { Link, useSearchParams } from 'react-router-dom'
import { Button, Input } from '../../components'
import { useCogfy, useTitle } from '../../hooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

function isPasswordValid (password: string) {
  return password.length >= 8
}

export function ResetPasswordPage () {
  const cogfy = useCogfy()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const [error, setError] = useState<unknown | null>(null)
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      newPassword: ''
    },
    onSubmit: async values => {
      try {
        await cogfy.authentication.resetPassword({
          token: token!,
          newPassword: values.newPassword
        })
      } catch (err) {
        setError(err)
      }
    }
  })

  useTitle({
    title: t('resetPasswordPage:Reset password')
  })

  return (
    <div
      className="h-screen w-screen flex flex-col justify-center items-center bg-gray-100"
    >
      <div className="text-center w-96 border rounded-lg p-6 shadow-md bg-white">
        <h1 className="text-2xl font-bold text-primary">{t('resetPasswordPage:Reset password')}</h1>
        {token && (formik.isSubmitting || formik.submitCount === 0) && (
          <form
            onSubmit={formik.handleSubmit}
            className="text-center"
          >
            <Input
              label={t('resetPasswordPage:New password')}
              type="password"
              disabled={formik.isSubmitting}
              helperText="Must have at least 8 characters"
              {...formik.getFieldProps('newPassword')}
            />
            <Button
              disabled={formik.isSubmitting || !isPasswordValid(formik.values.newPassword)}
              type="submit"
              color="primary"
              className="w-full mt-4"
            >
              {t('Confirm')}
            </Button>
          </form>
        )}
        {!token && (
          <div className="text-center py-4">
            <p className="text-gray-500">
              {t('resetPasswordPage:Invalid link')}.
            </p>
          </div>
        )}
        {formik.submitCount > 0 && !formik.isSubmitting && (
          <div className="text-center py-4">
            {!error && (
              <p className="text-gray-500">
                {t('resetPasswordPage:Your password has been reset successfully')}.
              </p>
            )}
            {Boolean(error) && (
              <p className="text-error">
                {t('resetPasswordPage:Error resetting password')}.
              </p>
            )}
          </div>
        )}
        <div className="text-left pt-4">
          <Link className="link" to="/sign-in">
            {t('forgotPasswordPage:Back to sign in')}
          </Link>
        </div>
      </div>
    </div>
  )
}
